import api, { ENDPOINTS } from 'api';
import { CsosnDto } from 'model/types/fiscal.types';

const getFromCache = (): CsosnDto[] => {
  return JSON.parse(window.sessionStorage.getItem('cache-get-all-csosn') ?? '[]');
};

export async function obterTodosCsosn(): Promise<CsosnDto[]> {
  const cache = getFromCache();
  if (cache.length > 0) {
    return cache;
  }

  const { data } = await api.get<CsosnDto[]>(ENDPOINTS.CSOSN_LISTAR());

  window.sessionStorage.setItem('csonCache', JSON.stringify(data ?? []));

  return !!data ? data : ([] as CsosnDto[]);
}

export async function obterCsosn(id: string): Promise<CsosnDto | null> {
  const data = await obterTodosCsosn();
  return data.find(i => i.codigo === id) ?? null;
}
