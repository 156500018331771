import { toMoneyFormat } from 'common/formatters';
import { CupomItemDto, SituacaoCupom } from 'model/types/cupomfiscal.types';
import { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { ModalEditarItemCupom } from './CupomAlterarItemModal';

type CupomTabelaItemProps = {
  cupomId: string;
  itens: CupomItemDto[];
  podeEditar: boolean;
  onChanged: () => void;
};

export function CupomTabelaItem({ cupomId, itens, podeEditar, onChanged }: CupomTabelaItemProps) {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [itemSelecionado, setItemSelecionado] = useState<CupomItemDto>();

  const handleEditarItem = (item: CupomItemDto) => {
    setItemSelecionado(item);
    setIsOpenModal(true);
  };

  const handleChanged = () => {
    setIsOpenModal(false);
    onChanged();
  };

  return (
    <>
      {itemSelecionado && (
        <ModalEditarItemCupom
          item={itemSelecionado}
          idCupomFiscal={cupomId}
          podeEditar={podeEditar}
          isOpen={isOpenModal}
          onToggle={setIsOpenModal}
          onChanged={handleChanged}
        />
      )}

      <Card>
        <CardHeader className="bg-transparent border-bottom text-uppercase">Itens / Produtos</CardHeader>
        <CardBody>
          <Row xs={1}>
            <Col className="table-responsive">
              <Table className="table table-hover table-striped table-condensed">
                <thead className="table-light">
                  <tr>
                    <th>Num.</th>
                    <th className="text-wrap">Produto Vendido</th>
                    <th className="text-end">Total Item</th>
                    <th className="text-center">NCM</th>
                    <th className="text-center">CFOP</th>
                    <th className="text-center">CSOSN</th>
                    <th className="text-end col-sm-1"></th>
                  </tr>
                </thead>
                <tbody>
                  {itens
                    .sort((a, b) => a.numero - b.numero)
                    .map(item => (
                      <tr key={item.id}>
                        <td valign="middle">{item.numero.toString().padStart(3, '0')}</td>
                        <td valign="middle">{item.descricao}</td>
                        <td valign="middle" align="right">
                          {toMoneyFormat(item.totalItem)}
                        </td>
                        <td valign="middle" align="center">
                          {item.ncm}
                        </td>
                        <td valign="middle" align="center">
                          {item.cfop}
                        </td>
                        <td valign="middle" align="center">
                          {item.csosn}
                        </td>
                        <td valign="middle" align="right">
                          <button type="button" onClick={() => handleEditarItem(item)} className="btn btn-link p-0">
                            Editar
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
