import { SpinnerButton } from 'components/Button/SpinnerButton';
import { InputDecimal } from 'components/Form/InputDecimal';
import { SelectCsosn } from 'components/Form/SelectCsosn';
import { SelectPisCofins } from 'components/Form/SelectPisCofins';
import { useFormTabOnEnter } from 'hooks/useFormTabOnEnter';
import { Controller, useForm } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Label, ModalFooter, Form } from 'reactstrap';
import { toDecimal } from 'common/decimals';
import { showToastErrors, showToastSuccess } from 'common/toast_config';
import { AddRegraFiscalCommand } from 'model/types/produto.types';
import { adicionarRegraFiscal } from 'services/produtoService';
import { useRef } from 'react';

type ProdutoRegraFiscalModalProps = {
  produtoId: string;
  isOpen: boolean;
  handleClose: () => void;
  onSuccess: () => void;
};

type FormData = {
  csosn: string;
  pisCofins: string;
  aliquotaPis: string;
  aliquotaCofins: string;
};

const formDataDefault: FormData = {
  csosn: '',
  pisCofins: '',
  aliquotaPis: '',
  aliquotaCofins: '',
};

export function ProdutoRegraFiscalModal({ produtoId, isOpen, handleClose, onSuccess }: ProdutoRegraFiscalModalProps) {
  const { handleOnKeyDown } = useFormTabOnEnter();
  const inputCsosnRef = useRef<any>();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = useForm<FormData>({ defaultValues: formDataDefault });

  const handleToggle = () => {
    !isSubmitting && isOpen && handleClose();
  };

  const handleOpened = () => {
    reset(formDataDefault);
    inputCsosnRef.current.focus();
  };

  const handleFormSubmit = async (formData: FormData) => {
    try {
      const command: AddRegraFiscalCommand = {
        codigoCsosn: formData.csosn,
        codigoPisCofins: formData.pisCofins,
        aliquotaPis: toDecimal(formData.aliquotaPis),
        aliquotaCofins: toDecimal(formData.aliquotaCofins),
      };

      await adicionarRegraFiscal(produtoId, command);
      showToastSuccess('Regra fiscal adicionada com sucesso!');
      onSuccess();
    } catch (error: any) {
      showToastErrors(error);
    }
  };

  return (
    <Modal
      size="xl"
      className="modal-fullscreen-lg-down"
      toggle={handleToggle}
      backdrop={true}
      onOpened={handleOpened}
      isOpen={isOpen}
    >
      <Form onSubmit={handleSubmit(handleFormSubmit)} onKeyDown={handleOnKeyDown}>
        <ModalHeader toggle={handleToggle}>Produto - Regras Fiscais</ModalHeader>
        <ModalBody className="overflow-visible">
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label>Código de Situação da Operação do Simples Nacional (CSOSN)</Label>
                <Controller
                  control={control}
                  name="csosn"
                  render={({ field }) => <SelectCsosn {...field} ref={inputCsosnRef} />}
                />
              </FormGroup>
            </Col>

            <Col sm={6}>
              <FormGroup>
                <Label>Situação Tributária PIS e COFINS</Label>
                <Controller control={control} name="pisCofins" render={({ field }) => <SelectPisCofins {...field} />} />
              </FormGroup>
            </Col>

            <Col sm={3}>
              <FormGroup>
                <Label className="text-nowrap">Alíquota do PIS</Label>
                <InputDecimal {...register('aliquotaPis')} />
              </FormGroup>
            </Col>

            <Col sm={3}>
              <FormGroup>
                <Label className="text-nowrap">Alíquota do COFINS</Label>
                <InputDecimal {...register('aliquotaCofins')} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <SpinnerButton type="submit" loading={isSubmitting} color="primary">
            {'Adicionar regra'}
          </SpinnerButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
