import { useState, useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PageContent } from 'components/Common/PageContent';
import { AddItemVendaModal } from 'components/Vendas/AddItemVendaModal';
import { ConfirmButton } from 'components/Button/ConfirmButton';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import { decimalRound } from 'common/decimals';
import { toMoneyFormat } from 'common/formatters';
import { TotaisVenda } from './TotaisVenda';
import { PageFooter } from 'components/Common/PageFooter';
import { Pagamentos } from './Pagamentos';
import { showToastErrors } from 'common/toast_config';
import { CancelarVendaModal } from 'components/Vendas/CancelarVendaModal';
import { FinalizarVendaModal } from 'components/Vendas/FinalizarVendaModal';

// models
import { VendaDto, VendaSituacao } from 'model/types/venda.types';
import { addCpfCnpjMask } from 'common/mask';
import { EmitirCupomVendaModal } from 'components/Vendas/EmitirCupomVendaModal';

import {
  alterarTotalVenda,
  informarClienteNaVenda,
  obterVenda,
  removerClienteVenda,
  removerItemVenda,
} from 'services/vendaService';
import { ROUTES } from 'config/routes';
import { AddCliente } from './AddCliente';
import { EmitirNotaVendaModal } from 'components/Vendas/EmitirNotaVendaModal';

interface ParamsData {
  id: string;
}

export function Venda() {
  const { id } = useParams<ParamsData>();
  const [venda, setVenda] = useState<VendaDto>({} as VendaDto);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [showAddItem, setShowAddItem] = useState(false);
  const [showAddCliente, setShowAddCliente] = useState<boolean>(false);
  const [showCancelarVenda, setShowCancelarVenda] = useState<boolean>(false);
  const [showFinalizarVenda, setShowFinalizarVenda] = useState<boolean>(false);
  const [showEmitirCupom, setShowEmitirCupom] = useState<boolean>(false);
  const [showEmitirNota, setShowEmitirNota] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const carregarVenda = useCallback(async () => {
    const { data } = await obterVenda(id);

    if (data) {
      setVenda(data);
      setCanEdit(data.situacao === VendaSituacao.Aberta);
      setShowAddCliente(false);
    }
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        await carregarVenda();
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [carregarVenda, id]);

  async function handleOnClickRemoverCliente() {
    setIsLoading(true);

    try {
      await removerClienteVenda(id);
      await carregarVenda();
    } catch (error: any) {
      showToastErrors(error?.messages);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleAdicionarCliente(clienteId: string) {
    try {
      setIsLoading(true);
      await informarClienteNaVenda(id, clienteId);
      await carregarVenda();
    } catch (error: any) {
      showToastErrors(error?.messages);
    } finally {
      setIsLoading(false);
    }
  }

  async function removerItemClickHandler(itemId: string) {
    try {
      setIsLoading(true);
      await removerItemVenda(id, itemId);
      await carregarVenda();
    } catch (error: any) {
      showToastErrors(error?.messages);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSaveTotal(desconto: number, frete: number) {
    setIsLoading(true);
    try {
      const request = {
        valorDesconto: desconto,
        valorFrete: frete,
      };

      await alterarTotalVenda(id, request);
      await carregarVenda();
    } catch (error: any) {
      showToastErrors(error?.messages);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCloseCancelarModal(sucesso: boolean) {
    setShowCancelarVenda(false);
    if (!sucesso) return;

    try {
      setIsLoading(true);
      await carregarVenda();
    } catch (error: any) {
      showToastErrors(error?.messages);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCloseFinalizarModal(sucesso: boolean) {
    setShowFinalizarVenda(false);
    if (!sucesso) return;

    try {
      setIsLoading(true);
      await carregarVenda();
    } catch (error: any) {
      showToastErrors(error?.messages);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleOnChangedPagamentos() {
    setIsLoading(true);

    try {
      await carregarVenda();
    } catch (error: any) {
      showToastErrors(error?.messages);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleItemAdded() {
    try {
      await carregarVenda();
    } catch (error: any) {
      showToastErrors(['Erro ao recarregar a venda depois de adicionar um item (use F5)']);
    }
  }

  return (
    <>
      <AddItemVendaModal vendaId={id} isOpen={showAddItem} onToggle={setShowAddItem} onItemAdded={handleItemAdded} />

      <CancelarVendaModal vendaId={id} show={showCancelarVenda} onCloseClick={handleCloseCancelarModal} />

      <FinalizarVendaModal vendaId={id} show={showFinalizarVenda} onCloseClick={handleCloseFinalizarModal} />

      <EmitirCupomVendaModal vendaId={id} isOpen={showEmitirCupom} onToggle={setShowEmitirCupom} />

      <EmitirNotaVendaModal vendaId={venda.id} isOpen={showEmitirNota} onToggle={setShowEmitirNota} />

      <PageContent title="Vendas" subTitle={'Venda - ' + venda.situacaoTexto} isLoading={isLoading}>
        <Card>
          <CardBody>
            <div className="small fw-bold">Empresa:</div>
            {!!venda.empresa && (
              <>
                <div className="fs-5 text-uppercase">{venda.empresa.nome}</div>
                <div className="small text-muted">CPF/CNPJ: {addCpfCnpjMask(venda.empresa.cpfCnpj)}</div>
              </>
            )}
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            {!!venda.cliente ? (
              <Row xs={1}>
                <Col>
                  <div className="small fw-bold">Cliente/Consumidor:</div>
                  <div className="fs-5 text-uppercase">{venda.cliente.nome}</div>
                  <div className="small text-muted">CPF/CNPJ: {addCpfCnpjMask(venda.cliente.cpfCnpj)}</div>
                </Col>

                {canEdit && (
                  <Col className="mt-2">
                    <button className="btn btn-link p-0" onClick={handleOnClickRemoverCliente}>
                      Clique aqui para remover o cliente
                    </button>
                  </Col>
                )}
              </Row>
            ) : (
              <Row>
                {!showAddCliente ? (
                  <Col xs={12}>
                    <div className="fs-5">
                      Venda não possui um cliente
                      {canEdit && (
                        <button className="btn btn-link font-size-16 p-0 ps-1" onClick={() => setShowAddCliente(true)}>
                          clique aqui para adicionar.
                        </button>
                      )}
                    </div>
                  </Col>
                ) : (
                  <AddCliente onCancel={() => setShowAddCliente(false)} onSubmit={handleAdicionarCliente} />
                )}
              </Row>
            )}
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <Row>
              <Col className="table-responsive">
                <Table className="table table-hover table-striped table-condensed">
                  <thead className="table-light">
                    <tr>
                      <th hidden={venda.situacao !== VendaSituacao.Aberta}></th>
                      <th>Num.</th>
                      <th className="text-wrap">Nome do produto</th>
                      <th className="text-end">Qtd</th>
                      <th className="text-end">P. Unitário</th>
                      <th className="text-end">Desconto</th>
                      <th className="text-end">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {venda.itens
                      ?.sort((a, b) => a.numero - b.numero)
                      .map(item => (
                        <tr key={item.id}>
                          <td valign="middle" align="center" hidden={venda.situacao !== VendaSituacao.Aberta}>
                            <ConfirmButton
                              className="btn btn-sm"
                              color="danger"
                              onClick={e => removerItemClickHandler(item.id)}
                            >
                              <i className="mdi mdi-trash-can-outline"></i>
                            </ConfirmButton>
                          </td>
                          <td valign="middle">{item.numero.toString().padStart(3, '0')}</td>
                          <td valign="middle">{item.produtoNome}</td>
                          <td valign="middle" align="right">
                            {toMoneyFormat(item.quantidade)}
                          </td>
                          <td valign="middle" align="right">
                            {toMoneyFormat(item.precoUnitario)}
                          </td>
                          <td valign="middle" align="right">
                            {toMoneyFormat(item.descontoItem)}
                          </td>
                          <td valign="middle" align="right">
                            {toMoneyFormat(item.totalItem)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={99}>
                        <div className="d-flex justify-content-between">
                          <div>Total dos itens</div>
                          <div className="fw-bold">R$ {toMoneyFormat(venda.totalItens)}</div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </Col>
            </Row>

            <Row xs={1} className="mt-4">
              <TotaisVenda
                onSave={handleSaveTotal}
                disabled={!canEdit}
                totalItens={venda.totalItens}
                totalDesconto={venda.descontoNaVenda}
                totalFrete={venda.totalFrete}
                totalVenda={venda.totalDaVenda}
                totalPago={venda.totalPago}
              />
            </Row>
          </CardBody>
        </Card>

        <Pagamentos
          disabled={!canEdit}
          vendaId={id}
          valorRestante={decimalRound(venda.totalDaVenda - venda.totalPago)}
          onLoading={setIsLoading}
          pagamentos={venda.pagamentos}
          onChanged={handleOnChangedPagamentos}
        />
      </PageContent>

      {venda.situacao !== VendaSituacao.Cancelada && (
        <PageFooter>
          <Row sm={1} md={2} className="gy-3">
            <Col md={5} className="d-flex flex-row align-items-center gap-2">
              {canEdit && (
                <Button size="sm" color="primary" onClick={() => setShowAddItem(true)}>
                  Adicionar item
                </Button>
              )}

              {venda.situacao === VendaSituacao.Aberta && (
                <Button size="sm" color="success" onClick={() => setShowFinalizarVenda(true)}>
                  Finalizar Venda
                </Button>
              )}

              {venda.situacao === VendaSituacao.Finalizada && (
                <Button size="sm" color="info" onClick={() => setShowEmitirCupom(true)}>
                  Emitir Cupom Fiscal (NFC-e)
                </Button>
              )}

              {venda.situacao === VendaSituacao.Finalizada && (
                <Button size="sm" color="info" onClick={() => setShowEmitirNota(true)}>
                  Emitir Nota Fiscal (NF-e)
                </Button>
              )}

              {venda.situacao !== VendaSituacao.Cancelada && (
                <Button size="sm" color="danger" onClick={() => setShowCancelarVenda(true)}>
                  Cancelar Venda
                </Button>
              )}
            </Col>

            <Col md={7} className="d-none d-md-flex justify-content-end">
              <ul className="list-inline d-flex gap-2">
                <li className="list-inline-item">
                  <label>Situação</label>
                  <div className="text-primary">{venda.situacaoTexto}</div>
                </li>
                <li className="list-inline-item">
                  <label>Total venda</label>
                  <div className="text-end text-primary">{toMoneyFormat(venda.totalDaVenda)}</div>
                </li>
                <li className="list-inline-item">
                  <label>Total pago</label>
                  <div className="text-end text-success">{toMoneyFormat(venda.totalPago)}</div>
                </li>
                <li className="list-inline-item">
                  <label>Total restante</label>
                  <div className="text-end text-danger">
                    {toMoneyFormat(decimalRound(venda.totalDaVenda - venda.totalPago, 2))}
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </PageFooter>
      )}
    </>
  );
}
