import { toDecimal } from 'common/decimals';
import { toDecimalFormat, toMoneyFormat } from 'common/formatters';
import { showToastErrors } from 'common/toast_config';
import { SpinnerButton } from 'components/Button/SpinnerButton';
import { InputControl } from 'components/Form/InputControl';
import { InputDecimal } from 'components/Form/InputDecimal';
import { SelectCfop } from 'components/Form/SelectCfop';
import { SelectCsosn } from 'components/Form/SelectCsosn';
import { SelectNcm } from 'components/Form/SelectNcm';
import { SelectOrigemMercadoria } from 'components/Form/SelectOrigemMercadoria';
import { SelectPisCofins } from 'components/Form/SelectPisCofins';
import { useFormTabOnEnter } from 'hooks/useFormTabOnEnter';
import { AlterarItemCommand, NotaItemDto } from 'model/types/notafiscal.types';
import { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { alterarItemNota } from 'services/nfeService';

type Props = {
  show: boolean;
  notaId: string;
  item: NotaItemDto;
  podeEditar: boolean;
  onClose: (success: boolean) => void;
};

interface ItemForm {
  notaFiscalId: string;
  notaFiscalItemId: string;
  icmsCodigo: string;
  creditoAliquota: string;
  creditoBaseCalculo: string;
  creditoValor: string;
  icmsAliquota: string;
  reducaoBaseCalculoPercentual: string;
  icmsBaseCalculo: string;
  icmsValor: string;
  fcpAliquota: string;
  fcpBaseCalculo: string;
  fcpValor: string;
  mvaStPercentual: string;
  reducaoBaseCalculoStPercentual: string;
  icmsStBaseCalculo: string;
  icmsStValor: string;
  icmsStAliquota: string;
  fcpStAliquota: string;
  fcpStBaseCalculo: string;
  fcpStValor: string;
  pisCodigo: string;
  pisAliquota: string;
  pisBaseCalculo: string;
  pisValor: string;
  cofinsCodigo: string;
  cofinsAliquota: string;
  cofinsBaseCalculo: string;
  cofinsValor: string;
  cest: string;
  ncm: string;
  codigoCfop: string;
  origemMercadoria: string;
}

export const NotaAlterarTributacaoItemModal = ({ show, notaId, item, onClose, podeEditar }: Props) => {
  const inputFocus = useRef<any>(null);
  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const { handleOnKeyDown } = useFormTabOnEnter();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<ItemForm>();

  const csosnWatch = watch('icmsCodigo');

  const carregarDadosItem = () => {
    setValue('codigoCfop', item.cfop);
    setValue('cofinsCodigo', item.cofins);
    setValue('icmsCodigo', item.csosn);
    setValue('ncm', item.ncm);
    setValue('notaFiscalId', item.notaFiscalId);
    setValue('origemMercadoria', item.origemMercadoria.toString());
    setValue('pisCodigo', item.pis);
    setValue('icmsAliquota', toMoneyFormat(item.icmsAliquota));
    setValue('reducaoBaseCalculoPercentual', toMoneyFormat(item.reducaoBaseCalculoPercentual));
    setValue('icmsStValor', toMoneyFormat(item.icmsStValor));
    setValue('icmsValor', toMoneyFormat(item.icmsValor));
    setValue('icmsStBaseCalculo', toMoneyFormat(item.icmsStBaseCalculo));
    setValue('reducaoBaseCalculoStPercentual', toMoneyFormat(item.reducaoBaseCalculoStPercentual));
    setValue('mvaStPercentual', toMoneyFormat(item.mvaStPercentual));
    setValue('icmsStAliquota', toMoneyFormat(item.icmsStAliquota));
    setValue('pisAliquota', toMoneyFormat(item.pisAliquota));
    setValue('pisBaseCalculo', toMoneyFormat(item.pisBaseCalculo));
    setValue('pisValor', toMoneyFormat(item.pisValor));
    setValue('cofinsAliquota', toMoneyFormat(item.cofinsAliquota));
    setValue('cofinsBaseCalculo', toMoneyFormat(item.cofinsBaseCalculo));
    setValue('cofinsValor', toMoneyFormat(item.cofinsValor));
    setValue('creditoAliquota', toMoneyFormat(item.creditoAliquota));
    setValue('creditoBaseCalculo', toMoneyFormat(item.creditoBaseCalculo));
    setValue('creditoValor', toMoneyFormat(item.creditoValor));
    setValue('icmsBaseCalculo', toMoneyFormat(item.icmsBaseCalculo));
    setValue('fcpAliquota', toMoneyFormat(item.fcpAliquota));
    setValue('fcpBaseCalculo', toMoneyFormat(item.fcpBaseCalculo));
    setValue('fcpValor', toMoneyFormat(item.fcpValor));
    setValue('fcpStAliquota', toMoneyFormat(item.fcpStAliquota));
    setValue('fcpStBaseCalculo', toMoneyFormat(item.fcpStBaseCalculo));
    setValue('fcpStValor', toMoneyFormat(item.fcpStValor));
    setValue('cest', item.cest);
  };

  const canEditField = (field: string) => {
    if (!podeEditar) {
      return false;
    }

    const allFields = [
      { field: 'creditoAliquota', csosn: ['101', '201', '900'] },
      { field: 'creditoBaseCalculo', csosn: ['101', '201', '900'] },
      { field: 'creditoValor', csosn: ['101', '201', '900'] },
      { field: 'icmsStValor', csosn: ['201', '202', '203', '900'] },
      { field: 'icmsStBaseCalculo', csosn: ['201', '202', '203', '900'] },
      { field: 'reducaoBaseCalculoStPercentual', csosn: ['201', '202', '203', '900'] },
      { field: 'mvaStPercentual', csosn: ['201', '202', '203', '900'] },
      { field: 'icmsStAliquota', csosn: ['201', '202', '203', '900'] },
      { field: 'fcpStValor', csosn: ['202', '203', '900'] },
      { field: 'fcpStBaseCalculo', csosn: ['202', '203', '900'] },
      { field: 'fcpStAliquota', csosn: ['202', '203', '900'] },
      { field: 'icmsValor', csosn: ['900'] },
      { field: 'icmsBaseCalculo', csosn: ['900'] },
      { field: 'reducaoBaseCalculoPercentual', csosn: ['900'] },
      { field: 'icmsAliquota', csosn: ['900'] },
      { field: 'fcpValor', csosn: ['900'] },
      { field: 'fcpBaseCalculo', csosn: ['900'] },
      { field: 'fcpAliquota', csosn: ['900'] },
    ];

    const exists = !!allFields.find(f => f.field === field)?.csosn.includes(csosnWatch);

    if (!exists) {
      setValue(field as keyof ItemForm, toMoneyFormat(0));
    }
    return exists;
  };

  const toggle = (tab: string) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  function handleToggle() {
    if (isSubmitting) return;
    onClose(false);
  }

  function handleOpened() {
    setCurrentActiveTab('1');
    carregarDadosItem();
    focusInput();
  }

  function focusInput() {
    inputFocus.current?.focus();
  }

  const handleOnChangeAliquotaCredito = (aliquota: number) => {
    const { creditoBaseCalculo } = getValues();

    let baseCalculo = toDecimal(creditoBaseCalculo, 2);
    if (baseCalculo === 0) {
      baseCalculo = toDecimal(item.totalItem, 2);
    }

    const valorCredito = (aliquota * baseCalculo) / 100;

    setValue('creditoValor', toMoneyFormat(valorCredito));
    setValue('creditoBaseCalculo', toMoneyFormat(baseCalculo));
  };

  const handleOnChangeBaseCalculoCredito = (baseCalculo: number) => {
    const { creditoAliquota } = getValues();

    const valorCredito = (toDecimal(creditoAliquota, 2) * baseCalculo) / 100;

    setValue('creditoValor', toMoneyFormat(valorCredito));
  };

  const handleOnChangeAliquotaIcms = (aliquota: number) => {
    const { icmsBaseCalculo } = getValues();

    let baseCalculo = toDecimal(icmsBaseCalculo, 2);
    if (baseCalculo === 0) {
      baseCalculo = toDecimal(item.totalItem);
    }
    const valorIcms = (aliquota * baseCalculo) / 100;

    setValue('icmsValor', toMoneyFormat(valorIcms));
    setValue('icmsBaseCalculo', toMoneyFormat(baseCalculo));
  };

  const handleOnChangeReducaoBaseCalculoIcms = (reducao: number) => {
    const { icmsAliquota } = getValues();

    let baseCalculo = toDecimal(item.totalItem, 2);
    const aliquotaIcms = toDecimal(icmsAliquota, 2);

    baseCalculo -= (reducao * baseCalculo) / 100;

    const valorIcms = (aliquotaIcms * baseCalculo) / 100;

    setValue('icmsBaseCalculo', toMoneyFormat(baseCalculo));
    setValue('icmsValor', toMoneyFormat(valorIcms));
  };

  const handleOnChangeBaseCalculoIcms = (baseCalculo: number) => {
    const { icmsAliquota } = getValues();

    const aliquotaIcms = toDecimal(icmsAliquota, 2);

    if (baseCalculo === 0) {
      baseCalculo = toDecimal(item.totalItem, 2);
    }

    const valorCredito = (aliquotaIcms * baseCalculo) / 100;

    setValue('icmsValor', toMoneyFormat(valorCredito));
  };

  const handleOnChangAliquotaFcp = (aliquota: number) => {
    const { fcpBaseCalculo } = getValues();

    let baseCalculo = toDecimal(fcpBaseCalculo, 2);

    if (baseCalculo === 0) {
      baseCalculo = toDecimal(item.totalItem, 2);
    }
    const valorCredito = (aliquota * baseCalculo) / 100;

    setValue('fcpBaseCalculo', toMoneyFormat(baseCalculo));
    setValue('fcpValor', toMoneyFormat(valorCredito));
  };

  const handleOnChangBaseCalculoFcp = (baseCalculo: number) => {
    const { fcpAliquota } = getValues();

    const aliquotaFcp = toDecimal(fcpAliquota, 2);

    if (baseCalculo === 0) {
      baseCalculo = toDecimal(item.totalItem, 2);
    }
    const valorCredito = (aliquotaFcp * baseCalculo) / 100;

    setValue('fcpAliquota', toMoneyFormat(aliquotaFcp));
    setValue('fcpValor', toMoneyFormat(valorCredito));
  };

  const handleChangeAliquotaIcmsSt = (aliquota: number) => {
    const { mvaStPercentual, reducaoBaseCalculoStPercentual } = getValues();

    const mvaSt = toDecimal(mvaStPercentual, 2);
    const reducao = toDecimal(reducaoBaseCalculoStPercentual, 2);
    const fatorMva = 1 + mvaSt / 100;
    const fatorReducao = 1 - reducao / 100;
    const baseCalculo = item.totalItem * fatorMva * fatorReducao;

    const valorCredito = (aliquota * baseCalculo) / 100;

    setValue('icmsStBaseCalculo', toMoneyFormat(baseCalculo));
    setValue('icmsStValor', toMoneyFormat(valorCredito));
  };

  const handleChangeMvaSt = (mva: number) => {
    const { reducaoBaseCalculoStPercentual, icmsStAliquota } = getValues();

    const aliquota = toDecimal(icmsStAliquota);
    const reducao = toDecimal(reducaoBaseCalculoStPercentual, 2);
    const fatorMva = 1 + mva / 100;
    const fatorReducao = 1 - reducao / 100;
    const baseCalculo = item.totalItem * fatorMva * fatorReducao;

    const valorCredito = (aliquota * baseCalculo) / 100;

    setValue('icmsStBaseCalculo', toMoneyFormat(baseCalculo));
    setValue('icmsStValor', toMoneyFormat(valorCredito));
  };

  const handleChangeReducaoSt = (reducao: number) => {
    const { icmsStAliquota, mvaStPercentual } = getValues();

    const aliquota = toDecimal(icmsStAliquota);
    const mva = toDecimal(mvaStPercentual);
    const fatorMva = 1 + mva / 100;
    const fatorReducao = 1 - reducao / 100;
    const baseCalculo = item.totalItem * fatorMva * fatorReducao;

    const valorCredito = (aliquota * baseCalculo) / 100;

    setValue('icmsStBaseCalculo', toMoneyFormat(baseCalculo));
    setValue('icmsStValor', toMoneyFormat(valorCredito));
  };

  const handleOnChangBaseCalculoSt = (baseCalculo: number) => {
    const { icmsStAliquota, mvaStPercentual, reducaoBaseCalculoStPercentual } = getValues();

    const aliquotaSt = toDecimal(icmsStAliquota, 2);
    const mvaSt = toDecimal(mvaStPercentual, 2);
    const reducaoSt = toDecimal(reducaoBaseCalculoStPercentual, 2);

    if (baseCalculo === 0) {
      baseCalculo = toDecimal(item.totalItem, 2);
    }

    const fatorMva = 1 + mvaSt / 100;
    const fatorReducao = 1 - reducaoSt / 100;

    const valorCredito = (aliquotaSt * (baseCalculo * fatorMva * fatorReducao)) / 100;

    setValue('icmsStValor', toMoneyFormat(valorCredito));
  };

  const handleOnChangAliquotaFcpSt = (aliquota: number) => {
    const { fcpStBaseCalculo } = getValues();

    let baseCalculo = toDecimal(fcpStBaseCalculo, 2);

    if (baseCalculo === 0) {
      baseCalculo = toDecimal(item.totalItem, 2);
    }

    const valorCredito = (aliquota * baseCalculo) / 100;

    setValue('fcpStBaseCalculo', toMoneyFormat(baseCalculo));
    setValue('fcpStValor', toMoneyFormat(valorCredito));
  };

  const handleOnChangBaseCalculoFcpSt = (baseCalculo: number) => {
    const { fcpStAliquota } = getValues();
    const aliquota = toDecimal(fcpStAliquota, 2);

    if (baseCalculo === 0) {
      baseCalculo = toDecimal(item.totalItem, 2);
    }

    const valorCredito = (aliquota * baseCalculo) / 100;

    setValue('fcpStValor', toMoneyFormat(valorCredito));
  };

  const handleOnChangPisAliquota = (aliquota: number) => {
    const { pisBaseCalculo } = getValues();

    let baseCalculo = toDecimal(pisBaseCalculo, 2);

    if (baseCalculo === 0) {
      baseCalculo = toDecimal(item.totalItem, 2);
    }

    const valorCredito = (aliquota * baseCalculo) / 100;

    setValue('pisBaseCalculo', toMoneyFormat(baseCalculo));
    setValue('pisValor', toMoneyFormat(valorCredito));
  };

  const handleOnChangBaseCalculoPis = (baseCalculo: number) => {
    const { pisAliquota } = getValues();

    const aliquotaPis = toDecimal(pisAliquota, 2);

    if (baseCalculo === 0) {
      baseCalculo = toDecimal(item.totalItem, 2);
    }

    const valorCredito = (aliquotaPis * baseCalculo) / 100;

    setValue('pisValor', toMoneyFormat(valorCredito));
  };

  const handleOnChangCofinsAliquota = (aliquota: number) => {
    const { cofinsBaseCalculo } = getValues();

    let baseCalculo = toDecimal(cofinsBaseCalculo, 2);

    if (baseCalculo === 0) {
      baseCalculo = toDecimal(item.totalItem, 2);
    }

    const valorCredito = (aliquota * baseCalculo) / 100;

    setValue('cofinsBaseCalculo', toMoneyFormat(baseCalculo));
    setValue('cofinsValor', toMoneyFormat(valorCredito));
  };

  const handleOnChangBaseCalculoCofins = (baseCalculo: number) => {
    const { cofinsAliquota } = getValues();

    const aliquotaPis = toDecimal(cofinsAliquota, 2);

    if (baseCalculo === 0) {
      baseCalculo = toDecimal(item.totalItem, 2);
    }

    const valorCredito = (aliquotaPis * baseCalculo) / 100;

    setValue('cofinsValor', toMoneyFormat(valorCredito));
  };

  async function handleFormSubmit(data: ItemForm) {
    try {
      const command: AlterarItemCommand = {
        cest: data.cest,
        codigoCfop: data.codigoCfop,
        cofinsAliquota: toDecimal(data.cofinsAliquota),
        cofinsBaseCalculo: toDecimal(data.cofinsBaseCalculo),
        cofinsCodigo: data.cofinsCodigo,
        cofinsValor: toDecimal(data.cofinsValor),
        creditoAliquota: toDecimal(data.creditoAliquota),
        creditoBaseCalculo: toDecimal(data.creditoBaseCalculo),
        creditoValor: toDecimal(data.creditoValor),
        fcpAliquota: toDecimal(data.fcpAliquota),
        fcpBaseCalculo: toDecimal(data.fcpBaseCalculo),
        fcpStAliquota: toDecimal(data.fcpStAliquota),
        fcpStBaseCalculo: toDecimal(data.fcpStBaseCalculo),
        fcpStValor: toDecimal(data.fcpStValor),
        fcpValor: toDecimal(data.fcpValor),
        icmsBaseCalculo: toDecimal(data.icmsBaseCalculo),
        icmsCodigo: data.icmsCodigo,
        icmsStAliquota: toDecimal(data.icmsStAliquota),
        icmsStBaseCalculo: toDecimal(data.icmsStBaseCalculo),
        icmsStValor: toDecimal(data.icmsStValor),
        icmsValor: toDecimal(data.icmsValor),
        mvaStPercentual: toDecimal(data.mvaStPercentual),
        ncm: data.ncm,
        notaFiscalId: data.notaFiscalId,
        notaFiscalItemId: data.notaFiscalItemId,
        origemMercadoria: data.origemMercadoria,
        pisAliquota: toDecimal(data.pisAliquota),
        pisBaseCalculo: toDecimal(data.pisBaseCalculo),
        pisCodigo: data.pisCodigo,
        pisValor: toDecimal(data.pisValor),
        reducaoBaseCalculoPercentual: toDecimal(data.reducaoBaseCalculoPercentual),
        reducaoBaseCalculoStPercentual: toDecimal(data.reducaoBaseCalculoStPercentual),
        icmsAliquota: toDecimal(data.icmsAliquota),
      };

      await alterarItemNota(notaId, item.id, command);
      onClose(true);
    } catch (error: any) {
      showToastErrors(error);
      focusInput();
    }
  }

  return (
    <Modal
      size="xl"
      className="modal-fullscreen-xl-down"
      isOpen={show}
      toggle={handleToggle}
      centered={false}
      onOpened={handleOpened}
    >
      <ModalHeader toggle={handleToggle}>Edição de item da nfe</ModalHeader>
      <ModalBody>
        <Form onKeyDown={handleOnKeyDown} onSubmit={handleSubmit(handleFormSubmit)}>
          <>
            <Row>
              <Col md={5}>
                <FormGroup>
                  <Label>Descrição do Item / Produto</Label>
                  <Input className="form-control-sm" type="text" value={item.descricao} readOnly />
                </FormGroup>
              </Col>

              <Col md={7} className="d-flex flex-wrap gap-4 justify-content-end">
                <div className="text-end">
                  <div className="fw-bold">P. Unitário</div>
                  <div className="pt-1">{toMoneyFormat(item.precoUnitario)}</div>
                </div>

                <div className="text-end">
                  <div className="fw-bold">Qtde</div>
                  <div className="pt-1">{toDecimalFormat(item.quantidade)}</div>
                </div>

                <div className="text-end">
                  <div className="fw-bold">T. Unitário</div>
                  <div className="pt-1">{toMoneyFormat(item.totalUnitario)}</div>
                </div>

                <div className="text-end">
                  <div className="fw-bold">T. Desconto</div>
                  <div className="pt-1">{toMoneyFormat(item.totalDesconto)}</div>
                </div>

                <div className="text-end">
                  <div className="fw-bold">T. Frete</div>
                  <div className="pt-1">{toMoneyFormat(item.totalFrete)}</div>
                </div>

                <div className="text-end">
                  <div className="fw-bold">Total</div>
                  <div className="pt-1">{toMoneyFormat(item.totalItem)}</div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>NCM do Produto</Label>
                  <Controller
                    control={control}
                    name="ncm"
                    render={({ field }) => (
                      <SelectNcm {...field} className="select2-selection-sm" disabled={!podeEditar} ref={inputFocus} />
                    )}
                  />
                </FormGroup>
              </Col>

              <Col md={2}>
                <FormGroup>
                  <Label>Código CEST</Label>
                  <InputControl {...register('cest')} disabled={!podeEditar} sm />
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup>
                  <Label>Código CFOP</Label>
                  <Controller
                    control={control}
                    name="codigoCfop"
                    render={({ field }) => (
                      <SelectCfop className="select2-selection-sm" disabled={!podeEditar} {...field} />
                    )}
                  />
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup>
                  <Label>Origem Mercadoria</Label>
                  <Controller
                    control={control}
                    name="origemMercadoria"
                    render={({ field }) => (
                      <SelectOrigemMercadoria className="select2-selection-sm" disabled={!podeEditar} {...field} />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={currentActiveTab === '1' ? 'active' : ''}
                    onClick={() => {
                      toggle('1');
                    }}
                  >
                    ICMS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={currentActiveTab === '2' ? 'active' : ''}
                    onClick={() => {
                      toggle('2');
                    }}
                  >
                    PIS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={currentActiveTab === '3' ? 'active' : ''}
                    onClick={() => {
                      toggle('3');
                    }}
                  >
                    COFINS
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={currentActiveTab}>
                <TabPane tabId="1">
                  <Row xs={1} sm={1} className="justify-content-between mt-2">
                    <Col md={6}>
                      <FormGroup>
                        <Label>Situação Tributária ICMS</Label>
                        <Controller
                          control={control}
                          name="icmsCodigo"
                          render={({ field }) => (
                            <SelectCsosn className="select2-selection-sm" disabled={!podeEditar} {...field} />
                          )}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label>Aliq. Crédito</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">%</span>
                          <InputDecimal
                            {...register('creditoAliquota')}
                            disabled={!canEditField('creditoAliquota')}
                            onChangeDecimal={handleOnChangeAliquotaCredito}
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label>Base Cálculo</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">R$</span>
                          <InputDecimal
                            {...register('creditoBaseCalculo')}
                            disabled={!canEditField('creditoBaseCalculo')}
                            onChangeDecimal={handleOnChangeBaseCalculoCredito}
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label>V. Crédito</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">R$</span>
                          <InputDecimal {...register('creditoValor')} disabled={!canEditField('creditoValor')} />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Label>
                    <strong>ICMS Normal</strong>
                  </Label>
                  <Row>
                    <hr className="m-1" />
                    <Col md={2}>
                      <FormGroup>
                        <Label>Alíquota</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">%</span>
                          <InputDecimal
                            {...register('icmsAliquota')}
                            disabled={!canEditField('icmsAliquota')}
                            onChangeDecimal={handleOnChangeAliquotaIcms}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>Redução</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">%</span>
                          <InputDecimal
                            {...register('reducaoBaseCalculoPercentual')}
                            disabled={!canEditField('reducaoBaseCalculoPercentual')}
                            onChangeDecimal={handleOnChangeReducaoBaseCalculoIcms}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>Base de cálculo</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">R$</span>
                          <InputDecimal
                            {...register('icmsBaseCalculo')}
                            disabled={!canEditField('icmsBaseCalculo')}
                            onChangeDecimal={handleOnChangeBaseCalculoIcms}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>Valor ICMS</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">R$</span>
                          <InputDecimal {...register('icmsValor')} disabled={!canEditField('icmsValor')} />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <FormGroup>
                        <Label>Alíquota FCP</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">%</span>
                          <InputDecimal
                            {...register('fcpAliquota')}
                            disabled={!canEditField('fcpAliquota')}
                            onChangeDecimal={handleOnChangAliquotaFcp}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>Base Cálculo</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">R$</span>
                          <InputDecimal
                            {...register('fcpBaseCalculo')}
                            disabled={!canEditField('fcpBaseCalculo')}
                            onChangeDecimal={handleOnChangBaseCalculoFcp}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>Valor FCP</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">R$</span>
                          <InputDecimal {...register('fcpValor')} disabled={!canEditField('fcpValor')} />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Label>
                    <strong>ICMS Substituição</strong>
                  </Label>
                  <Row>
                    <hr className="m-1" />
                    <Col md={2}>
                      <FormGroup>
                        <Label>Alíquota ST</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">%</span>
                          <InputDecimal
                            {...register('icmsStAliquota')}
                            disabled={!canEditField('icmsStAliquota')}
                            onChangeDecimal={handleChangeAliquotaIcmsSt}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>MVA ST</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">%</span>
                          <InputDecimal
                            {...register('mvaStPercentual')}
                            disabled={!canEditField('mvaStPercentual')}
                            onChangeDecimal={handleChangeMvaSt}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>Redução ST</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">%</span>
                          <InputDecimal
                            {...register('reducaoBaseCalculoStPercentual')}
                            disabled={!canEditField('reducaoBaseCalculoStPercentual')}
                            onChangeDecimal={handleChangeReducaoSt}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>Base de cálculo ST</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">R$</span>
                          <InputDecimal
                            {...register('icmsStBaseCalculo')}
                            disabled={!canEditField('icmsStBaseCalculo')}
                            onChangeDecimal={handleOnChangBaseCalculoSt}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>Valor ICMS ST</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">R$</span>
                          <InputDecimal {...register('icmsStValor')} disabled={!canEditField('icmsStValor')} />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <FormGroup>
                        <Label>Alíquota FCP ST</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">%</span>
                          <InputDecimal
                            {...register('fcpStAliquota')}
                            disabled={!canEditField('fcpStAliquota')}
                            onChangeDecimal={handleOnChangAliquotaFcpSt}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>Base Cálculo</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">R$</span>
                          <InputDecimal
                            {...register('fcpStBaseCalculo')}
                            disabled={!canEditField('fcpStBaseCalculo')}
                            onChangeDecimal={handleOnChangBaseCalculoFcpSt}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>Valor FCP ST</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">R$</span>
                          <InputDecimal {...register('fcpStValor')} disabled={!canEditField('fcpStValor')} />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row className="mt-2">
                    <Col md="6">
                      <FormGroup>
                        <Label>Situação Tributária PIS</Label>
                        <Controller
                          control={control}
                          name="pisCodigo"
                          render={({ field }) => (
                            <div className="">
                              <SelectPisCofins className="select2-selection-sm" disabled={!podeEditar} {...field} />
                            </div>
                          )}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="2">
                      <FormGroup>
                        <Label>Alíquota</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">%</span>
                          <InputDecimal
                            {...register('pisAliquota')}
                            disabled={!podeEditar}
                            onChangeDecimal={handleOnChangPisAliquota}
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md="2">
                      <FormGroup>
                        <Label>Base de cálculo</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">R$</span>
                          <InputDecimal
                            {...register('pisBaseCalculo')}
                            disabled={!podeEditar}
                            onChangeDecimal={handleOnChangBaseCalculoPis}
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md="2">
                      <FormGroup>
                        <Label>Valor PIS</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">R$</span>
                          <InputDecimal {...register('pisValor')} disabled={!podeEditar} />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="3">
                  <Row className="mt-2">
                    <Col md="6">
                      <FormGroup>
                        <Label>Situação Tributária COFINS</Label>
                        <Controller
                          control={control}
                          name="cofinsCodigo"
                          render={({ field }) => (
                            <SelectPisCofins className="select2-selection-sm" disabled={!podeEditar} {...field} />
                          )}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="2">
                      <FormGroup>
                        <Label>Alíquota</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">%</span>
                          <InputDecimal
                            {...register('cofinsAliquota')}
                            disabled={!podeEditar}
                            onChangeDecimal={handleOnChangCofinsAliquota}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <Label>Base de cálculo</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">R$</span>
                          <InputDecimal
                            {...register('cofinsBaseCalculo')}
                            disabled={!podeEditar}
                            onChangeDecimal={handleOnChangBaseCalculoCofins}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <Label>Valor COFINS</Label>
                        <div className="input-group input-group-sm">
                          <span className="input-group-text">R$</span>
                          <InputDecimal {...register('cofinsValor')} disabled={!podeEditar} />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Row>
            <ModalFooter>
              <Row>
                <Col>
                  <div className="text-end mt-3">
                    {podeEditar && (
                      <SpinnerButton loading={isSubmitting} type="submit">
                        {isSubmitting ? 'Salvando...' : 'Salvar alterações'}
                      </SpinnerButton>
                    )}
                  </div>
                </Col>
              </Row>
            </ModalFooter>
          </>
        </Form>
      </ModalBody>
    </Modal>
  );
};
