import { useHistory } from 'react-router-dom';
import { Row, Col, BreadcrumbItem, Button } from 'reactstrap';

interface BreadcrumbProps {
  title: string;
  subTitle: string;
  showBack: boolean;
}

export function Breadcrumb({ title, subTitle, showBack }: BreadcrumbProps) {
  const history = useHistory();

  const handleOnClick = () => {
    history.goBack();
  };

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18 text-primary">
            <Button hidden={!showBack} onClick={handleOnClick} color="primary" size="sm" outline={true}>
              <i className="mdi mdi-keyboard-backspace"></i>
              <span className="ms-2">Voltar</span>
            </Button>
          </h4>

          <div className="page-title-right d-none d-sm-block">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>{title}</BreadcrumbItem>
              <BreadcrumbItem>{subTitle}</BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
}
