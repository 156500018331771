import {
  InputHTMLAttributes,
  ForwardRefRenderFunction,
  forwardRef,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useState,
} from 'react';
import { FieldError } from 'react-hook-form';
import classname from 'classnames';
import { toNumber } from 'lodash';

interface InputPrecoProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: FieldError;
  sm?: boolean;
}

const Component: ForwardRefRenderFunction<HTMLInputElement, InputPrecoProps> = (
  { name, error, onChange, onBlur, onKeyUp, sm = false, ...rest },
  ref,
) => {
  const [inputValue] = useState<string>();

  function addMask(value: string) {
    const originalValue = value?.toString() ?? '';
    const valueHasComma = originalValue.includes(',');
    let newValue = toNumber(originalValue.replace(/\D/g, '')).toString();

    if (valueHasComma && newValue.length < 3) {
      newValue = newValue.padStart(3, '0');
    }

    if (valueHasComma) {
      newValue = newValue.replace(/(\d)(\d{2})$/, '$1,$2');
      newValue = newValue.replace(/(?=(\d{3})+(\D))\B/g, '.');
    }

    return newValue;
  }

  function handleOnChange(event: ChangeEvent<HTMLInputElement>): void {
    event.target.value = addMask(event.target.value);
    onChange && onChange(event);
  }

  function handleOnBlur(event: FocusEvent<HTMLInputElement>): void {
    event.target.value = addMask(event.target.value);
    onBlur && onBlur(event);
  }

  function handleOnKeyUp(event: KeyboardEvent<HTMLInputElement>) {
    event.currentTarget.value = addMask(event.currentTarget.value);
    onKeyUp && onKeyUp(event);
  }

  function handleOnKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.ctrlKey || event.altKey || event.key === ',') return;

    if (event.key.length === 1 && /[^\d]/.test(event.key)) {
      event.preventDefault();
    }
  }

  function handleOnFocus(event: FocusEvent<HTMLInputElement>) {
    event.target.select();
  }

  return (
    <>
      <input
        ref={ref}
        name={name}
        value={inputValue}
        className={classname('form-control text-end', { 'is-invalid': !!error?.message }, { 'form-control-sm': sm })}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onKeyUp={handleOnKeyUp}
        onKeyDown={handleOnKeyDown}
        onFocus={handleOnFocus}
        {...rest}
      />
      {!!error?.message && <span className="text-danger small">{error?.message}</span>}
    </>
  );
};

export const InputQuantidade = forwardRef(Component);
