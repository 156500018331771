import { api, ENDPOINTS } from 'api';

export interface Cidade {
  id: string;
  nome: string;
  codigoIbge: number;
  siglaUf: string;
  nomeUf: string;
}

export async function obterCidadePeloNome(nomeCidade: string): Promise<Cidade[]> {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get<Cidade[]>(ENDPOINTS.CIDADES_POR_NOME(nomeCidade));
      if (data) resolve(data);
    } catch (error: any) {
      if (error.statusCode === 404) {
        error.messages = ['Cidade não encontratada.'];
      }
      reject(error);
    }
  });
}

export async function obterCidadePeloId(id: string): Promise<Cidade> {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get<Cidade>(ENDPOINTS.CIDADES_POR_ID(id));

      if (data) {
        resolve(data);
      }
    } catch (error: any) {
      if (error.statusCode === 404) {
        error.messages = ['Cidade não encontratada.'];
      }
      reject(error);
    }
  });
}

export async function obterCidadePeloIbge(ibge: number): Promise<Cidade> {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get<Cidade>(ENDPOINTS.CIDADES_POR_IBGE(ibge));
      if (data) {
        resolve(data);
      }
    } catch (error: any) {
      if (error.statusCode === 404) {
        error.messages = ['Cidade não encontratada.'];
      }
      reject(error);
    }
  });
}
