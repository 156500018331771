import api, { ENDPOINTS } from 'api';
import { PageData } from 'model/types/common.types';
import { CfopDto } from 'model/types/fiscal.types';

interface ObterPaginaQuery {
  textoPesquisa?: string | null;
  tipoOperacao?: number | null;
}

export async function obterCfopPeloId(id: string): Promise<CfopDto | null> {
  const { data } = await api.get<CfopDto>(ENDPOINTS.CFOP_POR_ID(id));
  return !!data ? data : null;
}

export async function obterPaginaCfop({ textoPesquisa, tipoOperacao }: ObterPaginaQuery): Promise<PageData<CfopDto>> {
  let url = ENDPOINTS.CFOP_LISTAR() + `?tamanhoPagina=25`;
  if (!!textoPesquisa) url += `&textoPesquisa=${textoPesquisa}`;
  if (!!tipoOperacao) url += `&tipoOperacao=${tipoOperacao}`;

  const { data } = await api.get<PageData<CfopDto>>(url);

  if (!data) {
    throw new Error('Não foi possível obter os dados da página');
  }

  return data;
}
