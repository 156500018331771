import api, { ENDPOINTS } from 'api';
import { PageData } from 'model/types/common.types';
import { NcmDto } from 'model/types/fiscal.types';

export async function obterNcmPeloId(id: string): Promise<NcmDto> {
  return new Promise(async resolve => {
    const { data } = await api.get<NcmDto>(ENDPOINTS.NCM_POR_ID(id));

    if (data) {
      resolve(data);
    }
  });
}

interface ObterPaginaNcmParams {
  textoPesquisa?: string | null;
  apenasUltimoNivel?: boolean | null;
}

export async function obterPaginaNcm(params: ObterPaginaNcmParams): Promise<PageData<NcmDto>> {
  const requestParams = {
    ...params,
    tamanhoPagina: 25,
  };

  const { data } = await api.get<PageData<NcmDto>>(ENDPOINTS.NCM_LISTAR(), requestParams);

  if (!data) {
    throw new Error('Não foi possível obter os dados da página');
  }

  return data;
}
