import { toMoneyFormat } from 'common/formatters';
import { NotaItemDto } from 'model/types/notafiscal.types';
import { Col, Row, Table } from 'reactstrap';

type NotaTabelaItemProps = {
  itens: NotaItemDto[];
  onEditarItem: (item: NotaItemDto) => void;
};

export function NotaTabelaItem({ itens, onEditarItem }: NotaTabelaItemProps) {
  return (
    <Row xs={1}>
      <Col className="table-responsive">
        <Table className="table table-hover table-striped table-condensed">
          <thead className="table-light">
            <tr>
              <th>Num.</th>
              <th className="text-wrap">Produto Vendido</th>
              <th className="text-end">Qtd</th>
              <th className="text-end">Unitário</th>
              <th className="text-end">Desconto</th>
              <th className="text-end">Frete</th>
              <th className="text-end">Total</th>
              <th className="text-center">NCM</th>
              <th className="text-center">CFOP</th>
              <th className="text-center">CSOSN</th>
              <th className="text-end col-sm-1"></th>
            </tr>
          </thead>
          <tbody>
            {itens
              .sort((a, b) => a.numero - b.numero)
              .map(item => (
                <tr key={item.id}>
                  <td valign="middle">{item.numero.toString().padStart(3, '0')}</td>
                  <td valign="middle">{item.descricao}</td>
                  <td valign="middle" align="right">
                    {toMoneyFormat(item.quantidade)}
                  </td>
                  <td valign="middle" align="right">
                    {toMoneyFormat(item.precoUnitario)}
                  </td>
                  <td valign="middle" align="right">
                    {toMoneyFormat(item.totalDesconto)}
                  </td>
                  <td valign="middle" align="right">
                    {toMoneyFormat(item.totalFrete)}
                  </td>
                  <td valign="middle" align="right">
                    {toMoneyFormat(item.totalItem)}
                  </td>
                  <td valign="middle" align="center">
                    {item.ncm}
                  </td>
                  <td valign="middle" align="center">
                    {item.cfop}
                  </td>
                  <td valign="middle" align="center">
                    {item.csosn}
                  </td>
                  <td valign="middle" align="right">
                    <button type="button" onClick={() => onEditarItem(item)} className="btn btn-link p-0">
                      Tributação
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}
