import { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { withRouter, Link, useParams, useHistory } from 'react-router-dom';
import { Row, Col, Card, CardBody, Container, Label, Input, Form } from 'reactstrap';
import { AlertErrors } from '../../components/Alert/AlertErrors';
import { Buffer } from 'buffer';

// import images
import profile from '../../assets/images/profile-img.png';
import logo from '../../assets/images/logo-dark.png';

// import contexts
import { enviarNovaSenha } from '../../api/requests';

// components
import { SpinnerButton } from '../../components/Button/SpinnerButton';
import { ROUTES } from 'config/routes';

function NovaSenhaPage() {
  const history = useHistory();
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [tokenData, setTokenData] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  useEffect(() => {
    try {
      const buff = Buffer.from(token, 'base64');
      const tokenData = JSON.parse(buff.toString('utf-8'));
      setTokenData(tokenData);
    } catch (error) {
      console.log(error);
      setTokenData({});
      setErrors(['Falha ao decodificar token de recuperação!']);
    }
  }, [token]);

  const handleOnSubmit = async event => {
    event.preventDefault();

    try {
      setLoading(true);
      setErrors([]);
      await enviarNovaSenha(tokenData.token, tokenData.email, newPassword, newPasswordConfirm);
      history.push(ROUTES.NOVA_SENHA_SUCESSO);
    } catch (error) {
      setErrors(error.messages);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <MetaTags>
        <title>Recuperar Senha</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to={ROUTES.HOME} className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-white p-4">
                        <h5 className="text-white">Nova Senha</h5>
                        <p>Informe a nova senha para acesso a sua conta.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to={ROUTES.HOME}>
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AlertErrors errors={errors} />

                    <Form className="form-horizontal" onSubmit={handleOnSubmit}>
                      <div className="mb-3">
                        <Label htmlFor="password">Nova senha</Label>
                        <Input
                          autoFocus
                          id="password"
                          name="password"
                          placeholder="Digite a nova senha"
                          className="form-control"
                          type="password"
                          onChange={e => setNewPassword(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="passwordConfirmation">Confirmação senha</Label>
                        <Input
                          name="passwordConfirmation"
                          id="passwordConfirmation"
                          placeholder="Confirme a senha digitada"
                          className="form-control"
                          type="password"
                          onChange={e => setNewPasswordConfirm(e.target.value)}
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <SpinnerButton color="primary" type="submit" loading={loading}>
                            Alterar senha
                          </SpinnerButton>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Volte para{' '}
                  <Link to={ROUTES.LOGIN} className="fw-medium text-primary">
                    Login
                  </Link>{' '}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export const NovaSenha = withRouter(NovaSenhaPage);
