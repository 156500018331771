import MetaTags from 'react-meta-tags';
import { Row, Col, Card, CardBody, Container, Alert } from 'reactstrap';

import { withRouter, Link, useHistory } from 'react-router-dom';

// import images
import profile from '../../assets/images/profile-img.png';
import logo from '../../assets/images/logo-dark.png';
import { ROUTES } from 'config/routes';

function SenhaAlteradaSucessoPage() {
  const history = useHistory();

  setTimeout(() => {
    history.push(ROUTES.LOGIN);
  }, 3000);

  return (
    <>
      <MetaTags>
        <title>Senha Altera Com Sucesso</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to={ROUTES.HOME} className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4"></div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to={ROUTES.HOME}>
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-0">
                    <div className="mt-5 text-center">
                      <p>
                        <Alert color="success">Senha alterada com sucesso</Alert>
                      </p>
                      <p>
                        Volte para{' '}
                        <Link to={ROUTES.LOGIN} className="fw-medium text-primary">
                          Login
                        </Link>{' '}
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export const SenhaAlteradaSucesso = withRouter(SenhaAlteradaSucessoPage);
