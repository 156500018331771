import { Card, CardBody, Col, Media } from 'reactstrap';

interface MiniWidgetProps {
  iconClassName: string;
  title: string;
  value: string;
}

export const MiniWidget = (props: MiniWidgetProps) => {
  return (
    <Col>
      <Card className="mini-stats-wid">
        <CardBody>
          <Media>
            <Media body>
              <p className="text-muted fw-medium">{props.title}</p>
              <h4 className="mb-0">{props.value}</h4>
            </Media>
            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
              <span className="avatar-title rounded-circle bg-primary">
                <i className={props.iconClassName + ' font-size-24'}></i>
              </span>
            </div>
          </Media>
        </CardBody>
      </Card>
    </Col>
  );
};
