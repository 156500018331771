import jwtDecode from 'jwt-decode';
import { create } from 'apisauce';
import ENDPOINTS from './endpoints';

export const handleRefreshToken = async () => {
  var token = localStorage.getItem('token');

  if (token === null || token === undefined || token === '') return;

  var exp = jwtDecode(token).exp;
  const dataExpiracaoToken = new Date(exp * 1000);

  const trintaMinutosEmMileSegundos = 1800000;
  const trintaMinutosAntesDoTokenVencer = new Date(dataExpiracaoToken.getTime() - trintaMinutosEmMileSegundos);

  if (trintaMinutosAntesDoTokenVencer <= new Date()) {
    var refreshTokenLocalStorage = localStorage.getItem('refreshToken');

    var postData = {
      refreshToken: refreshTokenLocalStorage,
    };

    const api = create({
      baseURL: '',
      headers: { Authorization: `BEARER ${token}` },
    });

    var response = await api.post(ENDPOINTS.REFRESH_TOKEN, postData);

    if (response === null || response === undefined || response.status !== 200) return;

    const { acessToken, refreshToken } = response.data;

    localStorage.setItem('token', acessToken);
    localStorage.setItem('refreshToken', refreshToken);
  }
};
