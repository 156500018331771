import React from 'react';
import { Link } from 'react-router-dom';
import { map } from 'lodash';
import { Badge, Card, CardBody, Col, Media, Row } from 'reactstrap';
import { EmitenteDto } from 'model/types/fiscal.types';
import { ROUTES } from 'config/routes';
import { addCpfCnpjMask } from 'common/mask';

interface CartoesEmitenteProps {
  emitentes: EmitenteDto[];
}

export const CartoesEmitente = ({ emitentes }: CartoesEmitenteProps) => {
  function getInitials(input: string) {
    const parts = input.split(' ');
    return parts.length === 1 ? parts[0].substring(0, 2) : parts[0].charAt(0) + parts[1].charAt(0);
  }

  return (
    <React.Fragment>
      {map(emitentes, (item, key) => (
        <Col xxl="4" xl="5" sm="6" xs="12" key={key}>
          <Card>
            <CardBody>
              <Media>
                <div className="avatar-md me-4">
                  <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                    {getInitials(item.empresa.nome)}
                  </span>
                </div>

                <Media className="overflow-hidden" body>
                  <h5 className="text-truncate font-size-15">{item.empresa.nome}</h5>
                  <p className="text-muted mb-1">CNPJ: {addCpfCnpjMask(item.empresa.cnpj)}</p>
                  <p className="text-muted mb-1">IE: {item.inscricaoEstadual}</p>
                </Media>
              </Media>
            </CardBody>
            <Row className="px-3 py-3 border-top">
              <Col sm={8}>
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <Badge className={'bg-success'}>{item.ambienteTexto}</Badge>
                  </li>
                  <li className="list-inline-item" id="dueDate">
                    {item.ufTexto}
                  </li>
                  <li className="list-inline-item" id="dueDate">
                    {item.modeloTexto}
                  </li>
                  <li className="list-inline-item" id="dueDate">
                    Série: {item.serie.toString().padStart(3, '0')}
                  </li>
                </ul>
              </Col>

              <Col sm={4} className="d-flex justify-content-end align-content-center">
                <Link to={ROUTES.EMITENTE_EDITAR(item.id)} className="btn btn-primary btn-sm btn-block">
                  Editar
                </Link>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};
