import { useState } from 'react';
import PropTypes from 'prop-types';

// components
import { ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';

const DropDownPageOption = ({ options, currSizePerPage, onSizePerPageChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <ButtonDropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle color="secondary" caret={true}>
        {currSizePerPage} linhas
      </DropdownToggle>
      <DropdownMenu>
        {options.map(option => {
          return (
            <DropdownItem key={option.text} onClick={() => onSizePerPageChange(option.page)}>
              {option.text}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

DropDownPageOption.propTypes = {
  options: PropTypes.array,
  currSizePerPage: PropTypes.string,
  onSizePerPageChange: PropTypes.func,
};

export default DropDownPageOption;
