import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { PageContent } from 'components/Common/PageContent';
import { ROUTES } from 'config/routes';
import { CartoesEmitente } from './CartaoEmitente';
import { obterEmitentes } from 'services/emitenteService';
import { EmitenteDto } from 'model/types/fiscal.types';

export function EmitenteListagem() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [emitentes, setEmitentes] = useState<EmitenteDto[]>([]);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);

    async function fetchData() {
      try {
        const data = await obterEmitentes();
        if (isCanceled) return;
        setEmitentes(data);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();

    return () => {
      isCanceled = true;
    };
  }, []);

  return (
    <PageContent title="Emitente" subTitle="Listagem Emitentes" isLoading={isLoading}>
      {isLoading ? (
        <div className="text-center">Carregando...</div>
      ) : (
        <Row>
          <CartoesEmitente emitentes={emitentes} />

          <Col xl="4" sm="6" className="d-flex align-items-center">
            <div className="text-center">
              <p className="lead">Emitente fiscal é utilizado para configurar a emissão dos cupons fiscais.</p>
              <Link to={ROUTES.EMITENTE_NOVO} className="btn btn-primary btn-sm">
                Adicionar novo
              </Link>
            </div>
          </Col>
        </Row>
      )}
    </PageContent>
  );
}
