import { Card, CardBody, CardHeader } from 'reactstrap';
import { RegraGlobalCfopDto } from 'model/types/fiscal.types';
import { sortBy } from 'lodash';
import { LinhaRegraCfop } from './LinhaRegraCfop';

type TabelaRegraGlobalProps = {
  dados: RegraGlobalCfopDto[];
  titulo: string;
};

export function TabelaRegraCfop({ titulo, dados }: TabelaRegraGlobalProps) {
  const orderedData = sortBy(
    dados,
    v => v.tipoPessoa,
    v => v.tipoIcms,
  );

  return (
    <Card>
      <CardHeader className="bg-transparent border-bottom text-uppercase">{titulo}</CardHeader>
      <CardBody>
        <div className="table-responsive">
          <table className="table table-borderless table-condensed">
            <thead>
              <tr>
                <th className="text-nowrap">Tipo Pessoa</th>
                <th className="text-nowrap">Tipo ICMS</th>
                <th className="text-nowrap">CFOP Dentro Estado</th>
                <th className="text-nowrap">CFOP Fora Estado</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orderedData.map(v => (
                <LinhaRegraCfop key={v.id} regra={v} />
              ))}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
}
