import {
  InputHTMLAttributes,
  ForwardRefRenderFunction,
  forwardRef,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useState,
} from 'react';
import { addMoneyMask } from 'common/mask';
import { toDecimal } from 'common/decimals';
import { FieldError } from 'react-hook-form';
import classNames from 'classnames';

interface InputPrecoProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: FieldError;
  withInputGroupSymbol?: boolean;
  onChangePrice?: (value: number) => void;
  sm?: boolean;
}

const InputPrecoComponent: ForwardRefRenderFunction<HTMLInputElement, InputPrecoProps> = (
  { name, error, onChange, onChangePrice, onBlur, onKeyUp, withInputGroupSymbol = true, sm = false, ...rest },
  ref,
) => {
  const [inputValue] = useState<string>();

  function handleOnChange(event: ChangeEvent<HTMLInputElement>): void {
    event.target.value = addMoneyMask(event.target.value);
    onChange && onChange(event);

    if (onChangePrice) {
      const priceValue = toDecimal(event.target.value);
      onChangePrice(priceValue);
    }
  }

  function handleOnBlur(event: FocusEvent<HTMLInputElement>): void {
    event.target.value = addMoneyMask(event.target.value);
    onBlur && onBlur(event);
  }

  function handleOnKeyUp(event: KeyboardEvent<HTMLInputElement>) {
    event.currentTarget.value = addMoneyMask(event.currentTarget.value);
    onKeyUp && onKeyUp(event);
  }

  function handleOnKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.ctrlKey || event.altKey) return;

    if (event.key.length === 1 && /[^\d]/.test(event.key)) {
      event.preventDefault();
    }
  }

  function handleOnFocus(event: FocusEvent<HTMLInputElement>): void {
    if (event.target.value) {
      event.target.select();
    }
  }

  return (
    <>
      <InputGroupSymbol sm={sm} withInputGroupSymbol={withInputGroupSymbol}>
        <input
          title="informe o valor"
          ref={ref}
          name={name}
          value={inputValue}
          className={classNames('form-control text-end', { 'is-invalid': !!error?.message, 'form-control-sm': sm })}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onKeyUp={handleOnKeyUp}
          onKeyDown={handleOnKeyDown}
          onFocus={handleOnFocus}
          {...rest}
        />
      </InputGroupSymbol>
      {!!error?.message && <span className="text-danger small">{error?.message}</span>}
    </>
  );
};

function InputGroupSymbol({
  children,
  withInputGroupSymbol = true,
  sm = false,
}: {
  children: React.ReactNode;
  withInputGroupSymbol?: boolean;
  sm?: boolean;
}) {
  if (!withInputGroupSymbol) {
    return <>{children}</>;
  }

  return (
    <div className={classNames('input-group', { 'input-group-sm': sm })}>
      <span className="input-group-text">R$</span>
      {children}
    </div>
  );
}

export const InputPreco = forwardRef(InputPrecoComponent);
