import { MouseEvent } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { PrimaryButton } from './PrimaryButton';

interface LinkButtonProps extends LinkProps {
  color?: 'primary' | 'secondary' | 'warning' | 'danger' | 'info' | 'success' | string;
  icon?: string;
  size?: string;
  children: string;
}

export function LinkButton({ children, onClick, icon, to, color, size, ...rest }: LinkButtonProps) {
  function handleClick(ev: MouseEvent<HTMLAnchorElement>) {
    if (to === '#') {
      ev.preventDefault();
    }

    onClick && onClick(ev);
  }

  return (
    <Link to={to} onClick={handleClick} {...rest}>
      <PrimaryButton size={size} type="button" icon={icon} color={color}>
        {children}
      </PrimaryButton>
    </Link>
  );
}
