import { forwardRef, ForwardRefRenderFunction, useRef, useEffect, useState } from 'react';
import AsyncSelect, { Async } from 'react-select/async';
import { KeyboardEventHandler, OptionTypeBase } from 'react-select';
import { FieldError } from 'react-hook-form';
import { obterProdutoEstoque, obterProdutosEstoque } from 'services/estoqueService';
import { ProdutoEstoqueDto } from 'model/types/produto.types';

export interface SelectProdutoProps {
  name?: string;
  value?: string;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onSelected?: (value: ProdutoSelectResult) => void;
  onKeyDown?: KeyboardEventHandler;
  menuPlacement?: 'top' | 'auto' | 'bottom';
  error?: FieldError;
  autoFocus?: boolean;
}

export type ProdutoSelectResult = ProdutoEstoqueDto;

interface ProdutoOption extends OptionTypeBase {
  value: string;
  label: string;
  object: ProdutoSelectResult;
}

const SelectProdutoComponent: ForwardRefRenderFunction<Async<ProdutoOption>, SelectProdutoProps> = (
  { name, value, onChange, onBlur, onSelected, menuPlacement = 'top', error, onKeyDown, autoFocus = undefined },
  ref,
) => {
  const loadOptionsRef = useRef<NodeJS.Timeout>();
  const [isLoading, setIsLoading] = useState(false);
  const [produtoValue, setProdutoValue] = useState<ProdutoOption | null>();

  useEffect(() => {
    let isCanceled = false;

    async function fetchValue() {
      if (!!!value) {
        setProdutoValue(null);
        return;
      }

      setIsLoading(true);

      try {
        const produto = await obterProdutoEstoque(value);
        if (isCanceled) return;
        setProdutoValue(produto ? transformToOption(produto) : null);
      } finally {
        setIsLoading(false);
      }
    }

    fetchValue();

    return () => {
      isCanceled = true;
    };
  }, [value]);

  function transformToOption(produto: ProdutoSelectResult): ProdutoOption {
    return {
      value: produto.id,
      label: produto.nome + ' - estoque: ' + produto.estoqueAtual,
      object: produto,
    };
  }

  function promiseOptions(inputValue: string): Promise<ProdutoOption[]> {
    loadOptionsRef.current && clearTimeout(loadOptionsRef.current);

    return new Promise<ProdutoOption[]>((resolve, reject) => {
      loadOptionsRef.current = setTimeout(async () => {
        try {
          const data = await obterProdutosEstoque(inputValue);
          const options = data.map(transformToOption);
          resolve(options);
        } catch (error: any) {
          console.error('SelectProduto loadOptions - Error', error);
          reject(error);
        }
      }, 1100);
    });
  }

  function handleOnChange(value: ProdutoOption | null) {
    if (!!!value) {
      onChange && onChange('');
      return;
    }

    setProdutoValue(value);
    onChange && onChange(value.value);
    onSelected && onSelected(value.object);
  }

  return (
    <>
      <AsyncSelect
        ref={ref}
        name={name}
        value={produtoValue}
        onChange={handleOnChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        loadOptions={promiseOptions}
        cacheOptions={false}
        isLoading={isLoading}
        defaultOptions={false}
        menuPlacement={menuPlacement}
        classNamePrefix="select2-selection"
        autoFocus={autoFocus}
        placeholder="digite para buscar..."
        noOptionsMessage={() => 'nenhum registro encontrado para o termo buscado'}
      />
      {!!error?.message && <span className="text-danger small">{error?.message}</span>}
    </>
  );
};

export const SelectProduto = forwardRef(SelectProdutoComponent);
