import { ENDPOINTS } from 'api';
import { PageContent } from 'components/Common/PageContent';
import { dateTimeColumn, decimalColumn, dummyColumn, textColumn } from 'components/Table/columnFactory';
import { Card, CardBody } from 'reactstrap';
import { ROUTES } from 'config/routes';
import { Link } from 'react-router-dom';
import RemotePageTable from 'components/Table/RemotePageTable';
import { SituacaoCupom } from 'model/types/cupomfiscal.types';

export function NotaListagem() {
  const consumnActions = dummyColumn('', (cell, row) => {
    return (
      <div className="text-start">
        <Link to={ROUTES.NOTA_ALTERAR(row.id)} className="btn btn-sm btn-info">
          Editar
        </Link>
      </div>
    );
  });

  const situacaoCor = (status: number): string => {
    switch (status) {
      case SituacaoCupom.Aberto:
        return 'bg-primary';
      case SituacaoCupom.Autorizado:
        return 'bg-success';
      case SituacaoCupom.Cancelado:
        return 'bg-danger';
      case SituacaoCupom.Inutilizado:
        return 'bg-danger';
      case SituacaoCupom.Rejeitado:
        return 'bg-warning';
      case SituacaoCupom.Denegado:
        return 'bg-danger';
      case SituacaoCupom.AutorizacaoPendente:
        return 'bg-warning';
      case SituacaoCupom.CancelamentoPendente:
        return 'bg-warning';
      case SituacaoCupom.ProcessandoAutorizacao:
        return 'bg-info';
      case SituacaoCupom.ProcessandoCancelamento:
        return 'bg-info';
      case SituacaoCupom.ProcessandoInutilizacao:
        return 'bg-info';

      default:
        return 'bg-secondary';
    }
  };

  const columns = [
    consumnActions,
    dummyColumn('Situação', (_, row) => (
      <div className={situacaoCor(row.situacao) + ' rounded font-size-10 text-white text-center'}>
        {row.situacaoTexto}
      </div>
    )),
    textColumn('Número', 'numeroFiscal'),
    textColumn('Série', 'serie'),
    dateTimeColumn('Emissão', 'dataEmissao'),
    textColumn('Consumidor', 'cliente'),
    textColumn('Chave Acesso', 'chaveAcesso'),
    decimalColumn('Produtos', 'totalProdutos'),
    decimalColumn('Total', 'total'),
  ];

  return (
    <>
      <PageContent title="Fiscal" subTitle="Listar Notas Fiscais">
        <Card>
          <CardBody>
            <RemotePageTable url={ENDPOINTS.NOTA_FISCAL_LISTAR()} columns={columns} />
          </CardBody>
        </Card>
      </PageContent>
    </>
  );
}
