import { ApiResponse } from 'apisauce';
import { api, ENDPOINTS } from 'api';
import { EmpresaCommand, EmpresaDto, ReceitaWSEmpresa } from 'model/types/empresa.types';

export async function obterEmpresas(): Promise<ApiResponse<EmpresaDto[]>> {
  return await api.get<EmpresaDto[]>(ENDPOINTS.EMPRESAS_LISTAR_TODAS());
}

export async function obterEmpresa(id: string): Promise<EmpresaDto | null> {
  const response = await api.get<EmpresaDto>(ENDPOINTS.EMPRESAS_POR_ID(id));
  if (!!response.data) {
    return response.data;
  }

  return !!response.data ? response.data : null;
}

export async function cadastrarEmpresa(empresa: EmpresaCommand): Promise<ApiResponse<EmpresaDto>> {
  return await api.post(ENDPOINTS.EMPRESAS_NOVA(), empresa);
}

export async function alterarEmpresa(id: string, empresa: EmpresaCommand): Promise<ApiResponse<EmpresaDto>> {
  return await api.put(ENDPOINTS.EMPRESAS_ALTERAR(id), empresa);
}

export async function receitaWSConsulta(cnpj: string): Promise<ApiResponse<ReceitaWSEmpresa>> {
  return await api.get(ENDPOINTS.RECEITA_WS_BUSCAR_POR_CNPJ(cnpj));
}
