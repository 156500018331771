import { Button, Spinner } from 'reactstrap';

interface SpinnerButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string;
  type: 'submit' | 'reset' | 'button';
  loading?: boolean;
  children: React.ReactNode;
  id?: string;
}

export function SpinnerButton({ color, type, loading, children, id, ...rest }: SpinnerButtonProps) {
  return (
    <Button {...rest} type={type} color={color ?? 'primary'} disabled={loading ?? false} id={id ?? `spinner_button_id`}>
      <Spinner hidden={!loading} size="sm" color="light" /> {children}
    </Button>
  );
}
