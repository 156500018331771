export interface CupomDto {
  id: string;
  numeroFiscal: number;
  serie: number;
  dataEmissao: string;
  chaveAcesso: string;
  total: number;
  totalProdutos: number;
  totalDesconto: number;
  totalFrete: number;
  empresa: {
    id: string;
    nome: string;
    cpfCnpj: string;
    cupomFiscalId: string;
  };
  cliente?: {
    id: string;
    nome: string;
    cpfCnpj: string;
    inscricaoEstadual: string;
    logradouro: string;
    numero: string;
    bairro: string;
    cep: string;
    cidadeUf: string;
  };
  itens: CupomItemDto[];
}

export interface CupomItemDto {
  id: string;
  descricao: string;
  numero: number;
  codigoBarra: string;
  origemMercadoria: string;
  cfop: string;
  csosn: string;
  ncm: string;
  cest: string;
  totalItem: number;
}

export enum SituacaoCupom {
  Aberto = 0,
  ProcessandoAutorizacao = 1,
  Rejeitado = 2,
  Autorizado = 3,
  Denegado = 4,
  Cancelado = 5,
  Inutilizado = 6,
  AutorizacaoPendente = 7,
  CancelamentoPendente = 8,
  ProcessandoCancelamento = 9,
  ProcessandoInutilizacao = 10,
}

export interface CupomFiscalCriado {
  cupomFiscalId: string;
}

export interface PacoteContadorUrl {
  url: string;
}

export interface ResumoSituacaoCupom {
  situacao: number;
  situacaoTexto: string;
  situacaoMensagem: string;
}

export interface AlterarItemCupomFiscalCommand {
  origemMercadoria: string;
  cfop: string;
  csosn: string;
  ncm: string;
  cest: string;
}
