import { OrigemMercadoriaDto } from 'model/types/fiscal.types';

const data: OrigemMercadoriaDto[] = [
  { id: 0, descricao: 'Nacional' },
  { id: 1, descricao: 'Estrangeira importação direta' },
  { id: 2, descricao: 'Estrangeira adquirida no mercado interno' },
  { id: 3, descricao: 'Nacional importação superior a 40%' },
  { id: 4, descricao: 'Nacional conformidade básicas' },
  { id: 5, descricao: 'Nacional importação inferior a 40%' },
  { id: 6, descricao: 'Estrangeira importação direta sem similar' },
  { id: 7, descricao: 'Estrangeira interna sem similar' },
  { id: 8, descricao: 'Nacional importação superior 70' },
];

export async function obterTodasOrigensMercadoria(): Promise<OrigemMercadoriaDto[]> {
  return new Promise(resolve => {
    resolve(data);
  });
}

export async function obterOrigemMercadoriaPeloId(id: string): Promise<OrigemMercadoriaDto | null> {
  return new Promise(resolve => {
    resolve(data.find(i => i.id === Number(id)) ?? null);
  });
}
