import { showToastErrors } from 'common/toast_config';
import { AlterarObservacaoNotaCommand } from 'model/types/notafiscal.types';
import { KeyboardEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, Label, Form } from 'reactstrap';
import { alterarObservacaoNota } from 'services/nfeService';

type NotaObservacao = {
  notaId: string;
  observacao: string;
  podeEditar: boolean;
  onDirty: (isDirty: boolean) => void;
  onSaving: (saving: boolean) => void;
  onChanged: () => void;
};

type FormData = {
  observacao: string;
};

export function NotaObservacao({ observacao, notaId, podeEditar, onDirty, onSaving, onChanged }: NotaObservacao) {
  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormData>({ defaultValues: { observacao: observacao } });

  useEffect(() => {
    onDirty(isDirty);
  }, [isDirty]);

  const handleValidSubmit = async (values: FormData) => {
    onSaving(true);

    try {
      const command: AlterarObservacaoNotaCommand = {
        notaId: notaId,
        observacao: values.observacao,
      };

      await alterarObservacaoNota(command);
      reset(values);
      onChanged();
    } catch (error: any) {
      showToastErrors(error);
    } finally {
      onSaving(false);
    }
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key.toLowerCase() === 'enter' && event.ctrlKey) {
      event.preventDefault();
      event.currentTarget.requestSubmit();
    }
  };

  return (
    <Row>
      <Col sm="12" md="7">
        <Form onSubmit={handleSubmit(handleValidSubmit)} onKeyDown={handleOnKeyDown}>
          <Label>Informações complementares do contribuinte</Label>
          <textarea disabled={!podeEditar} rows={6} className="form-control" {...register('observacao')} />

          {isDirty && (
            <div className="d-flex gap-2 justify-content-end mt-2">
              <button type="button" className="btn btn-link p-0" onClick={() => reset()}>
                Cancelar
              </button>

              <button type="submit" className="btn btn-link p-0">
                Salvar informações
              </button>
            </div>
          )}
        </Form>
      </Col>

      <Col>
        <div className="fw-bold">Tributos Aproximados:</div>
        <p className="text-muted mt-3">
          Referente a Lei da Transparência 12.741/2012, que estabelece que ao vender para consumidor final, os valores
          aproximados dos tributos precisam ser informados no documento fiscal. O valor será calculado utilizando a
          Tabela IBPT e informado automáticamente na autorização do documento fiscal
        </p>
      </Col>
    </Row>
  );
}
