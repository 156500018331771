import classNames from 'classnames';
import { IndicadorPresenca } from 'model/types/common.types';
import { forwardRef, ForwardRefRenderFunction, SelectHTMLAttributes } from 'react';

type SelectPresencaCompradorType = SelectHTMLAttributes<HTMLSelectElement>;

type Option = {
  label: string;
  value: number;
};

const options: Option[] = [
  { label: 'Não se aplica', value: IndicadorPresenca.NaoSeAplica },
  { label: 'Operação Presencial', value: IndicadorPresenca.OperacaoPresencial },
  { label: 'Operação não presencial pela internet', value: IndicadorPresenca.PelaInternet },
  { label: 'Operação não presencial teleatendimento', value: IndicadorPresenca.PeloTeleatendimento },
  { label: 'Operação com entrega em domicilio', value: IndicadorPresenca.EntregaDomicilio },
  { label: 'Operação presencial fora do estabelecimento', value: IndicadorPresenca.PresencialForaEstabelecimento },
  { label: 'Operação não presencial outros', value: IndicadorPresenca.NaoPresencialOutros },
];

const Component: ForwardRefRenderFunction<HTMLSelectElement, SelectPresencaCompradorType> = (
  { className, ...rest },
  ref,
) => {
  return (
    <select ref={ref} className={classNames(['form-select', classNames])} {...rest}>
      {options.map(option => (
        <option key={option.value} value={option.value.toString()}>
          {option.value} - {option.label}
        </option>
      ))}
    </select>
  );
};

export const SelectIndicadorPresenca = forwardRef(Component);
