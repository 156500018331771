import api, { ENDPOINTS } from 'api';
import { ApiResponse } from 'apisauce';
import {
  AlterarCabecalhoNotaCommand,
  AlterarItemCommand,
  AlterarObservacaoNotaCommand,
  EmitirCartaCorrecaoNotaCommand,
  NotaDto,
  NotaFiscalCriado,
  ResumoSituacaoNota,
} from 'model/types/notafiscal.types';

export async function obterNotaPeloId(id: string): Promise<NotaDto | null> {
  const { data } = await api.get<NotaDto>(ENDPOINTS.NOTA_FISCAL_POR_ID(id));
  return !data ? null : data;
}

export async function criarNotaPelaVenda(vendaId: string): Promise<string> {
  const response = await api.post<NotaFiscalCriado>(ENDPOINTS.NOTA_FISCAL_CRIAR_PELA_VENDA(vendaId));

  if (!response.ok || !response.data) {
    throw Error('Ocorreu um erro ao emitir a nota');
  }

  return response.data.notaFiscalId;
}

export async function consultarSituacaoNota(notaId: string): Promise<ResumoSituacaoNota> {
  const { data } = await api.get<ResumoSituacaoNota>(ENDPOINTS.NOTA_FISCAL_CONSULTAR_SITUACAO(notaId));

  if (!data) {
    throw Error('Ocorreu um erro ao consultar a situação da nota');
  }

  return data;
}

export async function removerClienteNota(notaId: string): Promise<ApiResponse<string>> {
  return await api.delete<string>(ENDPOINTS.NOTA_FISCAL_REMOVER_CLIENTE(notaId));
}

export async function adicionarClienteNota(notaId: string, clienteId: string): Promise<ApiResponse<string>> {
  const requestData = { clienteId: clienteId };
  return await api.put<string>(ENDPOINTS.NOTA_FISCAL_ADD_CLIENTE(notaId), requestData);
}

export async function alterarCabecalhoNota(command: AlterarCabecalhoNotaCommand): Promise<ApiResponse<string>> {
  return await api.put<string>(ENDPOINTS.NOTA_FISCAL_ALTERAR_CABECALHO(command.notaId), command);
}

export async function alterarObservacaoNota(command: AlterarObservacaoNotaCommand): Promise<void> {
  const result = await api.put<void>(ENDPOINTS.NOTA_FISCAL_ALTERAR_OBSERVACAO(command.notaId), command);

  if (!result.ok) {
    throw Error('Falha ao alterar observação da nota');
  }
}

export async function alterarNumeracaoNota(id: string, serie: number, numeroFiscal: number) {
  await api.put(ENDPOINTS.NOTA_FISCAL_ALTERAR_NUMERACAO(id), {
    serie,
    numeroFiscal,
  });
}

export async function alterarItemNota(
  notaId: string,
  itemId: string,
  item: AlterarItemCommand,
): Promise<ApiResponse<string>> {
  return await api.put<string>(ENDPOINTS.NOTA_FISCAL_ITEM_ALTERAR(notaId, itemId), item);
}

export async function autorizarNotaFiscal(id: string): Promise<void> {
  await api.post(ENDPOINTS.NOTA_FISCAL_AUTORIZAR(id));
}

export async function confirmarSituacaoNotaFiscal(id: string): Promise<void> {
  await api.post(ENDPOINTS.NOTA_FISCAL_CONFIRMAR_SITUACAO(id));
}

export async function cancelarNotaFiscal(id: string, justificativa: string) {
  await api.post(ENDPOINTS.NOTA_FISCAL_CANCELAR(id), {
    justificativa,
  });
}

export async function sugerirTributacaoNotaFiscal(id: string) {
  await api.post(ENDPOINTS.NOTA_FISCAL_SUGERIR_IMPOSTOS(id));
}

export async function emitirCartaCorrecaoNota(command: EmitirCartaCorrecaoNotaCommand): Promise<void> {
  await api.post<void>(ENDPOINTS.NOTA_FISCAL_CARTA_CORRECAO(command.notaId), command);
}

export async function imprimirCartaCorrecaoNota(notaId: string, sequenciaCarta: number) {
  await api.get(ENDPOINTS.NOTA_FISCAL_iMPRIMIR_CARTA_CORRECAO(notaId, sequenciaCarta));
}
