import { useEffect } from 'react';
import { useQueryPagina } from 'hooks/queries/useQueryPagina';
import CustomTable from './CustomTable';
import { useRemotePageTableContext } from 'providers/RemotePageTableProvider';

type Props = {
  url: string;
  columns: any[];
  tableName?: string | null;
  rightBar?: React.ReactNode | null;
  dataVersion?: number | null;
  searchBar?: boolean | null;
};

export default function RemotePageTable({ url, columns, rightBar, tableName, dataVersion, searchBar }: Props) {
  const { storeConfig, getConfig } = useRemotePageTableContext();

  const {
    data: queryResult,
    isFetching,
    refetch,
  } = useQueryPagina(tableName ?? url, {
    url: url,
    numeroPagina: getConfig(url).currentPage,
    tamanhoPagina: getConfig(url).pageSize,
    textoPesquisa: getConfig(url).searchText,
  });

  useEffect(() => {
    if (dataVersion) {
      refetch();
    }
  }, [dataVersion, refetch]);

  const handleTableChange = async (type: string, { page, sizePerPage, searchText }: any) => {
    const currentPage = type === 'search' || page === 0 ? 1 : page;

    storeConfig(url, {
      currentPage: currentPage,
      pageSize: sizePerPage,
      searchText: searchText,
    });
  };

  return (
    <>
      <CustomTable
        remote={true}
        searchBar={searchBar ?? true}
        columns={columns}
        data={queryResult?.dados ?? []}
        loading={isFetching}
        rightBar={rightBar}
        onTableChange={handleTableChange}
        searchText={getConfig(url).searchText}
        pageOptions={{
          page: getConfig(url).currentPage,
          sizePerPage: getConfig(url).pageSize,
          totalSize: queryResult?.totalRegistros ?? 0,
        }}
      />
    </>
  );
}
