import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, InputGroup, Label, Row } from 'reactstrap';
import { PageContent } from 'components/Common/PageContent';
import { InputControl } from 'components/Form/InputControl';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ptBR } from 'date-fns/locale';
import { SelectCidade } from 'components/Form/SelectCidade';
import { SpinnerButton } from 'components/Button/SpinnerButton';
import { ROUTES } from 'config/routes';
import { addCepMask, addCpfCnpjMask, addTelefoneMask, removeMask } from 'common/mask';
import { obterEnderecoPorCep } from 'api/buscarCep';
import { obterCidadePeloIbge } from 'services/cidadeService';
import { obterEmpresa, cadastrarEmpresa, alterarEmpresa, receitaWSConsulta } from 'services/empresaService';
import { showToastErrors, showToastSuccess } from 'common/toast_config';
import { EmpresaCommand } from 'model/types/empresa.types';

registerLocale('pt-BR', ptBR);

interface ParamsData {
  id: string;
}

export type EmpresaForm = EmpresaCommand;

export const EmpresaCadastro = () => {
  const { id } = useParams<ParamsData>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    control,
    formState: { isSubmitting },
  } = useForm<EmpresaForm>();

  useEffect(() => {
    if (!!!id) return;

    let isCanceled = false;
    setIsLoading(true);

    async function fetchData() {
      try {
        const data = await obterEmpresa(id);
        if (isCanceled || data == null) return;

        setValue('nome', data.nome);
        setValue('razaoSocial', data.razaoSocial);
        setValue('cpfCnpj', addCpfCnpjMask(data.cpfCnpj));
        setValue('enderecoCep', addCepMask(data.endereco.cep.toString()));
        setValue('enderecoLogradouro', data.endereco.logradouro);
        setValue('enderecoNumero', data.endereco.numero);
        setValue('enderecoBairro', data.endereco.bairro);
        setValue('enderecoCidadeId', data.endereco.cidadeId);
        setValue('contatoEmail', data.contatoEmail);
        setValue('contatoTelefone', addTelefoneMask(data.contatoTelefone));
        setValue('contatoTelefone2', addTelefoneMask(data.contatoTelefone2));

        if (data.dataInicioAtividade) {
          setValue('dataInicioAtividade', new Date(data.dataInicioAtividade));
        }
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
    return () => {
      isCanceled = true;
    };
  }, [setValue, id]);

  const handleSubmitEmpresa: SubmitHandler<EmpresaForm> = async values => {
    setIsLoading(true);

    try {
      values.dataInicioAtividade = !!!values.dataInicioAtividade ? undefined : values.dataInicioAtividade;
      values.cpfCnpj = removeMask(values.cpfCnpj) ?? '';
      values.enderecoCep = removeMask(values.enderecoCep) ?? '';
      values.contatoTelefone = removeMask(values.contatoTelefone);
      values.contatoTelefone2 = removeMask(values.contatoTelefone2);

      if (!id) {
        const res = await cadastrarEmpresa(values);
        history.push(ROUTES.EMPRESA_ALTERAR(res.data?.id ?? ''));
      } else {
        await alterarEmpresa(id, values);
        showToastSuccess('Empresa foi alterada com sucesso!');
      }
    } catch (error: any) {
      if (error.statusCode === 400) {
        showToastErrors(error.messages);
        return;
      }

      return Promise.reject(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBuscarCnpjReceitaWs = async (event: any) => {
    setIsLoading(true);

    try {
      const cnpjBuscar = removeMask(getValues('cpfCnpj'));
      if (cnpjBuscar?.length !== 14) {
        showToastErrors(['Preciso de um CNPJ com 14 digitos para buscar.']);
      }

      const { data } = await receitaWSConsulta(cnpjBuscar ?? '');

      setValue('razaoSocial', data?.nome || '');
      setValue('nome', data?.fantasia || '');
      setValue('contatoEmail', data?.email || '');
      setValue('contatoTelefone', addTelefoneMask(data?.telefone) || '');
      setValue('enderecoCep', addCepMask(data?.cep) || '');
      setValue('enderecoLogradouro', data?.logradouro || '');
      setValue('enderecoNumero', data?.numero || '');
      setValue('enderecoBairro', data?.bairro || '');
      setValue('enderecoCidadeId', data?.cidadeId || '');

      const dataAbertura = new Date(data?.abertura ?? '');
      if (dataAbertura.getFullYear() < 1970) {
        setValue('dataInicioAtividade', undefined);
      }
    } catch (error: any) {
      showToastErrors(error.messages);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBuscarEnderecoPorCep = async (event: any) => {
    const cidadeViaCep = await obterEnderecoPorCep(removeMask(getValues().enderecoCep) ?? '');

    const cidade = await obterCidadePeloIbge(cidadeViaCep.ibge);

    setValue('enderecoCidadeId', cidade.id);
    setValue('enderecoLogradouro', cidadeViaCep.logradouro);
    setValue('enderecoBairro', cidadeViaCep.bairro);
  };

  function BottomBar() {
    return (
      <Row>
        <Col>
          <div className="text-start">
            <SpinnerButton onClick={handleSubmit(handleSubmitEmpresa)} loading={isSubmitting} type="button">
              Salvar dados empresa
            </SpinnerButton>
          </div>
        </Col>
      </Row>
    );
  }

  function SubTitle() {
    return !id ? 'Cadastro de Nova Empresa' : 'Alteração de Empresa';
  }

  return (
    <PageContent title="Empresas" subTitle={SubTitle()} isLoading={isLoading} bottomBar={BottomBar}>
      <Form>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <FormGroup>
                  <Label>Razão Social</Label>
                  <InputControl {...register('razaoSocial')} />
                </FormGroup>

                <FormGroup>
                  <Label>Nome Fantasia</Label>
                  <InputControl {...register('nome')} />
                </FormGroup>

                <Row xs={1}>
                  <Col sm={5} md={4}>
                    <FormGroup>
                      <Label>CPF/CNPJ</Label>
                      <InputGroup>
                        <InputControl
                          {...register('cpfCnpj')}
                          onKeyUp={e => (e.currentTarget.value = addCpfCnpjMask(e.currentTarget.value))}
                        />
                        <SpinnerButton onClick={handleBuscarCnpjReceitaWs} type="button" color="primary">
                          Buscar
                        </SpinnerButton>
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col sm={4} md={3}>
                    <FormGroup>
                      <Label>Data Inicio Atividade</Label>
                      <Controller
                        name="dataInicioAtividade"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            className="form-control"
                            ref={field.ref}
                            locale="pt-BR"
                            showYearDropdown
                            dateFormat="dd/MM/yyyy"
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            selected={field.value}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row md={1}>
          <Col lg>
            <Card>
              <CardHeader className="bg-transparent border-bottom text-uppercase">Endereço</CardHeader>
              <CardBody>
                <Row sm={1}>
                  <Col md={4}>
                    <FormGroup>
                      <Label>CEP</Label>
                      <InputGroup>
                        <InputControl
                          {...register('enderecoCep')}
                          onKeyUp={e => (e.currentTarget.value = addCepMask(e.currentTarget.value))}
                        />

                        <SpinnerButton onClick={handleBuscarEnderecoPorCep} type="button" color="primary">
                          Buscar
                        </SpinnerButton>
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col md>
                    <FormGroup>
                      <Label>Logradouro</Label>
                      <InputControl {...register('enderecoLogradouro')} />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <FormGroup>
                      <Label>Número</Label>
                      <InputControl {...register('enderecoNumero')} />
                    </FormGroup>
                  </Col>

                  <Col md>
                    <FormGroup>
                      <Label>Bairro</Label>
                      <InputControl {...register('enderecoBairro')} />
                    </FormGroup>
                  </Col>

                  <Col md>
                    <FormGroup>
                      <Label>Cidade / UF</Label>
                      <Controller
                        name="enderecoCidadeId"
                        control={control}
                        render={({ field }) => <SelectCidade {...field} />}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col md={6} lg={4}>
            <Card>
              <CardHeader className="bg-transparent border-bottom text-uppercase">Dados para Contato</CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>Email</Label>
                  <InputControl {...register('contatoEmail')} />
                </FormGroup>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Telefone 1</Label>
                      <InputControl
                        {...register('contatoTelefone')}
                        onKeyUp={e => (e.currentTarget.value = addTelefoneMask(e.currentTarget.value))}
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label>Telefone 2</Label>
                      <InputControl
                        {...register('contatoTelefone2')}
                        onKeyUp={e => (e.currentTarget.value = addTelefoneMask(e.currentTarget.value))}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Form>
    </PageContent>
  );
};
