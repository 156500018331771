import api from 'api';
import { useQuery } from 'react-query';

type PageResult = {
  dados: any[];
  numeroPagina: number;
  tamanhoPagina: number;
  totalPaginas: number;
  paginaAnterior: number;
  proximaPagina: number;
  totalRegistros: number;
};

type Params = {
  url: string;
  numeroPagina: number;
  tamanhoPagina: number;
  textoPesquisa?: string | null;
};

const getDadosPagina = async (p: Params) => {
  const queryParams = {
    numeroPagina: p.numeroPagina,
    tamanhoPagina: p.tamanhoPagina,
    ...(p.textoPesquisa && { textoPesquisa: p.textoPesquisa }),
  };

  const response = await api.get<PageResult>(p.url, queryParams);

  if (response.ok && response.data) {
    return response.data;
  }

  throw new Error('Erro ao buscar dados da página');
};

export function useQueryPagina(queryName: string, params: Params) {
  const queryKey = [queryName, params.numeroPagina, params.tamanhoPagina, params.textoPesquisa];

  return useQuery<PageResult>({
    queryKey: queryKey,
    queryFn: () => getDadosPagina(params),
    staleTime: 10000,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
  });
}
