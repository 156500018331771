import React, { useEffect, useState } from 'react';
import { range, toNumber, uniqueId } from 'lodash';
import { InputControl } from 'components/Form/InputControl';
import { InputPreco } from 'components/Form/InputPreco';
import { Row, Form, Label, Button, Col } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale';
import { ParcelaLinha } from './Pagamentos.types';

// css
import 'react-datepicker/dist/react-datepicker.css';

// models
import { toDecimal, decimalRound } from 'common/decimals';
import { toMoneyFormat } from 'common/formatters';
import { NovoPagamentoCommand, PagamentoVenda, Parcela } from 'model/types/venda.types';
import { opcoesPagamento, OpcaoPagamento } from './opcoesPagamento';

registerLocale('pt-BR', ptBR);

interface PagamentoProps {
  disabled: boolean;
  showLabel: boolean;
  pagamento?: PagamentoVenda | undefined;
  valorPagamentoInicial: number;
  parcelas?: Parcela[] | undefined;
  onCancel: () => void;
  onSave: (value: NovoPagamentoCommand) => void;
  onDelete: (pagamento: PagamentoVenda) => void;
}

export const Pagamento = ({
  pagamento,
  valorPagamentoInicial,
  parcelas = [],
  onCancel,
  onSave,
  onDelete,
  showLabel,
  disabled,
}: PagamentoProps) => {
  const [edicao] = useState<boolean>(!!pagamento);
  const [valorPagamento, setValorPagamento] = useState<number>(pagamento?.valor ?? valorPagamentoInicial);
  const [quantidadeParcelas, setQuantidadeParcelas] = useState<number>(pagamento?.quantidadeParcelas ?? 0);
  const [opcaoPagamento, setOpcaoPagamento] = useState<OpcaoPagamento>(
    opcoesPagamento.getByServerDescription(pagamento?.tipo ?? 'Dinheiro'),
  );
  const [linhaParcelas, setLinhaParcelas] = useState<ParcelaLinha[]>(
    parcelas.map(p => ({ id: uniqueId(), parcela: p })),
  );

  useEffect(() => {
    if (pagamento?.quantidadeParcelas === quantidadeParcelas && pagamento?.valor === valorPagamento) return;

    const valorParcela = decimalRound(valorPagamento / quantidadeParcelas, 2);
    const somaDasParcelas = decimalRound(valorParcela * quantidadeParcelas, 2);
    const diferenca = decimalRound(valorPagamento - somaDasParcelas, 2);
    const novasLinhas: ParcelaLinha[] = [];
    const dateNow: Date = new Date();

    for (let i = 1; i <= quantidadeParcelas; i++) {
      const parcela = {
        numero: i,
        vencimento: new Date(dateNow.getFullYear(), dateNow.getMonth() + i, dateNow.getDate()).toISOString(),
        valor: valorParcela,
      };

      if (i === 1) {
        parcela.valor = decimalRound(parcela.valor + diferenca, 2);
      }

      novasLinhas.push({ id: uniqueId(), parcela });
    }

    setLinhaParcelas(novasLinhas);
  }, [quantidadeParcelas, valorPagamento, pagamento]);

  function setParcelaVencimento(idLinha: string, vencimento: string) {
    const newLines = [...linhaParcelas];
    const editedLine = linhaParcelas.find(l => l.id === idLinha);

    if (editedLine) {
      editedLine.parcela.vencimento = vencimento;
      setLinhaParcelas([...newLines]);
    }
  }

  function setParcelaValor(idLinha: string, valor: string) {
    const newLines = [...linhaParcelas];
    const editedLine = linhaParcelas.find(l => l.id === idLinha);

    if (editedLine) {
      editedLine.parcela.valor = toDecimal(valor, 2);
      setLinhaParcelas([...newLines]);
    }
  }

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    handleSave();
  }

  function handleOnChangeOpcaoPagamento(event: React.ChangeEvent<HTMLSelectElement>) {
    const opcao = opcoesPagamento.getByTipo(event.target.value);

    if (opcao.apenasAvista) {
      setQuantidadeParcelas(0);
    }

    if (opcao) {
      setOpcaoPagamento(opcao);
    }
  }

  function handleSave() {
    const novoPaamento: NovoPagamentoCommand = {
      tipo: opcaoPagamento.tipo,
      quantidadeParcelas: quantidadeParcelas,
      valor: valorPagamento,
      parcelas: opcaoPagamento.aceitaParcelas ? linhaParcelas.map(l => l.parcela) ?? [] : [],
    };

    onSave(novoPaamento);
  }

  function handleDeleteClick() {
    !!pagamento && onDelete(pagamento);
  }

  return (
    <Form className="align-items-center" onSubmit={handleSubmit}>
      <Row sm={1} xs={1} className="gy-2 gy-md-0">
        <div className="col-md-4 col-xl-3">
          {showLabel && <Label>Forma de Pagamento</Label>}
          <select
            disabled={edicao}
            className="form-select"
            defaultValue={opcaoPagamento.tipo}
            onChange={handleOnChangeOpcaoPagamento}
          >
            {opcoesPagamento.getOpcoes().map(opcao => (
              <option key={opcao.tipo} value={opcao.tipo}>
                {opcao.descricao}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-3 col-xl-2">
          {showLabel && <Label>Condição</Label>}
          <select
            disabled={edicao}
            defaultValue={quantidadeParcelas}
            className="form-select"
            onChange={e => setQuantidadeParcelas(toNumber(e.target.value))}
          >
            <option value={0}>A Vista</option>
            {!opcaoPagamento.apenasAvista &&
              range(1, 25).map(i => (
                <option key={i} value={i} hidden={i === 1 && opcaoPagamento.tipo === 10}>
                  {i}x R$ {toMoneyFormat(decimalRound(valorPagamento / i, 2))}
                </option>
              ))}
          </select>
        </div>

        <div className="col-md-3 col-xl-3">
          {showLabel && <Label>Valor pagamento</Label>}
          <InputPreco
            disabled={edicao}
            defaultValue={toMoneyFormat(valorPagamento)}
            onChangePrice={v => setValorPagamento(v)}
          />
        </div>

        <div className="col-md-2 col-xl-2 justify-content-start align-items-end d-flex gap-2">
          {edicao ? (
            <Button
              hidden={disabled}
              title="deletar o pgamento salvo"
              type="button"
              className="btn btn-danger"
              onClick={handleDeleteClick}
            >
              <i className="mdi mdi-trash-can-outline"></i>
            </Button>
          ) : (
            <>
              <Button title="salvar o pgamento" type="button" className="btn btn-success" onClick={handleSave}>
                <i className="mdi mdi-content-save-outline"></i>
              </Button>

              <Button
                title="desistir de adicionar o pagamento "
                type="button"
                className="btn btn-warning"
                onClick={() => onCancel()}
              >
                <i className="mdi mdi-content-save-off-outline"></i>
              </Button>
            </>
          )}
        </div>
      </Row>

      {opcaoPagamento.aceitaParcelas && linhaParcelas.length > 0 && (
        <Row className="mt-2 gy-1">
          {linhaParcelas.map((value, i) => (
            <Col key={value.id} xs={12}>
              <Row className="ms-md-5 justify-content-start">
                <div className="col-2 col-sm-2">
                  <Label hidden={i !== 0}>Num.</Label>
                  <InputControl sm={true} defaultValue={value.parcela.numero} readOnly />
                </div>

                <div className="col-5 col-sm-4 col-md-3 col-lg-2">
                  <Label hidden={i !== 0}>Vencimento</Label>
                  <DatePicker
                    disabled={edicao}
                    className="form-control form-control-sm"
                    locale="pt-BR"
                    showYearDropdown
                    dateFormat="dd/MM/yyyy"
                    selected={new Date(value.parcela.vencimento)}
                    onChange={selectedDate =>
                      setParcelaVencimento(value.id, new Date(selectedDate?.toString() ?? 'now').toISOString())
                    }
                  />
                </div>

                <div className="col-5 col-sm-4 col-md-3 col-lg-2">
                  <Label hidden={i !== 0}>V. Parcela</Label>
                  <InputPreco
                    disabled={edicao}
                    sm={true}
                    defaultValue={toMoneyFormat(value.parcela.valor)}
                    onChange={e => setParcelaValor(value.id, e.target.value)}
                  />
                </div>
              </Row>
            </Col>
          ))}
        </Row>
      )}
    </Form>
  );
};
