import { PageContent } from 'components/Common/PageContent';
import { SelectEmpresa } from 'components/Form/SelectEmpresa';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Card, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { InputControl } from 'components/Form/InputControl';
import { SpinnerButton } from 'components/Button/SpinnerButton';
import { AlertErrors } from 'components/Alert/AlertErrors';
import { getCertificadoDigital, postCertificadoDigital, putCertificadoDigital } from 'api/requests';

interface ParamsData {
  id: string;
}
export interface CertificadoForm {
  empresaId: string;
  senhaCertificadoDigital: string;
  selectedFiles: File[];
}

export function CertificadoCadastro() {
  const { id } = useParams<ParamsData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [errorsApi, setErrorsApi] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>('');

  const { register, setValue, handleSubmit, reset, control } = useForm<CertificadoForm>({
    defaultValues: { empresaId: '', senhaCertificadoDigital: '', selectedFiles: [] },
  });

  useEffect(() => {
    if (!!!id) return;

    let isCanceled = false;
    setIsLoading(true);

    async function fetchData() {
      try {
        const { data } = await getCertificadoDigital(id);
        if (isCanceled || data == null) return;

        setValue('empresaId', data.empresaId);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
    return () => {
      isCanceled = true;
    };
  }, [id, setValue]);

  const formatBytes = (bytes = 0, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const handleAcceptedFiles = (files: any[]) => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );

    setSelectedFiles(files);
    setValue('selectedFiles', files);
  };

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString().split(',')[1]);
      reader.onerror = error => reject(error);
    });

  const submitCertificado = async (data: CertificadoForm) => {
    setIsLoading(true);
    setErrorsApi([]);
    setSuccessMessage('');

    try {
      const certificadoBase = {
        empresaId: data.empresaId,
        senha: data.senhaCertificadoDigital,
        certificado: await toBase64(data.selectedFiles[0]),
      };

      if (!!id) {
        await putCertificadoDigital(certificadoBase, id);
        setSuccessMessage('Certificado Digital alterado com sucesso!');
      } else {
        await postCertificadoDigital(certificadoBase);
        setSuccessMessage('Certificado Digital cadastrado com sucesso!');
        reset();
        setSelectedFiles([]);
      }
    } catch (error: any) {
      setErrorsApi(error.messages);
    } finally {
      setIsLoading(false);
    }
  };

  const buttonBar = () => {
    return (
      <Row>
        <Col>
          <SpinnerButton onClick={handleSubmit(submitCertificado)} color="primary" type="button" loading={isLoading}>
            {!!id ? 'Alterar Certificado' : 'Cadastrar Certificado'}
          </SpinnerButton>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <PageContent title="Certificados" subTitle="Certificados Digitais" bottomBar={buttonBar}>
        <Form>
          {!!successMessage && <Alert color="success">{successMessage}</Alert>}
          {!!errorsApi && <AlertErrors errors={errorsApi}></AlertErrors>}
          <Row>
            <FormGroup>
              <Label>Empresa</Label>
              <Controller name="empresaId" control={control} render={({ field }) => <SelectEmpresa {...field} />} />
            </FormGroup>
          </Row>

          <Row className="mt-2">
            <FormGroup>
              <Card>
                <CardBody>
                  <Dropzone onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)} maxFiles={1}>
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div className="dz-message needsclick" {...getRootProps()}>
                          <InputControl {...register('selectedFiles')} {...getInputProps()} />
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                          </div>
                          {!!!id ? (
                            <h4>Solte o Certificado Digital aqui ou clique para buscar.</h4>
                          ) : (
                            <h4>
                              Solte o Certificado Digital aqui para <strong>altera-lo</strong>.
                            </h4>
                          )}
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + '-file'}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col md={6}>
                                <Link to="#" className="text-muted font-weight-bold">
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                              <Col md={6} className="col-auto">
                                <Row>
                                  <FormGroup>
                                    <Label>Senha Certificado Digital</Label>
                                    <InputControl {...register('senhaCertificadoDigital')}></InputControl>
                                  </FormGroup>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                </CardBody>
              </Card>
            </FormGroup>
          </Row>
        </Form>
      </PageContent>
    </>
  );
}
