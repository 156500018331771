import { useEffect, useState } from 'react';
import { PageContent } from 'components/Common/PageContent';
import { RegraGlobalCfopDto, TipoConsumidor } from 'model/types/fiscal.types';
import { filter } from 'lodash';
import { iniciarRegrasGlobaisCfop, obterRegrasGlobaisCfop } from 'services/fiscalService';
import { showToastErrors, showToastSuccess } from 'common/toast_config';
import { TabelaRegraCfop } from './TabelaRegraGlobal';
import { PrimeiroAcessoRegrasCfop } from './PrimeiroAcessoRegrasCfop';

export function ConfigRegrasGlobais() {
  const [loading, setLoading] = useState<boolean>(false);
  const [versaoDados, setVersaoDados] = useState(0);
  const [dados, setDados] = useState<RegraGlobalCfopDto[] | null>(null);

  useEffect(() => {
    async function fetchDados() {
      setLoading(true);
      try {
        const response = await obterRegrasGlobaisCfop();
        setDados(response);
      } catch (error: any) {
        setDados(null);
        showToastErrors(error);
      } finally {
        setLoading(false);
      }
    }

    fetchDados();
  }, [versaoDados]);

  const handleClickPrimeiroAcesso = async () => {
    setLoading(true);
    try {
      await iniciarRegrasGlobaisCfop();
      setVersaoDados(versaoDados + 1);
      showToastSuccess('As regras inicias foram criadas com sucesso, altere se necessário');
    } catch (error: any) {
      showToastErrors(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageContent title="Fiscal" subTitle="Regras Globais" isLoading={loading}>
        {!!dados && (
          <>
            {dados.length === 0 ? (
              <PrimeiroAcessoRegrasCfop hidden={loading} onClick={handleClickPrimeiroAcesso} />
            ) : (
              <>
                <TabelaRegraCfop
                  titulo="Regras de CFOP para Contribuinte"
                  dados={filter(dados, v => v.tipoConsumidor === TipoConsumidor.Contribuinte)}
                />
                <TabelaRegraCfop
                  titulo="Regras de CFOP para Consumidor Final"
                  dados={filter(dados, v => v.tipoConsumidor === TipoConsumidor.ConsumidorFinal)}
                />
              </>
            )}
          </>
        )}
      </PageContent>
    </>
  );
}
