import PropTypes from 'prop-types';

// DataTable
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';

import overlayFactory from 'react-bootstrap-table2-overlay/dist/react-bootstrap-table2-overlay';

// Components
import { Row, Col } from 'reactstrap';
import DropDownPageOption from './DropDownPageOption';
import CustomSearch from './CustomSearch';

const sizePerPageList = [
  { text: '8 linhas', value: 8 },
  { text: '10 linhas', value: 10 },
  { text: '25 linhas', value: 25 },
  { text: '50 linhas', value: 50 },
  { text: '100 linhas', value: 100 },
];

const defaultPageOptions = {
  sizePerPageList,
  sizePerPageRenderer: props => <DropDownPageOption {...props} />,
  alwaysShowAllBtns: true,
  custom: true,
};

const CustomTable = ({
  columns,
  rightBar,
  pageOptions,
  data,
  loading,
  remote,
  onTableChange,
  searchText,
  searchBar,
}) => {
  const NoDataIndication = () => {
    return <div className="text-center">{loading ? 'Buscando dados...' : 'Nenhum registro foi localizado'}</div>;
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory({ ...defaultPageOptions, ...pageOptions })}>
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="id"
            columns={columns}
            data={data}
            bootstrap4
            search={{ defaultSearch: searchText }}
          >
            {toolkitProps => (
              <>
                <Row>
                  <Col xs={12} md={8} className="d-md-none">
                    <div className="mb-2">{rightBar}</div>
                  </Col>

                  <Col xs={12} md={4}>
                    <div hidden={!searchBar} className="mb-2">
                      <CustomSearch
                        {...toolkitProps.searchProps}
                        onBeforeSearch={() => {
                          paginationProps.page = 1;
                        }}
                        placeholder={'Pesquisa rápida'}
                      />
                    </div>
                  </Col>

                  <Col xs={12} md={8} className="d-none d-md-block">
                    <div className="mb-2 text-md-end">{rightBar}</div>
                  </Col>
                </Row>

                <Row>
                  <Col xl={12}>
                    <div className="table-responsive">
                      <BootstrapTable
                        remote={remote ?? false}
                        loading={loading ?? false}
                        noDataIndication={<NoDataIndication />}
                        classes={'table table-sm align-middle table-check'}
                        onTableChange={onTableChange}
                        overlay={overlayFactory({
                          spinner: true,
                          styles: {
                            overlay: base => ({
                              ...base,
                              background: 'rgba(200, 200, 200, 0.4)',
                            }),
                          },
                        })}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                        hover
                        bordered={false}
                        striped={false}
                        responsive
                      />
                    </div>
                  </Col>
                </Row>

                <Row xs={12} className="gy-2 mt-2">
                  <Col
                    sm={2}
                    className="d-flex align-content-start flex-wrap justify-content-sm-start justify-content-center"
                  >
                    <SizePerPageDropdownStandalone {...paginationProps} />
                  </Col>

                  <Col className="pagination pagination-rounded justify-content-sm-end justify-content-center">
                    <PaginationListStandalone {...paginationProps} />
                  </Col>
                </Row>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </>
  );
};

CustomTable.propTypes = {
  url: PropTypes.string,
  columns: PropTypes.array,
  rightBar: PropTypes.any,
  pageOptions: PropTypes.object,
  data: PropTypes.array,
  loading: PropTypes.bool,
  remote: PropTypes.bool,
};

export default CustomTable;
