import jwtDecode, { JwtPayload } from 'jwt-decode';

export function autorizacaoValida() {
  const token = localStorage.getItem('token');
  if (!token) return false;

  const payload = jwtDecode<JwtPayload>(token);

  if (!payload.exp || Date.now() >= payload.exp * 1000) {
    return false;
  }

  return true;
}
