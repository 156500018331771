import { FormEvent, useState } from 'react';
import { Col, Form, Label } from 'reactstrap';
import { SelectCliente } from 'components/Form/SelectCliente';

type Props = {
  onSubmit: (clienteId: string) => void;
  onCancel: () => void;
};

export function NotaClienteAdd({ onSubmit, onCancel }: Props) {
  const [clienteId, setClienteId] = useState<string | null>();

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!!clienteId) {
      onSubmit(clienteId);
    }
  };

  const handleOnCancel = () => onCancel();

  return (
    <Col>
      <Form onSubmit={handleOnSubmit}>
        <Label>Escolha um cliente e utilize o 'salvar' para vincula-lo a Nota!</Label>
        <SelectCliente autoFocus onChange={setClienteId} />

        <div className="mt-3 d-flex justify-content-end">
          <button type="submit" className="btn btn-link font-size-14">
            Salvar cliente
          </button>

          <button type="button" className="btn btn-link font-size-14" onClick={handleOnCancel}>
            Cancelar
          </button>
        </div>
      </Form>
    </Col>
  );
}
