import { useRef, useState } from 'react';
import { Col, Label, Modal, ModalBody, Row } from 'reactstrap';
import { InputControl } from 'components/Form/InputControl';
import { cancelarVenda } from 'services/vendaService';
import { AlertErrors } from 'components/Alert/AlertErrors';
import { SpinnerButton } from 'components/Button/SpinnerButton';

interface CancelarVendaModalProps {
  show: boolean;
  vendaId: string;
  onCloseClick: (success: boolean) => void;
}

export const CancelarVendaModal = ({ show, vendaId, onCloseClick }: CancelarVendaModalProps) => {
  const inputFocus = useRef<HTMLInputElement>(null);
  const [justificativa, setJustificativa] = useState<string>('');
  const [errors, setErrors] = useState<string[]>();
  const [cancelando, setCancelando] = useState(false);
  const [sucesso, setSucesso] = useState(false);

  function handleOnOpened() {
    setErrors([]);
    setSucesso(false);
    setJustificativa('');
    setCancelando(false);
    focusInput();
  }

  function focusInput() {
    inputFocus.current?.focus();
  }

  function handleToggle() {
    if (cancelando) return;
    onCloseClick(sucesso);
  }

  async function handleCancelarVenda() {
    setCancelando(true);
    try {
      await cancelarVenda(vendaId, justificativa);
      setSucesso(true);
    } catch (error: any) {
      setErrors(error.messages);
      focusInput();
    } finally {
      setCancelando(false);
    }
  }

  return (
    <Modal isOpen={show} toggle={handleToggle} centered={true} onOpened={handleOnOpened}>
      <ModalBody className="py-3 px-5">
        {sucesso ? (
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <i className="mdi mdi-check-circle-outline" style={{ fontSize: '9em', color: 'green' }} />
                <h3>Venda cancelada!</h3>
                <button type="button" className="btn btn-success btn-lg" onClick={() => onCloseClick(sucesso)}>
                  (ESC) Fechar
                </button>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <i className="mdi mdi-alert-circle-outline" style={{ fontSize: '4em', color: 'orange' }} />
                  <h4>Cancelar a Venda?</h4>
                  <h6>Você não poderá desfazer essa ação!</h6>
                  <p className="text-danger mt-2">
                    Caso exista algum documento fiscal (NF-e ou NFC-e) para essa Venda, os documentos não serão
                    cancelados e devem ser cancelados individualmente.
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="mt-1">
              <Col>
                <AlertErrors className={'mb-2'} errors={errors} />

                <div className="text-center">
                  <Label>Qual a justificativa para cancelar a venda?</Label>
                  <InputControl ref={inputFocus} onChange={e => setJustificativa(e.target.value)} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center mt-3">
                  <SpinnerButton
                    loading={cancelando}
                    type="button"
                    color="primary"
                    className="ms-2"
                    onClick={handleCancelarVenda}
                  >
                    {cancelando ? 'Cancelando...' : 'Sim, cancelar a Venda!'}
                  </SpinnerButton>

                  <button
                    hidden={cancelando}
                    type="button"
                    className="btn btn-secondary ms-2"
                    onClick={() => onCloseClick(sucesso)}
                  >
                    Desistir!
                  </button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
