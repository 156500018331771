import { AlertErrors } from 'components/Alert/AlertErrors';
import { SpinnerButton } from 'components/Button/SpinnerButton';
import { InputControl } from 'components/Form/InputControl';
import { useRef, useState } from 'react';
import { Col, Label, Modal, ModalBody, Row } from 'reactstrap';
import { cancelarCupomFiscal } from 'services/nfceService';

interface CancelarCupomFiscalProps {
  show: boolean;
  cupomFiscalId: string;
  onClose: (success: boolean) => void;
}

export const CupomCancelarModal = ({ show, cupomFiscalId, onClose }: CancelarCupomFiscalProps) => {
  const inputFocus = useRef<HTMLInputElement>(null);
  const [cancelando, setCancelando] = useState(false);
  const [justificativa, setJustificativa] = useState<string>('');
  const [errors, setErrors] = useState<string[]>();
  const [sucesso, setSucesso] = useState(false);

  function handleOnOpened() {
    setErrors([]);
    setSucesso(false);
    setJustificativa('');
    setCancelando(false);
    focusInput();
  }

  function focusInput() {
    inputFocus.current?.focus();
  }

  function handleToggle() {
    if (cancelando) return;
    onClose(sucesso);
  }

  async function handleCancelarCupomFiscal() {
    setCancelando(true);

    try {
      await cancelarCupomFiscal(cupomFiscalId, justificativa);
      setSucesso(true);
    } catch (error: any) {
      setErrors(error.messages);
      focusInput();
    } finally {
      setCancelando(false);
    }
  }

  return (
    <Modal isOpen={show} toggle={handleToggle} centered={true} onOpened={handleOnOpened}>
      <ModalBody className="py-3 px-5">
        {sucesso ? (
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <i className="mdi mdi-check-circle-outline" style={{ fontSize: '9em', color: 'green' }} />
                <h3>Cupom fiscal cancelado!</h3>
                <button type="button" className="btn btn-success btn-lg" onClick={() => onClose(sucesso)}>
                  (ESC) Fechar
                </button>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <i className="mdi mdi-alert-circle-outline" style={{ fontSize: '9em', color: 'orange' }} />
                  <h3>Cancelar o Cupom Fiscal?</h3>
                  <h5>{'Você não poderá desfazer essa ação!'}</h5>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <AlertErrors className={'mb-2'} errors={errors} />

                <div className="text-center">
                  <Label>Qual a justificativa para cancelar o cupom fiscal?</Label>
                  <InputControl ref={inputFocus} onChange={e => setJustificativa(e.target.value)} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center mt-3">
                  <SpinnerButton
                    loading={cancelando}
                    type="button"
                    className="btn btn-success btn-lg ms-2"
                    onClick={handleCancelarCupomFiscal}
                  >
                    {cancelando ? 'Cancelando...' : 'Sim, cancelar!'}
                  </SpinnerButton>

                  <button
                    hidden={cancelando}
                    type="button"
                    className="btn btn-danger btn-lg ms-2"
                    onClick={() => onClose(sucesso)}
                  >
                    Desistir!
                  </button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
