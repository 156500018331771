import { useEffect, useState } from 'react';
import { PageContent } from 'components/Common/PageContent';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { showToast, showToastErrors, showToastSuccess } from 'common/toast_config';
import { PageFooter } from 'components/Common/PageFooter';
import { toDateTimeFormat, toMoneyFormat } from 'common/formatters';
import { NotaAlterarNumeracaoModal } from './NotaAlterarNumeracaoModal';
import { NotaAlterarTributacaoItemModal } from './NotaAlterarTributacaoItemModal';
import { SituacaoNotaTexto } from 'components/Nota/SituacaoNotaTexto';
import { NotaCancelarModal } from './NotaCancelarModal';
import { DanfeNfeModal } from 'components/Nota/DanfeNfeModal';
import { InputPreco } from 'components/Form/InputPreco';
import { NotaCliente } from './NotaCliente';
import { NotaTabelaItem } from './NotaTabelaItem';
import { NotaIndicadoresOperacao, NotaIndicadoresOperacaoValues } from './NotaIndicadoresOperacao';

import {
  NotaItemDto,
  NotaDto,
  ResumoSituacaoNota,
  SituacaoNota,
  AlterarCabecalhoNotaCommand,
} from 'model/types/notafiscal.types';

import {
  adicionarClienteNota,
  alterarCabecalhoNota,
  autorizarNotaFiscal,
  confirmarSituacaoNotaFiscal,
  consultarSituacaoNota,
  obterNotaPeloId,
  removerClienteNota,
  sugerirTributacaoNotaFiscal,
} from 'services/nfeService';

import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { NotaAlterarCabecalhoModal } from './NotaAlterarCabecalhoModal';
import { NotaObservacao } from './NotaObservacao';
import { NotaCartaCorrecao } from './NotaCartaCorrecaoModal';
import { ImprimirCartaCorrecaoNfeModal } from 'components/Nota/ImprimirCartaCorrecaoNfeModal';

interface ParamsData {
  id: string;
}

export function NotaAlterar() {
  const { id } = useParams<ParamsData>();
  const [nota, setNota] = useState<NotaDto | null>(null);
  const [versaoDados, setVersaoDados] = useState<number>(0);
  const [situacaoNota, setSituacaoNota] = useState<ResumoSituacaoNota | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAlterarNumeracaoModal, setShowAlterarNumeracaoModal] = useState<boolean>(false);
  const [showCancelarNotaFiscal, setShowCancelarNotaFiscal] = useState(false);
  const [showAlterarTributacaoItemModal, setShowAlterarTributacaoItemModal] = useState<boolean>(false);
  const [showCartaCorrecaoModal, setShowCartaCorrecaoModal] = useState<boolean>(false);
  const [notaAlterarCabecalhoModalIsOpen, setNotaAlterarCabecalhoModalIsOpen] = useState<boolean>(false);
  const [showDanfeModal, setShowDanfeModal] = useState(false);
  const [itemSelecionado, setItemSelecionado] = useState<NotaItemDto | null>(null);
  const [dirtyValues, setDirtyValues] = useState<string[]>([]);
  const [showImprimirCartaCorrecao, setShowImprimirCartaCorrecao] = useState<boolean>(false);
  const [sequenciaCartaCorrecao, setSequenciaCartaCorrecao] = useState<number>(0);

  const podeAutorizar =
    situacaoNota?.situacao === SituacaoNota.Rascunho || situacaoNota?.situacao === SituacaoNota.Rejeitada;

  const podeConfirmarSituacao =
    situacaoNota?.situacao === SituacaoNota.AutorizacaoPendente ||
    situacaoNota?.situacao === SituacaoNota.CancelamentoPendente;

  const podeCancelar =
    situacaoNota?.situacao === SituacaoNota.Rejeitada ||
    situacaoNota?.situacao === SituacaoNota.Autorizada ||
    situacaoNota?.situacao === SituacaoNota.Rascunho;

  const podeVisualizarDanfe =
    situacaoNota?.situacao === SituacaoNota.Autorizada || situacaoNota?.situacao === SituacaoNota.Cancelada;

  const podeEditar =
    situacaoNota?.situacao === SituacaoNota.Rascunho || situacaoNota?.situacao === SituacaoNota.Rejeitada;

  const podeCorrigir = situacaoNota?.situacao === SituacaoNota.Autorizada;

  useEffect(() => {
    let isCanceled = false;

    async function fetchData() {
      try {
        setIsLoading(true);

        const [notaData, situacaoData] = await Promise.all([obterNotaPeloId(id), consultarSituacaoNota(id)]);

        if (isCanceled) return;

        setSituacaoNota(situacaoData);
        setNota(notaData);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();

    return () => {
      isCanceled = true;
    };
  }, [id, versaoDados]);

  async function handleRemoverCliente() {
    setIsLoading(true);

    try {
      await removerClienteNota(id);
      setVersaoDados(v => v + 1);
    } catch (error: any) {
      showToastErrors(error?.messages);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleAdicionarCliente(clienteId: string) {
    setIsLoading(true);

    try {
      await adicionarClienteNota(id, clienteId);
      setVersaoDados(v => v + 1);
    } catch (error: any) {
      showToastErrors(error?.messages);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCloseAlterarNumeracaoModal(sucesso: boolean) {
    setShowAlterarNumeracaoModal(false);
    if (!sucesso) return;
    setVersaoDados(v => v + 1);
  }

  async function handleCloseAlterarTributacaoItemModal(sucesso: boolean) {
    setShowAlterarTributacaoItemModal(false);
    if (!sucesso) return;
    showToastSuccess('Item foi alterado com sucesso');
    setVersaoDados(v => v + 1);
  }

  const verificarStatusNota = async (id: string): Promise<void> => {
    return await new Promise<void>((resolve, reject) => {
      const interval = 1500;
      let attempt = 0;

      async function consultar() {
        try {
          const response = await consultarSituacaoNota(id);

          if ((response.situacao !== 0 && response.situacao !== 1 && response.situacao !== 11) || ++attempt >= 10) {
            showToast('Situação da nota foi alterada.', 'primary');
            setSituacaoNota(response);
            setVersaoDados(v => v + 1);
            resolve();
            return;
          }

          setTimeout(consultar, interval);
        } catch (error: any) {
          reject(error);
        }
      }

      setTimeout(consultar, 5000);
    });
  };

  const handleAutorizarNota = async () => {
    window.scrollTo(0, 0);
    setIsLoading(true);

    try {
      // O autorizar nota está demorando 10 segundos para responder
      // verificar oq pode estar travando essa operação
      await autorizarNotaFiscal(id);
      await verificarStatusNota(id);
    } catch (error: any) {
      showToastErrors(error.messages);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmarSituacao = async () => {
    window.scrollTo(0, 0);
    setIsLoading(true);

    try {
      await confirmarSituacaoNotaFiscal(id);
      await verificarStatusNota(id);
    } catch (error: any) {
      showToastErrors(error.messages);
    } finally {
      setIsLoading(false);
    }
  };

  const handleShowAlterarTributacaoItemModal = (item: NotaItemDto) => {
    setItemSelecionado(item);
    setShowAlterarTributacaoItemModal(true);
  };

  const handleCloseCancelarModal = (sucesso: boolean) => {
    setShowCancelarNotaFiscal(false);
    if (!sucesso) return;
    setVersaoDados(v => v + 1);
  };

  const handleOnSaveIndicadores = async (values: NotaIndicadoresOperacaoValues) => {
    if (!nota) return;
    setIsLoading(true);

    try {
      const command: AlterarCabecalhoNotaCommand = {
        notaId: nota.id,
        destinoOperacao: values.indicadorDestinoOperacao,
        indicadorOperacaoConsumidor: values.indicadorConsumidor,
        indicadorPrecensaComprador: values.indicadorPresenca,
        emissaoEm: moment(nota.dataEmissao).toDate(),
        saidaEntradaEm: moment(nota.saidaEntradaEm).toDate(),
        naturezaOperacao: nota.naturezaOperacao,
      };

      await alterarCabecalhoNota(command);
    } catch (error: any) {
      showToastErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateDirtyValues = (key: string, isDirty: boolean) => {
    if (isDirty) {
      setDirtyValues([...dirtyValues, key]);
    } else {
      setDirtyValues(dirtyValues.filter(i => i != key));
    }
  };

  const handleSugerirTributacoes = async () => {
    setIsLoading(true);
    try {
      await sugerirTributacaoNotaFiscal(id);
      setVersaoDados(v => v + 1);
    } catch (error: any) {
      showToastErrors(error);
      setIsLoading(false);
    }
  };

  const handleCloseModalCartaCorrecao = (toggle: boolean) => {
    setShowCartaCorrecaoModal(toggle);
    setVersaoDados(v => v + 1);
  };

  const imprimirCartaCorrecao = async (notaId: string, sequenciaCarta: number) => {
    setShowImprimirCartaCorrecao(true);
    setSequenciaCartaCorrecao(sequenciaCarta);
  };

  return (
    <>
      {nota && (
        <NotaAlterarNumeracaoModal
          notaId={id}
          numeroFiscal={nota.numeroFiscal ?? 0}
          serie={nota.serie ?? 0}
          show={showAlterarNumeracaoModal}
          onClose={handleCloseAlterarNumeracaoModal}
        />
      )}

      {nota && (
        <NotaAlterarCabecalhoModal
          nota={nota}
          isOpen={notaAlterarCabecalhoModalIsOpen}
          onToggle={setNotaAlterarCabecalhoModalIsOpen}
          onChanged={() => {
            setNotaAlterarCabecalhoModalIsOpen(false);
            setVersaoDados(v => v + 1);
          }}
        />
      )}

      {itemSelecionado && (
        <NotaAlterarTributacaoItemModal
          notaId={id}
          item={itemSelecionado}
          show={showAlterarTributacaoItemModal}
          onClose={handleCloseAlterarTributacaoItemModal}
          podeEditar={podeEditar}
        />
      )}

      <NotaCancelarModal notaFiscalId={id} show={showCancelarNotaFiscal} onClose={handleCloseCancelarModal} />
      <DanfeNfeModal notaFiscalId={id} isOpen={showDanfeModal} onToggle={setShowDanfeModal} />

      {nota && (
        <NotaCartaCorrecao
          NotaId={nota.id}
          isOpen={showCartaCorrecaoModal}
          toggle={toggle => handleCloseModalCartaCorrecao(toggle)}
        />
      )}

      <PageContent title="Fiscal" subTitle="Nota Fiscal" isLoading={isLoading}>
        <Card>
          <CardBody>
            <Row hidden={isLoading}>
              <Col className="text-center">
                <b>Situação da Nota Fiscal</b>
                {!!situacaoNota && <SituacaoNotaTexto loading={isLoading} situacao={situacaoNota} />}
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardHeader className="bg-transparent border-bottom text-uppercase">Dados da Nota Fiscal</CardHeader>
          <CardBody>
            <Row className="gy-3">
              <Col md={2} sm={4}>
                <div className="text-nowrap">Data Emissão:</div>
                <div>{toDateTimeFormat(nota?.dataEmissao)}</div>
              </Col>

              <Col md={2} sm={4}>
                <div className="text-nowrap">Data Saida/Ent:</div>
                <div>{toDateTimeFormat(nota?.saidaEntradaEm)}</div>
              </Col>

              <Col md={3} sm={4}>
                <div className="text-nowrap">Série/Número</div>
                <div>
                  {nota?.serie} / {nota?.numeroFiscal.toString().padStart(8, '0')}
                </div>
              </Col>

              <Col md={5} sm={12}>
                <div className="">Chave de Acesso:</div>
                <div>{nota?.chaveAcesso ?? 'Ainda não possui chave'}</div>
              </Col>
            </Row>

            <Row className="gy-3 mt-2">
              <Col sm={2}>
                <div className="text-nowrap">Tipo Operação:</div>
                <div>Saida</div>
              </Col>

              <Col sm={2}>
                <div className="text-nowrap">Finalidade:</div>
                <div>Normal</div>
              </Col>

              <Col sm>
                <div className="text-nowrap">Natureza da Operação:</div>
                <div>{nota?.naturezaOperacao}</div>
              </Col>
            </Row>

            {podeEditar && (
              <Row className="mt-3">
                <Col>
                  <button className="btn btn-link p-0" onClick={() => setNotaAlterarCabecalhoModalIsOpen(true)}>
                    Clique aqui para alterar informações do cabeçalho da nota
                  </button>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>

        <Card>
          <CardHeader className="bg-transparent border-bottom text-uppercase">Cliente / Destinatário</CardHeader>
          <CardBody>
            <Row>
              <Col sm="7">
                <NotaCliente
                  cliente={nota?.cliente ?? null}
                  onAddCliente={handleAdicionarCliente}
                  onRemoverCliente={handleRemoverCliente}
                  podeEditar={podeEditar}
                />
              </Col>

              {nota && (
                <Col>
                  <NotaIndicadoresOperacao
                    nota={nota}
                    onSave={handleOnSaveIndicadores}
                    onDirty={isDirty => updateDirtyValues('NotaIndicadoresOperacao', isDirty)}
                    podeEditar={podeEditar}
                  />
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardHeader className="bg-transparent border-bottom text-uppercase">
            Itens / Produtos da Nota Fiscal
          </CardHeader>
          <CardBody>
            {podeEditar && (
              <div className="mb-4">
                <div className="fw-bold">Sobre as tributações e impostos dos Itens da NF-e</div>
                <div className="lead font-size-14">
                  Os códigos tributários e impostos são sugeridos no item seguindo a configuração do Produto e Regras de
                  CFOP, quando uma NF-e é criada de uma Venda com Cliente já são sugerido impotos e tributações para os
                  itens. Para sugerir novamente os impostos é só clicar no link a seguir:
                </div>

                <div className="text-center mt-3">
                  <button type="button" className="btn btn-link p-0 font-size-14" onClick={handleSugerirTributacoes}>
                    Sugerir tributações e impostos
                  </button>
                </div>
              </div>
            )}

            <NotaTabelaItem itens={nota?.itens ?? []} onEditarItem={handleShowAlterarTributacaoItemModal} />
          </CardBody>
        </Card>

        <Card>
          <CardHeader className="bg-transparent border-bottom text-uppercase">
            Informações complementares da nota
          </CardHeader>
          <CardBody>
            {nota && (
              <NotaObservacao
                onDirty={isDirty => updateDirtyValues('NotaObservacao', isDirty)}
                onSaving={setIsLoading}
                onChanged={() => setVersaoDados(v => v + 1)}
                notaId={nota.id}
                observacao={nota.observacao}
                podeEditar={podeEditar}
              />
            )}
          </CardBody>
        </Card>

        <Card>
          <CardHeader className="bg-transparent border-bottom text-uppercase">TOTAIS DA NOTA</CardHeader>
          <CardBody>
            <Row className="justify-content-between">
              <Col md={2}>
                <FormGroup>
                  <Label>Total Produtos</Label>
                  <InputPreco disabled={true} sm value={toMoneyFormat(nota?.totalProdutos)} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Total Desconto</Label>
                  <InputPreco disabled={true} sm value={toMoneyFormat(nota?.totalDesconto)} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Total Frete</Label>
                  <InputPreco disabled={true} sm value={toMoneyFormat(nota?.totalFrete)} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Total Nota</Label>
                  <InputPreco disabled={true} sm value={toMoneyFormat(nota?.total)} />
                </FormGroup>
              </Col>
            </Row>
            <Row className="justify-content-between">
              <hr className="m-1" />
              <Col md={2}>
                <FormGroup>
                  <Label>Base Cálculo ICMS</Label>
                  <InputPreco disabled={true} sm value={toMoneyFormat(nota?.totalBcIcms)} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Total ICMS</Label>
                  <InputPreco disabled={true} sm value={toMoneyFormat(nota?.totalIcms)} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Base Cálculo ICMS-ST</Label>
                  <InputPreco disabled={true} sm value={toMoneyFormat(nota?.totalBcSt)} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Total ICMS-ST</Label>
                  <InputPreco disabled={true} sm value={toMoneyFormat(nota?.totalSt)} />
                </FormGroup>
              </Col>
            </Row>
            <Row className="justify-content-between">
              <hr className="m-1" />
              <Col md={2}>
                <FormGroup>
                  <Label>Base Cálculo FCP-ST</Label>
                  <InputPreco disabled={true} sm value={toMoneyFormat(nota?.totalBcFcpSt)} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Total FCP-ST</Label>
                  <InputPreco disabled={true} sm value={toMoneyFormat(nota?.totalFcpSt)} />
                </FormGroup>
              </Col>

              <Col md={2}>
                <FormGroup>
                  <Label>Total PIS</Label>
                  <InputPreco disabled={true} sm value={toMoneyFormat(nota?.totalPis)} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Total COFINS</Label>
                  <InputPreco disabled={true} sm value={toMoneyFormat(nota?.totalCofins)} />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        {nota && nota.correcoes && nota.correcoes.length > 0 && (
          <Card>
            <CardHeader className="bg-transparent border-bottom text-uppercase">Carta correção</CardHeader>
            <CardBody>
              {nota.correcoes
                .sort((a, b) => a.sequencia - b.sequencia)
                .slice(-1)
                .map(correcao => (
                  <>
                    <Row>
                      <Col md={1}>
                        <Label>
                          <b>Número</b>
                        </Label>
                        <div>{correcao.sequencia.toString().padStart(3, '0')}</div>
                      </Col>

                      <Col md={3}>
                        <Label>
                          <b>Emitida Em</b>
                        </Label>
                        <div>{toDateTimeFormat(correcao.feitoEm)}</div>
                      </Col>

                      <Col md={8}>
                        <b>Informações Corrigidas</b>
                        <div>{correcao.correcao}</div>
                      </Col>
                    </Row>
                    <Row>
                      <div className="text-end mt-3">
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => imprimirCartaCorrecao(nota.id, correcao.sequencia)}
                        >
                          Imprimir
                        </button>
                      </div>
                    </Row>
                  </>
                ))}
            </CardBody>
          </Card>
        )}

        {nota && (
          <ImprimirCartaCorrecaoNfeModal
            isOpen={showImprimirCartaCorrecao}
            notaFiscalId={nota?.id}
            sequenciaCartaCorrecao={sequenciaCartaCorrecao}
            onToggle={toggle => setShowImprimirCartaCorrecao(toggle)}
          />
        )}
      </PageContent>

      <PageFooter>
        <div className="d-flex gap-3">
          {dirtyValues.length > 0 ? (
            <div>Nota possui alterações que não foram salvas ainda</div>
          ) : (
            <>
              {podeAutorizar && (
                <Button size="sm" color="success" onClick={() => handleAutorizarNota()}>
                  Autorizar Nota Fiscal
                </Button>
              )}
              {podeVisualizarDanfe && (
                <Button size="sm" color="info" onClick={() => setShowDanfeModal(true)}>
                  Imprimir DANFE
                </Button>
              )}
              {podeConfirmarSituacao && (
                <Button size="sm" color="primary" onClick={() => handleConfirmarSituacao()}>
                  Confirmar Situação
                </Button>
              )}
              {podeCorrigir && (
                <Button size="sm" color="primary" onClick={() => setShowCartaCorrecaoModal(true)}>
                  Enviar Carta Correção
                </Button>
              )}
              {podeCancelar && (
                <Button size="sm" color="danger" onClick={() => setShowCancelarNotaFiscal(true)}>
                  Cancelar Nota
                </Button>
              )}
              {podeAutorizar && (
                <Button size="sm" color="warning" onClick={() => setShowAlterarNumeracaoModal(true)}>
                  Alterar Numeração
                </Button>
              )}
            </>
          )}
        </div>
      </PageFooter>
    </>
  );
}
