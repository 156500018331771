import { showToastErrors } from 'common/toast_config';
import { yup, yupResolver } from 'common/yup';
import { SpinnerButton } from 'components/Button/SpinnerButton';
import { toNumber } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { enviarArquivoImportacaoDados } from 'services/importacaoDados';

type NovaImportacaoModalProps = {
  isOpen: boolean;
  onToggle: (toggle: boolean) => void;
  onCreated?: () => void;
};

type ModalData = {
  tipoDados: string;
  arquivoDados: FileList;
};

const yupScheme = yup.object().shape({
  tipoDados: yup.string().required('O tipo de dados é obrigatório'),
  arquivoDados: yup.mixed().test('required', 'O arquivo é obrigatório', v => v && v.length > 0),
});

export function NovaImportacaoModal({ isOpen, onToggle, onCreated }: NovaImportacaoModalProps) {
  const [sucesso, setSucesso] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loteId, setLoteId] = useState<string>('');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ModalData>({ resolver: yupResolver(yupScheme) });

  const resetModal = () => {
    setSucesso(false);
    setLoteId('');
    setLoading(false);
    reset();
  };

  const handleToggle = () => {
    if (loading) return;
    onToggle(!isOpen);
  };

  const handleClosed = () => {
    resetModal();
  };

  const fileToBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (!reader.result) {
          resolve('');
          return;
        }

        resolve(reader.result.toString().split(',')[1]);
      };
      reader.onerror = error => reject(error);
    });

  const enviarLote = async (formData: ModalData) => {
    setLoading(true);

    try {
      const postData = {
        tipoDados: toNumber(formData.tipoDados),
        arquivoDados: await fileToBase64(formData.arquivoDados[0]),
      };

      const response = await enviarArquivoImportacaoDados(postData);

      setLoteId(response.loteId);
      setSucesso(true);
      onCreated && onCreated();
    } catch (error: any) {
      showToastErrors(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={handleToggle} onClosed={handleClosed}>
        <Form onSubmit={handleSubmit(enviarLote)}>
          <ModalHeader toggle={handleToggle}>Enviar Novo Lote de Dados</ModalHeader>
          <ModalBody>
            {sucesso ? (
              <Row>
                <Col className="text-center">
                  <i style={{ fontSize: '62px' }} className="bx bx-check-circle text-success" />

                  <p className="text-center font-size-16 p-2 mt-2">
                    O arquivo de dados foi aceito pelo servidor e um lote foi gerado para a importação. Para acompanhar
                    a situação do lote, acesse a grid de importação de dados.
                  </p>

                  <p className="text-center">O lote está sendo processado no servidor, você pode fechar essa janela</p>
                  <p className="text-center">
                    Identificação do Lote:
                    <br /> <strong>{loteId}</strong>
                  </p>
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col sm={16}>
                    <Label className="form-label">Tipo Dados</Label>
                    <div>
                      <select
                        disabled={loading}
                        title="tipo de dados"
                        className="form-control"
                        {...register('tipoDados')}
                      >
                        <option value="">Selecione o tipo de dado para importação</option>
                        <option value="1">Clientes</option>
                        <option value="2">Produtos</option>
                      </select>

                      {errors.tipoDados && <span className="text-danger">{errors.tipoDados.message}</span>}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col sm={16}>
                    <div className="mt-3">
                      <Label className="form-label">Informe abaixo o arquivo CSV para ser importado</Label>
                      <input
                        disabled={loading}
                        className="form-control"
                        type="file"
                        accept=".zip"
                        {...register('arquivoDados')}
                      />
                      {errors.arquivoDados && <span className="text-danger">{errors.arquivoDados.message}</span>}
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </ModalBody>

          {!sucesso && (
            <ModalFooter className="justify-content-center">
              <SpinnerButton color="success" type="submit" loading={loading}>
                {loading ? 'Enviando...' : 'Enviar Lote de Dados'}
              </SpinnerButton>
            </ModalFooter>
          )}
        </Form>
      </Modal>
    </>
  );
}
