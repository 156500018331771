import { forwardRef, ForwardRefRenderFunction, InputHTMLAttributes } from 'react';
import { FieldError } from 'react-hook-form';
import classNames from 'classnames';

interface InputControlProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: FieldError;
  sm?: boolean;
  onlyNumbers?: boolean;
}

const InputControlComponent: ForwardRefRenderFunction<HTMLInputElement, InputControlProps> = (
  { error = null, sm = false, onlyNumbers, ...rest },
  ref,
) => {
  const classes = classNames('form-control', { 'form-control-sm': sm, 'is-invalid': !!error?.message });

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.ctrlKey || event.altKey || onlyNumbers !== true) return;

    if (event.key.length === 1 && /[^\d]/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <input ref={ref} className={classes} onKeyDown={handleOnKeyDown} {...rest} />
      {!!error?.message && <span className="text-danger small">{error?.message}</span>}
    </>
  );
};

export const InputControl = forwardRef(InputControlComponent);
