import { AlertErrors } from 'components/Alert/AlertErrors';
import { SpinnerButton } from 'components/Button/SpinnerButton';
import { InputControl } from 'components/Form/InputControl';
import { useRef, useState } from 'react';
import { Col, Label, Modal, ModalBody, Row } from 'reactstrap';
import { alterarNumeracaoNota } from 'services/nfeService';

type Props = {
  show: boolean;
  notaId: string;
  serie: number;
  numeroFiscal: number;
  onClose: (success: boolean) => void;
};

export const NotaAlterarNumeracaoModal = ({ show, notaId, serie, numeroFiscal, onClose }: Props) => {
  const inputFocus = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<string[]>();
  const [sucesso, setSucesso] = useState(false);
  const [alterando, setAlterando] = useState(false);
  const [notaSerie, setNotaSerie] = useState<number>(0);
  const [notaNumeroFiscal, setNotaNumeroFiscal] = useState<number>(0);

  function handleToggle() {
    if (alterando) return;
    onClose(sucesso);
  }

  async function handlerAlterarNumeracao() {
    setAlterando(true);

    try {
      await alterarNumeracaoNota(notaId, notaSerie, notaNumeroFiscal);
      setSucesso(true);
    } catch (error: any) {
      setErrors(error.messages);
      focusInput();
    } finally {
      setAlterando(false);
    }
  }

  function handleOnOpened() {
    setErrors([]);
    setSucesso(false);
    setNotaSerie(serie);
    setNotaNumeroFiscal(numeroFiscal);
    focusInput();
  }

  function focusInput() {
    inputFocus.current?.focus();
    inputFocus.current?.select();
  }

  return (
    <Modal isOpen={show} toggle={handleToggle} centered={true} onOpened={handleOnOpened}>
      <ModalBody className="py-3 px-5">
        {sucesso ? (
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <i className="mdi mdi-check-circle-outline" style={{ fontSize: '9em', color: 'green' }}></i>
                <h4>Numeração da nota fiscal alterada com sucesso!</h4>
                <button type="button" className="btn btn-success btn-lg" onClick={() => onClose(sucesso)}>
                  (ESC) Fechar
                </button>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <div className="text-center">
                <i className="mdi mdi-alert-circle-outline" style={{ fontSize: '5em', color: 'orange' }} />
                <h3>Ateração da Numeração Fiscal</h3>
                <p className="text-danger font-size-14">
                  Essa ação altera o número da nota fiscal e também a sequencia para as próximas notas desse emissor.
                  <br />
                  <b>A alteração não poderá ser desfeita!</b>
                </p>
              </div>
            </Row>
            <Row className="mt-1 g-2" xs={1} sm={2}>
              {!!errors && errors.length > 0 && (
                <Col xs={12} sm={12}>
                  <AlertErrors className={'mb-2'} errors={errors} />
                </Col>
              )}

              <Col>
                <Label>Série</Label>
                <InputControl
                  ref={inputFocus}
                  onlyNumbers={true}
                  value={notaSerie}
                  onChange={e => setNotaSerie(Number(e.target.value))}
                />
              </Col>

              <Col>
                <Label>Número Fiscal</Label>
                <InputControl
                  onlyNumbers={true}
                  value={notaNumeroFiscal}
                  onChange={e => setNotaNumeroFiscal(Number(e.target.value))}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center mt-3">
                  <SpinnerButton
                    loading={alterando}
                    type="button"
                    className="btn btn-success btn-lg ms-2"
                    onClick={handlerAlterarNumeracao}
                  >
                    {alterando ? 'Alterando...' : 'Sim, alterar!'}
                  </SpinnerButton>

                  <button
                    hidden={alterando}
                    type="button"
                    className="btn btn-danger btn-lg ms-2"
                    onClick={() => onClose(sucesso)}
                  >
                    Desistir!
                  </button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
