import { PageContent } from 'components/Common/PageContent';
import { Dashboard } from 'components/Dashboard';

// import contexts
import { DashboardContextProvider, useDashboardContext } from 'components/Dashboard/context';

const HomePageContent = () => {
  const { loading: isLoading } = useDashboardContext();

  return (
    <PageContent isLoading={isLoading} showBreadcrumb={false} showBack={false} title="Home" subTitle="Dashboard">
      <Dashboard />
    </PageContent>
  );
};

export const HomePage = () => {
  return (
    <DashboardContextProvider>
      <HomePageContent />
    </DashboardContextProvider>
  );
};
