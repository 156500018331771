import { showToastErrors } from 'common/toast_config';
import { SpinnerButton } from 'components/Button/SpinnerButton';
import { SelectCliente } from 'components/Form/SelectCliente';
import { SelectEmpresa } from 'components/Form/SelectEmpresa';
import { ROUTES } from 'config/routes';
import { useFormTabOnEnter } from 'hooks/useFormTabOnEnter';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Form, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { criarVenda } from 'services/vendaService';

interface NovaVendaModalProps {
  isOpen: boolean;
  onToggle: (toogle: boolean) => void;
}

export const NovaVendaModal = ({ isOpen, onToggle }: NovaVendaModalProps) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [clienteId, setClienteId] = React.useState<string | null>();
  const [emrpesaId, setEmpresaId] = React.useState<string | null>();
  const { handleOnKeyDown } = useFormTabOnEnter();

  const handleToogle = () => {
    if (loading) return;
    onToggle(!isOpen);
  };

  const handleOpened = () => {
    setEmpresaId(null);
    setClienteId(null);
  };

  async function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!!!emrpesaId) {
      showToastErrors(['É obrigatório selecionar uma empresa']);
      return;
    }

    setLoading(true);

    try {
      const { data } = await criarVenda({ empresaId: emrpesaId, clienteId: clienteId ?? undefined });

      if (data) {
        history.push(ROUTES.VENDAS_EDITAR(data.id));
        return;
      }

      showToastErrors(['Resposta não esperada do servidor']);
      console.warn('response.data', data);
      setLoading(false);
    } catch (error: any) {
      showToastErrors(error.messages);
      setLoading(false);
    }
  }

  return (
    <Modal
      size="md"
      className="modal-fullscreen-md-down"
      isOpen={isOpen}
      backdrop={true}
      toggle={handleToogle}
      onOpened={handleOpened}
    >
      <ModalHeader toggle={handleToogle}>Criar Nova Venda</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i className="mdi mdi-cart-plus" style={{ fontSize: '4em', color: 'gray' }} />
              <h3>Criar uma Nova Venda?</h3>
              <h6>Será criado uma venda nova e você será redirecionado para ela.</h6>
            </div>
          </Col>
        </Row>

        <hr />

        <Form onSubmit={handleOnSubmit} onKeyDown={handleOnKeyDown}>
          <Row xs={1} className="gy-3">
            <Col>
              <Label>
                Qual é a empresa que está venda?
                <span className="small text-danger">&nbsp;(obrigatório)</span>
              </Label>
              <SelectEmpresa value={emrpesaId} onChange={setEmpresaId} />
            </Col>

            <Col>
              <Label>
                Quem é o cliente dessa venda?
                <span className="small text-muted">&nbsp;(pode ser adicionado depois)</span>
              </Label>
              <SelectCliente value={clienteId} onChange={setClienteId} />
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="text-center mt-3">
                <SpinnerButton loading={loading} type="submit" className="btn btn-success btn-lg ms-2">
                  Sim, criar a venda!
                </SpinnerButton>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};
