import moment from 'moment';
import { useContext, createContext, useState, useCallback } from 'react';
import { VendaDto, VendaSituacao } from 'model/types/venda.types';
import { obterTodasVendasPeriodo } from 'services/vendaService';

type DashboardContextType = {
  loading: boolean;
  dadosPeriodo: DashboardDadosPeriodo | null;
  atualizarDadosPeriodo: (anoMes: string) => void;
};

const DashboardContext = createContext<DashboardContextType | null>(null);

type Props = {
  children: React.ReactNode;
};

type DashboardDadosPeriodo = {
  anoMes: string;
  dados: VendaDto[];
};

export const DashboardContextProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dadosPeriodo, setDadosPeriodo] = useState<DashboardDadosPeriodo | null>(null);

  const atualizarDadosPeriodo = useCallback(async (anoMes: string) => {
    const periodoInicio = moment(anoMes, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
    const periodoFim = moment(anoMes, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');

    setLoading(true);

    try {
      const dados = await obterTodasVendasPeriodo({
        situacao: VendaSituacao.Finalizada,
        dataInicio: periodoInicio,
        dataFim: periodoFim,
      });

      setDadosPeriodo({ anoMes, dados });
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <DashboardContext.Provider value={{ loading, dadosPeriodo, atualizarDadosPeriodo }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);
  if (context === undefined || context === null) {
    throw new Error('useDashboardContext must be used within a DashboardContextProvider');
  }

  return context;
};
