import { Link } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import ENDPOINTS from 'api/endpoints';

// Components
import { Row, Col, Card, CardBody } from 'reactstrap';
import RemotePageTable from 'components/Table/RemotePageTable';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import { PageContent } from 'components/Common/PageContent';
import { textColumn, cpfCnpjColumn, telefoneColumn, dummyColumn } from 'components/Table/columnFactory';

const columnActions = dummyColumn('', (cell, row) => {
  return (
    <div className="text-start">
      <Link to={ROUTES.CLIENTE_ALTERAR(row.id)} className="btn btn-sm btn-info">
        Editar
      </Link>
    </div>
  );
});

const columns = [
  columnActions,
  textColumn('Nome', 'nome'),
  textColumn('Razão Social', 'razaoSocial'),
  cpfCnpjColumn('CPF/CNPJ', 'cpfCnpj'),
  telefoneColumn('Telefone', 'telefone'),
];

const RightBar = () => {
  return (
    <>
      <Link to={ROUTES.CLIENTE_NOVO}>
        <PrimaryButton icon="plus">Novo Cliente</PrimaryButton>
      </Link>
    </>
  );
};

export function ListagemClientes() {
  return (
    <>
      <PageContent title="Clientes" subTitle="Listar Clientes">
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <RemotePageTable
                  tableName="listagem-clientes"
                  url={ENDPOINTS.CLIENTES_LISTAR()}
                  columns={columns}
                  rightBar={<RightBar />}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </PageContent>
    </>
  );
}
