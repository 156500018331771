export enum ModeloDocumento {
  NFCE = 65,
  NFE = 55,
}

export enum AmbienteSefaz {
  Producao = 1,
  Homologacao = 2,
}

export enum TipoPessoa {
  Fisica = 1,
  Juridica = 2,
}

export enum TipoConsumidor {
  Contribuinte = 0,
  ConsumidorFinal = 1,
}

export enum TipoIcms {
  Normal = 1,
  Substituicao = 5,
}

// types

export interface OrigemMercadoriaDto {
  id: number;
  descricao: string;
}

export interface CfopDto {
  codigo: string;
  descricao: string;
  tipoOperacao: 0 | 1;
}

export interface NcmDto {
  codigo: string;
  descricao: string;
}

export interface CsosnDto {
  codigo: string;
  descricao: string;
}

export interface PisCofinsDto {
  codigo: string;
  descricao: string;
  tipoVenda: boolean;
}

export interface EmitenteDto {
  id: string;
  empresa: {
    id: string;
    nome: string;
    nomeFantasia: string;
    cnpj: string;
  };
  modelo: number;
  modeloTexto: string;
  ambiente: number;
  ambienteTexto: string;
  inscricaoEstadual: string;
  uf: number;
  ufTexto: string;
  serie: number;
  numeroInicial: number;
  idToken: string;
  csc: string;
}

export interface RegraGlobalCfopDto {
  id: string;
  tipoPessoa: TipoPessoa;
  tipoPessoaTexto: string;
  tipoConsumidor: TipoConsumidor;
  tipoConsumidorTexto: string;
  tipoIcms: TipoIcms;
  tipoIcmsTexto: string;
  cfopDentroEstado: string;
  cfopForaEstado: string;
}

export interface EmitenteCommand {
  modelo: number;
  ambiente: number;
  empresaId: string;
  inscricaoEstadual: string;
  uf: number;
  serie: number;
  numeroInicial: number;
  idToken: string;
  csc: string;
}

export interface AlterarRegraGlobalCfopCommand {
  id: string;
  cfopDentroEstado: string;
  cfopForaEstado: string;
}
