import axios from 'axios';

export const obterEnderecoPorCep = async cepBuscado => {
  if (cepBuscado === '') return '';
  var validaCep = /^[0-9]{8}$/;
  if (validaCep.test(cepBuscado) === false) return '';

  var { data } = await axios.get('https://viacep.com.br/ws/' + cepBuscado + '/json/');

  return data;
};
