import { api, ENDPOINTS } from 'api';
import { omit } from 'lodash';
import { EmitenteCommand, EmitenteDto } from 'model/types/fiscal.types';

export async function obterEmitentes(): Promise<EmitenteDto[]> {
  const { data } = await api.get<EmitenteDto[]>(ENDPOINTS.EMITENTES_LISTAR());
  if (!!!data) throw new Error('Obter emitentes retornou dados indefindo.');
  return data;
}

export async function obterEmitentePorId(id: string): Promise<EmitenteDto | null> {
  const { data } = await api.get<EmitenteDto>(ENDPOINTS.EMITENTES_POR_ID(id));
  return data ? data : null;
}

export async function alterarEmitente(id: string, command: EmitenteCommand): Promise<void> {
  const updateData = omit(command, ['empresaId', 'modelo']);
  await api.put<void>(ENDPOINTS.EMITENTES_ALTERAR(id), updateData);
}

export async function cadastrarEmitente(command: EmitenteCommand): Promise<void> {
  await api.post<void>(ENDPOINTS.EMITENTES_NOVO(), command);
}
