import { forwardRef, ForwardRefRenderFunction, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { OptionTypeBase } from 'react-select';
import AsyncSelect, { Async } from 'react-select/async';
import { NcmDto } from 'model/types/fiscal.types';
import { obterPaginaNcm, obterNcmPeloId } from 'services/ncmService';

export interface SelectNcmProps {
  name?: string;
  value?: string;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onSelected?: (value: NcmDto) => void;
  onKeyDown?: KeyboardEventHandler;
  menuPlacement?: 'top' | 'auto' | 'bottom';
  error?: FieldError;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string | undefined;
}

interface NcmOption extends OptionTypeBase {
  value: string;
  label: string;
  object: NcmDto;
}

const Component: ForwardRefRenderFunction<Async<NcmOption>, SelectNcmProps> = (
  {
    name,
    value,
    onBlur,
    onChange,
    onSelected,
    onKeyDown,
    menuPlacement = 'top',
    error,
    autoFocus,
    disabled,
    className,
  },
  ref,
) => {
  const loadOptionsRef = useRef<NodeJS.Timeout>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<NcmOption | null>(null);

  useEffect(() => {
    let isCanceled = false;

    if (!value) {
      setSelectedItem(null);
      return;
    }

    async function fetchValue() {
      setIsLoading(true);
      try {
        const data = await obterNcmPeloId(value as string);
        if (isCanceled) return;
        setSelectedItem(!!data ? mapOption(data) : null);
      } finally {
        setIsLoading(false);
      }
    }

    fetchValue();

    return () => {
      isCanceled = true;
    };
  }, [value]);

  function mapOption(ncm: NcmDto): NcmOption {
    return {
      value: ncm.codigo,
      label: `${ncm.codigo} - ${ncm.descricao}`,
      object: ncm,
    };
  }

  function promiseOptions(textSearch: string): Promise<any> {
    loadOptionsRef.current && clearTimeout(loadOptionsRef.current);

    return new Promise((resolve, reject) => {
      loadOptionsRef.current = setTimeout(async () => {
        try {
          const data = await obterPaginaNcm({ textoPesquisa: textSearch });

          const options = data.dados.map((p: NcmDto) => {
            return {
              value: p.codigo,
              label: `${p.codigo} - ${p.descricao}`,
              object: p,
            };
          });

          resolve(options);
        } catch (error: any) {
          console.error('SelectNcm::promiseOptions', error);
          reject(error);
        }
      }, 1100);
    });
  }

  function handleOnChange(value: NcmOption | null) {
    if (!!!value) {
      onChange && onChange('');
      return;
    }

    setSelectedItem(value);
    onChange && onChange(value.value);
    onSelected && onSelected(value.object);
  }

  return (
    <>
      <AsyncSelect
        ref={ref}
        name={name}
        isDisabled={disabled}
        value={selectedItem}
        onChange={handleOnChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        menuPlacement={menuPlacement}
        isLoading={isLoading}
        cacheOptions
        defaultOptions={false}
        loadOptions={promiseOptions}
        classNamePrefix="select2-selection"
        className={className}
        placeholder="digite para buscar..."
        noOptionsMessage={() => 'nenhum ncm localizado para a busca'}
        autoFocus={autoFocus}
      />
      {!!error?.message && <span className="text-danger small">{error?.message}</span>}
    </>
  );
};

export const SelectNcm = forwardRef(Component);
