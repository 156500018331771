import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-drawer/lib/react-drawer.css';
import { Link } from 'react-router-dom';

// Import menuDropdown
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';

// Logos
import logoLight from '../../assets/images/logo-light.png';
import logoDark from '../../assets/images/logo-dark.png';
import logoSmall from '../../assets/images/logo-light-sm.png';

//i18n
import { withTranslation } from 'react-i18next';

// Redux Store
import { showRightSidebarAction, changeSidebarType } from '../../store/actions';

// Constants
import { layoutTypes } from 'constants/layout';
import { ROUTES } from 'config/routes';

const TopBar = props => {
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }

  const VerticalTypePart = () => {
    return (
      <div className="d-flex">
        <div className="navbar-brand-box d-lg-none d-md-block">
          <Link to={ROUTES.HOME} className="logo logo-light logo-dark">
            <span className="logo-sm">
              <img src={logoSmall} alt="" height="35" />
            </span>
          </Link>
        </div>

        <button
          type="button"
          onClick={() => {
            tToggle();
          }}
          className="btn btn-sm px-3 font-size-16 header-item "
          id="vertical-menu-btn"
        >
          <i className="fa fa-fw fa-bars" />
        </button>
      </div>
    );
  };

  const HorizontalTypePart = () => {
    return (
      <>
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to={ROUTES.HOME} className="logo logo-dark">
              <span className="logo-sm">
                <img src={logoDark} alt="" height="23" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="" height="35" />
              </span>
            </Link>

            <Link to={ROUTES.HOME} className="logo logo-light">
              <span className="logo-sm">
                <img src={logoLight} alt="" height="23" />
              </span>
              <span className="logo-lg">
                <img src={logoLight} alt="" height="35" />
              </span>
            </Link>
          </div>

          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
            data-toggle="collapse"
            onClick={props.openLeftMenuCallBack}
            data-target="#topnav-menu-content"
          >
            <i className="fa fa-fw fa-bars" />
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {props.layoutType === layoutTypes.VERTICAL && <VerticalTypePart />}
            {props.layoutType === layoutTypes.HORIZONTAL && <HorizontalTypePart />}
          </div>

          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen mt-1" />
              </button>
            </div>

            {/* <NotificationDropdown /> */}
            <ProfileMenu />
          </div>
        </div>
      </header>
    </>
  );
};

TopBar.propTypes = {
  layoutType: PropTypes.string,
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  openLeftMenuCallBack: PropTypes.func,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  changeSidebarType,
})(withTranslation()(TopBar));
