import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yup, yupResolver } from 'common/yup';

// components
import { Modal, ModalBody, ModalHeader, ModalFooter, Alert, Form, FormGroup, Label } from 'reactstrap';
import { SpinnerButton } from 'components/Button/SpinnerButton';
import { NovoUsuarioData, sendNovoUsuarioOrganizacao } from 'services/usuarioService';
import { AlertErrors } from 'components/Alert/AlertErrors';
import { InputControl } from 'components/Form/InputControl';

interface NovoUsuarioModalProps {
  isOpen: boolean;
  onToggle: (toggle: boolean) => void;
  onSuccess?: () => void;
}

const schema = yup.object().shape({
  email: yup.string().required().email(),
  senha: yup.string().required().min(6),
  confirmacaoSenha: yup.string().oneOf([yup.ref('senha')], 'Senhas diferem'),
});

export function NovoUsuarioModal({ isOpen, onToggle, onSuccess }: NovoUsuarioModalProps) {
  const [apiErrors, setApiErrors] = useState<string[]>([]);

  const {
    register,
    handleSubmit,
    setFocus,
    reset,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = useForm<NovoUsuarioData>({ resolver: yupResolver(schema) });

  function handleToggle(e: any) {
    if (isSubmitting) return;
    resetModal();
    onToggle(!isOpen);
  }

  function resetModal() {
    setApiErrors([]);
    reset();
  }

  function handleOpened() {
    setFocus('email');
  }

  const handleSubmitNovoUsuario: SubmitHandler<NovoUsuarioData> = async values => {
    try {
      setApiErrors([]);
      await sendNovoUsuarioOrganizacao(values);
      onSuccess && onSuccess();
      resetModal();
    } catch (error: any) {
      if (error.statusCode === 400) {
        setApiErrors(error.messages);
        return;
      }

      return Promise.reject(error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} backdrop={true} onOpened={handleOpened}>
      <ModalHeader toggle={handleToggle}>Minha Organização - Novo Usuário</ModalHeader>
      <Form autoComplete="off" onSubmit={handleSubmit(handleSubmitNovoUsuario)}>
        <ModalBody>
          <AlertErrors errors={apiErrors} />
          {isSubmitSuccessful && !!!apiErrors && <Alert color="success">Usuário cadastrado com sucesso!</Alert>}

          <FormGroup>
            <Label>E-mail do usuário para acesso</Label>
            <InputControl {...register('email')} error={errors.email} />
          </FormGroup>

          <FormGroup className="mt-3">
            <Label>Senha de acesso</Label>
            <InputControl type="password" {...register('senha')} error={errors.senha} autoComplete="new-password" />
          </FormGroup>

          <FormGroup className="mt-3">
            <Label>Confirme a Senha</Label>
            <InputControl
              type="password"
              {...register('confirmacaoSenha')}
              error={errors.confirmacaoSenha}
              autoComplete="new-password"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <SpinnerButton type="submit" loading={isSubmitting}>
            Cadastrar Usuário
          </SpinnerButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
