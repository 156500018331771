import React from 'react';
import lodash, { uniqueId } from 'lodash';
import { PageContent } from 'components/Common/PageContent';
import { obterPaginaVendas } from 'services/vendaService';
import { VendaDto, VendaSituacao } from 'model/types/venda.types';
import { toDateTimeFormat, toMoneyFormat } from 'common/formatters';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Button, Card, CardBody, CardTitle, Col, Input, Label, Row, Table } from 'reactstrap';
import { showToastErrors } from 'common/toast_config';
import { ptBR } from 'date-fns/locale';
import moment, { Moment } from 'moment';

registerLocale('pt-BR', ptBR);

const periodosPredefinidos = [
  { id: uniqueId(), label: 'Hoje', value: 'today' },
  { id: uniqueId(), label: 'Ontem', value: 'yesterday' },
  { id: uniqueId(), label: 'Ultimos 7 dias', value: 'last7days' },
  { id: uniqueId(), label: 'Ultimos 30 dias', value: 'last30days' },
];

const situacoesPredefinidas = [
  { id: uniqueId(), label: 'Abertas', value: VendaSituacao.Aberta },
  { id: uniqueId(), label: 'Finalizadas', value: VendaSituacao.Finalizada },
  { id: uniqueId(), label: 'Canceladas', value: VendaSituacao.Cancelada },
];

type FiltroDataOperacao = {
  dataInicio: Moment;
  dataFim: Moment;
};

const defaultFiltroDataOparacao: FiltroDataOperacao = {
  dataInicio: moment(),
  dataFim: moment(),
};

export const RelatorioVendas = () => {
  const [loading, setLoading] = React.useState(false);
  const [vendas, setVendas] = React.useState<VendaDto[]>([]);
  const [filtroPeriodo, setFiltroPeriodo] = React.useState<string>(periodosPredefinidos[0].value);
  const [filtroSituacao, setFiltroSituacao] = React.useState<VendaSituacao>(situacoesPredefinidas[1].value);
  const [customDataOperacao, setCustomDataOperacao] = React.useState<FiltroDataOperacao>(defaultFiltroDataOparacao);

  const obterDataOperacao = React.useCallback(() => {
    switch (filtroPeriodo) {
      case 'yesterday':
        return {
          dataInicio: moment().subtract(1, 'days'),
          dataFim: moment().subtract(1, 'days'),
        };

      case 'last7days':
        return {
          dataInicio: moment().subtract(7, 'days'),
          dataFim: moment(),
        };

      case 'last30days':
        return {
          dataInicio: moment().subtract(30, 'days'),
          dataFim: moment(),
        };

      case 'custom':
        return customDataOperacao;

      default:
        return {
          dataInicio: moment(),
          dataFim: moment(),
        };
    }
  }, [customDataOperacao, filtroPeriodo]);

  const buscarVendas = React.useCallback(async () => {
    const dataOperacao = obterDataOperacao();
    const periodoInicio = dataOperacao.dataInicio.format('YYYY-MM-DD');
    const periodoFim = dataOperacao.dataFim.format('YYYY-MM-DD');

    const dados: VendaDto[] = [];

    let paginaAtual = 1;
    let possuiMais = true;

    do {
      const pagina = await obterPaginaVendas({
        situacao: filtroSituacao,
        periodoOperacao: `${periodoInicio},${periodoFim}`,
        numeroPagina: paginaAtual,
        tamanhoPagina: 999,
      });

      dados.push(...pagina.dados);
      possuiMais = paginaAtual++ < pagina.totalPaginas;
    } while (possuiMais);

    return dados;
  }, [obterDataOperacao, filtroSituacao]);

  React.useEffect(() => {
    let cancelado = false;
    const fetchData = async () => {
      try {
        setLoading(true);
        const dados = await buscarVendas();
        if (cancelado) return;
        setVendas(dados);
      } catch (err: any) {
        showToastErrors(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      cancelado = true;
    };
  }, [buscarVendas, filtroPeriodo, filtroSituacao]);

  return (
    <PageContent title="Vendas" subTitle="Relatório de Vendas" isLoading={loading}>
      <Row>
        <Col lg="3" className="d-print-none">
          <Card>
            <CardBody>
              <CardTitle>Filtros</CardTitle>
              <div>
                <div className="pt-2">
                  <h5 className="font-size-14 mb-3">Data Operação</h5>
                  {periodosPredefinidos.map(item => (
                    <div className="form-check mt-2" key={item.id}>
                      <Input
                        type="radio"
                        value={item.value}
                        className="form-check-input"
                        id={item.id}
                        checked={filtroPeriodo === item.value}
                        onChange={e => {
                          e.currentTarget.checked && setFiltroPeriodo(item.value);
                        }}
                      />
                      <Label className="form-check-label" htmlFor={item.id}>
                        {item.label}
                      </Label>
                    </div>
                  ))}

                  <div className="form-check mt-2">
                    <Input
                      type="radio"
                      value="custom"
                      className="form-check-input"
                      id="periodo-custom"
                      checked={filtroPeriodo === 'custom'}
                      onChange={e => {
                        e.currentTarget.checked && setFiltroPeriodo('custom');
                      }}
                    />
                    <Label className="form-check-label" htmlFor="periodo-custom">
                      Definido
                    </Label>

                    <Row className="g-1">
                      <Col>
                        <DatePicker
                          onKeyDown={e => e.preventDefault()}
                          disabled={filtroPeriodo !== 'custom'}
                          className="form-control form-control-sm"
                          locale="pt-BR"
                          showYearDropdown
                          dateFormat="dd/MM/yyyy"
                          selected={customDataOperacao.dataInicio.toDate()}
                          onChange={e => setCustomDataOperacao({ ...customDataOperacao, dataInicio: moment(e) })}
                        />
                      </Col>
                      <Col>
                        <DatePicker
                          onKeyDown={e => e.preventDefault()}
                          disabled={filtroPeriodo !== 'custom'}
                          className="form-control form-control-sm"
                          locale="pt-BR"
                          showYearDropdown
                          dateFormat="dd/MM/yyyy"
                          selected={customDataOperacao.dataFim.toDate()}
                          onChange={e => setCustomDataOperacao({ ...customDataOperacao, dataFim: moment(e) })}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="pt-4">
                  <h5 className="font-size-14 mb-3">Situação</h5>
                  {situacoesPredefinidas.map(item => (
                    <div className="form-check mt-2" key={item.id}>
                      <Input
                        type="radio"
                        value={item.value}
                        className="form-check-input"
                        id={item.id}
                        checked={filtroSituacao === item.value}
                        onChange={e => {
                          e.currentTarget.checked && setFiltroSituacao(item.value);
                        }}
                      />
                      <Label className="form-check-label" htmlFor={item.id}>
                        {item.label}
                      </Label>
                    </div>
                  ))}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="9">
          <Card>
            <CardBody>
              <CardTitle>Relatório de Vendas</CardTitle>
              <div className="table-responsive mt-3">
                <Table className="table table-bordered table-condensed table-striped">
                  <thead>
                    <tr>
                      <th>Data Operação</th>
                      <th>Número</th>
                      <th>Desconto</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vendas.map(venda => (
                      <tr key={venda.id}>
                        <td>{toDateTimeFormat(venda.dataOperacao)}</td>
                        <td>{venda.numero.toString().padStart(6, '0')}</td>
                        <td>{toMoneyFormat(venda.totalDesconto, true)}</td>
                        <td>{toMoneyFormat(venda.totalDaVenda, true)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2}>Quantidade vendas: {vendas.length}</td>
                      <td>
                        {toMoneyFormat(
                          lodash.sumBy(vendas, v => v.totalDesconto),
                          true,
                        )}
                      </td>
                      <td>
                        {toMoneyFormat(
                          lodash.sumBy(vendas, v => v.totalDaVenda),
                          true,
                        )}
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </div>

              <div className="mt-1 text-end d-print-none">
                <Button color="success" onClick={() => window.print()}>
                  <i className="fa fa-print" />
                  &nbsp;Imprimir
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageContent>
  );
};
