import classnames from 'classnames';
import { ResumoSituacaoNota, SituacaoNota } from 'model/types/notafiscal.types';
import { Spinner } from 'reactstrap';

interface SituacaoNotaTextoProps {
  situacao: ResumoSituacaoNota;
  loading: boolean;
}

export const SituacaoNotaTexto = ({ situacao, loading = false }: SituacaoNotaTextoProps) => {
  const getTextColor = (situacao: number): string => {
    switch (situacao) {
      case SituacaoNota.Autorizada:
        return 'success';
      case SituacaoNota.Rejeitada:
      case SituacaoNota.Inutilizada:
      case SituacaoNota.Cancelada:
        return 'danger';
      case SituacaoNota.AutorizacaoPendente:
      case SituacaoNota.CancelamentoPendente:
        return 'warning';
      default:
        return 'primary';
    }
  };

  const getSituacaoTexto = (situacao: ResumoSituacaoNota): string => {
    if (situacao.situacao === SituacaoNota.Rejeitada) {
      return `${situacao.situacaoTexto} - ${situacao.situacaoMensagem}`;
    }

    return situacao.situacaoTexto;
  };

  const displaySpinner = (situacao: number) => {
    return (
      situacao === SituacaoNota.ProcessandoAutorizacao ||
      situacao === SituacaoNota.ProcessandoCancelamento ||
      situacao === SituacaoNota.ProcessandoInutilizacao ||
      loading
    );
  };

  return (
    <div className={classnames(['font-size-18', `text-${getTextColor(situacao.situacao)}`])}>
      {!loading && getSituacaoTexto(situacao)}
      {displaySpinner(situacao.situacao) && (
        <div>
          <Spinner color="primary" size="sm" title="Caso esteja demorando muito utilize F5 para recarregar a pagina." />
        </div>
      )}
    </div>
  );
};
