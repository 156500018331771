import { Modal, ModalHeader, ModalBody, Form, FormGroup, Col, Row, Label, Input, Button } from 'reactstrap';
import { AlterarCabecalhoNotaCommand, NotaDto } from 'model/types/notafiscal.types';
import { toDateTimeFormat } from 'common/formatters';
import { Controller, useForm } from 'react-hook-form';
import { alterarCabecalhoNota } from 'services/nfeService';
import { showToastErrors } from 'common/toast_config';
import { InputControl } from 'components/Form/InputControl';
import moment from 'moment';
import { LoadingContent } from 'components/Common/LoadingContent';
//Import Flatepicker
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';

const flatpickrOptions = {
  dateFormat: 'Z',
  locale: Portuguese,
  altInput: true,
  altFormat: 'j \\d\\e F \\d\\e Y \\a\\s H:i',
  enableTime: true,
  time_24hr: true,
  hourIncrement: 1,
  minuteIncrement: 5,
};

type NotaAlterarCabecalhoProps = {
  nota: NotaDto;
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
  onChanged: () => void;
};

type FormData = {
  dataEmissao: Date[] | undefined;
  dataSaidEnt: Date[] | undefined;
  naturezaOperacao: string;
};

export function NotaAlterarCabecalhoModal({ nota, isOpen, onToggle, onChanged }: NotaAlterarCabecalhoProps) {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const handleToggle = () => {
    if (!isSubmitting) {
      onToggle(!isOpen);
    }
  };

  const handleOpened = () => {
    setValue('dataEmissao', [moment(nota.dataEmissao).toDate()]);
    // setValue('dataSaidEnt', nota.saidaEntradaEm);
    setValue('naturezaOperacao', nota.naturezaOperacao);
  };

  const handleValidSubmit = async (values: FormData) => {
    if (!values.dataEmissao) {
      showToastErrors('Data emissão é obrigatório');
      return;
    }

    try {
      const dataEmissao = moment(values.dataEmissao[0]);
      const dataSaida = values.dataSaidEnt ? moment(values.dataSaidEnt[0]) : null;

      const command: AlterarCabecalhoNotaCommand = {
        notaId: nota.id,
        destinoOperacao: nota.destinoOperacao,
        indicadorOperacaoConsumidor: nota.indicadorOperacaoConsumidor,
        indicadorPrecensaComprador: nota.indicadorPrecensaComprador,
        naturezaOperacao: values.naturezaOperacao,
        emissaoEm: dataEmissao.toDate(),
        saidaEntradaEm: dataSaida?.toDate(),
      };

      await alterarCabecalhoNota(command);
      onChanged();
    } catch (error: any) {
      showToastErrors(error);
    }
  };

  return (
    <Modal
      size="lg"
      className="modal-fullscreen-lg-down"
      isOpen={isOpen}
      toggle={handleToggle}
      centered={false}
      onOpened={handleOpened}
    >
      <ModalHeader toggle={handleToggle}>Editar Informações Cabeçalho NF-e</ModalHeader>
      <ModalBody>
        <LoadingContent isLoading={isSubmitting}>
          <Form onSubmit={handleSubmit(handleValidSubmit)}>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>Data Emissão:</Label>
                  <Controller
                    name="dataEmissao"
                    control={control}
                    render={({ field }) => (
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="Data emissão da NF-e"
                        options={flatpickrOptions}
                        {...field}
                      />
                    )}
                  />
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label>Data Saida/Ent:</Label>
                  <Controller
                    name="dataSaidEnt"
                    control={control}
                    render={({ field }) => (
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="Data saida da NF-e (opcional)"
                        options={flatpickrOptions}
                        {...field}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <FormGroup>
                <Label>Natureza da Operação</Label>
                <InputControl {...register('naturezaOperacao')} />
              </FormGroup>
            </Row>

            <div className="text-end">
              <Button color="primary">Salvar alterações</Button>
            </div>
          </Form>
        </LoadingContent>
      </ModalBody>
    </Modal>
  );
}
