import { forwardRef, ForwardRefRenderFunction, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { OptionTypeBase } from 'react-select';
import AsyncSelect, { Async } from 'react-select/async';
import { obterTodasOrigensMercadoria } from 'services/origemMercadoriaService';
import { OrigemMercadoriaDto } from 'model/types/fiscal.types';
import { sortBy } from 'lodash';

export interface SelectOrigemMercadoriaProps {
  name?: string;
  value?: string;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onSelected?: (value: OrigemSelected) => void;
  onKeyDown?: KeyboardEventHandler;
  menuPlacement?: 'top' | 'auto' | 'bottom';
  error?: FieldError;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string | undefined;
}

export type OrigemSelected = OrigemMercadoriaDto;

interface OrigemOption extends OptionTypeBase {
  value: string;
  label: string;
  object: OrigemSelected;
}

const Component: ForwardRefRenderFunction<Async<OrigemOption>, SelectOrigemMercadoriaProps> = (
  {
    name,
    value,
    onChange,
    onSelected,
    onBlur,
    onKeyDown,
    menuPlacement = 'auto',
    error,
    autoFocus,
    disabled,
    className,
  },
  ref,
) => {
  const loadOptionsRef = useRef<NodeJS.Timeout>();
  const [isLoading, setIsLoading] = useState(false);
  const [optionSelected, setOptionSelected] = useState<OrigemOption | null>(null);

  useEffect(() => {
    let isCanceled = false;

    async function fetchValue() {
      setIsLoading(true);
      try {
        const dataOptions = await fetchOptions();
        if (isCanceled) return;
        setOptionSelected(dataOptions.find(x => x.value === value) ?? null);
      } finally {
        setIsLoading(false);
      }
    }

    if (!value) {
      setOptionSelected(null);
    } else {
      fetchValue();
    }

    return () => {
      isCanceled = true;
    };
  }, [value]);

  async function fetchOptions(): Promise<OrigemOption[]> {
    const data = await obterTodasOrigensMercadoria();

    const mappedOptions = data.map(item => ({
      value: item.id.toString(),
      label: `${item.id} - ${item.descricao}`,
      object: item,
    }));

    return mappedOptions;
  }

  function promiseOptions(inputValue: string): Promise<OrigemOption[]> {
    loadOptionsRef.current && clearTimeout(loadOptionsRef.current);

    return new Promise<OrigemOption[]>((resolve, reject) => {
      loadOptionsRef.current = setTimeout(async () => {
        try {
          const fetchedData = await fetchOptions();
          const filteredData = fetchedData.filter(
            i =>
              inputValue === '' ||
              i.label.toLowerCase().startsWith(inputValue.toLowerCase()) ||
              i.label.toLowerCase().includes(inputValue.toLowerCase()),
          );

          resolve(sortBy(filteredData, 'label'));
        } catch (error: any) {
          console.error('SelectOrigemMercadoria loadOptions - Error', error);
          reject(error);
        }
      }, 100);
    });
  }

  function handleOnChange(value: OrigemOption | null) {
    if (!!!value) {
      onChange && onChange('');
      return;
    }

    setOptionSelected(value);
    onChange && onChange(value.value);
    onSelected && onSelected(value.object);
  }

  return (
    <>
      <AsyncSelect
        ref={ref}
        name={name}
        isDisabled={disabled}
        isLoading={isLoading}
        onKeyDown={onKeyDown}
        value={optionSelected}
        onChange={handleOnChange}
        onBlur={onBlur}
        defaultOptions={true}
        loadOptions={promiseOptions}
        menuPlacement={menuPlacement}
        className={className}
        classNamePrefix="select2-selection"
        placeholder="digite para buscar..."
        noOptionsMessage={() => 'nenhum registro encontrado para o termo buscado'}
        autoFocus={autoFocus}
      />
      {!!error?.message && <span className="text-danger small">{error?.message}</span>}
    </>
  );
};

export const SelectOrigemMercadoria = forwardRef(Component);
