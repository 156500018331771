import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { ApiError } from '../api/api.types';

toastr.options = {
  positionClass: 'toast-top-right',
  timeOut: 4500,
  extendedTimeOut: 1500,
  closeButton: true,
  debug: false,
  progressBar: false,
  preventDuplicates: false,
  newestOnTop: true,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
  showDuration: 200,
  hideDuration: 100,
};

type ToastColor = 'success' | 'info' | 'warning' | 'danger' | 'primary' | 'default';

const showToastErrors = (error: string | string[] | Error | any) => {
  const apiError = error as ApiError;
  if (apiError.messages && apiError.problem) {
    const messages = !!apiError.messages.length ? apiError.messages : [`${apiError.problem} - ${apiError.statusCode}`];
    messages.forEach((i: string) => toastr.error(i));
    return;
  }

  if (Array.isArray(error)) {
    error.forEach(i => toastr.error(i));
    return;
  }

  if (error instanceof Error) {
    toastr.error(error.message);
    return;
  }

  if (typeof error === 'string') {
    toastr.error(error);
    return;
  }

  console.warn('showToastErrors', error);
  toastr.error('Algo inesperado ocorreu e não foi possível completar a operação.');
};

const showToastSuccess = (message: string) => {
  toastr.success(message);
};

const showToastInfo = (message: string) => {
  toastr.info(message);
};

const showToast = (message: string, color: ToastColor) => {
  switch (color) {
    case 'success':
      toastr.success(message);
      break;
    case 'info':
      toastr.info(message);
      break;
    case 'warning':
      toastr.warning(message);
      break;
    case 'danger':
      toastr.error(message);
      break;
    default:
      toastr.info(message);
  }
};

export { toastr, showToastErrors, showToastSuccess, showToastInfo, showToast };
