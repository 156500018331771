import { SpinnerButton } from 'components/Button/SpinnerButton';
import { PageContent } from 'components/Common/PageContent';
import { InputControl } from 'components/Form/InputControl';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col, Form, InputGroup, Label, Row } from 'reactstrap';
import { SelectTipoPessoa } from 'components/Form/SelectTipoPessoa';
import { SelectCidade } from 'components/Form/SelectCidade';
import { obterEnderecoPorCep } from 'api/buscarCep';
import { obterCidadePeloIbge } from 'services/cidadeService';
import { receitaWSConsulta } from 'services/empresaService';
import { addCepMask, addCpfCnpjMask, addTelefoneMask, removeMask } from 'common/mask';
import { showToastErrors, showToastSuccess } from 'common/toast_config';
import { PageFooter } from 'components/Common/PageFooter';
import { toNumber } from 'lodash';
import { SelectIndicadorIE } from 'components/Form/SelectIndicadorIE';

import {
  alterarCliente,
  AlterarClienteCommand,
  cadastrarNovoCliente,
  ClienteCommand,
  NovoClienteCommand,
  obterClientePeloId,
} from 'services/clienteService';

interface ParamsData {
  id: string;
}

interface ClienteForm {
  nome: string;
  tipoPessoa: string;
  indicadorIe: string;
  razaoSocial: string;
  email: string;
  telefone: string;
  cpf: string;
  cnpj: string;
  inscricaoEstadual: string;
  enderecoCep: string;
  enderecoLogradouro: string;
  enderecoNumero: string;
  enderecoBairro: string;
  enderecoCidadeId: string;
}

export function ClienteCadastro() {
  const { register, handleSubmit, setValue, watch, control, getValues, reset } = useForm<ClienteForm>();

  const { id } = useParams<ParamsData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const watchTipoPessoa = watch('tipoPessoa', '1');

  useEffect(() => {
    let isCanceled = false;

    async function fetchData() {
      setIsLoading(true);

      try {
        const clienteDto = await obterClientePeloId(id);
        if (isCanceled || clienteDto == null) return;

        setValue('nome', clienteDto.nome);
        setValue('tipoPessoa', clienteDto.tipo.toString());
        setValue('indicadorIe', clienteDto.indicadorIe.toString());
        setValue('razaoSocial', clienteDto.razaoSocial);
        setValue('email', clienteDto.email);
        setValue('telefone', addTelefoneMask(clienteDto.telefone) ?? '');
        setValue('cpf', addCpfCnpjMask(clienteDto.cpfCnpj) ?? '');

        if (clienteDto.cpfCnpj.length === 14) {
          setValue('cpf', '');
          setValue('cnpj', addCpfCnpjMask(clienteDto.cpfCnpj) ?? '');
        }

        setValue('inscricaoEstadual', clienteDto.inscricaoEstadual);
        setValue('enderecoCep', addCepMask(clienteDto.endereco.cep) ?? '');
        setValue('enderecoLogradouro', clienteDto.endereco.logradouro);
        setValue('enderecoNumero', clienteDto.endereco.numero);
        setValue('enderecoBairro', clienteDto.endereco.bairro);

        const cidade = await obterCidadePeloIbge(Number(clienteDto.endereco.cidadeCodigoIbge));

        setValue('enderecoCidadeId', cidade.id);
      } catch (error: any) {
        showToastErrors(error.messages);
      } finally {
        setIsLoading(false);
      }
    }

    if (!!id) {
      fetchData();
    }

    return () => {
      isCanceled = true;
    };
  }, [id, setValue]);

  const handleOnSubmit = async (data: ClienteForm) => {
    setIsLoading(true);

    try {
      const command: ClienteCommand = {
        nome: data.nome,
        tipo: toNumber(data.tipoPessoa),
        indicadorIe: toNumber(data.indicadorIe),
        cpfCnpj: removeMask(data.tipoPessoa === '1' ? data.cpf : data.cnpj) ?? '',
        razaoSocial: data.razaoSocial,
        telefone: removeMask(data.telefone) ?? '',
        inscricaoEstadual: data.inscricaoEstadual,
        email: data.email,
        temEndereco: false,
        enderecoCep: undefined,
        enderecoBairro: undefined,
        enderecoCidadeId: undefined,
        enderecoLogradouro: undefined,
        enderecoNumero: undefined,
      };

      command.temEndereco =
        !!data.enderecoCep ||
        !!data.enderecoBairro ||
        !!data.enderecoCidadeId ||
        !!data.enderecoLogradouro ||
        !!data.enderecoNumero;

      if (command.temEndereco) {
        command.enderecoCep = data.enderecoCep;
        command.enderecoBairro = data.enderecoBairro;
        command.enderecoCidadeId = data.enderecoCidadeId;
        command.enderecoLogradouro = data.enderecoLogradouro;
        command.enderecoNumero = data.enderecoNumero;
      }

      if (!!id) {
        const alterarCommand: AlterarClienteCommand = { ...command, id: id };
        await alterarCliente(alterarCommand);
        showToastSuccess('Cliente alterado com sucesso!');
        return;
      }

      const novoClienteCommand: NovoClienteCommand = { ...command };
      await cadastrarNovoCliente(novoClienteCommand);
      showToastSuccess('Cliente cadastrado com sucesso!');
      reset();
    } catch (error: any) {
      showToastErrors(error.messages);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchCep = async (event: any) => {
    setIsLoading(true);

    try {
      const cep = removeMask(getValues().enderecoCep) ?? '';
      if (!cep) {
        showToastErrors(['Preciso de um CEP válido para buscar o endereço']);
        return;
      }

      const cidadeViaCep = await obterEnderecoPorCep(cep);
      const cidade = await obterCidadePeloIbge(cidadeViaCep.ibge);

      setValue('enderecoCidadeId', cidade.id);
      setValue('enderecoLogradouro', cidadeViaCep.logradouro);
      setValue('enderecoBairro', cidadeViaCep.bairro);
    } catch (error: any) {
      showToastErrors(error.messages);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchCnpj = async (event: any) => {
    setIsLoading(true);

    try {
      const cnpjBuscar = removeMask(getValues().cnpj) ?? '';
      if (!cnpjBuscar || cnpjBuscar.length !== 14) {
        showToastErrors(['CNPJ informado para a consulta é inválido.']);
        return;
      }

      const { data } = await receitaWSConsulta(cnpjBuscar);

      setValue('nome', data?.fantasia ?? '');
      setValue('razaoSocial', data?.nome ?? '');
      setValue('email', data?.email ?? '');
      setValue('telefone', addTelefoneMask(data?.telefone) ?? '');
      setValue('enderecoCep', addCepMask(data?.cep) ?? '');
      setValue('enderecoLogradouro', data?.logradouro ?? '');
      setValue('enderecoBairro', data?.bairro ?? '');
      setValue('enderecoNumero', data?.numero ?? '');
      setValue('enderecoCidadeId', data?.cidadeId ?? '');
    } catch (error: any) {
      showToastErrors(error.messages);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageContent title="Clientes" subTitle="Cadastro Cliente" isLoading={isLoading}>
        <Form>
          <Card>
            <CardBody>
              <Row xs={1}>
                <Col sm={3} className="mt-3">
                  <Label>Tipo Pessoa</Label>
                  <SelectTipoPessoa {...register('tipoPessoa')} />
                </Col>

                {watchTipoPessoa === '1' && (
                  <Col sm={3} className="mt-3">
                    <Label>CPF</Label>
                    <InputControl
                      {...register('cpf')}
                      onKeyUp={e => (e.currentTarget.value = addCpfCnpjMask(e.currentTarget.value))}
                    />
                  </Col>
                )}

                {watchTipoPessoa === '2' && (
                  <Col sm={3} className="mt-3">
                    <Label>CNPJ</Label>
                    <InputGroup>
                      <InputControl
                        {...register('cnpj')}
                        onKeyUp={e => (e.currentTarget.value = addCpfCnpjMask(e.currentTarget.value))}
                      />

                      <SpinnerButton onClick={handleSearchCnpj} type="button" color="info">
                        Consultar
                      </SpinnerButton>
                    </InputGroup>
                  </Col>
                )}

                <Col sm={3} className="mt-3">
                  <Label>Inscrição Estadual</Label>
                  <InputControl {...register('inscricaoEstadual')}></InputControl>
                </Col>

                <Col sm={3} className="mt-3">
                  <Label>Indicador IE</Label>
                  <SelectIndicadorIE {...register('indicadorIe')} />
                </Col>
              </Row>

              {watchTipoPessoa === '2' && (
                <Row>
                  <Col xs={12} className="mt-3">
                    <Label>Razão Social</Label>
                    <InputControl {...register('razaoSocial')}></InputControl>
                  </Col>
                </Row>
              )}

              <Row>
                <Col xs={12} className="mt-3">
                  <Label>Nome</Label>
                  <InputControl {...register('nome')}></InputControl>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={8} className="mt-3">
                  <Label>E-mail</Label>
                  <InputControl {...register('email')}></InputControl>
                </Col>
                <Col xs={12} sm={4} className="mt-3">
                  <Label>Telefone</Label>
                  <InputControl
                    {...register('telefone')}
                    onKeyUp={e => (e.currentTarget.value = addTelefoneMask(e.currentTarget.value))}
                  ></InputControl>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4>Endereço</h4>
                  <Row>
                    <Col xs={12} sm={5} className="mt-3">
                      <Label>CEP</Label>
                      <InputGroup>
                        <InputControl
                          {...register('enderecoCep')}
                          onKeyUp={e => (e.currentTarget.value = addCepMask(e.currentTarget.value))}
                        />

                        <SpinnerButton onClick={handleSearchCep} type="button" color="info">
                          Consultar
                        </SpinnerButton>
                      </InputGroup>
                    </Col>
                    <Col xs={12} sm={7} className="mt-3">
                      <Label>Logradouro</Label>
                      <InputControl {...register('enderecoLogradouro')}></InputControl>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} sm={4} className="mt-3">
                      <Label>Número</Label>
                      <InputControl {...register('enderecoNumero')}></InputControl>
                    </Col>
                    <Col xs={12} sm={4} className="mt-3">
                      <Label>Bairro</Label>
                      <InputControl {...register('enderecoBairro')}></InputControl>
                    </Col>
                    <Col xs={12} sm={4} className="mt-3">
                      <Label>Cidade / UF</Label>
                      <Controller
                        name="enderecoCidadeId"
                        control={control}
                        render={({ field }) => <SelectCidade {...field} />}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </PageContent>

      <PageFooter>
        <Row>
          <Col>
            <SpinnerButton onClick={handleSubmit(handleOnSubmit)} color="primary" type="button" loading={isLoading}>
              {!!id ? 'Alterar Cliente' : 'Cadastrar Cliente'}
            </SpinnerButton>
          </Col>
        </Row>
      </PageFooter>
    </>
  );
}
