import { ENDPOINTS } from 'api';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import { PageContent } from 'components/Common/PageContent';
import { decimalColumn, dummyColumn, textColumn } from 'components/Table/columnFactory';
import RemotePageTable from 'components/Table/RemotePageTable';
import { ROUTES } from 'config/routes';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';

const columnActions = dummyColumn('', (cell, row) => {
  return (
    <div className="text-start">
      <Link to={ROUTES.PRODUTO_ALTERAR(row.id)} className="btn btn-sm btn-info">
        Editar
      </Link>
    </div>
  );
});

const columns = [
  columnActions,
  textColumn('Nome', 'nome'),
  textColumn('Unidade', 'unidadeMedida'),
  decimalColumn('Estoque', 'estoqueAtual', { decimals: 4 }),
  decimalColumn('Preço Venda', 'precoVenda'),
  decimalColumn('Preço Custo', 'precoCusto'),
];

const RightBar = () => {
  return (
    <>
      <Link to={ROUTES.PRODUTO_NOVO}>
        <PrimaryButton icon="plus">Novo Produto</PrimaryButton>
      </Link>
    </>
  );
};

export function ListagemProdutos() {
  return (
    <>
      <PageContent title="Clientes" subTitle="Listar Clientes">
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <RemotePageTable url={ENDPOINTS.ESTOQUE_PRODUTOS_LISTAR()} columns={columns} rightBar={<RightBar />} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </PageContent>
    </>
  );
}
