import { api, ENDPOINTS } from 'api';
import { ApiResponse } from 'apisauce';

export interface ClienteDto {
  id: string;
  nome: string;
  tipo: number;
  indicadorIe: number;
  razaoSocial: string;
  email: string;
  telefone: string;
  cpfCnpj: string;
  inscricaoEstadual: string;
  endereco: {
    bairro: string;
    cep: string;
    cidade: string;
    cidadeCodigoIbge: string;
    logradouro: string;
    numero: string;
    uf: string;
  };
}

export async function obterClientePeloNome(nome: string): Promise<ClienteDto[]> {
  return new Promise(async (resolve, reject) => {
    try {
      const url = ENDPOINTS.CLIENTES_LISTAR() + `?textoPesquisa=${nome}&tamanhoPagina=25`;
      const { data } = await api.get<any>(url);

      if (data) {
        resolve(data.dados as ClienteDto[]);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export async function obterClientePeloId(id: string): Promise<ClienteDto> {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get<ClienteDto>(ENDPOINTS.CLIENTES_POR_ID(id));
      if (data) {
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export interface ClienteCommand {
  nome: string;
  tipo: number;
  indicadorIe: number;
  razaoSocial: string;
  email: string;
  telefone: string;
  cpfCnpj: string;
  inscricaoEstadual: string;
  temEndereco: boolean;
  enderecoCep?: string;
  enderecoLogradouro?: string;
  enderecoNumero?: string;
  enderecoBairro?: string;
  enderecoCidadeId?: string;
}

export type NovoClienteCommand = ClienteCommand;
export interface AlterarClienteCommand extends ClienteCommand {
  id: string;
}

export async function cadastrarNovoCliente(request: NovoClienteCommand): Promise<ApiResponse<ClienteDto>> {
  return await api.post(ENDPOINTS.CLIENTES_NOVO(), request);
}

export async function alterarCliente(request: AlterarClienteCommand): Promise<ApiResponse<ClienteDto>> {
  return await api.put(ENDPOINTS.CLIENTES_ALTERAR(request.id), request);
}
