export type PaginaVendas = {
  numeroPagina: number;
  tamanhoPagina: number;
  totalRegistros: number;
  totalPaginas: number;
  paginaAnterior?: number | null;
  proximaPagina?: number | null;
  dados: VendaDto[];
};

export type PaginaVendasParams = {
  situacao?: VendaSituacao;
  periodoOperacao?: string;
  usuarioCriou?: string;
  tamanhoPagina?: number;
  numeroPagina?: number;
};

export type TodasVendasPeriodoParams = {
  situacao?: VendaSituacao;
  usuarioCriou?: string;
  dataInicio: string;
  dataFim: string;
};

export interface VendaDto {
  id: string;
  numero: number;
  situacao: number;
  situacaoTexto: string;
  dataOperacao: Date;
  dataCadastro: Date;
  usuarioCriou: string;
  empresa: EmpresaVendaDto;
  cliente?: ClienteVendaDto;
  quantidadeItens: number;
  totalBrutoItens: number;
  totalDescontoItens: number;
  totalItens: number;
  descontoNaVenda: number;
  totalFrete: number;
  totalDesconto: number;
  totalDaVenda: number;
  totalPago: number;
  itens: VendaItemDto[];
  pagamentos: PagamentoVenda[];
}

export enum VendaSituacao {
  Aberta = 1,
  Finalizada = 7,
  Cancelada = 10,
}

export interface VendaItemDto {
  id: string;
  vendaId: string;
  numero: number;
  produtoId: string;
  produtoNome: string;
  precoUnitario: number;
  quantidade: number;
  totalBruto: number;
  descontoItem: number;
  totalItem: number;
  descontoRateio: number;
  totalFinal: number;
}

interface EmpresaVendaDto {
  id: string;
  nome: string;
  nomeFantasia: string;
  cpfCnpj: string;
}

interface ClienteVendaDto {
  id: string;
  nome: string;
  cpfCnpj: string;
  telefone: string;
  razaoSocial?: string;
  email?: string;
  endereco?: {
    cep: string;
    logradouro: string;
    complemento: string;
    bairro: string;
    numero: string;
    cidade: string;
    uf: string;
  };
}

export interface PagamentoVenda {
  id: string;
  tipo: string;
  valor: number;
  quantidadeParcelas: number;
  parcelas: Parcela[];
}

export interface Parcela {
  numero: number;
  vencimento: string;
  valor: number;
}

// Commands

export interface NovaVendaCommand {
  empresaId: string;
  clienteId?: string;
  dataOperacao?: Date;
}

export interface NovoPagamentoCommand {
  tipo: number;
  quantidadeParcelas: number;
  valor: number;
  parcelas: Parcela[];
}

export interface AlterarTotalVendaCommand {
  valorDesconto: number;
  valorFrete: number;
}

export interface AddItemCommand {
  produtoId: string;
  quantidade: number;
  precoUnitario: number;
  desconto: number;
}
