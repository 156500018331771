import { Alert } from 'reactstrap';

interface AlertErrorsProps {
  className?: string | undefined;
  errors: string[] | undefined;
  id?: string;
}

export function AlertErrors({ className, errors, id }: AlertErrorsProps) {
  if (!errors || errors.length === 0) return <></>;

  return (
    <Alert color="danger" id={id ?? `back_end_erros`} className={className}>
      {errors.map((msg, i) => {
        return <div key={i}>{msg}</div>;
      })}
    </Alert>
  );
}
