import { useRemotePageTableContext } from 'providers/RemotePageTableProvider';
import { useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { ROUTES } from '../../config/routes';

function LogoutPage() {
  const tables = useRemotePageTableContext();

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('usuario');
    tables.clear();
  }, [tables]);

  return <Redirect to={{ pathname: ROUTES.LOGIN }} />;
}

export const Logout = withRouter(LogoutPage);
