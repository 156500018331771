import { forwardRef, ForwardRefRenderFunction, SelectHTMLAttributes } from 'react';

type SelectTipoPessoaProps = SelectHTMLAttributes<HTMLSelectElement>;

interface Option {
  label: string;
  value: string;
}

const options: Option[] = [
  { label: 'Física', value: '1' },
  { label: 'Jurídica', value: '2' },
];

const Component: ForwardRefRenderFunction<HTMLSelectElement, SelectTipoPessoaProps> = ({ ...rest }, ref) => {
  return (
    <select ref={ref} className="form-select" {...rest}>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export const SelectTipoPessoa = forwardRef(Component);
