import { useState, useEffect } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

// import images
import logo from '../../assets/images/logo-dark.png';

// import contexts
import { getAccessToken } from '../../api/requests';
import { autorizacaoValida } from '../../services/autorizacao';

// components
import { Row, Col, Container, Input, Label } from 'reactstrap';
import { AlertErrors } from '../../components/Alert/AlertErrors';
import { SpinnerButton } from '../../components/Button/SpinnerButton';
import { ROUTES } from 'config/routes';

function LoginPage() {
  const history = useHistory();
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (autorizacaoValida()) {
      history.push(ROUTES.HOME);
      return;
    }
  }, [history]);

  const handleValidSubmit = async event => {
    event.preventDefault();
    setErrors(null);
    setLoading(true);

    try {
      const response = await getAccessToken(email, password);
      const { acessToken, refreshToken } = response.data;
      localStorage.setItem('token', acessToken);
      localStorage.setItem('refreshToken', refreshToken);
      history.push(ROUTES.HOME);
    } catch (error) {
      setErrors(error.messages);
      setLoading(false);
    }
  };

  return (
    <>
      <MetaTags>
        <title>Login | Sistema Fusion</title>
      </MetaTags>
      <Container fluid className="p-0">
        <Row className="g-0">
          <Col xl={3} md={5}>
            <div className="auth-full-page-content p-md-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5 align-items-center m-auto">
                    <Link to={ROUTES.LOGIN} className="d-block auth-logo">
                      <img alt="logo-fusion" src={logo} className="auth-logo-dark" />
                    </Link>
                  </div>

                  <div className="my-auto m-auto align-items-center">
                    <div>
                      <h5 className="text-primary">Bem Vindo !</h5>
                      <p className="text-muted">Faça o login para desfrutar de todos os Recursos Incríveis.</p>
                    </div>

                    <div className="mt-4">
                      <AlertErrors errors={errors} id="login_alerta_erros_back_end" />

                      <form className="form-horizontal" onSubmit={handleValidSubmit}>
                        <Col xs={12}>
                          <Label for="email">Email</Label>
                          <Input
                            autoFocus
                            autoComplete="off"
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Informe seu e-mail"
                            onChange={e => setEmail(e.target.value)}
                          />
                        </Col>

                        <Col xs={12} className="mt-1">
                          <div className="float-end">
                            <Link to={ROUTES.RECUPERAR_SENHA} className="text-muted" tabIndex={-1}>
                              Esqueceu a senha?
                            </Link>
                          </div>
                          <Label for="password">Senha</Label>
                          <Input
                            autoComplete="off"
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Informe sua senha"
                            onChange={e => setPassword(e.target.value)}
                          />
                        </Col>

                        <div className="mt-3 d-grid">
                          <SpinnerButton
                            type="submit"
                            color="primary"
                            loading={loading}
                            id="spinner_login_acessar_sistema"
                          >
                            Acessar
                          </SpinnerButton>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="mt-3 mt-md-4 text-center">
                    <p className="mb-0">
                      © {new Date().getFullYear()} Sistema Fusion.
                      <br />
                      Feito com <i className="mdi mdi-heart text-danger"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col xl={9} md={7} className="d-none d-md-flex">
            <div className="auth-full-bg pt-lg-5 p-4">
              <div className="w-100">
                <div className="bg-overlay"></div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export const Login = withRouter(LoginPage);
