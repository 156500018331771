import { addCpfCnpjMask } from 'common/mask';
import { IndicadorIeLabel } from 'model/types/common.types';
import { NotaClienteDto } from 'model/types/notafiscal.types';
import { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { NotaClienteAdd } from './NotaClienteAdd';

type NotaClienteProps = {
  cliente: NotaClienteDto | null;
  podeEditar: boolean;
  onAddCliente: (clienteId: string) => void;
  onRemoverCliente: () => void;
};

export function NotaCliente({ cliente, podeEditar, onAddCliente, onRemoverCliente }: NotaClienteProps) {
  const [showAddCliente, setShowAddCliente] = useState(false);

  return (
    <>
      {!cliente ? (
        <Row>
          {!showAddCliente ? (
            <Col xs={12}>
              <div className="fs-5">
                Nota não possui um cliente
                <button className="btn btn-link font-size-16 p-0 ps-1" onClick={() => setShowAddCliente(true)}>
                  clique aqui para adicionar.
                </button>
              </div>
            </Col>
          ) : (
            <NotaClienteAdd onCancel={() => setShowAddCliente(false)} onSubmit={onAddCliente} />
          )}
        </Row>
      ) : (
        <>
          <Row xs={1} className="gy-2">
            <Col>
              <div className="fs-5 text-uppercase">{cliente.nome}</div>
              <div className="text-muted">CPF/CNPJ: {addCpfCnpjMask(cliente.cpfCnpj)}</div>
              {cliente.inscricaoEstadual && <div className="text-muted">IE: {cliente.inscricaoEstadual}</div>}
              <div className="text-muted">INDICADOR IE: {IndicadorIeLabel.get(cliente.indicadorIe)}</div>
            </Col>

            <Col>
              {cliente.logradouro ? (
                <div className="fs-5 text-uppercase">{cliente.logradouro}</div>
              ) : (
                <div>{'SEM ENDEREÇO'}</div>
              )}
              {cliente.numero && <div className="text-muted">{`NÚMERO: ${cliente.numero}`}</div>}
              {cliente.bairro && <div className="text-muted">{`BAIRRO: ${cliente.bairro}`}</div>}
              {cliente.cidadeUf && <div className="text-muted">{`CIDADE: ${cliente.cidadeUf}`}</div>}
            </Col>

            {podeEditar && (
              <Col>
                <div>
                  <button type="button" className="btn btn-link p-0" onClick={() => onRemoverCliente()}>
                    Clique aqui para remover o cliente
                  </button>
                </div>
              </Col>
            )}
          </Row>
        </>
      )}
    </>
  );
}
