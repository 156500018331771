import { toNumber } from 'lodash';

export function removeMask(value?: string): string | undefined {
  if (!value) return value;

  return value.replace(/[^0-9]/gm, '');
}

export function addMoneyMask(value?: string | number): string {
  const originalValue = value?.toString() ?? '';
  let newValue = toNumber(originalValue.replace(/\D/g, '')).toString();

  if (newValue.length < 3) {
    newValue = newValue.padStart(3, '0');
  }

  newValue = newValue.replace(/(\d)(\d{2})$/, '$1,$2');
  newValue = newValue.replace(/(?=(\d{3})+(\D))\B/g, '.');

  return newValue;
}

export function addCpfCnpjMask(value?: string): string {
  const rawValue = value?.replace(/[^0-9]/gm, '') ?? '';

  if (rawValue.length === 11) {
    return rawValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
  } else if (rawValue.length === 14) {
    return rawValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
  } else if (rawValue.length > 14) {
    return addCpfCnpjMask(rawValue.substring(0, 14));
  }

  return rawValue;
}

export function addCepMask(value?: string): string {
  const rawValue = value?.replace(/[^0-9]/gm, '') ?? '';

  if (rawValue.length === 8) {
    return rawValue.replace(/^(\d{5})(\d{3})$/, '$1-$2');
  } else if (rawValue.length > 8) {
    return addCepMask(rawValue.substring(0, 8));
  }

  return rawValue;
}

export function addTelefoneMask(value?: string): string {
  const rawValue = value?.replace(/[^0-9]/gm, '') ?? '';

  if (rawValue.length === 11) {
    return rawValue.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, '($1) $2 $3-$4');
  } else if (rawValue.length === 10) {
    return rawValue.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
  } else if (rawValue.length > 11) {
    return addTelefoneMask(rawValue.substring(0, 11));
  }

  return rawValue;
}
