import { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardBody, Col, Media, Row } from 'reactstrap';
import { PageContent } from 'components/Common/PageContent';
import { AlterarSenhaPerfil } from './AlterarSenhaPerfil';

import { obterUsuarioLogado } from 'api/requests';

const usuarioVazio = { nome: '', email: '', id: '' };
const organizacaoVazia = { nome: '', responsavel: '', emailPrincipal: '' };

export function UsuarioPerfil() {
  const [usuario, setUsuario] = useState(usuarioVazio);
  const [organizacao, setOrganizacao] = useState(organizacaoVazia);

  useEffect(() => {
    let isCanceled = false;

    async function fetchData() {
      let { data: usuario } = await obterUsuarioLogado();
      if (isCanceled) return;

      setUsuario(usuario);
      setOrganizacao(usuario.organizacoes[0]);
    }

    fetchData();

    return () => (isCanceled = true);
  }, []);

  return (
    <>
      <PageContent title="Perfil" subTitle="Meu Usuário">
        <Row className="">
          <Col lg={12}>
            <Card outline color="primary" className="border">
              <CardBody>
                <Media>
                  <div className="avatar-md">
                    <span className="avatar-title rounded-circle fs-2">{usuario.nome.charAt(0).toUpperCase()}</span>
                  </div>

                  <Media body className="align-self-center">
                    <div className="text-muted ms-2">
                      <h5 className="text-primary">{usuario.nome.split('@')[0]}</h5>
                      <p className="mb-1">{usuario.email}</p>
                    </div>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Card outline color="primary" className="border">
              <CardHeader className="bg-transparent">
                <h5 className="my-0 text-primary">
                  <i className="mdi mdi-bullseye-arrow me-2" />
                  Detalhes da Organização
                </h5>
              </CardHeader>
              <CardBody>
                <CardTitle className="mt-0">{organizacao.nome}</CardTitle>
                <ul className="list-unstyled mb-0">
                  <li>Responsável: {organizacao.responsavel}</li>
                  <li>Email: {organizacao.emailPrincipal}</li>
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="">
          <Col lg={12}>
            <AlterarSenhaPerfil usuarioId={usuario.id} />
          </Col>
        </Row>
      </PageContent>
    </>
  );
}
