import classnames from 'classnames';
import { ResumoSituacaoCupom, SituacaoCupom } from 'model/types/cupomfiscal.types';
import { Spinner } from 'reactstrap';

interface SituacaoCupomTextoProps {
  situacao: ResumoSituacaoCupom;
  loading: boolean;
}

export const SituacaoCupomTexto = ({ situacao, loading = false }: SituacaoCupomTextoProps) => {
  const getTextColor = (situacao: number): string => {
    switch (situacao) {
      case SituacaoCupom.Autorizado:
        return 'success';
      case SituacaoCupom.Rejeitado:
      case SituacaoCupom.Inutilizado:
      case SituacaoCupom.Cancelado:
        return 'danger';
      case SituacaoCupom.AutorizacaoPendente:
      case SituacaoCupom.CancelamentoPendente:
        return 'warning';
      default:
        return 'primary';
    }
  };

  const getSituacaoTexto = (situacao: ResumoSituacaoCupom): string => {
    if (situacao.situacao === SituacaoCupom.Rejeitado) {
      return `${situacao.situacaoTexto} - ${situacao.situacaoMensagem}`;
    }

    return situacao.situacaoTexto;
  };

  const displaySpinner = (situacao: number) => {
    return (
      situacao === SituacaoCupom.ProcessandoAutorizacao ||
      situacao === SituacaoCupom.ProcessandoCancelamento ||
      situacao === SituacaoCupom.ProcessandoInutilizacao ||
      loading
    );
  };

  return (
    <div className={classnames(['font-size-18', `text-${getTextColor(situacao.situacao)}`])}>
      {!loading && getSituacaoTexto(situacao)}
      {displaySpinner(situacao.situacao) && (
        <div>
          <Spinner color="primary" size="sm" title="Caso esteja demorando muito utilize F5 para recarregar a pagina." />
        </div>
      )}
    </div>
  );
};
