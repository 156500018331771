import { useEffect, useState } from 'react';
import { ROUTES } from 'config/routes';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import { Col, Row, Table } from 'reactstrap';
import { PageContent } from 'components/Common/PageContent';
import { LinkButton } from 'components/Button/LinkButton';
import { obterEmpresas } from 'services/empresaService';
import { addCpfCnpjMask } from 'common/mask';
import { EmpresaDto } from 'model/types/empresa.types';
import { showToastErrors } from 'common/toast_config';

export function EmpresaListagem() {
  const [isFetching, setIsFetching] = useState<boolean>();
  const [empresas, setEmpresas] = useState<EmpresaDto[]>([]);

  useEffect(() => {
    let isCanceled = false;
    setIsFetching(true);

    async function fetchData() {
      try {
        const { data } = await obterEmpresas();
        if (isCanceled) return;
        if (data) setEmpresas(data);
      } catch (error: any) {
        showToastErrors(error);
      } finally {
        setIsFetching(false);
      }
    }

    fetchData();

    return () => {
      isCanceled = true;
    };
  }, []);

  function getInitials(input: string) {
    const parts = input.split(' ');
    return parts.length === 1 ? parts[0].substring(0, 2) : parts[0].charAt(0) + parts[1].charAt(0);
  }

  return (
    <PageContent title="Empresas" subTitle="Listagem das Empresas" isLoading={isFetching}>
      <Row>
        <Col lg="12" hidden={isFetching}>
          {!empresas.length ? (
            <div className="text-center">
              <p className="lead">
                Não consegui localizar o cadastro de uma Empresa para cadastrar uma nova utilize o botão abaixo.
              </p>
              <LinkButton size="sm" color="success" to={'nova-empresa'}>
                Adicionar Empresa
              </LinkButton>
            </div>
          ) : (
            <div className="table-responsive">
              <Table className="project-list-table table-nowrap align-middle table-borderless">
                <thead>
                  <tr>
                    <th scope="col" className="text-center" style={{ width: '70px' }}>
                      #
                    </th>
                    <th scope="col">Empresa</th>
                    <th scope="col">CPF / CNPJ</th>
                    <th scope="col">Cidade</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {empresas.map(v => (
                    <tr key={v.id}>
                      <td>
                        <span className="avatar-title-auto avatar-sm rounded-circle bg-primary">
                          {getInitials(v.nome ?? '')}
                        </span>
                      </td>
                      <td>
                        <h5 className="text-truncate font-size-14">
                          <Link to="#" className="text-dark">
                            {v.nome}
                          </Link>
                        </h5>
                        <p className="text-muted mb-0">{v.razaoSocial}</p>
                      </td>
                      <td>{addCpfCnpjMask(v.cpfCnpj)}</td>
                      <td>
                        {v.endereco.cidadeNome}-{v.endereco.siglaUf}
                      </td>
                      <td>
                        <Link to={ROUTES.EMPRESA_ALTERAR(v.id)} className="btn btn-sm btn-info">
                          Editar
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>
    </PageContent>
  );
}
