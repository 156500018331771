import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Label } from 'reactstrap';
import { InputPreco } from 'components/Form/InputPreco';
import { toDecimal, decimalRound } from 'common/decimals';
import { toMoneyFormat } from 'common/formatters';
import { useForm } from 'react-hook-form';

type TotaisVendaProp = {
  disabled: boolean;
  totalItens: number;
  totalDesconto: number;
  totalFrete: number;
  totalVenda: number;
  totalPago: number;
  onSave?: (totalDesconto: number, totalFrete: number) => void;
};

type FormData = {
  totalDesconto: string;
  totalFrete: string;
};

export function TotaisVenda({
  totalItens,
  totalDesconto,
  totalFrete,
  totalVenda,
  totalPago,
  disabled,
  onSave,
}: TotaisVendaProp) {
  const [total, setTotal] = useState<number>(0);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<FormData>();

  useEffect(() => {
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalItens, totalDesconto, totalFrete, totalVenda]);

  const resetValues = () => {
    reset(
      {
        totalDesconto: toMoneyFormat(totalDesconto),
        totalFrete: toMoneyFormat(totalFrete),
      },
      { keepDirty: false },
    );

    setTotal(totalVenda);
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'totalDesconto' || name === 'totalFrete') {
        const totalDesconto = toDecimal(value.totalDesconto);
        const totalFrete = toDecimal(value.totalFrete);
        const total = decimalRound(totalItens - totalDesconto + totalFrete, 2);

        setTotal(total);
        reset({}, { keepValues: true, keepDirty: true });
      }
    });
    return () => subscription.unsubscribe();
  }, [totalItens, reset, setValue, watch]);

  function handleSaveChanges(values: FormData) {
    const totalDesconto = toDecimal(values.totalDesconto);
    const totalFrete = toDecimal(values.totalFrete);

    onSave && onSave(totalDesconto, totalFrete);
  }

  return (
    <Col>
      <div className="py-1 d-flex justify-content-end align-items-center">
        <TotalLabel>Desconto:</TotalLabel>
        <TotalContent>
          <InputPreco disabled={disabled} {...register('totalDesconto')} />
        </TotalContent>
      </div>

      <div className="py-1 d-flex justify-content-end align-items-center">
        <TotalLabel>Frete:</TotalLabel>
        <TotalContent>
          <InputPreco disabled={disabled} {...register('totalFrete')} />
        </TotalContent>
      </div>

      <div className="mt-3 py-1 d-flex justify-content-end align-items-center">
        <TotalLabel>Total venda:</TotalLabel>
        <TotalContent>
          <span className="fw-bold fs-3">R$ {toMoneyFormat(total)}</span>
        </TotalContent>
      </div>

      <div className="py-1 d-flex justify-content-end align-items-center">
        <Label className="me-4 fw-normal">Total pago:</Label>
        <TotalContent>
          <span className="fs-5">R$ {toMoneyFormat(totalPago)}</span>
        </TotalContent>
      </div>

      {!disabled && isDirty && (
        <div className="mt-1 py-1 d-flex justify-content-end align-items-center">
          <button className="btn btn-link" onClick={handleSubmit(handleSaveChanges)}>
            <i className="bx bx-check-double"></i> Aplicar alteração no valor
          </button>

          <button className="btn btn-link" onClick={() => resetValues()}>
            <i className="bx bx-flag"></i> Cancelar
          </button>
        </div>
      )}
    </Col>
  );
}

const TotalLabel = styled.div.attrs(() => ({ className: 'fs-6 me-4' }))``;

const TotalContent = styled.div.attrs(() => ({ className: 'text-end' }))`
  width: 170px;
`;
