import { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Media } from 'reactstrap';
import { toMoneyFormat } from 'common/formatters';
import { obterUsuarioLogado } from 'services/usuarioService';
import { IUsuario } from '../../model/types/usuario.types';
import { VendaDto, VendaSituacao } from 'model/types/venda.types';
import { obterTodasVendasPeriodo } from 'services/vendaService';
import moment from 'moment';
import { sumBy } from 'lodash';

export const CardUserWelcome = () => {
  const [usuario, setUsuario] = useState<IUsuario | null>(null);
  const [vendas, setVendas] = useState<VendaDto[]>([]);
  const [totalVendido, setTotalVendido] = useState<number>(0);

  useEffect(() => {
    async function fetchData() {
      const userData = await obterUsuarioLogado();
      setUsuario(userData ?? null);

      if (!!userData) {
        const vendas = await obterTodasVendasPeriodo({
          situacao: VendaSituacao.Finalizada,
          usuarioCriou: userData.id,
          dataInicio: moment().startOf('month').format('YYYY-MM-DD'),
          dataFim: moment().endOf('month').format('YYYY-MM-DD'),
        });

        setVendas(vendas);
        setTotalVendido(sumBy(vendas, v => v.totalDaVenda));
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <Col>
        <Card className="bg-primary bg-soft">
          <CardBody>
            <Row>
              <Col lg="5">
                <Media>
                  <div className="me-3">
                    <div className="avatar-sm">
                      <span className="avatar-title rounded-circle fs-5">
                        {usuario?.nome.substring(0, 2).toUpperCase()}
                      </span>
                    </div>
                  </div>
                  <Media className="align-self-center" body>
                    <div className="text-primary">
                      <p className="mb-0">Bem vindo de volta!</p>
                      <h5 className="mb-0 text-primary">{usuario?.nome.split('@')[0]}</h5>
                    </div>
                  </Media>
                </Media>
              </Col>

              <Col lg className="align-self-center">
                <div className="text-lg-center mt-4 mt-lg-0">
                  <Row>
                    <Col>
                      <div>
                        <p className="text-primary text-truncate mb-1">Vendas (mês)</p>
                        <h5 className="mb-0 text-primary">{vendas.length}</h5>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <p className="text-primary text-truncate mb-1">Total Vendido</p>
                        <h5 className="mb-0 text-primary">{toMoneyFormat(totalVendido, true)}</h5>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <p className="text-primary text-truncate mb-1">Ticket Médio</p>
                        <h5 className="mb-0 text-primary">
                          {toMoneyFormat(vendas.length > 0 ? totalVendido / vendas.length : 0, true)}
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default CardUserWelcome;
