import jwtDecode from 'jwt-decode';
import { api, ENDPOINTS } from '../api';
import { ApiResponse } from 'apisauce';
import { IUsuario } from 'model/types/usuario.types';

export interface NovoUsuarioData {
  email: string;
  senha: string;
  confirmacaoSenha: string;
}

export interface VincularUsuarioData {
  usuario: string;
}

export interface AlterarSenhaData {
  senhaAtual: string;
  novaSenha: string;
  confirmacaoSenha: string;
}

export async function obterUsuarioLogado(): Promise<IUsuario | null> {
  const token = localStorage.getItem('token');
  if (!!token) {
    const jwt = jwtDecode<any>(token);
    const res = await api.get<IUsuario>(ENDPOINTS.OBTER_USUARIO_PELO_ID(jwt.sub));
    if (res.data) return res.data;
  }

  return null;
}

export async function sendNovoUsuarioOrganizacao(postData: NovoUsuarioData): Promise<ApiResponse<any, any>> {
  return await api.post(ENDPOINTS.ORGANIZACAO_USUARIO_NOVO(), postData);
}

export async function vincularUsuarioNaOrganizacao(postData: VincularUsuarioData): Promise<ApiResponse<any, any>> {
  return await api.post(ENDPOINTS.ORGANIZACAO_VINCULAR_USUARIO(), postData);
}

export async function alterarSenha(usuarioId: string, postData: AlterarSenhaData) {
  return await api.post(ENDPOINTS.ALTERAR_SENHA(usuarioId), postData);
}
