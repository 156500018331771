import React from 'react';
import { VendaDto } from 'model/types/venda.types';
import { obterVendaPeloId } from 'services/vendaService';
import { showToastErrors } from 'common/toast_config';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { toMoneyFormat } from 'common/formatters';
import { Link } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import { SpinnerButton } from 'components/Button/SpinnerButton';
import { criarNotaPelaVenda } from 'services/nfeService';

interface EmitirNotaVendaModalProps {
  vendaId: string;
  isOpen: boolean;
  onToggle: (toggle: boolean) => void;
}

export const EmitirNotaVendaModal = ({ vendaId, isOpen, onToggle }: EmitirNotaVendaModalProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [venda, setVenda] = React.useState<VendaDto | null>(null);
  const [successMessage, setSuccessMessage] = React.useState<string>('');
  const [notaId, setNotaId] = React.useState<string | null>(null);

  const handleToogle = () => {
    if (loading) return;
    onToggle(!isOpen);
  };

  const loadVenda = async () => {
    if (!vendaId) return;

    setLoading(true);

    try {
      const vendaResult = await obterVendaPeloId(vendaId);
      setVenda(vendaResult);
    } catch (error: any) {
      showToastErrors(error.messages);
    } finally {
      setLoading(false);
    }
  };

  const handleOpened = () => {
    setSuccessMessage('');
    setNotaId(null);
    setVenda(null);
    loadVenda();
  };

  const handleCriar = async () => {
    if (venda === null) {
      showToastErrors(['Venda ainda está carregando. Aguarde um momento.']);
      return;
    }

    setSuccessMessage('');
    setLoading(true);

    try {
      const notaCriadaId = await criarNotaPelaVenda(venda.id);
      setNotaId(notaCriadaId);
      setSuccessMessage('Nota criada com sucesso.');
    } catch (error: any) {
      showToastErrors(error.messages);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      size="md"
      className="modal-fullscreen-md-down"
      isOpen={isOpen}
      backdrop={true}
      toggle={handleToogle}
      onOpened={handleOpened}
    >
      <ModalHeader toggle={handleToogle}>Criar Nota Fiscal (NF-e)</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i className="mdi mdi-certificate-outline" style={{ fontSize: '4em', color: 'gray' }} />
              <h4>Criar Nota Fiscal (NF-e) para a Venda?</h4>
            </div>

            <div className="mt-2 d-flex gap-3 justify-content-center">
              <div>Num. Venda: {venda?.numero.toString().padStart(4, '0') ?? '...'}</div>
              <div>Total Venda: {!!venda ? toMoneyFormat(venda?.totalDaVenda) : '...'}</div>
            </div>

            {!!successMessage && <div className="mt-2 text-center text-success font-size-14">{successMessage}</div>}

            {!!successMessage && !!notaId && !loading && (
              <div className="mt-3 font-size-14 text-center">
                <Link to={ROUTES.NOTA_ALTERAR(notaId)} className="text-decoration-underline">
                  Ir para o nota fiscal
                </Link>
              </div>
            )}
          </Col>
        </Row>

        {!successMessage && (
          <Row>
            <Col lg={12}>
              <div className="text-center mt-3">
                <SpinnerButton loading={loading} type="button" color="primary" onClick={handleCriar}>
                  Sim, Criar a Nota Fiscal (NF-e)
                </SpinnerButton>
              </div>
            </Col>
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
};
