import { api, ENDPOINTS } from 'api';
import { ProdutoEstoqueDto } from 'model/types/produto.types';

export async function obterProdutoEstoque(id: string): Promise<ProdutoEstoqueDto | null> {
  const { data } = await api.get<ProdutoEstoqueDto>(ENDPOINTS.ESTOQUE_PRODUTOS_POR_ID(id));
  return !!data ? data : null;
}

export async function obterProdutosEstoque(nome: string): Promise<ProdutoEstoqueDto[]> {
  return new Promise(async resolve => {
    const urlProdutos = ENDPOINTS.ESTOQUE_PRODUTOS_LISTAR() + `?textoPesquisa=${nome}&tamanhoPagina=25`;
    const { data } = await api.get<any>(urlProdutos);

    if (data) {
      resolve(data.dados);
    }
  });
}

export async function adicionarEstoque(produtoId: string, acrescimo: number): Promise<void> {
  const postData = {
    produtoId: produtoId,
    acrescimo: acrescimo,
  };

  await api.post(ENDPOINTS.ESTOQUE_ADICIONAR(), postData);
}

export async function debitarEstoque(produtoId: string, debito: number): Promise<void> {
  const postData = {
    produtoId: produtoId,
    debito: debito,
  };

  await api.post(ENDPOINTS.ESTOQUE_DEBITAR(), postData);
}
