import jwtDecode from 'jwt-decode';

const account = process.env.REACT_APP_API_ACCOUNT;
const cadastros = process.env.REACT_APP_API_CADASTROS;
const estoque = process.env.REACT_APP_API_ESTOQUE;
const fiscal = process.env.REACT_APP_API_FISCAL;
const vendas = process.env.REACT_APP_API_VENDAS;
const produtos = process.env.REACT_APP_API_PRODUTOS;

const tenant = () => {
  try {
    const token = localStorage.getItem('token');
    const tenants = JSON.parse(jwtDecode(token).tenants);

    return tenants[0];
  } catch (error) {
    return '';
  }
};

export const ENDPOINTS = {
  // Account
  AUTENTICAR: `${account}/usuarios/autenticar`,
  REFRESH_TOKEN: `${account}/usuarios/refresh-token`,
  RECUPERAR_SENHA: `${account}/usuarios/recuperar/senha`,
  RECUPERAR_SENHA_NOVA: `${account}/usuarios/recuperar/senha/nova`,
  OBTER_USUARIO_PELO_ID: id => `${account}/usuarios/${id}`,
  ALTERAR_SENHA: id => `${account}/usuarios/${id}/alterar-senha`,
  OBTER_ORGANIZACAO_PELO_ID: id => `${account}/organizacoes/${id}`,
  ORGANIZACAO_USUARIOS: () => `${account}/organizacoes/${tenant()}/usuarios`,
  ORGANIZACAO_USUARIO_NOVO: () => `${account}/organizacoes/${tenant()}/novo-usuario`,
  ORGANIZACAO_VINCULAR_USUARIO: () => `${account}/organizacoes/${tenant()}/vincular-usuario`,

  // Cidades
  CIDADES_POR_NOME: nome => `${cadastros}/cidades?nome=${nome}`,
  CIDADES_POR_ID: id => `${cadastros}/cidades/${id}`,
  CIDADES_POR_IBGE: ibge => `${cadastros}/cidades/ibge/${ibge}`,

  // Empresas
  EMPRESAS_LISTAR_TODAS: () => `${cadastros}/${tenant()}/empresas/todas`,
  EMPRESAS_POR_ID: id => `${cadastros}/${tenant()}/empresas/${id}`,
  EMPRESAS_NOVA: () => `${cadastros}/${tenant()}/empresas`,
  EMPRESAS_ALTERAR: id => `${cadastros}/${tenant()}/empresas/${id}`,

  // Certificados
  CERTIFICADOS_LISTAR_TODOS: () => `${fiscal}/${tenant()}/certificados-digitais`,
  CERTIFICADOS_DIGITAIS_NOVO: () => `${fiscal}/${tenant()}/certificados-digitais`,
  CERTIFICADOS_DIGITAIS_ALTERAR: id => `${fiscal}/${tenant()}/certificados-digitais/${id}`,
  CERTIFICADOS_DIGITAIS_POR_ID: id => `${fiscal}/${tenant()}/certificados-digitais/${id}`,

  // Clientes
  CLIENTES_LISTAR: () => `${cadastros}/${tenant()}/clientes`,
  CLIENTES_NOVO: () => `${cadastros}/${tenant()}/clientes`,
  CLIENTES_ALTERAR: id => `${cadastros}/${tenant()}/clientes/${id}`,
  CLIENTES_POR_ID: id => `${cadastros}/${tenant()}/clientes/${id}`,

  // Produtos
  PRODUTOS_NOVO: () => `${produtos}/${tenant()}/produtos`,
  PRODUTOS_ALTERAR: () => `${produtos}/${tenant()}/produtos`,
  PRODUTOS_POR_ID: id => `${produtos}/${tenant()}/produtos/${id}`,
  PRODUTOS_UNIDADES: () => `${produtos}/${tenant()}/produtos/unidades`,
  PRODUTOS_REGRA_FISCAL_ADD: produtoId => `${produtos}/${tenant()}/produtos/${produtoId}/regras-fiscais`,
  PRODUTOS_REGRA_FISCAL_DELETAR: (produtoId, regraId) =>
    `${produtos}/${tenant()}/produtos/${produtoId}/regras-fiscais/${regraId}`,

  // Tributações Fiscais
  CSOSN_LISTAR: () => `${fiscal}/csosn/todos`,
  PIS_VENDA_LISTAR: () => `${fiscal}/piscofins/vendas`,
  CFOP_POR_ID: id => `${fiscal}/cfop/${id}`,
  CFOP_LISTAR: () => `${fiscal}/cfop/vendas`,
  NCM_POR_ID: id => `${fiscal}/ncm/${id}`,
  NCM_LISTAR: () => `${fiscal}/ncm/validos`,

  // Emitentes
  EMITENTES_LISTAR: () => `${fiscal}/${tenant()}/emitentes`,
  EMITENTES_POR_ID: id => `${fiscal}/${tenant()}/emitentes/${id}`,
  EMITENTES_NOVO: () => `${fiscal}/${tenant()}/emitentes`,
  EMITENTES_ALTERAR: id => `${fiscal}/${tenant()}/emitentes/${id}`,

  // Estoque
  ESTOQUE_PRODUTOS_LISTAR: () => `${estoque}/${tenant()}/produtos`,
  ESTOQUE_PRODUTOS_POR_ID: id => `${estoque}/${tenant()}/produtos/${id}`,
  ESTOQUE_ADICIONAR: () => `${estoque}/${tenant()}/produtos/adicionar-estoque`,
  ESTOQUE_DEBITAR: () => `${estoque}/${tenant()}/produtos/debitar-estoque`,

  // Vendas
  VENDAS_PAGINA: () => `${vendas}/${tenant()}/vendas`,
  VENDAS_NOVA: () => `${vendas}/${tenant()}/vendas`,
  VENDAS_LISTAR: () => `${vendas}/${tenant()}/vendas`,
  VENDAS_OBTER: id => `${vendas}/${tenant()}/vendas/${id}`,
  VENDAS_ADD_ITEM: vendaId => `${vendas}/${tenant()}/vendas/${vendaId}/itens`,
  VENDAS_REMOVER_ITEM: (vendaId, id) => `${vendas}/${tenant()}/vendas/${vendaId}/itens/${id}`,
  VENDAS_REMOVER_CLIENTE: vendaId => `${vendas}/${tenant()}/vendas/${vendaId}/cliente`,
  VENDAS_ADD_CLIENTE: vendaId => `${vendas}/${tenant()}/vendas/${vendaId}/cliente`,
  VENDAS_ALTERAR_TOTAL: vendaId => `${vendas}/${tenant()}/vendas/${vendaId}/alterar-total`,
  VENDAS_FINALIZAR: vendaId => `${vendas}/${tenant()}/vendas/${vendaId}/finalizar`,
  VENDAS_CANCELAR: vendaId => `${vendas}/${tenant()}/vendas/${vendaId}/cancelar`,
  VENDAS_ADD_PAGAMENTO: vendaId => `${vendas}/${tenant()}/vendas/${vendaId}/pagamentos`,
  VENDAS_DELETAR_PAGAMENTO: (vendaId, id) => `${vendas}/${tenant()}/vendas/${vendaId}/pagamentos/${id}`,

  // ReceitaWS
  RECEITA_WS_BUSCAR_POR_CNPJ: cnpj => `${cadastros}/${tenant()}/empresas/consulta-api/${cnpj}`,

  // Cupom Fiscal
  CUPOM_FISCAL_LISTAR: () => `${fiscal}/${tenant()}/cupons`,
  CUPOM_FISCAL_POR_ID: id => `${fiscal}/${tenant()}/cupons/${id}`,
  CUPOM_FISCAL_AUTORIZAR: id => `${fiscal}/${tenant()}/cupons/${id}/autorizar`,
  CUPOM_FISCAL_CONSULTAR_SITUACAO: id => `${fiscal}/${tenant()}/cupons/${id}/consultar-situacao`,
  CUPOM_FISCAL_CRIAR_PELA_VENDA: vendaId => `${fiscal}/${tenant()}/cupons/gerar-da-venda/${vendaId}`,
  CUPOM_FISCAL_CONFIRMAR_SITUACAO: id => `${fiscal}/${tenant()}/cupons/${id}/confirmar-situacao`,
  CUPOM_FISCAL_CANCELAR: id => `${fiscal}/${tenant()}/cupons/${id}/cancelar`,
  CUPOM_FISCAL_ALTERAR_NUMERACAO: id => `${fiscal}/${tenant()}/cupons/${id}/altera-numeracao-fiscal`,
  CUPOM_FISCAL_ITEM_ALTERAR: (id, itemId) => `${fiscal}/${tenant()}/cupons/${id}/itens/${itemId}`,
  CUPOM_FISCAL_DANFE: id => `${fiscal}/${tenant()}/cupons/${id}/danfe`,
  CUPOM_FISCAL_INSERE_CLIENTE: id => `${fiscal}/${tenant()}/cupons/${id}/insere-cliente`,
  CUPOM_FISCAL_DELETA_CLIENTE: id => `${fiscal}/${tenant()}/cupons/${id}/deleta-cliente`,
  CUPOM_FISCAL_ENVIAR_PACOTE_CONTADOR: () => `${fiscal}/${tenant()}/cupons/enviar-pacote-contador`,

  // Nota Fiscal
  NOTA_FISCAL_LISTAR: () => `${fiscal}/${tenant()}/notas-fiscais`,
  NOTA_FISCAL_POR_ID: id => `${fiscal}/${tenant()}/notas-fiscais/${id}`,
  NOTA_FISCAL_CRIAR_PELA_VENDA: id => `${fiscal}/${tenant()}/notas-fiscais/criar-da-venda/${id}`,
  NOTA_FISCAL_CONSULTAR_SITUACAO: id => `${fiscal}/${tenant()}/notas-fiscais/${id}/consultar-situacao`,
  NOTA_FISCAL_REMOVER_CLIENTE: id => `${fiscal}/${tenant()}/notas-fiscais/${id}/remove-cliente`,
  NOTA_FISCAL_ADD_CLIENTE: id => `${fiscal}/${tenant()}/notas-fiscais/${id}/adiciona-cliente`,
  NOTA_FISCAL_ALTERAR_CABECALHO: id => `${fiscal}/${tenant()}/notas-fiscais/${id}/altera-cabecalho`,
  NOTA_FISCAL_ALTERAR_OBSERVACAO: id => `${fiscal}/${tenant()}/notas-fiscais/${id}/altera-observacao`,
  NOTA_FISCAL_ALTERAR_NUMERACAO: id => `${fiscal}/${tenant()}/notas-fiscais/${id}/altera-numeracao-fiscal`,
  NOTA_FISCAL_ITEM_ALTERAR: (id, itemId) => `${fiscal}/${tenant()}/notas-fiscais/${id}/itens/${itemId}`,
  NOTA_FISCAL_AUTORIZAR: id => `${fiscal}/${tenant()}/notas-fiscais/emitir/${id}`,
  NOTA_FISCAL_CONFIRMAR_SITUACAO: id => `${fiscal}/${tenant()}/notas-fiscais/${id}/confirmar-situacao`,
  NOTA_FISCAL_CANCELAR: id => `${fiscal}/${tenant()}/notas-fiscais/${id}/cancelar`,
  NOTA_FISCAL_DANFE: id => `${fiscal}/${tenant()}/notas-fiscais/${id}/danfe`,
  NOTA_FISCAL_SUGERIR_IMPOSTOS: id => `${fiscal}/${tenant()}/notas-fiscais/${id}/sugerir-impostos`,
  NOTA_FISCAL_CARTA_CORRECAO: id => `${fiscal}/${tenant()}/notas-fiscais/${id}/correcao`,
  NOTA_FISCAL_iMPRIMIR_CARTA_CORRECAO: (notaId, sequenciaId) =>
    `${fiscal}/${tenant()}/notas-fiscais/${notaId}/correcao/${sequenciaId}/imprimir`,

  // Fiscal
  REGRAS_GLOBAIS_CFOP_LISTAR: () => `${fiscal}/${tenant()}/regras-globais/cfop`,
  REGRAS_GLOBAIS_CFOP_INICIAR: () => `${fiscal}/${tenant()}/regras-globais/cfop/sugerir-iniciais`,
  REGRAS_GLOBAIS_CFOP_ALTERAR: id => `${fiscal}/${tenant()}/regras-globais/cfop/${id}`,

  // Importação
  IMPORTACAO_DADOS_LISTAR: () => `${cadastros}/${tenant()}/importacao-dados`,
  IMPORTACAO_DADOS_IMPORTAR: () => `${cadastros}/${tenant()}/importacao-dados`,
  IMPORTACAO_DADOS_POR_ID: id => `${cadastros}/${tenant()}/importacao-dados/${id}`,
};

export default ENDPOINTS;
