import { useRef, useState } from 'react';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import { finalizarVenda } from 'services/vendaService';
import { AlertErrors } from 'components/Alert/AlertErrors';
import { SpinnerButton } from 'components/Button/SpinnerButton';

interface FinalizarVendaModalProps {
  show: boolean;
  vendaId: string;
  onCloseClick: (success: boolean) => void;
}

export const FinalizarVendaModal = ({ show, vendaId, onCloseClick }: FinalizarVendaModalProps) => {
  const inputFocus = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<string[]>();
  const [processando, setProcessando] = useState(false);
  const [sucesso, setSucesso] = useState(false);

  function handleOnOpened() {
    setErrors([]);
    setSucesso(false);
    setProcessando(false);
    focusInput();
  }

  function focusInput() {
    inputFocus.current?.focus();
  }

  function handleToggle() {
    if (processando) return;
    onCloseClick(sucesso);
  }

  async function handleSubmit() {
    try {
      setProcessando(true);
      await finalizarVenda(vendaId);
      setSucesso(true);
    } catch (error: any) {
      setErrors(error.messages);
      focusInput();
    } finally {
      setProcessando(false);
    }
  }

  return (
    <Modal isOpen={show} toggle={handleToggle} centered={true} onOpened={handleOnOpened}>
      <ModalBody className="py-3 px-5">
        {sucesso ? (
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <i className="mdi mdi-cash-check" style={{ fontSize: '9em', color: 'green' }} />
                <h3>Venda finalizada!</h3>
                <button type="button" className="btn btn-success btn-lg" onClick={() => onCloseClick(sucesso)}>
                  (ESC) Fechar
                </button>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <i className="mdi mdi-cash-register" style={{ fontSize: '9em', color: 'gray' }} />
                  <h3>Finalizar a Venda?</h3>
                  <h5>{'Você não poderá desfazer essa ação!'}</h5>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <AlertErrors className={'mb-2'} errors={errors} />
                <div className="text-center mt-3">
                  <SpinnerButton
                    loading={processando}
                    type="button"
                    className="btn btn-success btn-lg ms-2"
                    onClick={handleSubmit}
                  >
                    {processando ? 'Finalizando...' : 'Sim, Finalizar!'}
                  </SpinnerButton>

                  <button
                    hidden={processando}
                    type="button"
                    className="btn btn-danger btn-lg ms-2"
                    onClick={() => onCloseClick(sucesso)}
                  >
                    Não, volte!
                  </button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
