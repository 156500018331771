import { ApiErrorResponse, create } from 'apisauce';
import { ApiError } from './api.types';
import { ENDPOINTS as EP } from './endpoints';
import { handleRefreshToken } from './refreshTokenService';

const apiBase = create({
  baseURL: '',
});

apiBase.addAsyncRequestTransform(async request => {
  await handleRefreshToken();
  request.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
});

apiBase.addResponseTransform(response => {
  if (!response.ok) handleError(response);
});

const handleError = (response: ApiErrorResponse<any>) => {
  const messages = [];

  try {
    const statusCode = response.status ?? 0;
    if (statusCode === 0) messages.push('Não foi possível estabelecar conexão com nossos servidores');
    if (statusCode === 401) messages.push('Você precisa estar autenticado para fazer isso');
    if (statusCode === 403) messages.push('Você não possui permissão para fazer isso');
    if (statusCode === 404) messages.push('Recurso solicitado não existe');
    if (statusCode === 400) {
      response.data.errors.mensagens.forEach((msg: string) => {
        messages.push(msg);
      });
    }

    if (statusCode >= 500) messages.push('Algo de errado ocorreu em nossos servidores');
  } catch (ex: any) {
    console.error('Falha ao tratar o erro da API', ex);
    messages.push('Um erro ocorreu e não consegui identifica-lo');
  }

  const exception: ApiError = {
    statusCode: response.status,
    problem: response.problem,
    data: response.data,
    messages: messages,
  };

  throw exception;
};

export const api = apiBase;
export const ENDPOINTS = EP;

export default apiBase;
