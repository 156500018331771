import { forwardRef, ForwardRefRenderFunction, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { OptionTypeBase } from 'react-select';
import AsyncSelect, { Async } from 'react-select/async';
import { CsosnDto } from 'model/types/fiscal.types';
import { obterCsosn, obterTodosCsosn } from 'services/csosnService';
import { sortBy } from 'lodash';

export interface SelectCsosnProps {
  name?: string;
  value?: string;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onSelected?: (value: CsosnDto) => void;
  onKeyDown?: KeyboardEventHandler;
  menuPlacement?: 'top' | 'auto' | 'bottom';
  error?: FieldError;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string | undefined;
}

interface CsosnOption extends OptionTypeBase {
  value: string;
  label: string;
  object: CsosnDto;
}

const Component: ForwardRefRenderFunction<Async<CsosnOption>, SelectCsosnProps> = (
  {
    name,
    value,
    onBlur,
    onChange,
    onSelected,
    onKeyDown,
    menuPlacement = 'top',
    error,
    autoFocus,
    disabled,
    className,
  },
  ref,
) => {
  const loadOptionsRef = useRef<NodeJS.Timeout>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<CsosnOption | null>(null);

  useEffect(() => {
    let isCanceled = false;

    if (!value) {
      setSelectedItem(null);
      return;
    }

    async function fetchValue() {
      setIsLoading(true);
      try {
        const data = await obterCsosn(value as string);
        if (isCanceled) return;
        setSelectedItem(!!data ? mapToOption(data) : null);
      } finally {
        setIsLoading(false);
      }
    }

    fetchValue();

    return () => {
      isCanceled = true;
    };
  }, [value]);

  function mapToOption(ncm: CsosnDto): CsosnOption {
    return {
      value: ncm.codigo,
      label: `${ncm.codigo} - ${ncm.descricao}`,
      object: ncm,
    };
  }

  function promiseOptions(textSearch: string): Promise<any> {
    loadOptionsRef.current && clearTimeout(loadOptionsRef.current);

    return new Promise((resolve, reject) => {
      loadOptionsRef.current = setTimeout(async () => {
        try {
          const fetchedData = await obterTodosCsosn();
          const filteredData =
            textSearch?.length === 0
              ? fetchedData
              : fetchedData.filter(
                  x => x.codigo.startsWith(textSearch) || x.descricao.toLowerCase().includes(textSearch.toLowerCase()),
                );

          const data = sortBy(filteredData, 'id');

          const options = data.map(p => {
            return {
              value: p.codigo,
              label: `${p.codigo} - ${p.descricao}`,
              object: p,
            };
          });

          resolve(options);
        } catch (error: any) {
          console.error('SelectNcm::promiseOptions', error);
          reject(error);
        }
      }, 100);
    });
  }

  function handleOnChange(value: CsosnOption | null) {
    if (!!!value) {
      onChange && onChange('');
      return;
    }

    setSelectedItem(value);
    onChange && onChange(value.value);
    onSelected && onSelected(value.object);
  }

  return (
    <AsyncSelect
      ref={ref}
      name={name}
      isDisabled={disabled}
      value={selectedItem}
      onChange={handleOnChange}
      onBlur={onBlur}
      isLoading={isLoading}
      menuPlacement={menuPlacement}
      error={error}
      defaultOptions={true}
      loadOptions={promiseOptions}
      classNamePrefix="select2-selection"
      className={className}
      placeholder="Selecione ou digite para buscar um CSOSN"
      noOptionsMessage={() => 'Nenhum registro encontrado para o termo digitado'}
      autoFocus={autoFocus}
      onKeyDown={onKeyDown}
    />
  );
};

export const SelectCsosn = forwardRef(Component);
