import _uniqueId from 'lodash/uniqueId';
import { useState } from 'react';
import { Input, Label } from 'reactstrap';

interface CustomSearchProps {
  onBeforeSearch: (searchText: string) => void;
  onSearch: (searchText: string) => void;
  searchText?: string | null;
  placeholder: string;
}

const CustomSearch = ({ onSearch, placeholder, onBeforeSearch, searchText: defaultSearchText }: CustomSearchProps) => {
  const [id] = useState(_uniqueId('search-box-'));
  const [searchText, setSearchText] = useState('');

  const handleKeyPress = (ev: React.KeyboardEvent): void => {
    if (ev.key === 'Enter') {
      onBeforeSearch && onBeforeSearch(searchText);
      onSearch && onSearch(searchText);
    }
  };

  return (
    <>
      <div className="search-box">
        <div className="position-relative">
          <Input
            id={id}
            onKeyUp={handleKeyPress}
            onChange={e => setSearchText(e.target.value)}
            className="form-control rounded border-grey-300"
            defaultValue={defaultSearchText ?? ''}
            placeholder={placeholder}
          />

          <Label for={id} className="search-icon">
            <i className="bx bx-search-alt"></i>
          </Label>
        </div>
      </div>
    </>
  );
};

export default CustomSearch;
