import { toDecimalFormat } from 'common/formatters';
import { ConfirmButton } from 'components/Button/ConfirmButton';
import { ProdutoRegraFiscalDto } from 'model/types/produto.types';
import { Button, Row } from 'reactstrap';

type ProdutoRegrasFiscaisProps = {
  produtoId: string;
  regrasFiscais: ProdutoRegraFiscalDto[];
  onAddRegraFiscal: () => void;
  onDeleteRegraFiscal: (regraId: string) => void;
  onEditRegraFiscal?: (regraId: string) => void;
};

export function ProdutoRegrasFiscais({
  produtoId,
  regrasFiscais,
  onAddRegraFiscal,
  onEditRegraFiscal,
  onDeleteRegraFiscal,
}: ProdutoRegrasFiscaisProps) {
  const possuiRegras = regrasFiscais.length > 0;

  return (
    <>
      <Row xs={1}>
        <div className="mb-3">
          <div className="fs-5">Regras fiscais específicas para o produto</div>
        </div>

        {!possuiRegras ? (
          <div className="text-center">
            <p>
              Nenhuma regra fiscal criada para o produto, ao emitir uma nota fiscal iremos utilizar as configurações de
              impostos globais. Se o produto possuir impostos específicos ou algum tipo de benefício adicione uma regra
              para o Produto clicando no botão abaixo.
            </p>
            <div className="mt-2">
              <Button onClick={onAddRegraFiscal} type="button" size="sm" color="success" tabIndex={-1}>
                Adicionar Regra Fiscal
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="text-center">
              <p>
                Foi configurado regras fiscais específicas para o Produto, essas regras serão priorizadas ao criar uma
                NF-e / NFC-e
              </p>
            </div>
            <div className="mt-2 table-responsive">
              <table className="table table-sm">
                <thead className="table-light">
                  <tr>
                    <th>#</th>
                    <th>CSOSN</th>
                    <th>PIS/COFINS</th>
                    <th className="text-end">Alíquota do PIS</th>
                    <th className="text-end">Alíquota do COFINS</th>
                  </tr>
                </thead>
                <tbody>
                  {regrasFiscais.map(regra => (
                    <tr key={regra.id}>
                      <td scope="row">
                        <div className="d-flex gap-1">
                          {onEditRegraFiscal && (
                            <Button
                              tabIndex={-1}
                              onClick={() => onEditRegraFiscal(regra.id)}
                              type="button"
                              size="sm"
                              color="primary"
                            >
                              <i className="bx bxs-edit" />
                            </Button>
                          )}

                          <ConfirmButton
                            tabIndex={-1}
                            onClick={() => onDeleteRegraFiscal(regra.id)}
                            type="button"
                            size="sm"
                            color="danger"
                          >
                            <i className="bx bxs-trash" />
                          </ConfirmButton>
                        </div>
                      </td>
                      <td>{regra.codigoCsosn}</td>
                      <td>
                        {regra.codigoPis}/{regra.codigoCofins}
                      </td>
                      <td className="text-end">{toDecimalFormat(regra.aliquotaPis)}</td>
                      <td className="text-end">{toDecimalFormat(regra.aliquotaCofins)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </Row>
    </>
  );
}
