import React from 'react';
import { toMoneyFormat } from 'common/formatters';
import { SpinnerButton } from 'components/Button/SpinnerButton';
import { VendaDto } from 'model/types/venda.types';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { consultarSituacaoCupom, criarCupomPelaVenda } from 'services/nfceService';
import { obterVendaPeloId } from 'services/vendaService';
import { showToastErrors } from 'common/toast_config';
import { Link } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import { ResumoSituacaoCupom, SituacaoCupom } from 'model/types/cupomfiscal.types';

interface EmitirCupomVendaModalProps {
  vendaId: string;
  isOpen: boolean;
  onToggle: (toggle: boolean) => void;
}

export const EmitirCupomVendaModal = ({ vendaId, isOpen, onToggle }: EmitirCupomVendaModalProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [venda, setVenda] = React.useState<VendaDto | null>(null);
  const [successMessage, setSuccessMessage] = React.useState<string>('');
  const [statusMessage, setStatusMessage] = React.useState<string>('');
  const [cupomId, setCupomId] = React.useState<string | null>(null);

  const handleToogle = () => {
    if (loading) return;
    onToggle(!isOpen);
  };

  const loadVenda = async () => {
    if (!vendaId) return;

    setLoading(true);

    try {
      const vendaResult = await obterVendaPeloId(vendaId);
      setVenda(vendaResult);
    } catch (error: any) {
      showToastErrors(error.messages);
    } finally {
      setLoading(false);
    }
  };

  const handleOpened = () => {
    setStatusMessage('');
    setSuccessMessage('');
    setCupomId(null);
    setVenda(null);
    loadVenda();
  };

  const informarStatusCupom = (resumoSituacao: ResumoSituacaoCupom) => {
    if (resumoSituacao.situacaoMensagem === null) {
      setStatusMessage(resumoSituacao.situacaoTexto);
      return;
    }

    setStatusMessage(`${resumoSituacao.situacaoTexto} - ${resumoSituacao.situacaoMensagem}`);
  };

  const verificarStatusCupom = async (id: string): Promise<void> => {
    return await new Promise<void>((resolve, reject) => {
      const interval = 2000;
      let attempt = 0;

      async function consultar() {
        try {
          const response = await consultarSituacaoCupom(id);

          if (
            response.situacao === SituacaoCupom.Autorizado ||
            response.situacao === SituacaoCupom.Rejeitado ||
            ++attempt >= 10
          ) {
            informarStatusCupom(response);
            resolve();
            return;
          }

          setTimeout(consultar, interval);
        } catch (error: any) {
          reject(error);
        }
      }

      setTimeout(consultar, 5000);
    });
  };

  const handleEmitir = async () => {
    if (venda === null) {
      showToastErrors(['Venda ainda está carregando. Aguarde um momento.']);
      return;
    }

    setSuccessMessage('');
    setStatusMessage('');
    setLoading(true);

    try {
      const cupomCriadoId = await criarCupomPelaVenda(venda.id);
      setCupomId(cupomCriadoId);
      setSuccessMessage('Cupom criado com sucesso, aguarde a situação de autorização.');
      await verificarStatusCupom(cupomCriadoId);
    } catch (error: any) {
      showToastErrors(error.messages);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      size="md"
      className="modal-fullscreen-md-down"
      isOpen={isOpen}
      backdrop={true}
      toggle={handleToogle}
      onOpened={handleOpened}
    >
      <ModalHeader toggle={handleToogle}>Emitir Cupom Fiscal (NFC-e)</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i className="mdi mdi-certificate-outline" style={{ fontSize: '4em', color: 'gray' }} />
              <h4>Emitir Cupom Fiscal (NFC-e) para a Venda?</h4>
            </div>

            <div className="mt-2 d-flex gap-3 justify-content-center">
              <div>Num. Venda: {venda?.numero.toString().padStart(4, '0') ?? '...'}</div>
              <div>Total Venda: {!!venda ? toMoneyFormat(venda?.totalDaVenda) : '...'}</div>
            </div>

            {!!successMessage && <div className="mt-2 text-center text-success font-size-14">{successMessage}</div>}

            {!!statusMessage && (
              <div className="mt-2 text-center font-size-16">
                <div>Situação do Cupom:</div>
                <div className="text-info">{statusMessage}</div>

                {!!cupomId && !loading && (
                  <div className="mt-3 font-size-14">
                    <Link to={ROUTES.CUPOM_ALTERAR(cupomId)} className="text-decoration-underline">
                      Ir para o cupom fiscal
                    </Link>
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>

        {!statusMessage && (
          <Row>
            <Col lg={12}>
              <div className="text-center mt-3">
                <SpinnerButton loading={loading} type="button" className="btn btn-primary ms-2" onClick={handleEmitir}>
                  Sim, Criar e Autorizar o Cupom Fiscal!
                </SpinnerButton>
              </div>
            </Col>
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
};
