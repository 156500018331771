import { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AlertErrors } from 'components/Alert/AlertErrors';
import ENDPOINTS from 'api/endpoints';
import api from 'api';

interface DanfeNfeModalProps {
  notaFiscalId: string;
  isOpen: boolean;
  onToggle: (toogle: boolean) => void;
}

export const DanfeNfeModal = ({ notaFiscalId: cupomId, isOpen, onToggle }: DanfeNfeModalProps) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [base64Pdf, setBase64Pdf] = useState<string | null>();

  const handleToogle = () => {
    onToggle(!isOpen);
  };

  const carregarDanfe = async (id: string) => {
    setLoading(true);
    try {
      const { data } = await api.get<any>(ENDPOINTS.NOTA_FISCAL_DANFE(id));
      if (!data) {
        setErrors(['Não foi possível carregar o DANFE']);
        return;
      }
      setBase64Pdf(data.base64Pdf);
    } catch (error: any) {
      setErrors(error.messages);
    } finally {
      setLoading(false);
    }
  };

  const handleOpened = () => {
    carregarDanfe(cupomId);
  };

  return (
    <Modal
      size="xl"
      className="modal-fullscreen-sm-down modal-dialog modal-dialog-scrollable"
      isOpen={isOpen}
      backdrop={true}
      toggle={handleToogle}
      onOpened={handleOpened}
    >
      <ModalHeader className="d-print-none" toggle={handleToogle}>
        DANFE: Nota Fiscal Eletrônica
      </ModalHeader>
      <ModalBody>
        {loading && <div className="text-center">Carregando...</div>}
        {!loading && errors.length > 0 && <AlertErrors errors={errors} />}

        {!loading && errors.length === 0 && !!base64Pdf && (
          <embed src={`data:application/pdf;base64,${base64Pdf}`} height="550" width="100%" title="Danfe NF-e"></embed>
        )}
      </ModalBody>
    </Modal>
  );
};
