import { forwardRef, ForwardRefRenderFunction, useRef, useEffect, useState } from 'react';
import AsyncSelect, { Async } from 'react-select/async';
import { OptionTypeBase } from 'react-select';
import { Cidade, obterCidadePeloId, obterCidadePeloNome } from 'services/cidadeService';

export interface SelectCidadeProps {
  name: string;
  value: string;
  onBlur: () => void;
  onChange: (event: any) => void;
  menuPlacement?: 'top' | 'auto' | 'bottom';
}

const SelectCidadeComponent: ForwardRefRenderFunction<Async<OptionTypeBase>, SelectCidadeProps> = (
  { name, value, onChange, onBlur, menuPlacement = 'top' },
  ref,
) => {
  const loadOptionsRef = useRef<NodeJS.Timeout>();
  const [cidadeValue, setCidadeValue] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!!!value || value === cidadeValue?.value) return;
    let isCanceled = false;

    async function fetchValue() {
      setIsLoading(true);

      try {
        const cidade = await obterCidadePeloId(value);
        if (isCanceled) return;
        setCidadeValue({ value: cidade.id, label: `${cidade.nome} - ${cidade.siglaUf}` });
      } finally {
        setIsLoading(false);
      }
    }

    fetchValue();

    return () => {
      isCanceled = true;
    };
  }, [value, cidadeValue]);

  function promiseOptions(inputValue: string): Promise<any> {
    loadOptionsRef.current && clearTimeout(loadOptionsRef.current);

    return new Promise(resolve => {
      loadOptionsRef.current = setTimeout(async () => {
        const data = await obterCidadePeloNome(inputValue);

        const cidades = data.map((cidade: Cidade) => {
          return {
            value: cidade.id,
            label: `${cidade.nome} - ${cidade.siglaUf}`,
          };
        });

        resolve(cidades);
      }, 1100);
    });
  }

  return (
    <AsyncSelect
      ref={ref}
      name={name}
      onChange={(event: any) => {
        setCidadeValue(event);
        onChange(event.value);
      }}
      value={cidadeValue}
      onBlur={onBlur}
      loadOptions={promiseOptions}
      cacheOptions
      isLoading={isLoading}
      defaultOptions={false}
      classNamePrefix="select2-selection"
      placeholder="digite para buscar..."
      noOptionsMessage={() => 'nenhum registro encontrado para o termo buscado'}
      menuPlacement={menuPlacement}
    />
  );
};

export const SelectCidade = forwardRef(SelectCidadeComponent);
