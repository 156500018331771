import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { filter, isNumber, map, sortBy } from 'lodash';
import { Button, Card, CardBody, Col, Form, Input, Label, Row } from 'reactstrap';
import { PageContent } from 'components/Common/PageContent';
import { SelectEmpresa } from 'components/Form/SelectEmpresa';
import { UnidadeFederacao } from 'model/types/common.types';
import { yup, yupResolver } from 'common/yup';
import { Controller, useForm } from 'react-hook-form';
import { InputControl } from 'components/Form/InputControl';
import { showToastErrors, showToastSuccess } from 'common/toast_config';
import { alterarEmitente, cadastrarEmitente, obterEmitentePorId } from 'services/emitenteService';
import { EmitenteCommand, AmbienteSefaz, ModeloDocumento } from 'model/types/fiscal.types';
import { ROUTES } from 'config/routes';

interface ParamsData {
  id: string;
}

const unidadeFederacaoOptions = filter(
  map(UnidadeFederacao, (value, key) => ({
    value: UnidadeFederacao[key as any],
    label: key,
  })),
  e => isNumber(e.value),
);

interface FormData {
  empresaId: string;
  inscricaoEstadual: string;
  modelo: string;
  ambiente: string;
  unidadeFederacao: string;
  serie: string;
  numeroInicial: string;
  idToken: string;
  csc: string;
}

const schema = yup.object().shape({
  modelo: yup.number().required('Modelo é obrigatório'),
  empresaId: yup.string().required('Empresa é obrigatória'),
  inscricaoEstadual: yup.string().required('IE é obrigatória'),
  serie: yup.string().required('Serie é obrigatória'),
  numeroInicial: yup.string().required('Número Inicial é obrigatório'),
  idToken: yup
    .string()
    .nullable()
    .when('modelo', {
      is: ModeloDocumento.NFCE,
      then: s => s.required('Id Token obrigatório para NFC-e'),
      otherwise: s => s.oneOf([null, ''], 'Id Token só deve ser informado para NFC-e'),
    }),
  csc: yup
    .string()
    .nullable()
    .when('modelo', {
      is: ModeloDocumento.NFCE,
      then: s => s.required('CSC obrigatório para NFC-e'),
      otherwise: s => s.oneOf([null, ''], 'CSC só deve ser informado para NFC-e'),
    }),
});

export function EmitenteCadastro() {
  const history = useHistory();
  const { id } = useParams<ParamsData>();
  const [ehNovo, setEhNovo] = useState(false);
  const [nomeEmpresa, setNomeEmpresa] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (!!!id || id === 'novo-emitente') {
      setEhNovo(true);
      return;
    }

    let isCanceled = false;

    async function fetchData() {
      setIsLoading(true);

      try {
        const data = await obterEmitentePorId(id);
        if (isCanceled || data == null) return;

        setNomeEmpresa(data.empresa.nome);

        setValue('empresaId', data.empresa.id);
        setValue('inscricaoEstadual', data.inscricaoEstadual);
        setValue('modelo', data.modelo.toString());
        setValue('ambiente', data.ambiente.toString());
        setValue('unidadeFederacao', data.uf.toString());
        setValue('serie', data.serie.toString());
        setValue('numeroInicial', data.numeroInicial.toString());
        setValue('idToken', data.idToken);
        setValue('csc', data.csc);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();

    return () => {
      isCanceled = true;
    };
  }, [id, setValue]);

  const formSubmit = async (data: FormData) => {
    try {
      setIsLoading(true);

      const saveData: EmitenteCommand = {
        empresaId: data.empresaId,
        inscricaoEstadual: data.inscricaoEstadual,
        modelo: parseInt(data.modelo),
        ambiente: parseInt(data.ambiente),
        uf: parseInt(data.unidadeFederacao),
        serie: parseInt(data.serie),
        numeroInicial: parseInt(data.numeroInicial),
        idToken: data.idToken,
        csc: data.csc,
      };

      if (ehNovo) {
        await cadastrarEmitente(saveData);
        history.push(ROUTES.EMITENTE_LISTAGEM);
        showToastSuccess('Emitente salvo com sucesso.');
        return;
      }

      await alterarEmitente(id, saveData);
      setIsLoading(false);
      showToastSuccess('Emitente alterado com sucesso.');
    } catch (error: any) {
      setIsLoading(false);
      showToastErrors(error?.messages);
    }
  };

  return (
    <PageContent title="Emitente" subTitle="Cadastro Emitente" isLoading={isLoading}>
      <Form onSubmit={handleSubmit(formSubmit)}>
        <Card>
          <CardBody>
            <Row>
              <Col className="mb-3" sm={6} md={8}>
                <Label>Empresa do emitente fiscal</Label>
                {ehNovo ? (
                  <Controller
                    control={control}
                    name="empresaId"
                    render={({ field, fieldState }) => <SelectEmpresa {...field} error={fieldState.error} />}
                  />
                ) : (
                  <Input disabled={true} defaultValue={nomeEmpresa} />
                )}
              </Col>

              <Col className="mb-3">
                <Label className="text-nowrap">Inscrição Estadual</Label>
                <InputControl {...register('inscricaoEstadual')} error={errors.inscricaoEstadual} />
              </Col>
            </Row>

            <Row>
              <Col className="mb-3" sm>
                <Label>UF</Label>
                <select title="UF Emissão" className="form-select" {...register('unidadeFederacao')}>
                  {map(
                    sortBy(unidadeFederacaoOptions, e => e.label),
                    item => (
                      <option key={item.label} value={item.value}>
                        {item.label}
                      </option>
                    ),
                  )}
                </select>
              </Col>

              <Col className="mb-3" sm>
                <Label>Serie</Label>
                <InputControl maxLength={3} onlyNumbers={true} {...register('serie')} error={errors.serie} />
              </Col>

              <Col className="mb-3" sm>
                <Label>Numero Inicial</Label>
                <InputControl
                  maxLength={9}
                  onlyNumbers={true}
                  {...register('numeroInicial')}
                  error={errors.numeroInicial}
                />
              </Col>
            </Row>

            <Row>
              <Col className="mb-3" sm={2}>
                <Label>Modelo</Label>
                <select disabled={!ehNovo} title="Modelo Documento" className="form-select" {...register('modelo')}>
                  <option value={ModeloDocumento.NFE}>NF-e</option>
                  <option value={ModeloDocumento.NFCE}>NFC-e</option>
                </select>
              </Col>

              <Col className="mb-3" sm={2}>
                <Label>Ambiente</Label>
                <select title="Ambiente Emissão" className="form-select" {...register('ambiente')}>
                  <option value={AmbienteSefaz.Producao}>Produção</option>
                  <option value={AmbienteSefaz.Homologacao}>Homologação</option>
                </select>
              </Col>

              <Col className="mb-3" sm={2} md={1}>
                <Label className="text-nowrap">Id Token</Label>
                <InputControl {...register('idToken')} error={errors.idToken} />
              </Col>

              <Col className="mb-3" sm={4}>
                <Label>CSC</Label>
                <InputControl {...register('csc')} error={errors.csc} />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col className="d-flex justify-content-end">
                <Button type="submit" color="primary">
                  {ehNovo ? 'Cadastrar Emitente' : 'Atualizar Emitente'}
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </PageContent>
  );
}
