import api, { ENDPOINTS } from 'api';
import {
  ResumoSituacaoCupom,
  CupomFiscalCriado,
  CupomDto,
  AlterarItemCupomFiscalCommand,
  PacoteContadorUrl,
} from 'model/types/cupomfiscal.types';

export async function obterNfcePeloId(id: string): Promise<CupomDto> {
  return new Promise(async resolve => {
    const [nfceAny] = await Promise.all([await api.get<any>(ENDPOINTS.CUPOM_FISCAL_POR_ID(id))]);

    const nfceDtoRetorno: CupomDto = { ...nfceAny.data };

    resolve(nfceDtoRetorno);
  });
}

export async function alterarItemCupomFiscal(
  cupomId: string,
  itemId: string,
  command: AlterarItemCupomFiscalCommand,
): Promise<void> {
  const response = await api.put<any>(ENDPOINTS.CUPOM_FISCAL_ITEM_ALTERAR(cupomId, itemId), command);

  if (!response.ok) {
    throw new Error('Erro ao alterar item do cupom fiscal');
  }
}

export async function autorizarCupomFiscal(id: string): Promise<void> {
  await api.post(ENDPOINTS.CUPOM_FISCAL_AUTORIZAR(id));
}

export async function confirmarSituacaoCupomFiscal(id: string) {
  await api.post(ENDPOINTS.CUPOM_FISCAL_CONFIRMAR_SITUACAO(id));
}

export async function cancelarCupomFiscal(id: string, justificativa: string) {
  await api.post(ENDPOINTS.CUPOM_FISCAL_CANCELAR(id), {
    justificativa,
  });
}

export async function alterarNumeracao(id: string, serie: number, numeroFiscal: number) {
  await api.put(ENDPOINTS.CUPOM_FISCAL_ALTERAR_NUMERACAO(id), {
    serie,
    numeroFiscal,
  });
}

export async function criarCupomPelaVenda(vendaId: string): Promise<string> {
  const response = await api.post<CupomFiscalCriado>(ENDPOINTS.CUPOM_FISCAL_CRIAR_PELA_VENDA(vendaId));

  if (!response.ok || !response.data) {
    throw Error('Ocorreu um erro ao emitir o cupom');
  }

  return response.data.cupomFiscalId;
}

export async function consultarSituacaoCupom(cupomId: string): Promise<ResumoSituacaoCupom> {
  const { data } = await api.get<ResumoSituacaoCupom>(ENDPOINTS.CUPOM_FISCAL_CONSULTAR_SITUACAO(cupomId));

  if (!data) {
    throw Error('Ocorreu um erro ao consultar a situação do cupom');
  }

  return data;
}

interface InserirClienteCupomFiscalCommand {
  clienteId?: string | null;
}

export async function inserirClienteCupomFiscal(cupomId: string, clienteId?: string | null): Promise<void> {
  const command: InserirClienteCupomFiscalCommand = {
    clienteId: clienteId,
  };
  await api.put(ENDPOINTS.CUPOM_FISCAL_INSERE_CLIENTE(cupomId), command);
}

export async function deletarClienteCupomFiscal(cupomId: string): Promise<void> {
  await api.delete(ENDPOINTS.CUPOM_FISCAL_DELETA_CLIENTE(cupomId));
}

interface EnviarPacoteContadorCommand {
  contadorEmail: string;
  empresaId: string | null | undefined;
  dataInicio: Date;
  dataFinal: Date;
}

export async function enviarPacoteContador(
  empresaId: string | null | undefined,
  contadorEmail: string,
  dataInicio: Date,
  dataFinal: Date,
): Promise<string> {
  const command: EnviarPacoteContadorCommand = {
    contadorEmail,
    empresaId,
    dataInicio,
    dataFinal,
  };
  const response = await api.post<PacoteContadorUrl>(ENDPOINTS.CUPOM_FISCAL_ENVIAR_PACOTE_CONTADOR(), command);

  if (!response.ok || !response.data) {
    throw Error('Ocorreu um erro ao enviar o pacote de contador');
  }

  return response.data.url;
}
