import { Spinner } from 'reactstrap';
import styled from 'styled-components';

type LoadingContentProps = {
  children?: React.ReactNode;
  isLoading?: boolean;
};

export function LoadingContent({ children, isLoading }: LoadingContentProps) {
  return (
    <>
      {isLoading && (
        <Backdrop>
          <BackdropContent>
            <Spinner color="primary" />
          </BackdropContent>
        </Backdrop>
      )}

      {children && <div>{children}</div>}
    </>
  );
}

const Backdrop = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  opacity: 0.7;
  background-color: #fff;
  z-index: 99998;
`;

const BackdropContent = styled.div`
  position: relative;
  margin: 0 auto;
  top: 50%;
`;
