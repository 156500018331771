import { Link, withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import MetaTags from 'react-meta-tags';
import errorImage from '../../assets/images/error-img.png';
import { ROUTES } from 'config/routes';

export const Page404 = withRouter(() => {
  return (
    <>
      <div className="account-pages my-5 pt-5">
        <MetaTags>
          <title>404 Nada Encontrado | Skote - Sistema Fusion</title>
        </MetaTags>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-5">
                <h1 className="display-2 font-weight-medium">
                  4<i className="bx bx-buoy bx-spin text-primary display-3" />4
                </h1>
                <h4 className="text-uppercase">Desculpe, página não encontada</h4>
                <div className="mt-5 text-center">
                  <Link className="btn btn-primary " to={ROUTES.HOME}>
                    Voltar para o Inicio
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} xl={6}>
              <div>
                <img src={errorImage} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
});
