import { addCpfCnpjMask } from 'common/mask';
import { EmpresaDto } from 'model/types/empresa.types';
import { forwardRef, ForwardRefRenderFunction, useEffect, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { OptionTypeBase } from 'react-select';
import AsyncSelect, { Async } from 'react-select/async';
import { obterEmpresa, obterEmpresas } from 'services/empresaService';

type EmpresaSelected = EmpresaDto;

interface EmpresaOption extends OptionTypeBase {
  value: string;
  label: string;
  object: EmpresaSelected;
}

export interface SelectEmpresaProps {
  name?: string;
  value?: string | null;
  error?: FieldError;
  onBlur?: () => void;
  onChange?: (event: string) => void;
  onSelected?: (value: EmpresaSelected) => void;
  menuPlacement?: 'top' | 'auto' | 'bottom';
  isDisabled?: boolean;
  autoFocus?: boolean;
}

const SelectEmpresaComponent: ForwardRefRenderFunction<Async<EmpresaOption>, SelectEmpresaProps> = (
  { name, value, error, menuPlacement = 'auto', isDisabled = false, autoFocus, onChange, onBlur, onSelected },
  ref,
) => {
  const loadOptionsRef = useRef<NodeJS.Timeout>();
  const [isLoading, setIsLoading] = useState(false);
  const [empresa, setEmpresa] = useState<EmpresaOption | null>();

  useEffect(() => {
    let isCanceled = false;

    async function fetchValue() {
      if (!!!value) {
        setEmpresa(null);
        return;
      }

      if (value === empresa?.value) return;

      setIsLoading(true);

      try {
        const data = await obterEmpresa(value);
        if (!!!data || isCanceled) return;
        setEmpresa({ value: data.id, label: data.razaoSocial, object: data });
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }

    fetchValue();

    return () => {
      isCanceled = true;
    };
  }, [value, empresa]);

  function promiseOptions(inputValue: string): Promise<any> {
    loadOptionsRef.current && clearTimeout(loadOptionsRef.current);

    return new Promise((resolve, reject) => {
      loadOptionsRef.current = setTimeout(async () => {
        try {
          const apiRespose = await obterEmpresas();
          const data = apiRespose.data ?? [];

          const empresasFiltrada = data
            .filter(e => e.razaoSocial.toLowerCase().includes(inputValue.toLowerCase()) || inputValue === '')
            .map((empresa: EmpresaDto) => {
              return {
                value: empresa.id,
                label: (
                  <div>
                    {empresa.razaoSocial}&nbsp;
                    <span className="small text-muted">{addCpfCnpjMask(empresa.cpfCnpj)}</span>
                  </div>
                ),
              };
            });

          resolve(empresasFiltrada);
        } catch (error: any) {
          reject(error);
        }
      }, 1000);
    });
  }

  function handleOnChange(value: EmpresaOption | null) {
    if (!!!value) {
      onChange && onChange('');
      return;
    }

    setEmpresa(value);
    onChange && onChange(value.value);
    onSelected && onSelected(value.object);
  }

  return (
    <>
      <AsyncSelect
        ref={ref}
        name={name}
        onChange={handleOnChange}
        value={empresa}
        onBlur={onBlur}
        loadOptions={promiseOptions}
        cacheOptions
        isLoading={isLoading}
        defaultOptions={true}
        classNamePrefix="select2-selection"
        menuPlacement={menuPlacement}
        placeholder="digite para buscar..."
        noOptionsMessage={() => 'nenhum registro encontrado para o termo buscado'}
        isDisabled={isDisabled}
        autoFocus={autoFocus}
      />
      {!!error?.message && <span className="text-danger small">{error?.message}</span>}
    </>
  );
};

export const SelectEmpresa = forwardRef(SelectEmpresaComponent);
