import {
  InputHTMLAttributes,
  ForwardRefRenderFunction,
  forwardRef,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useState,
} from 'react';
import { addMoneyMask } from 'common/mask';
import { toDecimal } from 'common/decimals';
import { FieldError } from 'react-hook-form';
import classNames from 'classnames';

interface InputDecimalProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: FieldError;
  onChangeDecimal?: (value: number) => void;
  sm?: boolean;
}

const InputDecimalComponent: ForwardRefRenderFunction<HTMLInputElement, InputDecimalProps> = (
  { name, error, onChange, onChangeDecimal, onBlur, onKeyUp, sm = false, defaultValue, ...rest },
  ref,
) => {
  const [inputValue] = useState<string>();

  function handleOnChange(event: ChangeEvent<HTMLInputElement>): void {
    event.target.value = addMoneyMask(event.target.value);
    onChange && onChange(event);

    if (onChangeDecimal) {
      const priceValue = toDecimal(event.target.value);
      onChangeDecimal(priceValue);
    }
  }

  function handleOnBlur(event: FocusEvent<HTMLInputElement>): void {
    event.target.value = addMoneyMask(event.target.value);
    onBlur && onBlur(event);
  }

  function handleOnKeyUp(event: KeyboardEvent<HTMLInputElement>) {
    event.currentTarget.value = addMoneyMask(event.currentTarget.value);
    onKeyUp && onKeyUp(event);
  }

  function handleOnKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.ctrlKey || event.altKey) return;

    if (event.key.length === 1 && /[^\d]/.test(event.key)) {
      event.preventDefault();
    }
  }

  function handleOnFocus(event: FocusEvent<HTMLInputElement>): void {
    if (event.target.value) {
      event.target.select();
    }
  }

  return (
    <>
      <input
        title="informe o valor"
        ref={ref}
        name={name}
        value={inputValue}
        defaultValue={defaultValue ?? '0,00'}
        className={classNames('form-control text-end', { 'is-invalid': !!error?.message, 'form-control-sm': sm })}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onKeyUp={handleOnKeyUp}
        onKeyDown={handleOnKeyDown}
        onFocus={handleOnFocus}
        {...rest}
      />
      {!!error?.message && <span className="text-danger small">{error?.message}</span>}
    </>
  );
};

export const InputDecimal = forwardRef(InputDecimalComponent);
