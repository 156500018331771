import { api, ENDPOINTS } from 'api';
import { ImportacaoDadosResponse, ImportarDadosCommand, LoteImportacaoDto } from 'model/types/importacaoDados.types';

export async function enviarArquivoImportacaoDados(command: ImportarDadosCommand): Promise<ImportacaoDadosResponse> {
  const response = await api.post<ImportacaoDadosResponse>(ENDPOINTS.IMPORTACAO_DADOS_IMPORTAR(), command);
  if (response.ok && response.data) {
    return response.data;
  }

  throw new Error('Erro ao enviar arquivo de dados');
}

export async function obterLoteImportacaoPorId(loteId: string): Promise<LoteImportacaoDto> {
  const response = await api.get<LoteImportacaoDto>(ENDPOINTS.IMPORTACAO_DADOS_POR_ID(loteId));
  if (response.ok && response.data) {
    return response.data;
  }

  throw new Error('Erro ao obter lote de importação');
}
