import api, { ENDPOINTS } from 'api';
import { PisCofinsDto } from 'model/types/fiscal.types';

const CHAVE_CACHE_ITENS_PIS = 'pisCofinsCache';

const getFromCache = (): PisCofinsDto[] => {
  return JSON.parse(window.sessionStorage.getItem(CHAVE_CACHE_ITENS_PIS) ?? '[]');
};

export async function obterTodosPisCofins(): Promise<PisCofinsDto[]> {
  const cache = getFromCache();
  if (cache.length > 0) {
    return cache;
  }

  const { data } = await api.get<PisCofinsDto[]>(ENDPOINTS.PIS_VENDA_LISTAR());
  window.sessionStorage.setItem(CHAVE_CACHE_ITENS_PIS, JSON.stringify(data ?? []));

  return !!data ? data : ([] as PisCofinsDto[]);
}

export async function obterPisCofinsPorId(id: string): Promise<PisCofinsDto | null> {
  const data = await obterTodosPisCofins();
  return data.find(i => i.codigo === id) ?? null;
}
