import ENDPOINTS from 'api/endpoints';
import { PageContent } from 'components/Common/PageContent';
import RemotePageTable from 'components/Table/RemotePageTable';
import { Card, CardBody, Row, Col, Badge } from 'reactstrap';
import { textColumn, dummyColumn, dateTimeColumn } from 'components/Table/columnFactory';
import { VerDetalhesImportacaoModal } from './VerDetalhesImportacaoModal';
import { useState } from 'react';
import { NovaImportacaoModal } from './NovaImportacao';
import { LoteImportacaoResumoDto } from 'model/types/importacaoDados.types';

export function ListagemImportacao() {
  const [modalDetalhesIsOpen, setModalDetalhesIsOpen] = useState<boolean>(false);
  const [modalNovaImportacaoIsOpen, setModalNovaImportacao] = useState<boolean>(false);
  const [modalDetalhesLoteId, setModalDetalhesLoteId] = useState<string>('');
  const [dataVersion, setDataVersion] = useState<number>(0);

  const handleVerDetalhes = (lote: LoteImportacaoResumoDto) => {
    setModalDetalhesLoteId(lote.id);
    setModalDetalhesIsOpen(true);
  };

  const handleNovaImportacao = () => {
    setModalNovaImportacao(true);
  };

  const getColorStatusLoteBadge = (statusLote: string) => {
    switch (statusLote) {
      case 'Rejeitado':
        return 'danger';
      case 'Importado':
        return 'success';
      case 'ImportadoParcial':
        return 'warning';
      default:
        return 'primary';
    }
  };

  const verDetalhesColumn = dummyColumn<LoteImportacaoResumoDto>('', (_cell, row) => (
    <div className="text-start">
      <button type="button" className="btn btn-sm btn-link" onClick={() => handleVerDetalhes(row)}>
        Ver Detalhes
      </button>
    </div>
  ));

  const columns = [
    dummyColumn<LoteImportacaoResumoDto>('Status Lote', (_cell, row) => (
      <Badge color={getColorStatusLoteBadge(row.statusTexto)} pill>
        {row.statusTexto}
      </Badge>
    )),
    textColumn('Id. Lote', 'id'),
    dateTimeColumn('Data Cadastro', 'dataCadastro'),
    textColumn('Tipo Dados', 'tipoDadosTexto'),
    textColumn('Status Detalhe', 'statusDetalhes'),
    verDetalhesColumn,
  ];

  const RightBar = () => {
    return (
      <>
        <button type="button" className="btn btn-primary" onClick={handleNovaImportacao}>
          + Nova Importação
        </button>
      </>
    );
  };

  return (
    <>
      <NovaImportacaoModal
        isOpen={modalNovaImportacaoIsOpen}
        onToggle={toggle => setModalNovaImportacao(toggle)}
        onCreated={() => setDataVersion(v => v + 1)}
      />

      <VerDetalhesImportacaoModal
        loteId={modalDetalhesLoteId}
        isOpen={modalDetalhesIsOpen}
        onToggle={toggle => setModalDetalhesIsOpen(toggle)}
      />

      <PageContent title="Importação Dados" subTitle="Lotes importados">
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <RemotePageTable
                  searchBar={false}
                  tableName="listagem-importacao-dados"
                  dataVersion={dataVersion}
                  url={ENDPOINTS.IMPORTACAO_DADOS_LISTAR()}
                  columns={columns}
                  rightBar={<RightBar />}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </PageContent>
    </>
  );
}
