import { ApiResponse } from 'apisauce';
import { api, ENDPOINTS } from 'api';

import {
  AddItemCommand,
  AlterarTotalVendaCommand,
  NovaVendaCommand,
  NovoPagamentoCommand,
  PaginaVendas,
  PaginaVendasParams,
  TodasVendasPeriodoParams,
  VendaDto,
  VendaItemDto,
} from 'model/types/venda.types';

export async function obterPaginaVendas(params?: PaginaVendasParams): Promise<PaginaVendas> {
  const response = await api.get<PaginaVendas>(ENDPOINTS.VENDAS_PAGINA(), params);
  if (response.ok && response.data) return response.data;
  throw new Error('Erro ao obter página de vendas');
}

export async function obterTodasVendasPeriodo(params: TodasVendasPeriodoParams): Promise<VendaDto[]> {
  const dados: VendaDto[] = [];

  let paginaAtual = 1;
  let possuiMais = true;

  do {
    const pagina = await obterPaginaVendas({
      situacao: params.situacao,
      usuarioCriou: params.usuarioCriou,
      periodoOperacao: `${params.dataInicio},${params.dataFim}`,
      numeroPagina: paginaAtual,
      tamanhoPagina: 500,
    });

    dados.push(...pagina.dados);
    possuiMais = paginaAtual++ < pagina.totalPaginas;
  } while (possuiMais);

  return dados;
}

export async function obterVenda(id: string): Promise<ApiResponse<VendaDto>> {
  return await api.get<VendaDto>(ENDPOINTS.VENDAS_OBTER(id));
}

export async function obterVendaPeloId(id: string): Promise<VendaDto | null> {
  const { data } = await api.get<VendaDto>(ENDPOINTS.VENDAS_OBTER(id));
  return !data ? null : data;
}

export async function criarVenda(command: NovaVendaCommand): Promise<ApiResponse<VendaDto>> {
  return await api.post<VendaDto>(ENDPOINTS.VENDAS_NOVA(), command);
}

export async function addItemNaVenda(vendaId: string, command: AddItemCommand): Promise<ApiResponse<VendaItemDto>> {
  return await api.post<VendaItemDto>(ENDPOINTS.VENDAS_ADD_ITEM(vendaId), command);
}

export async function removerItemVenda(vendaId: string, itemId: string): Promise<void> {
  await api.delete(ENDPOINTS.VENDAS_REMOVER_ITEM(vendaId, itemId));
}

export async function removerClienteVenda(vendaId: string): Promise<ApiResponse<string>> {
  return await api.delete<string>(ENDPOINTS.VENDAS_REMOVER_CLIENTE(vendaId));
}

export async function informarClienteNaVenda(vendaId: string, clienteId: string): Promise<ApiResponse<string>> {
  const requestData = { clienteId: clienteId };
  return await api.post<string>(ENDPOINTS.VENDAS_ADD_CLIENTE(vendaId), requestData);
}

export async function alterarTotalVenda(vendaId: string, request: AlterarTotalVendaCommand): Promise<void> {
  await api.post<string>(ENDPOINTS.VENDAS_ALTERAR_TOTAL(vendaId), request);
}

export async function finalizarVenda(vendaId: string): Promise<ApiResponse<string>> {
  return await api.post<string>(ENDPOINTS.VENDAS_FINALIZAR(vendaId), null);
}

export async function cancelarVenda(vendaId: string, justificativa: string): Promise<ApiResponse<string>> {
  const requestData = {
    vendaId: vendaId,
    justificativa: justificativa ?? '',
  };

  return await api.post<string>(ENDPOINTS.VENDAS_CANCELAR(vendaId), requestData);
}

export async function adicionarPagamento(vendaId: string, request: NovoPagamentoCommand): Promise<void> {
  await api.post(ENDPOINTS.VENDAS_ADD_PAGAMENTO(vendaId), request);
}

export async function deletarPagamento(vendaId: string, pagamentoId: string): Promise<void> {
  await api.delete(ENDPOINTS.VENDAS_DELETAR_PAGAMENTO(vendaId, pagamentoId));
}
