import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toNumber } from 'lodash';
import { SpinnerButton } from 'components/Button/SpinnerButton';
import { PageContent } from 'components/Common/PageContent';
import { InputControl } from 'components/Form/InputControl';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { transformerMoney, transformerQuantidade, yup, yupResolver } from 'common/yup';
import { CreatableUnidade } from 'components/Produto/CreatableUnidade';
import { InputPreco } from 'components/Form/InputPreco';
import { SelectOrigemMercadoria } from 'components/Form/SelectOrigemMercadoria';
import { SelectNcm } from 'components/Form/SelectNcm';
import { InputQuantidade } from 'components/Form/InputQuantidade';
import { toMoneyFormat } from 'common/formatters';
import { toDecimal } from 'common/decimals';
import { showToastErrors, showToastSuccess } from 'common/toast_config';
import { alterarProduto, cadastrarProduto, deletarRegraFiscal, obterProduto } from 'services/produtoService';
import { AlterarProdutoCommand, NovoProdutoCommand, ProdutoRegraFiscalDto } from 'model/types/produto.types';
import { useFormTabOnEnter } from 'hooks/useFormTabOnEnter';
import { PageFooter } from 'components/Common/PageFooter';
import { ProdutoRegrasFiscais } from './ProdutoRegrasFiscais';
import { ProdutoRegraFiscalModal } from './ProdutoRegraFiscalModal';
import { ROUTES } from 'config/routes';

type ParamsData = {
  id: string;
};

type FormData = {
  nome: string;
  unidadeMedida: string;
  codigoBarras: string;
  estoqueInicial: number;
  estoqueMinimo: number;
  estoqueMaximo: number;
  precoCusto: string;
  precoVenda: string;
  origemMercadoriaId: string;
  ncm: string;
  cest: string;
};

const schema = yup.object().shape({
  nome: yup.string().required().min(2),
  unidadeMedida: yup.string().required(),
  codigoBarras: yup.string().max(18).nullable(),
  estoqueInicial: yup
    .number()
    .transform(transformerQuantidade)
    .required()
    .min(0)
    .typeError('Estoque inicial inválido.'),
  estoqueMinimo: yup.number().transform(transformerQuantidade).required().min(0).typeError('Estoque mínimo inválido'),
  estoqueMaximo: yup.number().transform(transformerQuantidade).required().min(0).typeError('Estoque máximo inválido.'),
  precoCusto: yup.number().transform(transformerMoney).required(),
  precoVenda: yup.number().transform(transformerMoney).required().moreThan(0, 'Valor precisa ser maior que 0,00.'),
  origemMercadoriaId: yup.string().required(),
  ncm: yup.string().required(),
});

const defaultValues = {
  unidadeMedida: '',
  estoqueInicial: 0,
  estoqueMinimo: 0,
  estoqueMaximo: 0,
  precoCusto: '0,00',
  precoVenda: '0,00',
};

export function ProdutoCadastro() {
  const history = useHistory();
  const { id } = useParams<ParamsData>();
  const { handleOnKeyDown } = useFormTabOnEnter();
  const [versaoDados, setVersaoDados] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [regrasFiscais, setRegrasFiscais] = useState<ProdutoRegraFiscalDto[]>([]);
  const [regraFiscalModalIsOpen, setRegraFiscalModalIsOpen] = useState<boolean>(false);

  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver(schema), defaultValues });

  useEffect(() => {
    let isCanceled = false;

    async function fetchData() {
      setIsLoading(true);
      setRegrasFiscais([]);

      try {
        const produtoData = await obterProduto(id);

        if (isCanceled) return;

        if (!!produtoData) {
          setValue('nome', produtoData.nome);
          setValue('codigoBarras', produtoData.codigoBarras);
          setValue('unidadeMedida', produtoData.unidadeMedida);
          setValue('estoqueMinimo', produtoData.estoqueMinimo);
          setValue('estoqueMaximo', produtoData.estoqueMaximo);
          setValue('precoCusto', toMoneyFormat(produtoData.precoCusto));
          setValue('precoVenda', toMoneyFormat(produtoData.precoVenda));
          setValue('origemMercadoriaId', produtoData.origemMercadoria.toString());
          setValue('ncm', produtoData.ncm);
          setValue('cest', produtoData.cest ?? '');
          setRegrasFiscais(produtoData.regrasFiscais);
        }
      } finally {
        setIsLoading(false);
      }
    }

    if (!!id) {
      // hack para yup aceitar enviar form em edição
      setValue('estoqueInicial', 1);
      fetchData();
    }

    return () => {
      isCanceled = true;
    };
  }, [id, versaoDados, setValue]);

  const submitProduto: SubmitHandler<FormData> = async values => {
    setIsLoading(true);

    try {
      const commonDataCommand = {
        codigoBarras: values.codigoBarras,
        unidadeMedida: values.unidadeMedida,
        estoqueMaximo: values.estoqueMaximo,
        estoqueMinimo: values.estoqueMinimo,
        nome: values.nome,
        precoCusto: toDecimal(values.precoCusto, 2),
        precoVenda: toDecimal(values.precoVenda, 2),
        origemMercadoria: toNumber(values.origemMercadoriaId),
        ncm: values.ncm,
        cest: values.cest,
      };

      if (!!id) {
        const command: AlterarProdutoCommand = {
          ...commonDataCommand,
          id: id,
        };

        await alterarProduto(command);
        showToastSuccess('Produto alterado com sucesso');
      } else {
        const command: NovoProdutoCommand = {
          ...commonDataCommand,
          estoqueInicial: values.estoqueInicial,
        };

        const res = await cadastrarProduto(command);
        showToastSuccess('Produto cadastrado com sucesso');
        history.push(ROUTES.PRODUTO_ALTERAR(res.id));
      }
    } catch (error: any) {
      showToastErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteRegraFiscal = async (regraId: string) => {
    setIsLoading(true);
    try {
      await deletarRegraFiscal(id, regraId);
      setVersaoDados(v => v + 1);
    } catch (error: any) {
      showToastErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClosedRegraFiscalModal = () => {
    setRegraFiscalModalIsOpen(false);
  };

  const handleSuccessRegraFiscalModal = () => {
    setRegraFiscalModalIsOpen(false);
    setVersaoDados(v => v + 1);
  };

  return (
    <>
      {!!id && (
        <ProdutoRegraFiscalModal
          produtoId={id}
          isOpen={regraFiscalModalIsOpen}
          handleClose={handleClosedRegraFiscalModal}
          onSuccess={handleSuccessRegraFiscalModal}
        />
      )}

      <Form onKeyDown={handleOnKeyDown} onSubmit={handleSubmit(submitProduto)}>
        <PageContent title="Produtos" subTitle="Listar Produtos" isLoading={isLoading}>
          <Card>
            <CardHeader className="bg-transparent border-bottom text-uppercase">Informações</CardHeader>
            <CardBody>
              <FormGroup>
                <Label>Nome</Label>
                <InputControl {...register('nome')} error={errors.nome} autoFocus />
              </FormGroup>

              <Row xs={1} sm={1}>
                <Col md={3}>
                  <FormGroup>
                    <Label>Unidade</Label>
                    <Controller
                      name="unidadeMedida"
                      control={control}
                      render={({ field }) => <CreatableUnidade {...field} />}
                    />

                    {!!errors?.unidadeMedida?.message && (
                      <span className="text-danger small">{errors?.unidadeMedida?.message}</span>
                    )}
                  </FormGroup>
                </Col>

                <Col md={3}>
                  <FormGroup>
                    <Label>Código de Barras</Label>
                    <InputControl {...register('codigoBarras')} error={errors.codigoBarras}></InputControl>
                  </FormGroup>
                </Col>

                <Col md={3}>
                  <FormGroup>
                    <Label>Preço Custo</Label>
                    <InputPreco {...register('precoCusto')} error={errors.precoCusto} />
                  </FormGroup>
                </Col>

                <Col md={3}>
                  <FormGroup>
                    <Label>Preço Venda</Label>
                    <InputPreco {...register('precoVenda')} error={errors.precoVenda} />
                  </FormGroup>
                </Col>
              </Row>

              <Row sm={1}>
                {!id && (
                  <Col md={3}>
                    <FormGroup>
                      <Label>Estoque Inicial</Label>
                      <InputQuantidade {...register('estoqueInicial')} error={errors.estoqueInicial} />
                    </FormGroup>
                  </Col>
                )}

                <Col md={3}>
                  <FormGroup>
                    <Label>Estoque Mínimo</Label>
                    <InputQuantidade {...register('estoqueMinimo')} error={errors.estoqueMinimo} />
                  </FormGroup>
                </Col>

                <Col md={3}>
                  <FormGroup>
                    <Label>Estoque Máximo</Label>
                    <InputQuantidade {...register('estoqueMaximo')} error={errors.estoqueMaximo} />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardHeader className="bg-transparent border-bottom text-uppercase">
              Dados Fiscais para Emissão de NF-e/NFC-e
            </CardHeader>
            <CardBody>
              <Row sm={1}>
                <Col md={3}>
                  <FormGroup>
                    <Label>Origem Mercadoria</Label>
                    <Controller
                      name="origemMercadoriaId"
                      control={control}
                      render={({ field }) => <SelectOrigemMercadoria error={errors.origemMercadoriaId} {...field} />}
                    />
                  </FormGroup>
                </Col>

                <Col md>
                  <FormGroup>
                    <Label>Código NCM</Label>
                    <Controller
                      name="ncm"
                      control={control}
                      render={({ field }) => <SelectNcm error={errors.ncm} {...field} />}
                    ></Controller>
                  </FormGroup>
                </Col>

                <Col md={2}>
                  <FormGroup>
                    <Label>Código CEST</Label>
                    <InputControl {...register('cest')} error={errors.cest} />
                  </FormGroup>
                </Col>
              </Row>

              {!!id && (
                <>
                  <hr />

                  <ProdutoRegrasFiscais
                    produtoId={id}
                    regrasFiscais={regrasFiscais}
                    onAddRegraFiscal={() => setRegraFiscalModalIsOpen(true)}
                    onDeleteRegraFiscal={handleDeleteRegraFiscal}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </PageContent>

        <PageFooter>
          <Row>
            <Col>
              <SpinnerButton color="primary" type="submit" loading={isLoading}>
                {!!id ? 'Salvar Alterações' : 'Cadastrar Produto'}
              </SpinnerButton>
            </Col>
          </Row>
        </PageFooter>
      </Form>
    </>
  );
}
