import { useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { transformerMoney, transformerQuantidade, yup, yupResolver } from 'common/yup';
import { InputPreco } from 'components/Form/InputPreco';
import { SelectProduto, ProdutoSelectResult } from 'components/Form/SelectProduto';
import { Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { addItemNaVenda } from 'services/vendaService';
import { toDecimal, decimalRound } from 'common/decimals';
import { InputQuantidade } from 'components/Form/InputQuantidade';
import { showToastErrors, showToastSuccess } from 'common/toast_config';
import { SpinnerButton } from 'components/Button/SpinnerButton';
import { useFormTabOnEnter } from 'hooks/useFormTabOnEnter';
import { toMoneyFormat } from 'common/formatters';

const yupSchema = yup.object().shape({
  produtoId: yup.string().required(),
  quantidade: yup
    .number()
    .transform(transformerQuantidade)
    .required()
    .min(0.01, 'Quantidade precisa ser maior que zero.')
    .typeError('Quantidade é inválida.'),
  precoUnitario: yup
    .number()
    .transform(transformerMoney)
    .required()
    .min(0.01, 'Preço Unitário precisa ser maior que zero.')
    .typeError('Preço Unitário é inválido.'),
  desconto: yup
    .number()
    .transform(transformerMoney)
    .required()
    .min(0.0, 'Desconto não pode ser negativo.')
    .typeError('Desconto é inválido.'),
  total: yup
    .number()
    .transform(transformerMoney)
    .required()
    .min(0.01, 'Total precisa ser maior que zero.')
    .typeError('Total é inválido.'),
});

const formDefaultValues = {
  produtoId: '',
  quantidade: '1',
  precoUnitario: '0,00',
  desconto: '0,00',
  total: '0,00',
};

interface ItemForm {
  produtoId: string;
  quantidade: string;
  precoUnitario: string;
  desconto: string;
  total: string;
}

interface AddItemVendaModalProps {
  isOpen: boolean;
  vendaId: string;
  onToggle: (toggle: boolean) => void;
  onSuccess?: () => void;
  onClosed?: () => void;
  onItemAdded?: () => void;
}

export function AddItemVendaModal(props: AddItemVendaModalProps) {
  const {
    register,
    setValue,
    handleSubmit,
    setFocus,
    watch,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm<ItemForm>({
    resolver: yupResolver(yupSchema),
    defaultValues: formDefaultValues,
  });

  const produtoRef = useRef<any>();
  const { handleOnKeyDown } = useFormTabOnEnter();

  function handleToggleModal() {
    if (isSubmitting) return;
    props.onToggle(!props.isOpen);
  }

  function handleOnOpened() {
    resetModal();
    focusInputProduto();
  }

  function focusInputProduto() {
    produtoRef.current.focus();
  }

  function resetModal() {
    reset(formDefaultValues);
    focusInputProduto();
  }

  function handleOnSelectedProduto(produto: ProdutoSelectResult) {
    reset({}, { keepValues: true });
    setValue('quantidade', '1');
    setValue('precoUnitario', toMoneyFormat(produto.precoVenda));
    setValue('desconto', '0,00');
    setValue('total', toMoneyFormat(produto.precoVenda * 1));
    setValue('produtoId', produto.id);
    setFocus('quantidade');
  }

  async function handleFormSubmit(data: ItemForm) {
    const command = {
      produtoId: data.produtoId,
      quantidade: toDecimal(data.quantidade),
      precoUnitario: toDecimal(data.precoUnitario),
      desconto: toDecimal(data.desconto),
    };

    try {
      await addItemNaVenda(props.vendaId, command);
      showToastSuccess('Item adicionado com sucesso.');
      props.onItemAdded && props.onItemAdded();
      resetModal();
    } catch (error: any) {
      showToastErrors(error.messages);
    }
  }

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'quantidade' || name === 'precoUnitario' || name === 'desconto') {
        reset({}, { keepValues: true });
        const quantidade = toDecimal(value.quantidade);
        const precoUnitario = toDecimal(value.precoUnitario);
        const desconto = toDecimal(value.desconto);
        const total = decimalRound(quantidade * precoUnitario - desconto, 2);

        setValue('total', toMoneyFormat(total > 0 ? total : 0));
      }
    });
    return () => subscription.unsubscribe();
  }, [reset, setValue, watch]);

  useEffect(() => {
    errors.produtoId && focusInputProduto();
  }, [errors.produtoId]);

  return (
    <Modal
      size="lg"
      className="modal-fullscreen-md-down"
      isOpen={props.isOpen}
      toggle={handleToggleModal}
      backdrop={true}
      onOpened={handleOnOpened}
      onClosed={props.onClosed}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} onKeyDown={handleOnKeyDown}>
        <ModalHeader toggle={handleToggleModal}>Venda - Add Item</ModalHeader>
        <ModalBody className="overflow-visible">
          <Row>
            <Col>
              <FormGroup>
                <Label>Qual produto deseja vender?</Label>
                <Controller
                  name="produtoId"
                  control={control}
                  render={({ field }) => (
                    <SelectProduto
                      {...field}
                      ref={produtoRef}
                      menuPlacement="auto"
                      onSelected={handleOnSelectedProduto}
                      error={errors.produtoId}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row xs={1} sm={4} className="mt-2">
            <Col>
              <FormGroup>
                <Label>Quantidade</Label>
                <InputQuantidade {...register('quantidade')} error={errors.quantidade} />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label>Preço Unitário</Label>
                <InputPreco {...register('precoUnitario')} error={errors.precoUnitario} />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label>Desconto</Label>
                <InputPreco {...register('desconto')} error={errors.desconto} />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label>Total</Label>
                <InputPreco readOnly={true} {...register('total')} error={errors.total} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <SpinnerButton loading={isSubmitting} type="button" color="primary" onClick={handleSubmit(handleFormSubmit)}>
            {isSubmitting ? 'Adicionando...' : 'Adicionar o item'}
          </SpinnerButton>
        </ModalFooter>
      </form>
    </Modal>
  );
}
