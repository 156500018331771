import { PageContent } from 'components/Common/PageContent';
import { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Input, Row, Button } from 'reactstrap';
import { ProdutoSelectResult, SelectProduto } from 'components/Form/SelectProduto';
import { InputQuantidade } from 'components/Form/InputQuantidade';
import { toDecimal, decimalRound } from 'common/decimals';
import { toMoneyFormat } from 'common/formatters';
import { useFormTabOnEnter } from 'hooks/useFormTabOnEnter';
import { adicionarEstoque, debitarEstoque } from 'services/estoqueService';
import { showToastErrors, showToastSuccess } from 'common/toast_config';
import { Controller, useForm } from 'react-hook-form';

enum TipoMovimento {
  Adicionar = 'Adicionar',
  Debitar = 'Debitar',
}

type FormData = {
  produtoId?: string;
  quantidade: string;
};

const formInitialValues: FormData = {
  produtoId: '',
  quantidade: '0',
};

export function MovimentarEstoque() {
  const { handleOnKeyDown } = useFormTabOnEnter();
  const { register, control, handleSubmit, watch, reset, setFocus } = useForm<FormData>({
    defaultValues: formInitialValues,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tipoSelecionado, setTipoSelecionado] = useState<TipoMovimento>(TipoMovimento.Adicionar);
  const [produto, setProduto] = useState<ProdutoSelectResult>();

  const calculateNewStock = (produto: ProdutoSelectResult, quantidade: string, tipo: TipoMovimento) => {
    let qtdeSomar = toDecimal(quantidade, 2);
    const estoqueAtual = produto.estoqueAtual ?? 0;

    if (tipo === TipoMovimento.Debitar) {
      qtdeSomar = qtdeSomar * -1;
    }
    return decimalRound(qtdeSomar + estoqueAtual, 2);
  };

  const clearForm = () => {
    reset();
    setProduto(undefined);
  };

  const handleOnSubmit = async (values: FormData) => {
    setIsLoading(true);
    try {
      if (!!!values.produtoId) {
        showToastErrors(['É preciso selecionar um produto.']);
        return;
      }

      const quantidade = toDecimal(values.quantidade, 2);
      if (quantidade <= 0) {
        showToastErrors(['Quantidade selecionada é inválida.']);
        return;
      }

      if (tipoSelecionado === TipoMovimento.Adicionar) {
        await adicionarEstoque(values.produtoId, quantidade);
        showToastSuccess('Estoque adicionado com sucesso!');
      } else if (tipoSelecionado === TipoMovimento.Debitar) {
        await debitarEstoque(values.produtoId, quantidade);
        showToastSuccess('Estoque debitado com sucesso!');
      }

      clearForm();
      setFocus('produtoId');
    } catch (error: any) {
      showToastErrors(error?.messages);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageContent title="Estoque" subTitle="Ajustar estoque" isLoading={isLoading}>
        <Card>
          <CardHeader></CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(handleOnSubmit)} onKeyDown={handleOnKeyDown}>
              <Row sm={2}>
                <Col sm={6}>
                  <div className="d-flex justify-content-center">
                    <FormGroup check inline>
                      <Input
                        type="radio"
                        id="tipoAcrescentar"
                        name="tipoMovimento"
                        defaultChecked={tipoSelecionado === TipoMovimento.Adicionar}
                        onChange={e => e.target.value === 'on' && setTipoSelecionado(TipoMovimento.Adicionar)}
                      />

                      <Label for="tipoAcrescentar" check>
                        {TipoMovimento.Adicionar}
                      </Label>
                    </FormGroup>

                    <FormGroup check inline>
                      <Input
                        type="radio"
                        id="tipoDescontar"
                        name="tipoMovimento"
                        defaultChecked={tipoSelecionado === TipoMovimento.Debitar}
                        onChange={e => e.target.value === 'on' && setTipoSelecionado(TipoMovimento.Debitar)}
                      />

                      <Label for="tipoDescontar" check>
                        {TipoMovimento.Debitar}
                      </Label>
                    </FormGroup>
                  </div>

                  <FormGroup className="mt-3">
                    <Label>Qual produto deseja movimentar o estoque?</Label>
                    <Controller
                      name="produtoId"
                      control={control}
                      render={({ field }) => (
                        <SelectProduto autoFocus menuPlacement="auto" {...field} onSelected={setProduto} />
                      )}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Qual a quantidade movimentar?</Label>
                    <InputQuantidade autoComplete="off" defaultValue={0} {...register('quantidade')} />
                  </FormGroup>

                  <Button type="submit" className="float-end" color="primary">
                    Aplicar movimentação
                  </Button>
                </Col>

                <Col sm={6}>
                  <div className="text-primary">Detalhes do produto:</div>
                  {!!produto && (
                    <div className="ps-sm-3">
                      <h4 className="mt-2">{produto.nome}</h4>
                      <h5>
                        <i className="mdi mdi-arrow-right"></i>
                        Estoque:&nbsp;<del className="me-2 text-muted">{toMoneyFormat(produto.estoqueAtual)}</del>
                        <b className="me-4 text-primary">
                          {toMoneyFormat(calculateNewStock(produto, watch('quantidade'), tipoSelecionado))}
                        </b>
                      </h5>
                      <h6>
                        <i className="mdi mdi-arrow-right"></i>Preço venda:&nbsp;
                        <b className="me-2">{toMoneyFormat(produto.precoVenda)}</b>
                      </h6>

                      <h6>
                        <i className="mdi mdi-arrow-right"></i>Estoque mínimo:&nbsp;
                        <b className="me-2">{toMoneyFormat(produto.estoqueMinimo)}</b>
                      </h6>

                      <h6>
                        <i className="mdi mdi-arrow-right"></i>Estoque máximo:&nbsp;
                        <b className="me-2">{toMoneyFormat(produto.estoqueMaximo)}</b>
                      </h6>
                    </div>
                  )}
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </PageContent>
    </>
  );
}
