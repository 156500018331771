import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IndicadorDestinoOperacao, IndicadorOperacaoConsumidor, IndicadorPresenca } from 'model/types/common.types';
import { SelectIndicadorPresenca } from 'components/Form/SelectIndicadorPresenca';
import { NotaDto } from 'model/types/notafiscal.types';
import { Form, FormGroup, Label } from 'reactstrap';

export type NotaIndicadoresOperacaoValues = {
  indicadorPresenca: IndicadorPresenca;
  indicadorConsumidor: IndicadorOperacaoConsumidor;
  indicadorDestinoOperacao: IndicadorDestinoOperacao;
};

type NotaIndicadoresOperacaoProps = {
  nota: NotaDto;
  podeEditar: boolean;
  onSave: (values: NotaIndicadoresOperacaoValues) => void;
  onDirty: (isDirty: boolean) => void;
};

type FormData = {
  indicadorPresenca: IndicadorPresenca;
  indicadorConsumidor: IndicadorOperacaoConsumidor;
  operacaoForaEstado: number;
};

const operacaoForaEstado = (indicador: IndicadorDestinoOperacao) => {
  return indicador === IndicadorDestinoOperacao.OperacaoInterestadual ? 1 : 0;
};

export function NotaIndicadoresOperacao({ nota, podeEditar, onSave, onDirty }: NotaIndicadoresOperacaoProps) {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm<FormData>();

  const resetFormValues = () => {
    reset();
    setValue('indicadorConsumidor', nota.indicadorOperacaoConsumidor);
    setValue('operacaoForaEstado', operacaoForaEstado(nota.destinoOperacao));
    setValue('indicadorPresenca', nota.indicadorPrecensaComprador);
  };

  useEffect(() => {
    resetFormValues();
  }, [nota]);

  useEffect(() => {
    onDirty(isDirty);
  }, [isDirty]);

  const handleValidSubmit = (values: FormData) => {
    const resultValues: NotaIndicadoresOperacaoValues = {
      indicadorConsumidor: Number(values.indicadorConsumidor),
      indicadorPresenca: Number(values.indicadorPresenca),
      indicadorDestinoOperacao:
        values.operacaoForaEstado == 1
          ? IndicadorDestinoOperacao.OperacaoInterestadual
          : IndicadorDestinoOperacao.OperacaoInterna,
    };

    onSave(resultValues);
    reset(values);
  };

  return (
    <Form onSubmit={handleSubmit(handleValidSubmit)}>
      <FormGroup>
        <Label>Nota para consumidor final:</Label>
        <select disabled={!podeEditar} title={undefined} className="form-select" {...register('indicadorConsumidor')}>
          <option value={0}>Não</option>
          <option value={1}>Sim</option>
        </select>
      </FormGroup>

      <FormGroup>
        <Label>Nota com destino para fora do estado:</Label>
        <select disabled={!podeEditar} title={undefined} className="form-select" {...register('operacaoForaEstado')}>
          <option value={0}>Não</option>
          <option value={1}>Sim</option>
        </select>
      </FormGroup>

      <FormGroup>
        <Label>Indicador de presença:</Label>

        <div className="text-muted my-2">
          Identifica se a operação foi feita de forma presencial ou não, também pode-se definir se foi uma operação pela
          internet, por meio de algum intermediador, marketplace ou similares.
        </div>

        <SelectIndicadorPresenca disabled={!podeEditar} className="mt-2" {...register('indicadorPresenca')} />
      </FormGroup>

      {podeEditar && isDirty && (
        <div className="mt-2 d-flex justify-content-end gap-3">
          <button type="button" className="btn btn-link p-0" onClick={resetFormValues}>
            Cancelar
          </button>

          <button type="submit" className="btn btn-link p-0">
            Salvar alterações
          </button>
        </div>
      )}
    </Form>
  );
}
