import { showToastSuccess } from 'common/toast_config';
import { AlertErrors } from 'components/Alert/AlertErrors';
import { PageContent } from 'components/Common/PageContent';
import { PageFooter } from 'components/Common/PageFooter';
import { InputControl } from 'components/Form/InputControl';
import { SelectEmpresa } from 'components/Form/SelectEmpresa';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import { Moment } from 'moment';
import { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Alert, Button, Card, CardBody, CardTitle, Col, FormGroup, Label, Row } from 'reactstrap';
import { enviarPacoteContador } from 'services/nfceService';

registerLocale('pt-BR', ptBR);

type FiltroIntervalo = {
  dataInicio: Moment;
  dataFim: Moment;
};

const defaultFiltroIntervalo: FiltroIntervalo = {
  dataInicio: moment().subtract(1, 'month').startOf('month'),
  dataFim: moment().subtract(1, 'month').endOf('month'),
};

export function CupomEnviarPacoteContador() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [empresaId, setEmpresaId] = useState<string | null | undefined>();
  const [errors, setErrors] = useState<string[]>();
  const [emailContador, setEmailContador] = useState<string>('');
  const [filtroIntervalo, setFiltroIntervalo] = useState<FiltroIntervalo>(defaultFiltroIntervalo);
  const [sucesso, setSucesso] = useState<boolean>(false);
  const [urlDownloadPacote, setUrlDownloadPacote] = useState<string>('');

  async function handlerEnviarPacoteContador() {
    setIsLoading(true);
    setErrors([]);

    try {
      const url = await enviarPacoteContador(
        empresaId,
        emailContador,
        filtroIntervalo.dataInicio.toDate(),
        filtroIntervalo.dataFim.toDate(),
      );
      setUrlDownloadPacote(url);
      showToastSuccess('Pacote enviado com sucesso!');
      setSucesso(true);
    } catch (error: any) {
      setErrors(error.messages);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <PageContent title="Fiscal" subTitle="Enviar documentos fiscais" isLoading={isLoading}>
        <AlertErrors errors={errors} />
        <Row>
          <Col>
            {sucesso && (
              <>
                <Card>
                  <CardBody>
                    <CardTitle>
                      <Alert color="success" className="text-center">
                        Pacote enviado com sucesso! <span>Clique aqui</span> para efetuar o download
                      </Alert>
                    </CardTitle>
                    <Row className="text-center">
                      <a href={urlDownloadPacote} target="_blank" rel="noreferrer">
                        <Button color="success">Download Pacote Contador</Button>
                      </a>
                    </Row>
                  </CardBody>
                </Card>
              </>
            )}
            {!!!sucesso && (
              <Card>
                <CardBody>
                  <CardTitle>Enviar pacote documentos fiscais</CardTitle>
                  <div>
                    <div className="pt-4">
                      <Row className="g-1">
                        <Col>
                          <FormGroup>
                            <h5 className="font-size-14">Data Inicio</h5>
                            <DatePicker
                              selected={filtroIntervalo.dataInicio.toDate()}
                              onChange={e => setFiltroIntervalo({ ...filtroIntervalo, dataInicio: moment(e) })}
                              onKeyDown={e => e.preventDefault()}
                              className="form-control"
                              locale="pt-BR"
                              showYearDropdown
                              dateFormat="dd/MM/yyyy"
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <h5 className="font-size-14">Data Fim</h5>
                            <DatePicker
                              selected={filtroIntervalo.dataFim.toDate()}
                              onChange={e => setFiltroIntervalo({ ...filtroIntervalo, dataFim: moment(e) })}
                              onKeyDown={e => e.preventDefault()}
                              className="form-control"
                              locale="pt-BR"
                              showYearDropdown
                              dateFormat="dd/MM/yyyy"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <FormGroup>
                          <Label>Empresa</Label>
                          <SelectEmpresa value={empresaId} onChange={setEmpresaId}></SelectEmpresa>
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup>
                          <Label>E-mail do Contador</Label>
                          <InputControl
                            value={emailContador}
                            onChange={e => setEmailContador(e.target.value)}
                          ></InputControl>
                        </FormGroup>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </PageContent>
      <PageFooter>
        {!!!sucesso && (
          <Button size="sm" color="success" onClick={handlerEnviarPacoteContador}>
            Enviar pacote
          </Button>
        )}
      </PageFooter>
    </>
  );
}
