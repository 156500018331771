import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageContent } from 'components/Common/PageContent';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import { VendaDto, VendaSituacao } from 'model/types/venda.types';
import { obterVenda } from 'services/vendaService';
import { showToastErrors } from 'common/toast_config';
import { toMoneyFormat } from 'common/formatters';
import { obterEmpresa } from 'services/empresaService';
import { EmpresaDto } from 'model/types/empresa.types';
import { addCpfCnpjMask, addTelefoneMask } from 'common/mask';
import { toDateTimeFormat } from 'common/formatters';
import { PageFooter } from 'components/Common/PageFooter';
import { EmitirNotaVendaModal } from 'components/Vendas/EmitirNotaVendaModal';

type ParamData = {
  id: string;
};

export function VisualizarVenda() {
  const { id } = useParams<ParamData>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [venda, setVenda] = useState<VendaDto | null>(null);
  const [empresa, setEmpresa] = useState<EmpresaDto | null>();
  const [showNovaVendaModal, setShowNovaVendaModal] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data } = await obterVenda(id);
        if (!!!data) {
          showToastErrors(['Não foi possível interpretar o retorno da Venda.']);
          return;
        }

        setVenda(data);
      } catch (error: any) {
        showToastErrors(error?.messages);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      if (!venda) return;

      try {
        const response = await obterEmpresa(venda.empresa.id);
        if (response === null) {
          showToastErrors(['Não consegui carregar os dados da empresa.']);
          return;
        }

        setEmpresa(response);
      } catch (error: any) {
        showToastErrors(['Não consegui carregar os dados da empresa.']);
      }
    };

    fetchData();
  }, [venda]);

  const imprimirVenda = () => {
    window.print();
  };

  return (
    <>
      <EmitirNotaVendaModal vendaId={id} isOpen={showNovaVendaModal} onToggle={setShowNovaVendaModal} />

      <PageContent title="Vendas" subTitle="Visualizar" isLoading={isLoading}>
        <Card className="shadow-none">
          <CardBody>
            <Row className="align-items-stretch">
              <Col sm={4}>
                <div className="align-content-center">
                  <div className="fw-bold small">Data Operacao:</div>
                  <div>{toDateTimeFormat(venda?.dataOperacao ?? '')}</div>
                </div>
              </Col>
              <Col className="d-flex align-items-center justify-content-end">
                <span className="text-muted fs-5">{venda?.situacaoTexto}</span>
                <span className="px-2">#</span>
                <b className="fs-4">{venda?.numero.toString().padStart(5, '0')}</b>
              </Col>
            </Row>

            <hr />

            {!!empresa && (
              <Row>
                <Col>
                  <div className="fw-bold small">Empresa:</div>
                  <div className="fs-5">{empresa?.nome}</div>
                  <div className="small">
                    {empresa?.razaoSocial}
                    <span className="ms-2">{addCpfCnpjMask(empresa?.cpfCnpj)}</span>
                  </div>
                  <div className="small">
                    Telefone: {addTelefoneMask(empresa?.contatoTelefone)}{' '}
                    {empresa?.contatoTelefone2 && `ou ${addTelefoneMask(empresa?.contatoTelefone2)}`}
                  </div>
                </Col>
              </Row>
            )}

            <hr />

            <Row>
              <Col>
                <div className="small fw-bold">Cliente:</div>
                {!!venda?.cliente ? (
                  <>
                    <div>{venda.cliente.nome}</div>
                    <div hidden={!venda.cliente.telefone}>{addTelefoneMask(venda.cliente.telefone)}</div>
                    <div hidden={!venda.cliente.email}>{addTelefoneMask(venda.cliente.email)}</div>

                    {!!venda?.cliente?.endereco && (
                      <>
                        <div className="d-flex flex-wrap gap-2">
                          <div>{venda.cliente.endereco.logradouro}</div>
                          <div>{venda.cliente.endereco.numero}</div>
                          <div>{venda.cliente.endereco.bairro}</div>
                          <div>{venda.cliente.endereco.cep}</div>
                          <div>{venda.cliente.endereco.complemento}</div>
                          <div className="clearfix">
                            {venda.cliente.endereco.cidade} - {venda.cliente.endereco.uf}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div>Não informado</div>
                )}
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <div className="table-responsive">
                  <Table className="table-sm">
                    <thead>
                      <tr>
                        <th className="text-nowrap" style={{ width: '70px' }}>
                          Num.
                        </th>
                        <th className="text-nowrap">Descrição do Item</th>
                        <th style={{ width: '90px' }} className="text-end text-nowrap">
                          Qtd
                        </th>
                        <th style={{ width: '90px' }} className="text-end text-nowrap">
                          Preço
                        </th>
                        <th style={{ width: '90px' }} className="text-end text-nowrap">
                          Desconto
                        </th>
                        <th style={{ width: '90px' }} className="text-end text-nowrap">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {venda?.itens
                        .sort((a, b) => a.numero - b.numero)
                        .map((item, index) => (
                          <tr key={item.id}>
                            <td className="text-nowrap">{item.numero}</td>
                            <td>{item.produtoNome}</td>
                            <td className="text-end text-nowrap">{toMoneyFormat(item.quantidade)}</td>
                            <td className="text-end text-nowrap">{toMoneyFormat(item.precoUnitario)}</td>
                            <td className="text-end text-nowrap">{toMoneyFormat(item.descontoItem)}</td>
                            <td className="text-end text-nowrap">{toMoneyFormat(item.totalItem)}</td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={6} className="text-end fw-bold">
                          {toMoneyFormat(venda?.totalItens ?? 0, true)}
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-2 fs-6">
                  <div className="hstack justify-content-end">
                    Total Itens:
                    <div className="text-end" style={{ minWidth: '100px' }}>
                      {toMoneyFormat(venda?.totalItens ?? 0, true)}
                    </div>
                  </div>

                  <div className="hstack justify-content-end">
                    Total Desconto:
                    <div className="text-end" style={{ minWidth: '100px' }}>
                      {toMoneyFormat(venda?.descontoNaVenda ?? 0, true)}
                    </div>
                  </div>

                  <div className="hstack justify-content-end">
                    Total Frete:
                    <div className="text-end" style={{ minWidth: '100px' }}>
                      {toMoneyFormat(venda?.totalFrete ?? 0, true)}
                    </div>
                  </div>

                  <div className="hstack justify-content-end fs-4 mt-2">
                    Total Venda:
                    <div className="text-end" style={{ minWidth: '100px' }}>
                      {toMoneyFormat(venda?.totalDaVenda ?? 0, true)}
                    </div>
                  </div>

                  <div className="hstack justify-content-end">
                    Total Pago:
                    <div className="text-end" style={{ minWidth: '100px' }}>
                      {toMoneyFormat(venda?.totalPago ?? 0, true)}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="fw-bold mb-2">Pagamentos:</div>
                {venda?.pagamentos.map((p, index) => (
                  <div key={p.id} className="d-flex gap-4">
                    <div className="me-3">{p.tipo}</div>
                    <div>{p.quantidadeParcelas === 0 ? 'Avista' : p.quantidadeParcelas + 'x'}</div>
                    <div className="text-end">{toMoneyFormat(p.valor)}</div>
                  </div>
                ))}
              </Col>
            </Row>

            <div className="d-print-none">
              <div className="float-end">
                <Button color="success" onClick={imprimirVenda} className="me-2" title="Ou use Ctrl + P">
                  <i className="fa fa-print me-2" />
                  Imprimir
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </PageContent>
    </>
  );
}
