import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { RemotePageTableProvider } from 'providers/RemotePageTableProvider';
import { QueryClientProvider, QueryClient } from 'react-query';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from 'reportWebVitals';
import './i18n';
import store from './store';
import App from './App';

const queryClient = new QueryClient();

const app = (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <RemotePageTableProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </RemotePageTableProvider>
    </QueryClientProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
reportWebVitals();
