export interface OpcaoPagamento {
  tipo: number;
  descricao: string;
  aceitaParcelas: boolean;
  apenasAvista: boolean;
}

const configs: OpcaoPagamento[] = [
  { tipo: 1, descricao: 'Dinheiro', aceitaParcelas: false, apenasAvista: true },
  { tipo: 2, descricao: 'Pix', aceitaParcelas: false, apenasAvista: true },
  { tipo: 5, descricao: 'Crediário Loja', aceitaParcelas: true, apenasAvista: false },
  { tipo: 10, descricao: 'Cartão Credito', aceitaParcelas: false, apenasAvista: false },
  { tipo: 11, descricao: 'Cartão Débito', aceitaParcelas: false, apenasAvista: true },
  { tipo: 16, descricao: 'Boleto', aceitaParcelas: true, apenasAvista: false },
  { tipo: 99, descricao: 'Outros', aceitaParcelas: false, apenasAvista: true },
];

export const opcoesPagamento = {
  getOpcoes: () => configs,

  getByServerDescription(descricao: string): OpcaoPagamento {
    switch (descricao) {
      case 'Dinheiro':
        return configs[0];
      case 'Pix':
        return configs[1];
      case 'Crediario':
        return configs[2];
      case 'CartaoCredito':
        return configs[3];
      case 'CartaoDebito':
        return configs[4];
      case 'Boleto':
        return configs[5];
      default:
        return configs[6];
    }
  },

  getByTipo(tipo: string): OpcaoPagamento {
    return configs.find(o => o.tipo.toString() === tipo) ?? configs[6];
  },
};
