import { MouseEventHandler } from 'react';
import { Button, Card, CardBody } from 'reactstrap';

type PrimeiroAcessoRegrasCfopProps = {
  hidden?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export function PrimeiroAcessoRegrasCfop({ hidden, onClick }: PrimeiroAcessoRegrasCfopProps) {
  return (
    <Card hidden={hidden}>
      <CardBody className="text-center">
        <p className="lead">
          Não encontrei Regras Globais de CFOP para sua Organização, provavelmente esse é seu primeiro acesso ao
          recurso. Para iniciar as Regras Globias com nossa sugestão <b>clique no botão abaixo</b>.
        </p>

        <Button type="button" onClick={onClick} size="sm" color="success">
          Configurar Regras Globais
        </Button>
      </CardBody>
    </Card>
  );
}
