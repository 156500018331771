import ENDPOINTS from './endpoints';
import jwtDecode from 'jwt-decode';
import api from '.';

export const get = async url => {
  return await api.get(url);
};

export const getAccessToken = async (email, password) => {
  var postData = {
    usuario: email,
    senha: password,
  };

  return await api.post(ENDPOINTS.AUTENTICAR, postData);
};

export const recuperarSenha = async email => {
  var postData = {
    usuario: email,
  };

  return await api.post(ENDPOINTS.RECUPERAR_SENHA, postData);
};

export const enviarNovaSenha = async (token, email, novaSenha, confirmacaoSenha) => {
  var postData = {
    usuario: email,
    token,
    novaSenha,
    confirmacaoSenha,
  };

  return await api.post(ENDPOINTS.RECUPERAR_SENHA_NOVA, postData);
};

export const obterUsuarioLogado = async () => {
  const token = localStorage.getItem('token');
  const jwt = jwtDecode(token);

  return await api.get(ENDPOINTS.OBTER_USUARIO_PELO_ID(jwt.sub));
};

export const obterOrganizacaoAtual = async () => {
  const token = localStorage.getItem('token');
  const jwt = jwtDecode(token);
  const tenants = JSON.parse(jwt.tenants);

  return await api.get(ENDPOINTS.OBTER_ORGANIZACAO_PELO_ID(tenants[0]));
};

export const postCertificadoDigital = async certificado => {
  return await api.post(ENDPOINTS.CERTIFICADOS_DIGITAIS_NOVO(), certificado);
};

export const putCertificadoDigital = async (certificado, certificadoId) => {
  return await api.put(ENDPOINTS.CERTIFICADOS_DIGITAIS_ALTERAR(certificadoId), certificado);
};

export const getCertificadoDigital = async certificadoId => {
  return await api.get(ENDPOINTS.CERTIFICADOS_DIGITAIS_POR_ID(certificadoId));
};
