import { showToastErrors } from 'common/toast_config';
import { LoadingContent } from 'components/Common/LoadingContent';
import { LoteImportacaoDto } from 'model/types/importacaoDados.types';
import { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { obterLoteImportacaoPorId } from 'services/importacaoDados';

type VerDetalhesImportacaoModalProps = {
  isOpen: boolean;
  loteId: string;
  onToggle: (toggle: boolean) => void;
};

export function VerDetalhesImportacaoModal({ isOpen, onToggle, loteId }: VerDetalhesImportacaoModalProps) {
  const [loading, setLoading] = useState(false);
  const [lote, setLote] = useState<LoteImportacaoDto | null>(null);

  const handleToggle = () => {
    if (loading) return;
    onToggle(!isOpen);
  };

  const handleOnOpened = async () => {
    setLoading(true);

    try {
      const dadosLote = await obterLoteImportacaoPorId(loteId);
      setLote(dadosLote);
    } catch (error: any) {
      showToastErrors(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal size="lg" onOpened={handleOnOpened} isOpen={isOpen} toggle={handleToggle} scrollable={true}>
      <ModalHeader toggle={handleToggle}>Mais detalhes sobre a importação</ModalHeader>
      <ModalBody className="text-center">
        <LoadingContent isLoading={loading}>
          <div>
            Lote:
            <br /> <strong>{loteId}</strong>
          </div>

          <div className="mt-1">
            Detalhes do status:
            <br /> <strong>{lote?.statusDetalhes}</strong>
          </div>

          <div className="mt-3">
            {lote?.avisos?.length && (
              <>
                <div className="mb-2">Lote possui alguns avisos:</div>
                {lote.avisos?.map((aviso, index) => (
                  <div key={index}>{aviso}</div>
                ))}
              </>
            )}

            {lote && !lote.avisos?.length && <div>Lote não possui avisos!!</div>}
          </div>
        </LoadingContent>
      </ModalBody>
    </Modal>
  );
}
