import { api, ENDPOINTS } from 'api';
import {
  AddRegraFiscalCommand,
  AlterarProdutoCommand,
  NovoProdutoCommand,
  ProdutoDto,
  UnidadeMedidaDto,
} from 'model/types/produto.types';

export async function obterProduto(id: string): Promise<ProdutoDto | null> {
  const { data } = await api.get<ProdutoDto>(ENDPOINTS.PRODUTOS_POR_ID(id));
  return !!data ? data : null;
}

export async function obterUnidadesMedida(): Promise<UnidadeMedidaDto[]> {
  const response = await api.get<UnidadeMedidaDto[]>(ENDPOINTS.PRODUTOS_UNIDADES());
  return response.data ?? [];
}

export async function obterProdutoPeloNome(nome: string): Promise<ProdutoDto[]> {
  return new Promise(async resolve => {
    const urlProdutos = ENDPOINTS.ESTOQUE_PRODUTOS_LISTAR() + `?textoPesquisa=${nome}&tamanhoPagina=25`;
    const { data } = await api.get<any>(urlProdutos);

    if (data) {
      resolve(data.dados as ProdutoDto[]);
    }
  });
}

export async function cadastrarProduto(command: NovoProdutoCommand): Promise<ProdutoDto> {
  const response = await api.post<ProdutoDto>(ENDPOINTS.PRODUTOS_NOVO(), command);

  if (response.ok && response.data) {
    return response.data;
  }

  throw new Error('Erro ao cadastrar produto');
}

export async function alterarProduto(command: AlterarProdutoCommand): Promise<void> {
  const response = await api.put<ProdutoDto>(ENDPOINTS.PRODUTOS_ALTERAR(), command);
  if (response.ok) {
    return;
  }

  throw new Error('Erro ao cadastrar produto');
}

export async function adicionarRegraFiscal(produtoId: string, command: AddRegraFiscalCommand): Promise<void> {
  const response = await api.post<void>(ENDPOINTS.PRODUTOS_REGRA_FISCAL_ADD(produtoId), command);

  if (!response.ok) {
    throw new Error('Erro ao adicionar regra fiscal no produto');
  }
}

export async function deletarRegraFiscal(produtoId: string, regraId: string): Promise<void> {
  const response = await api.delete(ENDPOINTS.PRODUTOS_REGRA_FISCAL_DELETAR(produtoId, regraId));

  if (response.ok) {
    return;
  }

  throw new Error('Erro ao alterar regra fiscal do produto');
}
