import { IndicadorDestinoOperacao, IndicadorIe, IndicadorOperacaoConsumidor, IndicadorPresenca } from './common.types';

export interface NotaDto {
  id: string;
  numeroFiscal: number;
  serie: number;
  dataEmissao: string;
  chaveAcesso?: string;
  naturezaOperacao: string;
  saidaEntradaEm?: string;
  indicadorPrecensaComprador: IndicadorPresenca;
  indicadorPrecensaCompradorTexto: string;
  indicadorOperacaoConsumidor: IndicadorOperacaoConsumidor;
  indicadorOperacaoConsumidorTexto: string;
  destinoOperacao: IndicadorDestinoOperacao;
  destinoOperacaoTexto: string;
  observacao: string;
  total: number;
  totalProdutos: number;
  totalDesconto: number;
  totalFrete: number;
  totalBcIcms: number;
  totalIcms: number;
  totalBcSt: number;
  totalSt: number;
  totalBcFcpSt: number;
  totalFcpSt: number;
  totalFcp: number;
  totalCofins: number;
  totalPis: number;
  empresa: Empresa;
  cliente: NotaClienteDto | null;
  itens: NotaItemDto[];
  correcoes: CartaCorrecaoDto[] | null | undefined;
}

export interface Empresa {
  id: string;
  nome: string;
  cpfCnpj: string;
  notaFiscalId: string;
}

export interface NotaClienteDto {
  id: string;
  nome: string;
  cpfCnpj: string;
  inscricaoEstadual: string;
  indicadorIe: IndicadorIe;
  logradouro: string;
  numero: string;
  bairro: string;
  cep: string;
  cidadeUf: string;
  notaFiscalId: string;
}

export interface NotaItemDto {
  id: string;
  notaFiscalId: string;
  descricao: string;
  numero: number;
  quantidade: number;
  precoUnitario: number;
  totalUnitario: number;
  totalDesconto: number;
  totalFrete: number;
  totalItem: number;
  unidadeMedida: string;
  origemMercadoria: string;
  cfop: string;
  ncm: string;
  cest: string;
  csosn: string;
  pis: string;
  cofins: string;
  valorAproximadoTotalTributos: number;
  creditoAliquota: number;
  creditoBaseCalculo: number;
  creditoValor: number;
  icmsModalidadeBaseCalculo: number;
  icmsModalidadeBaseCalculoTexto: string;
  icmsAliquota: number;
  reducaoBaseCalculoPercentual: number;
  icmsBaseCalculo: number;
  icmsValor: number;
  fcpAliquota: number;
  fcpBaseCalculo: number;
  fcpValor: number;
  icmsStModalidadeBaseCalculo: number;
  icmsStModalidadeBaseCalculoTexto: string;
  mvaStPercentual: number;
  reducaoBaseCalculoStPercentual: number;
  icmsStBaseCalculo: number;
  icmsStValor: number;
  icmsStAliquota: number;
  fcpStAliquota: number;
  fcpStBaseCalculo: number;
  fcpStValor: number;
  pisAliquota: number;
  pisBaseCalculo: number;
  pisValor: number;
  cofinsAliquota: number;
  cofinsBaseCalculo: number;
  cofinsValor: number;
}

export interface NotaFiscalCriado {
  notaFiscalId: string;
}

export interface ResumoSituacaoNota {
  situacao: number;
  situacaoTexto: string;
  situacaoMensagem: string;
}

export enum SituacaoNota {
  Rascunho = 0,
  ProcessandoAutorizacao = 1,
  Rejeitada = 2,
  Autorizada = 3,
  Denegada = 4,
  Cancelada = 5,
  Inutilizada = 6,
  AutorizacaoPendente = 7,
  CancelamentoPendente = 8,
  ProcessandoCancelamento = 9,
  ProcessandoInutilizacao = 10,
}

export enum AmbienteEmissao {
  Producao = 1,
  Homologacao = 2,
}

export enum TipoEmissao {
  Normal = 1,
}

export enum TipoOperacao {
  Entrada = 0,
  Saida = 1,
}

export enum ModalidadeFrete {
  PorContaEmitente = 0,
  PorContaDestinatario = 1,
  PorContaTerceiros = 2,
  TransporteProprioPorContaEmitente = 3,
  TransporteProprioPorContaDestinatario = 4,
  SemFrete = 9,
}

export enum FinalidadeEmissao {
  Normal = 1,
  Complementar = 2,
  Ajuste = 3,
  Devolucao = 4,
}

export interface AlterarItemCommand {
  notaFiscalId: string;
  notaFiscalItemId: string;
  icmsCodigo: string;
  creditoAliquota: number;
  creditoBaseCalculo: number;
  creditoValor: number;
  icmsAliquota: number;
  reducaoBaseCalculoPercentual: number;
  icmsBaseCalculo: number;
  icmsValor: number;
  fcpAliquota: number;
  fcpBaseCalculo: number;
  fcpValor: number;
  mvaStPercentual: number;
  reducaoBaseCalculoStPercentual: number;
  icmsStBaseCalculo: number;
  icmsStValor: number;
  icmsStAliquota: number;
  fcpStAliquota: number;
  fcpStBaseCalculo: number;
  fcpStValor: number;
  pisCodigo: string;
  pisAliquota: number;
  pisBaseCalculo: number;
  pisValor: number;
  cofinsCodigo: string;
  cofinsAliquota: number;
  cofinsBaseCalculo: number;
  cofinsValor: number;
  cest: string;
  ncm: string;
  codigoCfop: string;
  origemMercadoria: string;
}

export interface AlterarCabecalhoNotaCommand {
  notaId: string;
  naturezaOperacao: string;
  emissaoEm: Date;
  saidaEntradaEm?: Date | null;
  indicadorPrecensaComprador: number;
  indicadorOperacaoConsumidor: number;
  destinoOperacao: number;
}

export interface AlterarObservacaoNotaCommand {
  notaId: string;
  observacao: string | null;
}

export interface EmitirCartaCorrecaoNotaCommand {
  notaId: string;
  correcao: string;
}

export interface CartaCorrecaoDto {
  sequencia: number;
  feitoEm: Date;
  correcao: string;
}
