import { forwardRef, ForwardRefRenderFunction, useEffect, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import CreatableSelect, { Creatable } from 'react-select/creatable';
import { obterUnidadesMedida } from 'services/produtoService';

export interface CreatableUnidadeProps {
  name: string;
  value: string;
  onBlur: () => void;
  onChange: (event: any) => void;
  menuPlacement?: 'top' | 'auto' | 'bottom';
}

interface Option {
  label: string;
  value: string;
}

const unidadeIniciais: Option[] = [
  { label: 'UN', value: 'UN' },
  { label: 'KG', value: 'KG' },
  { label: 'CX', value: 'CX' },
];

const CreatableUnidadeComponent: ForwardRefRenderFunction<Creatable<OptionTypeBase>, CreatableUnidadeProps> = (
  { name, value, onChange, onBlur, menuPlacement = 'bottom' },
  ref,
) => {
  const [selectedValue, setSelectedValue] = useState<Option>();
  const [options, setOptions] = useState<Option[]>(unidadeIniciais);

  useEffect(() => {
    let isCanceled = false;

    async function fetchValue() {
      try {
        const data = await obterUnidadesMedida();
        if (isCanceled) return;
        if (data.length === 0) return;

        const novasOpcoes = data.map(u => ({ value: u.sigla, label: u.sigla }));

        const opcoesUnicas = unidadeIniciais
          .concat(novasOpcoes)
          .filter((v, i, a) => a.findIndex(ib => ib.value === v.value) === i);

        setOptions(opcoesUnicas);
      } finally {
      }
    }

    fetchValue();

    return () => {
      isCanceled = true;
    };
  }, []);

  useEffect(() => {
    setSelectedValue({ label: value, value: value });
  }, [value]);

  return (
    <CreatableSelect
      isClearable
      ref={ref}
      name={name}
      onBlur={onBlur}
      onChange={(event: any, action: any) => {
        if (action.action === 'clear') {
          setSelectedValue(undefined);
          onChange('');
          return;
        }

        setSelectedValue(event);
        onChange(event.value);
      }}
      value={selectedValue}
      options={options}
      formatCreateLabel={value => `Criar => ${value}`}
      menuPlacement={menuPlacement}
    />
  );
};

export const CreatableUnidade = forwardRef(CreatableUnidadeComponent);
