import React, { useState } from 'react';
import { Button, ButtonProps } from 'reactstrap';

interface PrimaryButtonProps extends ButtonProps {
  children?: React.ReactNode;
}

export function ConfirmButton({ children, onClick, onBlur, ...rest }: PrimaryButtonProps) {
  const [confirm, setConfirm] = useState<boolean>(false);

  const handleClick = (e: any) => {
    if (confirm) {
      onClick && onClick(e);
    } else {
      e.preventDefault();
      setConfirm(true);
    }
  };

  const handleBlur = (e: any) => {
    setConfirm(false);
    onBlur && onBlur(e);
  };

  return (
    <Button onClick={handleClick} onBlur={handleBlur} {...rest}>
      {confirm ? <i className="mdi mdi-check"></i> : !!children && children}
    </Button>
  );
}
