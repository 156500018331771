import { forwardRef, ForwardRefRenderFunction, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import Async from 'react-select/async';
import { PisCofinsDto } from 'model/types/fiscal.types';
import { FieldError } from 'react-hook-form';
import { OptionTypeBase } from 'react-select';
import { obterPisCofinsPorId, obterTodosPisCofins } from 'services/tributacaoService';
import { filter, sortBy } from 'lodash';

type Props = {
  name?: string;
  value?: string;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onSelected?: (value: PisCofinsDto) => void;
  onKeyDown?: KeyboardEventHandler;
  menuPlacement?: 'top' | 'auto' | 'bottom';
  error?: FieldError;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string | undefined;
};

interface PisCofinsOption extends OptionTypeBase {
  value: string;
  label: string;
  object: PisCofinsDto;
}

const Component: ForwardRefRenderFunction<Async<PisCofinsOption>, Props> = (
  {
    name,
    value,
    onBlur,
    onChange,
    onSelected,
    onKeyDown,
    error,
    autoFocus,
    disabled,
    menuPlacement = 'auto',
    className,
  },
  ref,
) => {
  const loadOptionsRef = useRef<NodeJS.Timeout>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<PisCofinsOption | null>(null);

  useEffect(() => {
    let isCanceled = false;

    if (!value) {
      setSelectedItem(null);
      return;
    }

    async function fetchValue() {
      setIsLoading(true);
      try {
        const data = await obterPisCofinsPorId(value ?? '');
        if (isCanceled) return;
        setSelectedItem(!!data ? mapToOption(data) : null);
      } finally {
        setIsLoading(false);
      }
    }

    fetchValue();

    return () => {
      isCanceled = true;
    };
  }, [value]);

  const mapToOption = (dto: PisCofinsDto): PisCofinsOption => {
    return {
      label: `${dto.codigo} - ${dto.descricao}`,
      value: dto.codigo,
      object: dto,
    };
  };

  const handleChange = (value: PisCofinsOption | null) => {
    if (!value) {
      onChange && onChange('');
      return;
    }

    setSelectedItem(value);
    onChange && onChange(value.value);
    onSelected && onSelected(value.data);
  };

  const loadOptionsHandler = (textSearch: string): Promise<any> => {
    loadOptionsRef.current && clearTimeout(loadOptionsRef.current);

    return new Promise((resolve, reject) => {
      loadOptionsRef.current = setTimeout(async () => {
        try {
          const fetchedData = await obterTodosPisCofins();
          const filteredData =
            textSearch?.length === 0
              ? fetchedData
              : filter(
                  fetchedData,
                  x => x.codigo.startsWith(textSearch) || x.descricao.toLowerCase().includes(textSearch.toLowerCase()),
                );
          const sortedData = sortBy(filteredData, 'id');
          const options = sortedData.map(p => mapToOption(p));
          resolve(options);
        } catch (error: any) {
          console.warn('SelectPisCofins::loadOptionsHandler', error);
          reject(error);
        }
      }, 100);
    });
  };

  return (
    <Async
      ref={ref}
      name={name}
      isDisabled={disabled}
      value={selectedItem}
      onChange={handleChange}
      onBlur={onBlur}
      isLoading={isLoading}
      menuPlacement={menuPlacement}
      error={error}
      defaultOptions={true}
      loadOptions={loadOptionsHandler}
      classNamePrefix="select2-selection"
      className={className}
      noOptionsMessage={() => 'Nenhum registro encontrado para o termo digitado'}
      autoFocus={autoFocus}
      onKeyDown={onKeyDown}
      placeholder="Selecione ou digite para buscar por um PIS/COFINS"
    />
  );
};

export const SelectPisCofins = forwardRef(Component);
