import { toDateFormat, toDateTimeFormat } from 'common/formatters';
import _uniqueId from 'lodash/uniqueId';
import { ColumnDescription, ColumnFormatter } from 'react-bootstrap-table-next';

interface ColumnOptions<T extends object = any, E = any> {
  formatter?: ColumnFormatter<T, E>;
  decimals?: number | undefined;
  headerStyle?: React.CSSProperties | (() => React.CSSProperties) | undefined;
}

export function textColumn<T extends object = any>(
  title: string,
  dataField: string,
  options?: ColumnOptions<T, string>,
): ColumnDescription<T, any> {
  return { text: title, dataField, ...options };
}

export function decimalColumn<T extends object = any>(
  title: string,
  dataField: string,
  options?: ColumnOptions<T, number>,
): ColumnDescription<T, any> {
  return {
    text: title,
    dataField,
    align: 'end',
    headerAlign: 'end',
    classes: 'text-nowrap',
    formatter: (cell: number) => {
      const value = Math.round((cell + Number.EPSILON) * 100) / 100;

      return value.toLocaleString('pt-br', {
        minimumFractionDigits: options?.decimals ?? 2,
      });
    },

    ...options,
  };
}

export function dateTimeColumn<T extends object = any>(
  title: string,
  dataField: string,
  options?: ColumnOptions<T, number>,
): ColumnDescription<T, any> {
  return {
    text: title,
    dataField,
    align: 'start',
    headerAlign: 'start',
    classes: 'text-nowrap',
    formatter: (cell: string) => toDateTimeFormat(cell),
    ...options,
  };
}

export function dateColumn<T extends object = any>(
  title: string,
  dataField: string,
  options?: ColumnOptions<T, number>,
): ColumnDescription<T, any> {
  return {
    text: title,
    dataField,
    align: 'start',
    headerAlign: 'start',
    classes: 'text-nowrap',
    formatter: (cell: string) => toDateFormat(cell),
    ...options,
  };
}

export function cpfCnpjColumn<T extends object = any>(title: string, dataField: string): ColumnDescription<T, any> {
  const formatter = (cell: string) => {
    if (cell?.length === 11) {
      return cell.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }

    if (cell?.length === 14) {
      return cell.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }

    return cell;
  };

  return textColumn(title, dataField, { formatter });
}

export function telefoneColumn<T extends object = any>(title: string, dataField: string): ColumnDescription<T, any> {
  const formatter = (cell: string) => {
    if (cell?.length === 11) {
      return cell.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }

    if (cell?.length === 10) {
      return cell.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }

    return cell;
  };

  return textColumn(title, dataField, { formatter });
}

export function dummyColumn<T extends object = any>(
  title: string,
  formatter: ColumnFormatter<T, any>,
): ColumnDescription<T, any> {
  const dummy = {
    dataField: _uniqueId('dummy'),
    text: title,
    isDummyField: true,
    formatter: formatter,
  };

  return dummy;
}
