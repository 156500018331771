import { ENDPOINTS } from 'api';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import { PageContent } from 'components/Common/PageContent';
import { dateTimeColumn, dummyColumn, textColumn } from 'components/Table/columnFactory';
import RemotePageTable from 'components/Table/RemotePageTable';
import { ROUTES } from 'config/routes';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';

const columnActions = dummyColumn('', (cell, row) => {
  return (
    <div className="text-start">
      <Link to={ROUTES.CERTIFICADO_ALTERAR(row.id)} className="btn btn-sm btn-info">
        Editar
      </Link>
    </div>
  );
});

const colunas = [
  columnActions,
  textColumn('Empresa Nome', 'empresaNome'),
  dateTimeColumn('Emissão em', 'emitidoEm'),
  dateTimeColumn('Vencimento em', 'vencimentoEm'),
  textColumn('Dias Restantes', 'diasRestantesParaVencer'),
];

const RightBar = () => {
  return (
    <>
      <Link to={ROUTES.CERTIFICADO_NOVO}>
        <PrimaryButton icon="plus">Novo Certificado</PrimaryButton>
      </Link>
    </>
  );
};

export function CertificadoListagem() {
  return (
    <>
      <PageContent title="Certificado" subTitle="Certificados Digitais">
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <RemotePageTable
                  url={ENDPOINTS.CERTIFICADOS_LISTAR_TODOS()}
                  columns={colunas}
                  rightBar={RightBar()}
                ></RemotePageTable>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </PageContent>
    </>
  );
}
