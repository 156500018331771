import { useState } from 'react';
import { Collapse } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import classname from 'classnames';

interface NavbarProps {
  menuOpen: boolean;
}

export function Navbar({ menuOpen }: NavbarProps) {
  const location = useLocation<Location>();
  const [menuItemOpen, setMenuItemOpen] = useState('');

  function toogleMenuItem(name: string) {
    if (menuIsOpen(name)) {
      setMenuItemOpen('');
      return;
    }

    setMenuItemOpen(name);
  }

  function menuIsOpen(name: string) {
    return menuItemOpen.slice(0, name.length) === name;
  }

  function menuIsActive(path: string, exact?: boolean) {
    try {
      if (exact) {
        return location.pathname === path;
      }

      return location.pathname.slice(0, path.length) === path;
    } catch (e) {
      return false;
    }
  }

  return (
    <>
      <div className="topnav shadow-sm">
        <div className="container-fluid">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
            <Collapse isOpen={menuOpen} className="navbar-collapse" id="topnav-menu-content">
              <ul className="navbar-nav">
                <li className={classname('nav-item dropdown', { active: menuIsActive('/', true) })}>
                  <Link to="/" className="nav-link dropdown-toggle arrow-none">
                    <i className="bx bxs-home me-2"></i>
                    Home
                  </Link>
                </li>

                <li className={classname('nav-item dropdown', { active: menuIsActive('/clientes') })}>
                  <Link
                    to="/#"
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault();
                      toogleMenuItem('clientes');
                    }}
                  >
                    <i className="bx bxs-user-account me-2"></i>
                    Clientes
                    <div className="arrow-down"></div>
                  </Link>
                  <div className={classname('dropdown-menu', { show: menuIsOpen('clientes') })}>
                    <div className="dropdown">
                      <Link to={ROUTES.CLIENTE_LISTAGEM} className="dropdown-item dropdown-toggle arrow-none">
                        Listar Clientes
                      </Link>

                      <Link to={ROUTES.CLIENTE_NOVO} className="dropdown-item dropdown-toggle arrow-none">
                        Novo Cliente
                      </Link>
                    </div>
                  </div>
                </li>

                <li className={classname('nav-item dropdown', { active: menuIsActive('/estoque') })}>
                  <Link
                    to="/#"
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault();
                      toogleMenuItem('estoque');
                    }}
                  >
                    <i className="bx bx-customize me-2"></i>
                    Estoque
                    <div className="arrow-down"></div>
                  </Link>
                  <div className={classname('dropdown-menu', { show: menuIsOpen('estoque') })}>
                    <div className="dropdown">
                      <Link className="dropdown-item dropdown-toggle arrow-none" to={ROUTES.PRODUTO_LISTAGEM}>
                        Listar Produtos
                      </Link>
                      <Link className="dropdown-item dropdown-toggle arrow-none" to={ROUTES.PRODUTO_NOVO}>
                        Novo Produto
                      </Link>
                      <Link className="dropdown-item dropdown-toggle arrow-none" to={ROUTES.AJUSTAR_ESTOQUE}>
                        Ajustar Estoque
                      </Link>
                    </div>
                  </div>
                </li>

                {/* Vendas */}
                <li className={classname('nav-item dropdown', { active: menuIsActive('/vendas') })}>
                  <Link
                    to="/#"
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault();
                      toogleMenuItem('vendas');
                    }}
                  >
                    <i className="bx bx-shopping-bag me-2"></i>
                    Vendas
                    <div className="arrow-down"></div>
                  </Link>
                  <div className={classname('dropdown-menu', { show: menuIsOpen('vendas') })}>
                    <div className="dropdown">
                      <Link className="dropdown-item dropdown-toggle arrow-none" to={ROUTES.VENDAS_LISTAGEM}>
                        Listar Vendas
                      </Link>

                      <Link className="dropdown-item dropdown-toggle arrow-none" to={ROUTES.VENDAS_RELATORIO}>
                        Relatório de Vendas
                      </Link>
                    </div>
                  </div>
                </li>

                <li className={classname('nav-item dropdown', { active: menuIsActive('/fiscal') })}>
                  <Link
                    to="/#"
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault();
                      toogleMenuItem('fiscal');
                    }}
                  >
                    <i className="bx bx-archive me-2"></i>
                    Fiscal
                    <div className="arrow-down"></div>
                  </Link>
                  <div className={classname('dropdown-menu', { show: menuIsOpen('fiscal') })}>
                    <div className="dropdown">
                      <Link className="dropdown-item dropdown-toggle arrow-none" to={ROUTES.CUPOM_LISTAGEM}>
                        Listar Cupons Fiscais
                      </Link>
                      <Link className="dropdown-item dropdown-toggle arrow-none" to={ROUTES.NOTA_LISTAGEM}>
                        Listar Notas Fiscais
                      </Link>
                      <Link
                        to={ROUTES.CUPOM_ENVIAR_PACOTE_CONTADOR}
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        Enviar Documentos Fiscais
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </>
  );
}
