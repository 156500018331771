import { useState } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import { PageContent } from 'components/Common/PageContent';
import { textColumn, dummyColumn } from 'components/Table/columnFactory';
import { ENDPOINTS } from 'api/endpoints';
import RemoteDataTable from 'components/Table/RemoteDataTable';
import { VincularUsuarioModal } from './VincularUsuarioModal';
import { NovoUsuarioModal } from './NovoUsuarioModal';

const columns = [
  dummyColumn('#', (cell, row) => {
    return (
      <>
        <div className="avatar-xs">
          <span className="avatar-title rounded-circle">{row.nome.charAt(0).toUpperCase()}</span>
        </div>
      </>
    );
  }),

  textColumn('Usuário', 'nome', {
    formatter: (cell, row) => {
      return (
        <>
          <h5>
            {row.email
              .split('@')[0]
              .split(/[\W_]/gm)
              .map((v: string) => v.charAt(0).toUpperCase() + v.slice(1))
              .join(' ')}
          </h5>
          <span className="text-muted">{row.email}</span>
        </>
      );
    },
  }),
];

export function ListagemUsuarios() {
  const [vincularUsuarioModalIsOpen, setVincularUsuarioModalIsOpen] = useState(false);
  const [novoUsuarioModalIsOpen, setNovoUsuarioModalIsOpen] = useState(false);
  const [usuarioDataVersion, setUsuarioDataVersion] = useState(1);

  function changeUserDataVersion() {
    setUsuarioDataVersion(usuarioDataVersion + 1);
  }

  return (
    <>
      <VincularUsuarioModal
        isOpen={vincularUsuarioModalIsOpen}
        onToggle={setVincularUsuarioModalIsOpen}
        onSuccess={changeUserDataVersion}
      />

      <NovoUsuarioModal
        isOpen={novoUsuarioModalIsOpen}
        onToggle={setNovoUsuarioModalIsOpen}
        onSuccess={changeUserDataVersion}
      />

      <PageContent title="Segurança" subTitle="Usuários da Organização">
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <RemoteDataTable
                  dataVersion={usuarioDataVersion}
                  columns={columns}
                  url={ENDPOINTS.ORGANIZACAO_USUARIOS()}
                  rightBar={
                    <Row>
                      <Col>
                        <PrimaryButton icon="link" className="me-3" onClick={() => setVincularUsuarioModalIsOpen(true)}>
                          Vincular Usuário
                        </PrimaryButton>

                        <PrimaryButton icon="link" onClick={() => setNovoUsuarioModalIsOpen(true)}>
                          Novo Usuário
                        </PrimaryButton>
                      </Col>
                    </Row>
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </PageContent>
    </>
  );
}
