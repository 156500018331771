import { Route, Redirect } from 'react-router-dom';
import { ROUTES } from '../../config/routes';
import { autorizacaoValida } from '../../services/autorizacao';

export function Authmiddleware({ component: Component, layout: Layout, isAuthProtected, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !autorizacaoValida()) {
          return <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: props.location } }} />;
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
}
