import styled from 'styled-components';
import { MetaTags } from 'react-meta-tags';
import { Container, Spinner } from 'reactstrap';
import { Breadcrumb } from './Breadcrumb';
import { PageFooter } from './PageFooter';

interface PageContentProps {
  title: string;
  subTitle: string;
  children?: React.ReactNode;
  isLoading?: boolean;
  bottomBar?: any;
  showBack?: boolean;
  showBreadcrumb?: boolean;
}

export function PageContent({
  title,
  subTitle,
  children,
  isLoading = false,
  bottomBar,
  showBack = true,
  showBreadcrumb = true,
}: PageContentProps) {
  const pageTitle = `${subTitle} | Sistema Fusion`;

  return (
    <>
      {isLoading && (
        <Backdrop>
          <BackdropContent>
            <Spinner color="primary" />
          </BackdropContent>
        </Backdrop>
      )}

      <div className="page-content">
        <MetaTags>
          <title>{pageTitle}</title>
        </MetaTags>

        <Container className="container-xxl" fluid={true}>
          {showBreadcrumb && <Breadcrumb showBack={showBack} title={title} subTitle={subTitle} />}
          {!!children && children}
        </Container>
      </div>

      {bottomBar && <PageFooter>{bottomBar()}</PageFooter>}
    </>
  );
}

const Backdrop = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  opacity: 0.7;
  background-color: #fff;
  z-index: 99998;
`;

const BackdropContent = styled.div`
  position: relative;
  margin: 0 auto;
  top: 50%;
`;
