import { forwardRef, ForwardRefRenderFunction, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { OptionTypeBase } from 'react-select';
import AsyncSelect, { Async } from 'react-select/async';
import { obterPaginaCfop, obterCfopPeloId } from 'services/cfopService';
import { CfopDto } from 'model/types/fiscal.types';

export interface SelectCfopProps {
  name?: string;
  value?: string;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onSelected?: (value: SelectedCfop) => void;
  onKeyDown?: KeyboardEventHandler;
  menuPlacement?: 'top' | 'auto' | 'bottom';
  error?: FieldError;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string | undefined;
}

type SelectedCfop = CfopDto;

interface CfopOption extends OptionTypeBase {
  value: string;
  label: string;
  object: SelectedCfop;
}

const Component: ForwardRefRenderFunction<Async<CfopOption>, SelectCfopProps> = (
  {
    name,
    value,
    menuPlacement = 'auto',
    error,
    autoFocus,
    onChange,
    onBlur,
    onSelected,
    onKeyDown,
    disabled,
    className,
  },
  ref,
) => {
  const loadOptionsRef = useRef<NodeJS.Timeout>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<CfopOption | null>(null);

  function mapOption(cfop: CfopDto): CfopOption {
    return {
      value: cfop.codigo,
      label: `${cfop.codigo} - ${cfop.descricao}`,
      object: cfop,
    };
  }

  useEffect(() => {
    let isCanceled = false;

    if (!value) {
      setSelectedItem(null);
      return;
    }

    async function fetchValue() {
      setIsLoading(true);
      try {
        const data = await obterCfopPeloId(value as string);
        if (isCanceled) return;
        setSelectedItem(!!data ? mapOption(data) : null);
      } finally {
        setIsLoading(false);
      }
    }

    fetchValue();

    return () => {
      isCanceled = true;
    };
  }, [value]);

  function promiseOptions(inputValue: string): Promise<any> {
    loadOptionsRef.current && clearTimeout(loadOptionsRef.current);

    return new Promise((resolve, reject) => {
      loadOptionsRef.current = setTimeout(async () => {
        try {
          const data = await obterPaginaCfop({ textoPesquisa: inputValue });
          const options = data.dados.map(mapOption);

          resolve(options);
        } catch (error: any) {
          console.error('SelectCfop::promiseOptions', error);
          reject(error);
        }
      }, 1100);
    });
  }

  function handleOnChange(value: CfopOption | null) {
    if (!!!value) {
      onChange && onChange('');
      return;
    }

    setSelectedItem(value);
    onChange && onChange(value.value);
    onSelected && onSelected(value.object);
  }

  return (
    <>
      <AsyncSelect
        ref={ref}
        name={name}
        isDisabled={disabled}
        value={selectedItem}
        onChange={handleOnChange}
        onBlur={onBlur}
        menuPlacement={menuPlacement}
        isLoading={isLoading}
        cacheOptions
        defaultOptions={false}
        loadOptions={promiseOptions}
        onKeyDown={onKeyDown}
        classNamePrefix="select2-selection"
        className={className}
        placeholder="digite para buscar o cfop..."
        noOptionsMessage={() => 'nenhum cfop encontrado para o termo pesquiasado'}
        autoFocus={autoFocus}
      />
      {!!error?.message && <span className="text-danger small">{error?.message}</span>}
    </>
  );
};

export const SelectCfop = forwardRef(Component);
