import { api, ENDPOINTS } from 'api';
import { AlterarRegraGlobalCfopCommand, RegraGlobalCfopDto } from 'model/types/fiscal.types';

export async function iniciarRegrasGlobaisCfop(): Promise<void> {
  const response = await api.post<void>(ENDPOINTS.REGRAS_GLOBAIS_CFOP_INICIAR());
  if (response.ok) {
    return;
  }

  throw new Error('Não foi possível iniciar as regras globais de CFOP');
}

export async function obterRegrasGlobaisCfop(): Promise<RegraGlobalCfopDto[]> {
  const response = await api.get<RegraGlobalCfopDto[]>(ENDPOINTS.REGRAS_GLOBAIS_CFOP_LISTAR());

  if (response.ok && response.data) {
    return response.data;
  }

  throw new Error('Erro ao obter regras fiscais globais');
}

export async function alterarRegraGlobalCfop(command: AlterarRegraGlobalCfopCommand): Promise<RegraGlobalCfopDto> {
  const response = await api.put<RegraGlobalCfopDto>(ENDPOINTS.REGRAS_GLOBAIS_CFOP_ALTERAR(command.id), command);

  if (response.ok && response.data) {
    return response.data;
  }

  throw new Error('Erro ao alterar regras fiscais globais');
}
