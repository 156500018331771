import { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale';
import { uniqueId } from 'lodash';
import { Pagamento } from './Pagamento';
import { PagamentoLinha } from './Pagamentos.types';
import { showToastErrors } from 'common/toast_config';

// css
import 'react-datepicker/dist/react-datepicker.css';

// models
import { adicionarPagamento, deletarPagamento } from 'services/vendaService';
import { NovoPagamentoCommand, PagamentoVenda } from 'model/types/venda.types';

registerLocale('pt-BR', ptBR);

interface PagamentosProps {
  disabled: boolean;
  vendaId: string;
  valorRestante: number;
  pagamentos: PagamentoVenda[];
  onLoading: (loading: boolean) => void;
  onChanged: () => void;
}

export const Pagamentos = ({ vendaId, valorRestante, pagamentos, disabled, onLoading, onChanged }: PagamentosProps) => {
  const [linhas, setLinhas] = useState<PagamentoLinha[]>([]);

  useEffect(() => {
    const mapLines = pagamentos?.map(i => ({
      id: uniqueId(),
      pagamento: i,
    }));

    setLinhas(mapLines ?? []);
  }, [pagamentos]);

  function handleNovaLinha() {
    const linhaVazia = { id: uniqueId() };
    setLinhas(p => [...p, linhaVazia]);
  }

  function handleLineCancel(linhaId: string) {
    setLinhas(p => p.filter(l => l.id !== linhaId));
  }

  async function handleSave(value: NovoPagamentoCommand) {
    onLoading(true);

    try {
      await adicionarPagamento(vendaId, value);
      onChanged();
    } catch (err: any) {
      showToastErrors(err?.messages);
    } finally {
      onLoading(false);
    }
  }

  async function handleDelete(pagamento: PagamentoVenda) {
    onLoading(true);

    try {
      await deletarPagamento(vendaId, pagamento.id);
      onChanged();
    } catch (err: any) {
      showToastErrors(err?.messages);
    } finally {
      onLoading(false);
    }
  }

  return (
    <>
      {!disabled && linhas.length === 0 && (
        <Card>
          <CardBody>
            <Row>
              <Col className="text-center font-size-16">
                <span>Ainda não foi adicionado nenhum pagamento</span>
                <button className="btn btn-link p-0 font-size-16" onClick={() => handleNovaLinha()}>
                  &nbsp;clique aqui para adicionar.
                </button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}

      <Card hidden={linhas.length === 0}>
        <CardBody>
          {linhas.map((linha, i) => (
            <div key={linha.id} className="mb-3">
              <Pagamento
                disabled={disabled}
                showLabel={i === 0}
                pagamento={linha.pagamento}
                valorPagamentoInicial={valorRestante}
                parcelas={linha.pagamento?.parcelas}
                onCancel={() => handleLineCancel(linha.id)}
                onSave={handleSave}
                onDelete={handleDelete}
              />
            </div>
          ))}

          {!disabled && valorRestante > 0 && linhas.filter(l => !l.pagamento?.id).length === 0 && (
            <div className="mt-4 d-flex justify-content-end">
              <button className="btn btn-link font-size-14" onClick={() => handleNovaLinha()}>
                Adicionar novo pagamento
              </button>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
};
