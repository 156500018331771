import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PageContent } from 'components/Common/PageContent';
import { dateTimeColumn, decimalColumn, dummyColumn, textColumn } from 'components/Table/columnFactory';
import { ROUTES } from 'config/routes';
import { ENDPOINTS } from 'api';
import { Card, CardBody, Badge, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown, Button } from 'reactstrap';
import { EmitirCupomVendaModal } from 'components/Vendas/EmitirCupomVendaModal';
import { EmitirNotaVendaModal } from 'components/Vendas/EmitirNotaVendaModal';
import { NovaVendaModal } from 'components/Vendas/NovaVendaModal';
import { VendaDto, VendaSituacao } from 'model/types/venda.types';
import classnames from 'classnames';
import RemotePageTable from 'components/Table/RemotePageTable';

const getBgColorSituacaoVenda = (situacao: number): string => {
  switch (situacao) {
    case 1:
      return 'bg-info';
    case 7:
      return 'bg-success';
    case 10:
      return 'bg-danger';
    default:
      return '';
  }
};

export function VendaListagem() {
  const [showEmitirCupom, setShowEmitirCupom] = useState<boolean>(false);
  const [showEmitirNota, setShowEmitirNota] = useState<boolean>(false);
  const [showNovaVenda, setShowNovaVenda] = useState<boolean>(false);
  const [vendaId, setVendaId] = useState<string>('');

  const rowOptions = (_cell: string, row: VendaDto): JSX.Element => {
    const ColumnOptionDropDown = () => {
      const [open, setOpen] = useState(false);

      return (
        <ButtonDropdown isOpen={open} toggle={() => setOpen(!open)}>
          <Link to={ROUTES.VENDAS_EDITAR(row.id)} className="btn btn-info btn-sm">
            Editar
          </Link>
          <DropdownToggle caret color="info" className="btn btn-sm">
            <i className="mdi mdi-chevron-down" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <Link to={ROUTES.VENDAS_VISUALIZAR(row.id)} className="btn btn-link p-0 link-primary">
                <i className="bx bx-show me-1" /> Visualizar Venda
              </Link>
            </DropdownItem>

            <DropdownItem hidden={row.situacao !== VendaSituacao.Finalizada}>
              <ButtonDropdown
                className="btn btn-link p-0 link-primary text-decoration-none"
                onClick={() => {
                  setVendaId(row.id);
                  setShowEmitirCupom(true);
                }}
              >
                <i className="bx bx-world me-1" /> Emitir Cupom Fiscal (NFC-e)
              </ButtonDropdown>
            </DropdownItem>

            <DropdownItem hidden={row.situacao !== VendaSituacao.Finalizada}>
              <ButtonDropdown
                className="btn btn-link p-0 link-primary text-decoration-none"
                onClick={() => {
                  setVendaId(row.id);
                  setShowEmitirNota(true);
                }}
              >
                <i className="bx bx-world me-1" /> Criar Nota Fiscal (NF-e)
              </ButtonDropdown>
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      );
    };

    return <ColumnOptionDropDown />;
  };

  const columns = [
    dummyColumn('', rowOptions),
    dummyColumn('Situação', (_cell, row) => (
      <Badge pill className={classnames([getBgColorSituacaoVenda(row.situacao)])}>
        {row.situacaoTexto}
      </Badge>
    )),
    textColumn('Número', 'numero', {
      formatter: (cell: number, row: any) => cell.toString().padStart(6, '0'),
    }),
    dateTimeColumn('Data Venda', 'dataOperacao'),
    textColumn('Cliente', 'cliente.nome', {
      formatter: (cell, _row) => (!!cell ? cell : <span className="small text-muted">não definido</span>),
    }),
    decimalColumn('Total Itens', 'totalItens'),
    decimalColumn('Desconto', 'descontoNaVenda'),
    decimalColumn('Frete', 'totalFrete'),
    decimalColumn('Total', 'totalDaVenda'),
  ];

  function RightBar() {
    return (
      <Button color="primary" onClick={() => setShowNovaVenda(true)}>
        Nova Venda
      </Button>
    );
  }

  return (
    <>
      <EmitirCupomVendaModal vendaId={vendaId} isOpen={showEmitirCupom} onToggle={setShowEmitirCupom} />
      <EmitirNotaVendaModal vendaId={vendaId} isOpen={showEmitirNota} onToggle={setShowEmitirNota} />

      <NovaVendaModal isOpen={showNovaVenda} onToggle={setShowNovaVenda} />

      <PageContent title="Vendas" subTitle="Listar Vendas">
        <Card>
          <CardBody>
            <RemotePageTable rightBar={<RightBar />} url={ENDPOINTS.VENDAS_LISTAR()} columns={columns} />
          </CardBody>
        </Card>
      </PageContent>
    </>
  );
}
