import { toMoneyFormat } from 'common/formatters';
import { showToastErrors, showToastSuccess } from 'common/toast_config';
import { SpinnerButton } from 'components/Button/SpinnerButton';
import { InputControl } from 'components/Form/InputControl';
import { InputPreco } from 'components/Form/InputPreco';
import { SelectCfop } from 'components/Form/SelectCfop';
import { SelectCsosn } from 'components/Form/SelectCsosn';
import { SelectNcm } from 'components/Form/SelectNcm';
import { SelectOrigemMercadoria } from 'components/Form/SelectOrigemMercadoria';
import { useFormTabOnEnter } from 'hooks/useFormTabOnEnter';
import { CupomItemDto, SituacaoCupom } from 'model/types/cupomfiscal.types';
import { useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { alterarItemCupomFiscal } from 'services/nfceService';

interface CupomItemProps {
  idCupomFiscal: string;
  item: CupomItemDto;
  isOpen: boolean;
  podeEditar: boolean;
  onToggle: (toggle: boolean) => void;
  onChanged: () => void;
}

interface FormItem {
  id: string;
  descricao: string;
  numero: number;
  codigoBarra: string;
  origemMercadoria: string;
  cfop: string;
  csosn: string;
  ncm: string;
  cest: string;
}

export const ModalEditarItemCupom = ({
  item,
  idCupomFiscal,
  podeEditar,
  isOpen,
  onToggle,
  onChanged,
}: CupomItemProps) => {
  const inputFocus = useRef<any>();
  const [loading, setLoading] = useState<boolean>();

  const { handleOnKeyDown } = useFormTabOnEnter();
  const { register, control, handleSubmit, setValue } = useForm<FormItem>();

  const handleOnSubmit: SubmitHandler<FormItem> = async values => {
    setLoading(true);

    try {
      const cupomId = idCupomFiscal;
      const itemId = values.id;

      await alterarItemCupomFiscal(cupomId, itemId, {
        cfop: values.cfop,
        csosn: values.csosn,
        cest: values.cest,
        ncm: values.ncm,
        origemMercadoria: values.origemMercadoria,
      });

      showToastSuccess('Item alterado com sucesso!');
      onChanged();
    } catch (error: any) {
      showToastErrors(error.messages);
    } finally {
      setLoading(false);
    }
  };

  const handleToggle = () => {
    onToggle(!isOpen);
  };

  const handleOpened = () => {
    setValue('id', item.id);
    setValue('descricao', item.descricao);
    setValue('codigoBarra', item.codigoBarra);
    setValue('origemMercadoria', item.origemMercadoria);
    setValue('cfop', item.cfop);
    setValue('csosn', item.csosn);
    setValue('ncm', item.ncm);
    setValue('cest', item.cest);

    inputFocus.current?.focus();
  };

  return (
    <Modal fullscreen="md" size="lg" isOpen={isOpen} toggle={handleToggle} onOpened={handleOpened}>
      <ModalHeader toggle={handleToggle} />
      <ModalBody>
        <Row className="mb-4">
          <Col xs={10}>
            <Label>Descrição item</Label>
            <div className="font-size-16">{item.descricao}</div>
          </Col>

          <Col xs={2} className="text-end">
            <Label>Total</Label>
            <div className="font-size-16">{toMoneyFormat(item.totalItem, true)}</div>
          </Col>
        </Row>

        <Form onSubmit={handleSubmit(handleOnSubmit)} onKeyDown={handleOnKeyDown}>
          <Row>
            <Col>
              <FormGroup>
                <Label>Origem Mercadoria</Label>
                <Controller
                  control={control}
                  name="origemMercadoria"
                  render={({ field }) => <SelectOrigemMercadoria disabled={!podeEditar} {...field} />}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={8}>
              <FormGroup>
                <Label>Código CFOP</Label>
                <Controller
                  control={control}
                  name="cfop"
                  render={({ field }) => <SelectCfop disabled={!podeEditar} {...field} />}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Código CSOSN</Label>
                <Controller
                  control={control}
                  name="csosn"
                  render={({ field }) => <SelectCsosn disabled={!podeEditar} {...field} />}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={8}>
              <FormGroup>
                <Label>Código NCM</Label>
                <Controller
                  control={control}
                  name="ncm"
                  render={({ field }) => <SelectNcm disabled={!podeEditar} {...field} />}
                />
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label>Código CEST</Label>
                <InputControl disabled={!podeEditar} {...register('cest')} />
              </FormGroup>
            </Col>
          </Row>

          {podeEditar && (
            <Row>
              <Col className="text-end">
                <SpinnerButton loading={loading} color="primary" type="submit">
                  Salvar Alterações
                </SpinnerButton>
              </Col>
            </Row>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};
