import { useState } from 'react';
import { SelectCfop } from 'components/Form/SelectCfop';
import { Input, Spinner } from 'reactstrap';
import { RegraGlobalCfopDto } from 'model/types/fiscal.types';
import { toString } from 'lodash';
import { alterarRegraGlobalCfop } from 'services/fiscalService';
import { showToastErrors } from 'common/toast_config';

enum DataStatus {
  Inicial = 0,
  Alterado = 1,
  Invalido = 2,
  Processando = 10,
}

type LinhaRegraCfopProps = {
  regra: RegraGlobalCfopDto;
};

export function LinhaRegraCfop({ regra }: LinhaRegraCfopProps) {
  const [dataState, setDataState] = useState<DataStatus>(DataStatus.Inicial);
  const [dados, setDados] = useState<RegraGlobalCfopDto>(regra);

  const handleChangeCfopDentroEstado = async (value: string) => {
    setDataState(DataStatus.Processando);

    try {
      const response = await alterarRegraGlobalCfop({
        id: regra.id,
        cfopDentroEstado: value,
        cfopForaEstado: regra.cfopForaEstado,
      });

      setDados(response);
      setDataState(DataStatus.Alterado);
    } catch (error: any) {
      setDataState(DataStatus.Invalido);
      showToastErrors(error);
    }
  };

  const handleChangeCfopForaEstado = async (value: string) => {
    setDataState(DataStatus.Processando);

    try {
      const response = await alterarRegraGlobalCfop({
        id: regra.id,
        cfopDentroEstado: regra.cfopDentroEstado,
        cfopForaEstado: value,
      });

      setDados(response);
      setDataState(DataStatus.Alterado);
    } catch (error: any) {
      setDataState(DataStatus.Invalido);
      showToastErrors(error);
    }
  };

  return (
    <tr>
      <td>
        <Input readOnly defaultValue={dados.tipoPessoaTexto} />
      </td>
      <td>
        <Input readOnly defaultValue={dados.tipoIcmsTexto} />
      </td>
      <td>
        <SelectCfop
          disabled={dataState === DataStatus.Processando}
          value={toString(dados.cfopDentroEstado)}
          onChange={handleChangeCfopDentroEstado}
        />
      </td>
      <td>
        <SelectCfop
          disabled={dataState === DataStatus.Processando}
          value={toString(dados.cfopForaEstado)}
          onChange={handleChangeCfopForaEstado}
        />
      </td>
      <td valign="middle">
        {dataState === DataStatus.Alterado && <i className="mdi mdi-check-bold text-success font-size-16" />}
        {dataState === DataStatus.Invalido && <i className="mdi mdi-close-thick text-danger font-size-16" />}
        {dataState === DataStatus.Processando && <Spinner size="sm" color="primary" />}
      </td>
    </tr>
  );
}
