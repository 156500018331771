import { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Alert, FormGroup, Label, Form } from 'reactstrap';
import { SpinnerButton } from 'components/Button/SpinnerButton';
import { VincularUsuarioData, vincularUsuarioNaOrganizacao } from 'services/usuarioService';
import { AlertErrors } from 'components/Alert/AlertErrors';
import { InputControl } from 'components/Form/InputControl';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yup, yupResolver } from 'common/yup';

interface VincularUsuarioModalProps {
  isOpen: boolean;
  onToggle: (toggle: boolean) => void;
  onSuccess?: () => void;
}

const scheme = yup.object().shape({
  usuario: yup.string().required().email(),
});

export function VincularUsuarioModal({ isOpen, onToggle, onSuccess }: VincularUsuarioModalProps) {
  const [apiErrors, setApiErrors] = useState([]);

  const {
    register,
    handleSubmit,
    setFocus,
    reset,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = useForm<VincularUsuarioData>({ resolver: yupResolver(scheme) });

  function handleToggle() {
    if (isSubmitting) return;
    resetModal();
    onToggle(!isOpen);
  }

  function resetModal() {
    reset();
    setApiErrors([]);
  }

  function handleOpened() {
    setFocus('usuario');
  }

  const handleValidSubmit: SubmitHandler<VincularUsuarioData> = async values => {
    try {
      setApiErrors([]);
      await vincularUsuarioNaOrganizacao(values);
      onSuccess && onSuccess();
      resetModal();
    } catch (error: any) {
      if (error.statusCode === 400) {
        setApiErrors(error.messages);
        return;
      }

      return Promise.reject(error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} backdrop={true} onOpened={handleOpened}>
      <ModalHeader toggle={handleToggle}>Minha Organização - Vincular Usuário</ModalHeader>
      <Form onSubmit={handleSubmit(handleValidSubmit)}>
        <ModalBody>
          <AlertErrors errors={apiErrors} />
          {isSubmitSuccessful && !!!apiErrors && <Alert color="success">Usuário vinculado com sucesso!</Alert>}

          <FormGroup>
            <Label>Digite o e-mail do usuário para ser vinculado</Label>
            <InputControl {...register('usuario')} error={errors.usuario} />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <SpinnerButton type="submit" loading={isSubmitting}>
            Vincular Agora
          </SpinnerButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
