import { forwardRef, ForwardRefRenderFunction, useRef, useEffect, useState } from 'react';
import AsyncSelect, { Async } from 'react-select/async';
import { OptionTypeBase } from 'react-select';
import { FieldError } from 'react-hook-form';
import { ClienteDto, obterClientePeloId, obterClientePeloNome } from 'services/clienteService';

export interface SelectClienteProps {
  name?: string;
  value?: string | null;
  onBlur?: () => void;
  onChange?: (id: string) => void;
  onSelected?: (cliente: ClienteDto) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
  menuPlacement?: 'top' | 'auto' | 'bottom';
  error?: FieldError;
  isClearable?: boolean;
  autoFocus?: boolean;
}

const Component: ForwardRefRenderFunction<Async<OptionTypeBase>, SelectClienteProps> = (
  {
    name,
    value,
    onChange,
    onSelected,
    onBlur,
    onKeyDown,
    menuPlacement = 'auto',
    error,
    isClearable = false,
    autoFocus = false,
  },
  ref,
) => {
  const loadOptionsRef = useRef<NodeJS.Timeout>();
  const [clienteValue, setClienteValue] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isCanceled = false;

    if (!value) {
      setClienteValue(null);
      return;
    }

    async function fetchValue() {
      setIsLoading(true);
      try {
        const data = await obterClientePeloId(value as string);
        if (isCanceled) return;
        setClienteValue({ value: data.id, label: data.nome });
      } finally {
        setIsLoading(false);
      }
    }

    fetchValue();

    return () => {
      isCanceled = true;
    };
  }, [value]);

  function promiseOptions(inputValue: string): Promise<any> {
    loadOptionsRef.current && clearTimeout(loadOptionsRef.current);

    return new Promise(resolve => {
      loadOptionsRef.current = setTimeout(async () => {
        const data = await obterClientePeloNome(inputValue);

        const options = data.map((p: ClienteDto) => {
          return {
            value: p.id,
            label: `${p.nome}`,
            object: p,
          };
        });

        resolve(options);
      }, 1100);
    });
  }

  return (
    <>
      <AsyncSelect
        ref={ref}
        name={name}
        onChange={(event: any) => {
          setClienteValue(event);
          onChange && onChange(event.value);
          onSelected && onSelected(event.object);
        }}
        value={clienteValue}
        onBlur={onBlur}
        loadOptions={promiseOptions}
        cacheOptions
        isLoading={isLoading}
        defaultOptions={false}
        menuPlacement={menuPlacement}
        classNamePrefix="select2-selection"
        placeholder="digite para buscar..."
        isClearable={isClearable}
        noOptionsMessage={() => 'nenhum registro encontrado para o termo buscado'}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
      />
      {!!error?.message && <span className="text-danger small">{error?.message}</span>}
    </>
  );
};

export const SelectCliente = forwardRef(Component);
