import { SituacaoCupom } from 'model/types/cupomfiscal.types';
import { Badge, Spinner } from 'reactstrap';

interface SituacaoCupomBadgeProps {
  situacao: number;
  situacaoTexto: string;
}

export const SituacaoCupomBadge = (props: SituacaoCupomBadgeProps) => {
  const displayBadge = (situacao: number) => {
    return (
      situacao !== SituacaoCupom.ProcessandoAutorizacao &&
      situacao !== SituacaoCupom.ProcessandoCancelamento &&
      situacao !== SituacaoCupom.ProcessandoInutilizacao
    );
  };

  const getBadgeColor = (situacao: number): string => {
    switch (situacao) {
      case SituacaoCupom.Autorizado:
        return 'success';
      case SituacaoCupom.Rejeitado:
      case SituacaoCupom.Inutilizado:
      case SituacaoCupom.Cancelado:
        return 'danger';
      case SituacaoCupom.AutorizacaoPendente:
      case SituacaoCupom.CancelamentoPendente:
        return 'warning';
      default:
        return 'secondary';
    }
  };

  return (
    <div className="px-2 text-center">
      {displayBadge(props.situacao) ? (
        <Badge color={getBadgeColor(props.situacao)}>{props.situacaoTexto}</Badge>
      ) : (
        <Spinner type="border" color="primary" size="sm" title={props.situacaoTexto} />
      )}
    </div>
  );
};
