import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

//i18n
import { withTranslation } from 'react-i18next';

// Routes
import { ROUTES } from '../../config/routes';

const SidebarContent = props => {
  const ref = useRef();
  const [matchingMenuItem, setMatchingMenuItem] = useState(null);

  useEffect(() => {
    const initMenu = pathName => {
      const ul = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');

      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          setMatchingMenuItem(items[i]);
          return;
        }
      }

      const parts = pathName.split('/');
      const lastPathName = parts.splice(0, parts.length - 1).join('/');

      if (lastPathName) {
        initMenu(lastPathName);
      }
    };

    new MetisMenu('#side-menu');
    initMenu(props.location.pathname);
  }, [props.location.pathname]);

  useEffect(() => {
    function activateParentDropdown(item) {
      item.classList.add('active');
      const parent = item.parentElement;
      const parent2El = parent.childNodes[1];
      if (parent2El && parent2El.id !== 'side-menu') {
        parent2El.classList.add('mm-show');
      }

      if (parent) {
        parent.classList.add('mm-active');
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.add('mm-show'); // ul tag

          const parent3 = parent2.parentElement; // li tag

          if (parent3) {
            parent3.classList.add('mm-active'); // li
            parent3.childNodes[0].classList.add('mm-active'); //a
            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.add('mm-show'); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.add('mm-show'); // li
                parent5.childNodes[0].classList.add('mm-active'); // a tag
              }
            }
          }
        }
        scrollElement(item);
        return false;
      }
      scrollElement(item);
      return false;
    }

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [matchingMenuItem]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <>
      <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Menu</li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-home"></i>
                <span>Dashboard</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to={ROUTES.DASHBOARD}>Principal</Link>
                </li>

                <li>
                  <Link to="/#">Financeiro</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">Cadastros</li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-user-detail"></i>
                <span>Clientes</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to={ROUTES.CLIENTE_LISTAGEM}>Listagem Clientes</Link>
                </li>
                <li>
                  <Link to={ROUTES.CLIENTE_NOVO}>Novo Cliente</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-cube"></i>
                <span>Produtos</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to={ROUTES.PRODUTO_LISTAGEM}>Listagem Produtos</Link>
                </li>
                <li>
                  <Link to={ROUTES.PRODUTO_NOVO('novo')}>Novo Produto</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
