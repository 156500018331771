import { useEffect, useCallback, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ApexOptions } from 'apexcharts';
import { toMoneyFormat, toPercentFormat } from 'common/formatters';
import { filter, sumBy } from 'lodash';
import classnames from 'classnames';

// Import Models
import { ROUTES } from 'config/routes';
import { VendaSituacao } from 'model/types/venda.types';
import { obterTodasVendasPeriodo } from 'services/vendaService';
import { useDashboardContext } from './context';

// Locale
import 'moment/locale/pt-br';

const options: ApexOptions = {
  chart: {
    width: '100%',
    type: 'line',
    toolbar: {
      show: false,
      tools: {
        zoom: false,
      },
    },
    dropShadow: {
      enabled: !0,
      color: '#000',
      top: 18,
      left: 7,
      blur: 8,
      opacity: 0.2,
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ['#556ee6'],
  stroke: {
    curve: 'smooth',
    width: 3,
  },
  xaxis: {
    type: 'datetime',
    labels: {
      format: 'dd/MM',
    },
  },
  yaxis: {
    labels: {
      formatter: function (val: number) {
        return toMoneyFormat(val);
      },
    },
  },
};

const anoMesOptions = Array.from(Array(6).keys()).map(i => moment().subtract(i, 'months').format('YYYY-MM'));
const anoMesOptionsDefault = anoMesOptions[0];

export const SalesEarning = () => {
  const { dadosPeriodo, atualizarDadosPeriodo } = useDashboardContext();
  const [periodoSelecionado, setPeriodoSelecionado] = useState<string>(anoMesOptionsDefault);
  const [seriesData, setSeriesData] = useState<any>([]);
  const [totalVendasMes, setTotalVendasMes] = useState<number>(0);
  const [totalVendasMesAnterior, setTotalVendasMesAnterior] = useState<number>(0);

  const series: ApexAxisChartSeries = [
    {
      name: 'Total',
      data: [...seriesData],
    },
  ];

  const calcularVendaPeriodoAnterior = useCallback(async (anoMes: string) => {
    const dataInicio = moment(anoMes, 'YYYY-MM').subtract(1, 'months');
    const dataFim = moment(dataInicio).endOf('month');

    const dados = await obterTodasVendasPeriodo({
      situacao: VendaSituacao.Finalizada,
      dataInicio: dataInicio.format('YYYY-MM-DD'),
      dataFim: dataFim.format('YYYY-MM-DD'),
    });

    setTotalVendasMesAnterior(sumBy(dados, v => v.totalDaVenda));
  }, []);

  useEffect(() => {
    if (dadosPeriodo === null) {
      return;
    }

    const daysInMonth = moment(dadosPeriodo.anoMes, 'YYYY-MM').daysInMonth();
    const totalPorDia = [];

    for (let i = 0; i < daysInMonth; i++) {
      const data = moment(dadosPeriodo.anoMes, 'YYYY-MM').add(i, 'days');

      const agregado = {
        x: data.toDate(),
        y: sumBy(
          filter(dadosPeriodo.dados, f => moment(f.dataOperacao).isSame(data, 'date')),
          v => v.totalDaVenda,
        ),
      };

      if (agregado.y > 0) {
        totalPorDia.push(agregado);
      }
    }

    setSeriesData(totalPorDia);
    setTotalVendasMes(sumBy(totalPorDia, v => v.y));
    calcularVendaPeriodoAnterior(dadosPeriodo.anoMes);
  }, [dadosPeriodo, calcularVendaPeriodoAnterior]);

  useEffect(() => {
    atualizarDadosPeriodo(periodoSelecionado);
  }, [periodoSelecionado, atualizarDadosPeriodo]);

  const onChangeMonth = (value: string) => {
    setPeriodoSelecionado(value);
  };

  const calculateGain = () => {
    if (totalVendasMesAnterior === 0) {
      return 0;
    }

    const gain = totalVendasMes - totalVendasMesAnterior;
    const gainPercent = gain / totalVendasMesAnterior;

    return gainPercent;
  };

  return (
    <Col>
      <Card>
        <CardBody>
          <div className="clearfix">
            <div className="float-end">
              <div className="input-group input-group-sm">
                <select
                  title="Mês"
                  className="form-select form-select-sm"
                  value={periodoSelecionado}
                  onChange={e => {
                    onChangeMonth(e.target.value);
                  }}
                >
                  {anoMesOptions.map((m, i) => (
                    <option key={m} value={m}>
                      {moment(m, 'YYYY-MM').format('MMM/YYYY')}
                    </option>
                  ))}
                </select>
                {/* <div className="input-group-append"> */}
                <label className="input-group-text">Mês</label>
                {/* </div> */}
              </div>
            </div>
            <h4 className="card-title mb-4">Gráfico de ganho nas Vendas</h4>
          </div>

          <Row>
            <Col lg="3">
              <div className="text-muted">
                <div className="mb-4">
                  <p>Mês Selecionado</p>
                  <h4>{toMoneyFormat(totalVendasMes, true)}</h4>
                  <div>
                    <>
                      <span
                        className={classnames('badge', 'font-size-12', 'me-1', {
                          'badge-soft-success': calculateGain() >= 0,
                          'badge-soft-danger': calculateGain() < 0,
                        })}
                      >
                        {toPercentFormat(calculateGain())}
                      </span>
                      &nbsp;Do mês anterior
                    </>
                  </div>
                </div>

                <div>
                  <Link to={ROUTES.VENDAS_RELATORIO} className="btn btn-primary  btn-sm">
                    Relatório Vendas&nbsp;<i className="mdi mdi-chevron-right ms-1"></i>
                  </Link>
                </div>

                <div className="mt-4">
                  <p className="mb-2">Mês Anterior</p>
                  <h5>{toMoneyFormat(totalVendasMesAnterior, true)}</h5>
                </div>
              </div>
            </Col>

            <Col lg="9">
              <div id="line-chart">
                <ReactApexChart series={series} options={options} type="line" height={290} className="apex-charts" />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};
