import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import CustomTable from './CustomTable';
import { get } from 'api/requests';

const RemoteDataTable = ({ url, columns, rightBar, dataVersion = 1 }) => {
  const refIsCanceled = useRef(false);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    refIsCanceled.current = false;

    const fetchData = async () => {
      setLoading(true);

      try {
        const { data } = await get(url);
        if (refIsCanceled.current) return;

        setDados(data);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => (refIsCanceled.current = true);
  }, [url, dataVersion]);

  return <CustomTable remote={false} columns={columns} data={dados} loading={loading} rightBar={rightBar} />;
};

RemoteDataTable.propTypes = {
  url: PropTypes.string,
  columns: PropTypes.array,
  rightBar: PropTypes.any,
};

export default RemoteDataTable;
