import { Container } from 'reactstrap';

import './pageFooter.css';

export function PageFooter({ children }: { children: React.ReactNode }) {
  return (
    <footer className="site-footer">
      <Container fluid className="mt-3">
        {children}
      </Container>
    </footer>
  );
}
