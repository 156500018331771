import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

//i18n
import { withTranslation } from 'react-i18next';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { ROUTES } from 'config/routes';

import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { obterUsuarioLogado } from '../../../api/requests';

const ProfileMenu = props => {
  const history = useHistory();
  const [menu, setMenu] = useState(false);
  const [nomeUsuario, setNomeUsuario] = useState('');

  useEffect(() => {
    let isCanceled = false;

    async function fetchLoggedUser() {
      let nomeUsuario;

      try {
        const usuarioStorage = localStorage.getItem('usuario');

        if (usuarioStorage) {
          const { nome } = JSON.parse(usuarioStorage);
          nomeUsuario = nome;
        } else {
          const { data } = await obterUsuarioLogado();
          if (isCanceled) return;
          localStorage.setItem('usuario', JSON.stringify(data));
          nomeUsuario = data.nome;
        }

        setNomeUsuario(
          nomeUsuario
            .split('@')[0]
            .split(/[\W_]/gm)
            .map(v => v.charAt(0).toUpperCase() + v.slice(1))
            .join(' '),
        );
      } catch (error) {
        if (isCanceled) return;
        history.push(ROUTES.LOGOUT);
      }
    }

    fetchLoggedUser();

    return () => (isCanceled = true);
  }, [history]);

  return (
    <>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)}>
        <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
          <i className="mdi mdi-account font-size-16" />
          &nbsp;
          <span className="font-size-14 fw-medium" id="profile_menu_email_usuario_logado">
            {nomeUsuario}
          </span>
          <i className="mdi mdi-chevron-down" />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <Link to={ROUTES.USUARIO_PERFIL} className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            <span>Meu Perfil</span>
          </Link>

          <Link to={ROUTES.USUARIO_LISTAGEM} className="dropdown-item">
            <i className="bx bxs-user-account font-size-16 align-middle me-1" />
            <span>Usuários</span>
          </Link>

          <Link to={ROUTES.EMPRESA_LISTAGEM} className="dropdown-item">
            <i className="bx bxs-bank font-size-16 align-middle me-1" />
            <span>Empresas</span>
          </Link>

          <Link to={ROUTES.IMPORTACAO_DADOS} className="dropdown-item">
            <i className="bx bx-data font-size-16 align-middle me-1" />
            <span>Importar dados</span>
          </Link>

          <Link to={ROUTES.CERTIFICADO_LISTAGEM} className="dropdown-item">
            <i className="bx bxs-certification font-size-16 align-middle me-1" />
            <span>Certificados</span>
          </Link>

          <Link to={ROUTES.EMITENTE_LISTAGEM} className="dropdown-item">
            <i className="bx bx-id-card font-size-16 align-middle me-1" />
            <span>Emitentes</span>
          </Link>

          <Link to={ROUTES.REGRAS_GLOBAIS_CFOP} className="dropdown-item">
            <i className="bx bx-hive font-size-16 align-middle me-1" />
            <span>Regras CFOP</span>
          </Link>

          <div className="dropdown-divider" />

          <Link to={ROUTES.LOGOUT} className="dropdown-item" id="profile_menu_sair">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t('Sair')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(ProfileMenu));
