import { forwardRef, ForwardRefRenderFunction, SelectHTMLAttributes } from 'react';

interface Option {
  label: string;
  value: string;
}

const options: Option[] = [
  { label: 'Não Contribuiente', value: '9' },
  { label: 'Contribuiente', value: '1' },
  { label: 'Contribuiente Isento de IE', value: '2' },
];

const Component: ForwardRefRenderFunction<HTMLSelectElement, SelectHTMLAttributes<HTMLSelectElement>> = (
  { ...rest },
  ref,
) => {
  return (
    <select ref={ref} className="form-select" {...rest}>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export const SelectIndicadorIE = forwardRef(Component);
