import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//i18n
import { withTranslation } from 'react-i18next';
import SidebarContent from './SidebarContent';

import { Link } from 'react-router-dom';

import logoFull from '../../assets/images/logo-light.png';
import logoSmall from '../../assets/images/logo-light-sm.png';
import { ROUTES } from 'config/routes';

const Sidebar = () => {
  return (
    <>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to={ROUTES.HOME} className="logo logo-light logo-dark">
            <span className="logo-sm">
              <img src={logoSmall} alt="" height="35" />
            </span>
            <span className="logo-lg">
              <img src={logoFull} alt="" height="45" />
            </span>
          </Link>
        </div>

        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>

        <div className="sidebar-background"></div>
      </div>
    </>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStatetoProps, {})(withRouter(withTranslation()(Sidebar)));
