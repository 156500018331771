export function toMoneyFormat(value: number | null | undefined, simbol = false): string {
  value = value ?? 0;
  const formatedValue = value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    currencyDisplay: 'symbol',
  });

  return simbol ? formatedValue : formatedValue.replace('R$', '').trim();
}

export function toDateFormat(value: string | Date): string {
  if (!value) return '';

  let date = value;
  if (!(date instanceof Date)) {
    date = new Date(value);
  }

  return Intl.DateTimeFormat('pt-BR', { dateStyle: 'short' }).format(date);
}

export function toDateTimeFormat(value: string | Date | null | undefined): string {
  if (!value) return '';

  let date = value;
  if (!(date instanceof Date)) {
    date = new Date(value);
  }

  return Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(date);
}

export function toDecimalFormat(value: number, digits = 2): string {
  value = value ?? 0;
  const formatedValue = value.toLocaleString('pt-BR', {
    style: 'decimal',
    currency: 'BRL',
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  });

  return formatedValue;
}

export function toPercentFormat(value: number): string {
  value = value ?? 0;
  const formatedValue = value.toLocaleString('pt-BR', {
    style: 'percent',
    currency: 'BRL',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return formatedValue;
}
