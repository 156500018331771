import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface NonAuthLayoutProps extends RouteComponentProps<any> {
  children: React.ReactNode;
}

function NonAuthLayoutComponent({ children }: NonAuthLayoutProps) {
  useEffect(() => {
    try {
      const body = document.getElementsByTagName('body')[0];

      body.removeAttribute('data-topbar');
      body.removeAttribute('data-layout');
      body.removeAttribute('data-layout-size');
      body.removeAttribute('data-layout-scrollable');
      body.removeAttribute('data-sidebar-size');
    } catch (error) {}
  }, []);

  return <>{children}</>;
}

export const NonAuthLayout = withRouter(NonAuthLayoutComponent);
