import MetaTags from 'react-meta-tags';
import { useState } from 'react';
import { Row, Col, Card, CardBody, Container, Alert, Label, Input } from 'reactstrap';
import { AlertErrors } from '../../components/Alert/AlertErrors';

import { withRouter, Link } from 'react-router-dom';

// import images
import profile from '../../assets/images/profile-img.png';
import logo from '../../assets/images/logo-dark.png';

// import contexts
import { recuperarSenha } from '../../api/requests';

// components
import { SpinnerButton } from '../../components/Button/SpinnerButton';
import { ROUTES } from 'config/routes';

function RecuperarSenhaPage() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [enviadoEmailSucesso, setEnviadoEmailSucesso] = useState(null);
  const [email, setEmail] = useState('');

  const handleOnSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    setErrors(null);
    setEnviadoEmailSucesso(null);

    try {
      const response = await recuperarSenha(email);
      setEnviadoEmailSucesso(response.data);
      setLoading(false);
    } catch (error) {
      setErrors(error.messages);
      setLoading(false);
    }
  };

  return (
    <>
      <MetaTags>
        <title>Recuperar Senha</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to={ROUTES.HOME} className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-white p-4">
                        <h5 className="text-white">Recuperação de Senha</h5>
                        <p>Informe seu e-mail que enviaremos um link para definir uma nova senha!</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to={ROUTES.HOME}>
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <form className="form-horizontal" onSubmit={handleOnSubmit}>
                      {!!enviadoEmailSucesso && <Alert color="success">{enviadoEmailSucesso}</Alert>}
                      <AlertErrors errors={errors} />

                      <div className="mb-3">
                        <Label htmlFor="email">Email</Label>
                        <Input
                          name="email"
                          id="email"
                          className="form-control"
                          placeholder="Seu e-mail"
                          type="email"
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <SpinnerButton color="primary" type="submit" loading={loading}>
                            Solicitar alteração de senha
                          </SpinnerButton>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Volte para{' '}
                  <Link to={ROUTES.LOGIN} className="fw-medium text-primary">
                    Login
                  </Link>{' '}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export const RecuperarSenha = withRouter(RecuperarSenhaPage);
