import { useState } from 'react';
import { Card, CardHeader, CardBody, Alert, Form, FormGroup, Label } from 'reactstrap';
import { SpinnerButton } from 'components/Button/SpinnerButton';
import { AlertErrors } from 'components/Alert/AlertErrors';
import { InputControl } from 'components/Form/InputControl';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yup, yupResolver, SchemaOf } from '../../common/yup';

import { alterarSenha, AlterarSenhaData } from 'services/usuarioService';

interface AlterarSenhaPerfilProps {
  usuarioId: string;
}

const scheme: SchemaOf<AlterarSenhaData> = yup.object({
  senhaAtual: yup.string().required(),
  novaSenha: yup.string().required(),
  confirmacaoSenha: yup
    .string()
    .required()
    .oneOf(['', yup.ref('novaSenha')], 'Senhas precisam ser iguais'),
});

export function AlterarSenhaPerfil({ usuarioId }: AlterarSenhaPerfilProps) {
  const [mensagemSucesso, setMensagemSucesso] = useState('');
  const [apiErrors, setApiErrors] = useState<string[]>([]);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<AlterarSenhaData>({ resolver: yupResolver(scheme) });

  const handleChangePassword: SubmitHandler<AlterarSenhaData> = async values => {
    try {
      setApiErrors([]);
      await alterarSenha(usuarioId, values);
      setMensagemSucesso('Senha alterada com sucesso!');
    } catch (ex: any) {
      setApiErrors(ex.messages);
    }
  };

  return (
    <>
      <Card outline color="secondary" className="border">
        <CardHeader className="bg-transparent">
          <h5 className="my-0 text-secondary">Segurança - Alterar Senha de Acesso</h5>
        </CardHeader>
        <CardBody>
          <AlertErrors errors={apiErrors} />
          {!!mensagemSucesso ? (
            <Alert color="success">{mensagemSucesso}</Alert>
          ) : (
            <Form autoComplete="off" onSubmit={handleSubmit(handleChangePassword)}>
              <FormGroup>
                <Label>Informe a Senha Atual</Label>
                <InputControl
                  type="password"
                  autoComplete="new-password"
                  {...register('senhaAtual')}
                  error={errors.senhaAtual}
                />
              </FormGroup>

              <FormGroup className="mt-2">
                <Label>Informe a Nova Senha</Label>
                <InputControl
                  type="password"
                  autoComplete="new-password"
                  {...register('novaSenha')}
                  error={errors.novaSenha}
                />
              </FormGroup>

              <FormGroup className="mt-2">
                <Label>Confirme a Nova Senha</Label>
                <InputControl
                  type="password"
                  autoComplete="new-password"
                  {...register('confirmacaoSenha')}
                  error={errors.confirmacaoSenha}
                />
              </FormGroup>

              <div className="text-center mt-4">
                <SpinnerButton loading={isSubmitting} type="submit" color="danger">
                  Alterar Senha
                </SpinnerButton>
              </div>
            </Form>
          )}
        </CardBody>
      </Card>
    </>
  );
}
