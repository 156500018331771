import { KeyboardEvent } from 'react';

export function useFormTabOnEnter() {
  const handleOnKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key.toLowerCase() === 'enter' && event.ctrlKey) {
      event.preventDefault();
      event.currentTarget.requestSubmit();
      return event;
    }

    if (event.key.toLowerCase() === 'enter') {
      event.preventDefault();
      const formElements = (event.currentTarget as any as HTMLElement[]) ?? [];
      const focusedElements = [...formElements].filter(
        element =>
          /input|textarea|select|button/i.test(element.tagName) &&
          element.getAttribute('tabIndex') !== '-1' &&
          element.getAttribute('disabled') === null &&
          element.getAttribute('readonly') === null &&
          element.getAttribute('type') !== 'hidden',
      );

      const currentIndex = focusedElements.indexOf(event.target as HTMLElement);
      const nextElement = focusedElements[currentIndex + 1];

      if (!nextElement) {
        event.currentTarget.requestSubmit();
      } else {
        nextElement.focus();
      }
    }
  };

  return { handleOnKeyDown };
}
