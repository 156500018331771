import { Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { EmitirCartaCorrecaoNotaCommand } from 'model/types/notafiscal.types';
import { showToastErrors } from 'common/toast_config';
import { FormEvent, useRef, useState } from 'react';
import { emitirCartaCorrecaoNota } from 'services/nfeService';
import { SpinnerButton } from 'components/Button/SpinnerButton';

type CartaPros = {
  NotaId: string;
  isOpen: boolean;
  toggle: (toggle: boolean) => void;
};

export function NotaCartaCorrecao({ NotaId, isOpen, toggle }: CartaPros) {
  const [texto, setTexto] = useState<string>('');
  const [enviando, setEnviando] = useState<boolean>(false);
  const [sucesso, setSucesso] = useState<boolean>(false);
  const [sizeModal, setSizeModal] = useState<string>('lg');
  const inputFocus = useRef<HTMLTextAreaElement>(null);

  const handleCloseModal = () => {
    toggle(!isOpen);
  };

  const handleOpenedModal = () => {
    setEnviando(false);
    setSucesso(false);
    setSizeModal('lg');
    focusInput();
  };

  const handleSubmitForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setEnviando(true);

      const command: EmitirCartaCorrecaoNotaCommand = {
        notaId: NotaId,
        correcao: texto,
      };
      await emitirCartaCorrecaoNota(command);
      setSucesso(true);
      setSizeModal('md');
    } catch (error: any) {
      showToastErrors(error);
    } finally {
      setEnviando(false);
    }
  };

  function focusInput() {
    inputFocus.current?.focus();
    inputFocus.current?.select();
  }

  return (
    <Modal isOpen={isOpen} toggle={handleCloseModal} size={sizeModal} fullscreen="lg" onOpened={handleOpenedModal}>
      <ModalHeader toggle={handleCloseModal}>Carta correção NFe</ModalHeader>
      <ModalBody>
        {sucesso ? (
          <>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <i className="mdi mdi-check-circle-outline" style={{ fontSize: '4em', color: 'green' }} />
                  <h5>Carta correção enviada com sucesso!</h5>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Label>
              <b>O que é uma Carta Correção e qual sua finalidade?</b>
              <p>
                A Carta Correção(CC-e) é um documento fiscal eletrônico que tem como finalidade corrigir erros cometidos
                na emissão da nota fiscal eletrônica após sua autorização.
              </p>
            </Label>
            <Label>
              <b>Algumas das informações que podem ser corrigidas através de uma Carta de Correção:</b>
              <p>
                * Informação equivocada do CFOP.
                <br />
                * Descrição de nome das mercadorias ou serviços prestados.
                <br />
                * Volume, peso, acondicionamento.
                <br />
                * Data de emissão ou saída das mercadorias.
                <br />
                * Dados do transportador.
                <br />* Razão social do destinatário.
              </p>
            </Label>
            <hr />
            <Label>Informe abaixo as correções da nota fiscal eletrônica:</Label>
            <Form onSubmit={e => handleSubmitForm(e)}>
              <textarea
                ref={inputFocus}
                className="form-control"
                minLength={15}
                maxLength={1000}
                rows={10}
                required
                placeholder="Texto Correção"
                onChange={e => setTexto(e.target.value)}
              />
              <div className="text-end mt-3">
                <SpinnerButton loading={enviando} type="submit" color="primary">
                  {enviando ? 'Enviando...' : 'Enviar Correção'}
                </SpinnerButton>
              </div>
            </Form>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}
