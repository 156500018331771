import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { userRoutes, authRoutes } from './routes/routes';
import { Authmiddleware } from './routes/middleware/Authmiddleware';
import VerticalLayout from './components/VerticalLayout';
import HorizontalLayout from './components/HorizontalLayout';
import { NonAuthLayout } from './components/NonAuth/NonAuthLayout';
import { ROUTES } from 'config/routes';

import './assets/scss/theme.scss';

const App = props => {
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case 'horizontal':
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();

  return (
    <Router>
      <Switch>
        {authRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
            exact
          />
        ))}

        {userRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={Layout}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}

        <Authmiddleware
          path="*"
          isAuthProtected={false}
          layout={NonAuthLayout}
          component={() => <Redirect to={ROUTES.NOT_FOUND} />}
          exact
        />
      </Switch>
    </Router>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
