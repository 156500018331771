import { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AlertErrors } from 'components/Alert/AlertErrors';
import ENDPOINTS from 'api/endpoints';
import api from 'api';

interface CartaCorrecaoNfeModalProps {
  notaFiscalId: string;
  sequenciaCartaCorrecao: number;
  isOpen: boolean;
  onToggle: (toogle: boolean) => void;
}

export const ImprimirCartaCorrecaoNfeModal = ({
  notaFiscalId,
  sequenciaCartaCorrecao,
  isOpen,
  onToggle,
}: CartaCorrecaoNfeModalProps) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [base64Pdf, setBase64Pdf] = useState<string | null>();

  const handleToogle = () => {
    onToggle(!isOpen);
  };

  const carregarDocumento = async (notaId: string, sequenciaCarta: number) => {
    setLoading(true);
    try {
      const { data } = await api.get<any>(ENDPOINTS.NOTA_FISCAL_iMPRIMIR_CARTA_CORRECAO(notaId, sequenciaCarta));
      if (!data) {
        setErrors(['Não foi possível carregar a Carta Correção']);
        return;
      }
      setBase64Pdf(data.base64pdf);
    } catch (error: any) {
      setErrors(error.messages);
    } finally {
      setLoading(false);
    }
  };

  const handleOpened = () => {
    carregarDocumento(notaFiscalId, sequenciaCartaCorrecao);
  };

  return (
    <Modal
      size="xl"
      className="modal-fullscreen-sm-down modal-dialog modal-dialog-scrollable"
      isOpen={isOpen}
      backdrop={true}
      toggle={handleToogle}
      onOpened={handleOpened}
    >
      <ModalHeader className="d-print-none" toggle={handleToogle}>
        CC-E: Carta de correção eletrônica
      </ModalHeader>
      <ModalBody>
        {loading && <div className="text-center">Carregando...</div>}
        {!loading && errors.length > 0 && <AlertErrors errors={errors} />}

        {!loading && errors.length === 0 && !!base64Pdf && (
          <embed src={`data:application/pdf;base64,${base64Pdf}`} height="550" width="100%" title="Danfe NF-e"></embed>
        )}
      </ModalBody>
    </Modal>
  );
};
