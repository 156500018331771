export interface PageData<T> {
  dados: T[];
  numeroPagina: number;
  paginaAnterior: number | null;
  proximaPagina: number | null;
  tamanhoPagina: number;
  totalPaginas: number;
  totalRegistros: number;
}

export enum UnidadeFederacao {
  AC = 12,
  AL = 27,
  AP = 16,
  AM = 13,
  BA = 29,
  CE = 23,
  DF = 53,
  ES = 32,
  GO = 52,
  MA = 21,
  MT = 51,
  MS = 50,
  MG = 31,
  PA = 15,
  PB = 25,
  PR = 41,
  PE = 26,
  PI = 22,
  RJ = 33,
  RN = 24,
  RS = 43,
  RO = 11,
  RR = 14,
  SC = 42,
  SP = 35,
  SE = 28,
  TO = 17,
}

export enum IndicadorIe {
  Contribuinte = 1,
  ContribuinteIsento = 2,
  NaoContribuinte = 9,
}

export const IndicadorIeLabel = new Map<number, string>([
  [IndicadorIe.Contribuinte, 'Contribuinte'],
  [IndicadorIe.ContribuinteIsento, 'Contribuinte isento'],
  [IndicadorIe.NaoContribuinte, 'Não contribuinte'],
]);

export enum IndicadorPresenca {
  NaoSeAplica = 0,
  OperacaoPresencial = 1,
  PelaInternet = 2,
  PeloTeleatendimento = 3,
  EntregaDomicilio = 4,
  PresencialForaEstabelecimento = 5,
  NaoPresencialOutros = 9,
}

export enum IndicadorOperacaoConsumidor {
  Normal = 0,
  ConsumidorFinal = 1,
}

export enum IndicadorDestinoOperacao {
  OperacaoInterna = 1,
  OperacaoInterestadual = 2,
  OperacaoComExterior = 3,
}
