import { Button, ButtonProps } from 'reactstrap';

interface PrimaryButtonProps extends ButtonProps {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
  icon?: string;
}

export function PrimaryButton({ children, onClick, icon, ...rest }: PrimaryButtonProps) {
  return (
    <Button type="button" color="primary" onClick={onClick} {...rest}>
      {!!icon && <i className={'me-1 mdi mdi-' + icon} />}
      {children}
    </Button>
  );
}
