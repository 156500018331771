import { ROUTES } from 'config/routes';

// error pages
import { Page404 } from '../pages/404';

// autenticacao
import { Login } from '../pages/Autenticacao/Login';
import { Logout } from '../pages/Autenticacao/Logout';
import { RecuperarSenha } from '../pages/Autenticacao/RecuperarSenha';
import { NovaSenha } from '../pages/Autenticacao/NovaSenha';
import { SenhaAlteradaSucesso } from '../pages/Autenticacao/SenhaAlteradaSucesso';

// dashboard
import { ListagemProdutos } from '../pages/Cadastros/Produtos/ListagemProdutos';
import { ListagemClientes } from '../pages/Cadastros/ListagemClientes';
import { UsuarioPerfil } from 'pages/Perfil/UsuarioPerfil';
import { ClienteCadastro } from '../pages/Cadastros/ClienteCadastro';
import { ListagemUsuarios } from '../pages/Cadastros/ListagemUsuarios';
import { EmpresaListagem } from 'pages/Empresa/EmpresaListagem';
import { EmpresaCadastro } from 'pages/Empresa/EmpresaCadastro';
import { CertificadoListagem } from 'pages/Certificado/CertificadoListagem';
import { CertificadoCadastro } from 'pages/Certificado/CertificadoCadastro';
import { ProdutoCadastro } from 'pages/Cadastros/Produtos/ProdutoCadastro';
import { MovimentarEstoque } from 'pages/Estoque/MovimentarEstoque';
import { Venda } from 'pages/Vendas/Venda';
import { VendaListagem } from 'pages/Vendas/Listagem';
import { VisualizarVenda } from 'pages/Vendas/Visualizar';
import { RelatorioVendas } from 'pages/Vendas/RelatorioVendas';
import { CupomListagem } from 'pages/CupomFiscal/CupomListagem';
import { CupomEnviarPacoteContador } from 'pages/CupomFiscal/CupomEnviarPacoteContador';
import { CupomAlterar } from 'pages/CupomFiscal/CupomAlterar';
import { EmitenteListagem } from 'pages/EmitenteFiscal/Listagem';
import { EmitenteCadastro } from 'pages/EmitenteFiscal/EmitenteCadastro';
import { HomePage } from 'pages/Home';
import { ConfigRegrasGlobais } from 'pages/RegrasCfop';
import { ListagemImportacao } from 'pages/ImportacaoDados/ListagemImportacao';
import { NotaListagem } from 'pages/NotaFiscal/NotaListagem';
import { NotaAlterar } from 'pages/NotaFiscal/NotaAlterar';

const userRoutes = [
  // Outros
  { path: ROUTES.USUARIO_PERFIL, component: UsuarioPerfil },
  { path: ROUTES.USUARIO_LISTAGEM, component: ListagemUsuarios },

  // Empresas
  { path: ROUTES.EMPRESA_LISTAGEM, component: EmpresaListagem },
  { path: ROUTES.EMPRESA_ALTERAR(':id'), component: EmpresaCadastro },
  { path: ROUTES.EMPRESA_NOVA, component: EmpresaCadastro },

  { path: ROUTES.CERTIFICADO_LISTAGEM, component: CertificadoListagem },
  { path: ROUTES.CERTIFICADO_NOVO, component: CertificadoCadastro },
  { path: ROUTES.CERTIFICADO_ALTERAR(':id'), component: CertificadoCadastro },

  // Emitentes
  { path: ROUTES.EMITENTE_LISTAGEM, component: EmitenteListagem },
  { path: ROUTES.EMITENTE_EDITAR(':id'), component: EmitenteCadastro },
  { path: ROUTES.EMITENTE_NOVO, component: EmitenteCadastro },

  // Fiscal
  { path: ROUTES.REGRAS_GLOBAIS_CFOP, component: ConfigRegrasGlobais },

  // Produtos
  { path: ROUTES.PRODUTO_LISTAGEM, component: ListagemProdutos },
  { path: ROUTES.PRODUTO_NOVO, component: ProdutoCadastro },
  { path: ROUTES.PRODUTO_ALTERAR(':id'), component: ProdutoCadastro },
  { path: ROUTES.AJUSTAR_ESTOQUE, component: MovimentarEstoque },

  // Clientes
  { path: ROUTES.CLIENTE_LISTAGEM, component: ListagemClientes },
  { path: ROUTES.CLIENTE_NOVO, component: ClienteCadastro },
  { path: ROUTES.CLIENTE_ALTERAR(':id'), component: ClienteCadastro },

  // Vendas
  { path: ROUTES.VENDAS_EDITAR(':id'), component: Venda },
  { path: ROUTES.VENDAS_LISTAGEM, component: VendaListagem },
  { path: ROUTES.VENDAS_VISUALIZAR(':id'), component: VisualizarVenda },
  { path: ROUTES.VENDAS_RELATORIO, component: RelatorioVendas },

  // NFCes
  { path: ROUTES.CUPOM_LISTAGEM, component: CupomListagem },
  { path: ROUTES.CUPOM_ALTERAR(':id'), component: CupomAlterar },
  { path: ROUTES.CUPOM_ENVIAR_PACOTE_CONTADOR, component: CupomEnviarPacoteContador },

  //NFe
  { path: ROUTES.NOTA_LISTAGEM, component: NotaListagem },
  { path: ROUTES.NOTA_ALTERAR(':id'), component: NotaAlterar },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: HomePage },

  //Migração
  { path: ROUTES.IMPORTACAO_DADOS, component: ListagemImportacao },
];

const authRoutes = [
  { path: ROUTES.LOGOUT, component: Logout },
  { path: ROUTES.LOGIN, component: Login },
  { path: ROUTES.RECUPERAR_SENHA, component: RecuperarSenha },
  { path: ROUTES.NOVA_SENHA, component: NovaSenha },
  { path: ROUTES.NOVA_SENHA_SUCESSO, component: SenhaAlteradaSucesso },
  { path: ROUTES.NOT_FOUND, component: Page404 },
];

export { userRoutes, authRoutes };
