import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ptForm as ptBr } from 'yup-locale-pt';

yup.setLocale(ptBr);

export const transformerMoney = (value: any, originalValue: any) => {
  if (isNaN(value)) {
    value = originalValue.replace('.', '').replace(',', '.');
    return Number(value);
  }
  return value;
};

export const transformerQuantidade = (value: any, originalValue: any) => {
  if (isNaN(value)) {
    value = originalValue.replace('.', '').replace(',', '.');
    return Number(value);
  }
  return value;
};

export const transformerStringVaziaEmUndefined = (value: any, originalValue: any) => {
  if (value === undefined || value === '') {
    return undefined;
  }
  return value;
};

export type { SchemaOf };

export { yupResolver, yup };
