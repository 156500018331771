import { toNumber } from 'lodash';

export function decimalRound(value: number, decimals = 2): number {
  const factor = 10 ** decimals;
  return Math.round((value + Number.EPSILON) * factor) / factor;
}

export function toDecimal(value?: any, decimals?: number): number {
  if (!value) return 0.0;
  if (typeof value === 'number') return value;
  value = value.toString().replace('.', '').replace(',', '.');
  const numberValue = toNumber(value);

  return decimals ? decimalRound(numberValue, decimals) : numberValue;
}
