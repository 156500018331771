import { PageContent } from 'components/Common/PageContent';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import { PageFooter } from 'components/Common/PageFooter';
import { CupomCancelarModal } from './CupomCancelarModal';
import { showToast, showToastErrors } from 'common/toast_config';
import { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale';
import { toDateTimeFormat, toMoneyFormat } from 'common/formatters';
import { addCpfCnpjMask } from 'common/mask';
import { SituacaoCupomTexto } from 'components/Cupom/SituacaoCupomTexto';

// services
import * as service from 'services/nfceService';
import { CupomDto, ResumoSituacaoCupom, SituacaoCupom } from 'model/types/cupomfiscal.types';
import { DanfeNfceModal } from 'components/Cupom/DanfeNfceModal';
import { CupomAlterarNumeracaoModal } from './CupomAlterarNumeracaoModal';
import { SelectCliente } from 'components/Form/SelectCliente';
import { CupomTabelaItem } from './CupomTabelaItem';
import { InputPreco } from 'components/Form/InputPreco';

// utils
registerLocale('pt-BR', ptBR);

interface ParamsData {
  id: string;
}

export function CupomAlterar() {
  const { id } = useParams<ParamsData>();
  const [clienteId, setClienteId] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [nfce, setNfce] = useState<CupomDto | null>();
  const [situacaoCupom, setSituacaoCupom] = useState<ResumoSituacaoCupom | null>();
  const [showCancelarCupomFiscal, setShowCancelarCupomFiscal] = useState(false);
  const [showProximoNumeroFiscal, setShowProximoNumeroFiscal] = useState(false);
  const [showDanfeModal, setShowDanfeModal] = useState(false);
  const [versaoDados, setVersaoDados] = useState(0);

  const podeConfirmarSituacao =
    situacaoCupom?.situacao === SituacaoCupom.AutorizacaoPendente ||
    situacaoCupom?.situacao === SituacaoCupom.CancelamentoPendente;

  const podeCancelar =
    situacaoCupom?.situacao === SituacaoCupom.Rejeitado || situacaoCupom?.situacao === SituacaoCupom.Autorizado;

  const podeVisualizarDanfe =
    situacaoCupom?.situacao === SituacaoCupom.Autorizado ||
    situacaoCupom?.situacao === SituacaoCupom.Cancelado ||
    situacaoCupom?.situacao === SituacaoCupom.Denegado;

  const podeEditar =
    situacaoCupom?.situacao == SituacaoCupom.Rejeitado || situacaoCupom?.situacao == SituacaoCupom.Aberto;

  useEffect(() => {
    let isCanceled = false;

    async function loadData() {
      try {
        setLoading(true);
        const data = await service.obterNfcePeloId(id);
        if (isCanceled || !data) return;

        const situacao = await service.consultarSituacaoCupom(id);
        if (isCanceled) return;

        setSituacaoCupom(situacao);
        setNfce(data);
      } finally {
        setLoading(false);
      }
    }

    loadData();

    return () => {
      isCanceled = true;
    };
  }, [id, versaoDados]);

  const verificarStatusCupom = async (id: string): Promise<void> => {
    return await new Promise<void>((resolve, reject) => {
      const interval = 2000;
      let attempt = 0;

      async function consultar() {
        try {
          const response = await service.consultarSituacaoCupom(id);

          if ((response.situacao !== 0 && response.situacao !== 1) || ++attempt >= 10) {
            showToast('Situação do cupom foi alterada.', 'primary');
            setSituacaoCupom(response);
            resolve();
            return;
          }

          setTimeout(consultar, interval);
        } catch (error: any) {
          reject(error);
        }
      }

      setTimeout(consultar, 5000);
    });
  };

  const handleAutorizarCupom = async () => {
    window.scrollTo(0, 0);
    setLoading(true);

    try {
      await service.autorizarCupomFiscal(id);
      await verificarStatusCupom(id);
      setLoading(false);
    } catch (error: any) {
      showToastErrors(error.messages);
      setLoading(false);
    }
  };

  const handleConfirmarSituacao = async () => {
    setLoading(true);

    try {
      await service.confirmarSituacaoCupomFiscal(id);
      await verificarStatusCupom(id);
    } catch (error: any) {
      showToastErrors(error.messages);
    } finally {
      setLoading(false);
    }
  };

  async function handleCloseCancelarModal(sucesso: boolean) {
    setShowCancelarCupomFiscal(false);
    if (!sucesso) return;
    setVersaoDados(v => v + 1);
  }

  async function handleCloseCupomProximoNumeracaoFiscal(sucesso: boolean) {
    setShowProximoNumeroFiscal(false);
    if (!sucesso) return;
    setVersaoDados(v => v + 1);
  }

  async function handleAdicionarCliente() {
    if (!clienteId) {
      showToastErrors(['Preciso que escolha um cliente antes.']);
      return;
    }

    setLoading(true);

    try {
      await service.inserirClienteCupomFiscal(id, clienteId);
      setVersaoDados(v => v + 1);
    } catch (error: any) {
      showToastErrors(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleRemoverCliente() {
    try {
      setLoading(true);
      await service.deletarClienteCupomFiscal(id);
      setClienteId(null);
      setVersaoDados(v => v + 1);
    } catch (error: any) {
      showToastErrors(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <CupomCancelarModal cupomFiscalId={id} show={showCancelarCupomFiscal} onClose={handleCloseCancelarModal} />

      <DanfeNfceModal cupomId={id} isOpen={showDanfeModal} onToggle={setShowDanfeModal} />

      <CupomAlterarNumeracaoModal
        cupomFiscalId={id}
        serie={nfce?.serie ?? 0}
        numeroFiscal={nfce?.numeroFiscal ?? 0}
        show={showProximoNumeroFiscal}
        onClose={handleCloseCupomProximoNumeracaoFiscal}
      />

      <PageContent title="Fiscal" subTitle="Cupom Fiscal" isLoading={loading}>
        {!!nfce && (
          <>
            <Card>
              <CardBody>
                <Row className="mt-3" hidden={loading}>
                  <Col>
                    <div className="text-center">
                      <b>Situação do Cupom Fiscal</b>
                      {!!situacaoCupom && <SituacaoCupomTexto loading={loading} situacao={situacaoCupom} />}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="bg-transparent border-bottom text-uppercase">DADOS DO CUPOM FISCAL</CardHeader>
              <CardBody>
                <Row className="gy-3">
                  <Col xs={3} sm={3}>
                    <b>Data Emissão:</b>
                    <div>{toDateTimeFormat(nfce.dataEmissao)}</div>
                  </Col>

                  <Col xs={3} sm={2}>
                    <b>Série / Número</b>
                    <div>
                      {nfce.serie} / {nfce.numeroFiscal.toString().padStart(8, '0')}
                    </div>
                  </Col>

                  <Col xs={12} sm={5}>
                    <b>Chave Acesso:</b>
                    <div>{nfce.chaveAcesso}</div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </>
        )}

        <Card>
          <CardHeader className="bg-transparent border-bottom text-uppercase">Cliente / Destinatário</CardHeader>
          <CardBody>
            {!nfce?.cliente && situacaoCupom?.situacao === SituacaoCupom.Rejeitado && (
              <Row xs="1">
                <Col>
                  <Label>Escolha o cliente para adicionar ao Cupom Fiscal</Label>
                  <SelectCliente value={clienteId} onChange={setClienteId} isClearable={true} />
                </Col>

                <Col className="mt-2 d-flex justify-content-end">
                  <Button size="sm" color="primary" onClick={handleAdicionarCliente}>
                    Adicionar cliente
                  </Button>
                </Col>
              </Row>
            )}
            {!!nfce?.cliente && (
              <Row xs="1" className="gy-1 gy-sm-2">
                <Col sm="6">
                  <div>
                    <h5>{nfce.cliente.nome}</h5>
                  </div>
                  <div>CPF/CNPJ: {addCpfCnpjMask(nfce.cliente.cpfCnpj)}</div>
                  <div>IE: {!!nfce.cliente.inscricaoEstadual ? nfce.cliente.inscricaoEstadual : 'Não Informado'}</div>
                </Col>

                <Col sm="6">
                  <div>
                    <div>
                      <h5>{nfce.cliente.logradouro}</h5>
                    </div>
                    <div>Número: {nfce.cliente.numero}</div>
                    <div>Bairro: {nfce.cliente.bairro}</div>
                    <div className="text-nowrap">CEP: {nfce.cliente.cep}</div>
                    <div className="text-nowrap">Cidade/Estado: {nfce.cliente.cidadeUf}</div>
                  </div>
                </Col>
              </Row>
            )}
            {!!nfce?.cliente && situacaoCupom?.situacao === SituacaoCupom.Rejeitado && (
              <Row className="mt-3">
                <Col className="d-flex justify-content-end">
                  <button className="btn btn-link p-0" onClick={handleRemoverCliente}>
                    Clique aqui para remover o cliente
                  </button>
                </Col>
              </Row>
            )}
            {nfce?.cliente === null && situacaoCupom?.situacao === SituacaoCupom.Autorizado && (
              <div className="text-center">
                <b>Cliente não informado</b>
              </div>
            )}
          </CardBody>
        </Card>

        {!!nfce?.itens && !!situacaoCupom && (
          <div className="accordion accordion-flush">
            <CupomTabelaItem
              cupomId={nfce.id}
              itens={nfce.itens}
              podeEditar={podeEditar}
              onChanged={() => setVersaoDados(v => v + 1)}
            />
          </div>
        )}

        <Card>
          <CardHeader className="bg-transparent border-bottom text-uppercase">Totalizadores</CardHeader>
          <CardBody>
            <Row>
              <Col sm={3}>
                <Label>Total Produtos</Label>
                <InputPreco value={toMoneyFormat(nfce?.totalProdutos)} readOnly sm />
              </Col>
              <Col sm={3}>
                <Label>Total Desconto</Label>
                <InputPreco value={toMoneyFormat(nfce?.totalDesconto)} readOnly sm />
              </Col>
              <Col sm={3}>
                <Label>Total Frete</Label>
                <InputPreco value={toMoneyFormat(nfce?.totalFrete)} readOnly sm />
              </Col>
              <Col sm={3}>
                <Label>Total Cupom</Label>
                <InputPreco value={toMoneyFormat(nfce?.total)} readOnly sm />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </PageContent>

      <PageFooter>
        <div className="d-flex gap-3">
          {situacaoCupom?.situacao === SituacaoCupom.Rejeitado && (
            <Button size="sm" color="primary" onClick={() => handleAutorizarCupom()}>
              Autorizar Cupom
            </Button>
          )}

          {podeConfirmarSituacao && (
            <Button size="sm" color="success" onClick={() => handleConfirmarSituacao()}>
              Confirmar Situação
            </Button>
          )}

          {podeVisualizarDanfe && (
            <Button size="sm" color="info" onClick={() => setShowDanfeModal(true)}>
              Imprimir DANFE
            </Button>
          )}

          {podeCancelar && (
            <Button size="sm" color="danger" onClick={() => setShowCancelarCupomFiscal(true)}>
              Cancelar Cupom
            </Button>
          )}

          {situacaoCupom?.situacao === SituacaoCupom.Rejeitado && (
            <Button size="sm" color="warning" onClick={() => setShowProximoNumeroFiscal(true)}>
              Alterar Numeração
            </Button>
          )}
        </div>
      </PageFooter>
    </>
  );
}
