export const ROUTES = {
  HOME: '/',
  NOT_FOUND: '/nada-encontrado',

  LOGIN: '/login',
  LOGOUT: '/logout',

  RECUPERAR_SENHA: '/recuperar-senha',
  NOVA_SENHA: '/nova-senha/:token',
  NOVA_SENHA_SUCESSO: '/senha-alterada-sucesso',

  USUARIO_PERFIL: '/usuarios/meu-usuario',
  USUARIO_LISTAGEM: '/usuarios/listagem',

  EMPRESA_LISTAGEM: '/configuracoes/empresas',
  EMPRESA_ALTERAR: (id: string) => `/configuracoes/empresas/${id}`,
  EMPRESA_NOVA: `/configuracoes/nova-empresa`,
  REGRAS_GLOBAIS_CFOP: '/configuracoes/regras-globais-cfop',

  CERTIFICADO_LISTAGEM: `/configuracoes/certificados`,
  CERTIFICADO_ALTERAR: (id: string) => `/configuracoes/certificados/${id}`,
  CERTIFICADO_NOVO: `/configuracoes/novo-certificado`,

  EMITENTE_NOVO: `/configuracoes/novo-emitente`,
  EMITENTE_LISTAGEM: `/configuracoes/emitentes`,
  EMITENTE_EDITAR: (id: string) => `/configuracoes/emitentes/${id}`,

  PRODUTO_LISTAGEM: '/estoque/produtos',
  PRODUTO_ALTERAR: (id: string) => `/estoque/produtos/${id}`,
  PRODUTO_NOVO: '/estoque/novo-produto',
  AJUSTAR_ESTOQUE: '/estoque/ajustar-estoque',

  CLIENTE_LISTAGEM: '/clientes/listagem',
  CLIENTE_ALTERAR: (id: string) => `/clientes/${id}`,
  CLIENTE_NOVO: '/clientes/novo-cliente',

  VENDAS_LISTAGEM: '/vendas/listagem',
  VENDAS_VISUALIZAR: (id: string) => `/vendas/visualizar/${id}`,
  VENDAS_EDITAR: (id: string) => `/vendas/venda/${id}`,
  VENDAS_RELATORIO: '/vendas/relatorio',

  CUPOM_LISTAGEM: '/fiscal/cupons/listagem',
  CUPOM_ENVIAR_PACOTE_CONTADOR: '/fiscal/enviar-pacote-contador',
  CUPOM_ALTERAR: (id: string) => `/fiscal/cupons/${id}`,

  IMPORTACAO_DADOS: '/importacao-dados/listagem',

  NOTA_LISTAGEM: '/fiscal/notas/listagem',
  NOTA_ALTERAR: (id: string) => `/fiscal/notas/${id}`,
};
