import React from 'react';
import { Row } from 'reactstrap';
import CardUserWelcome from 'components/Dashboard/CardUserWelcome';
import { MiniWidget } from 'components/Dashboard/MiniWidget';
import { SalesEarning } from 'components/Dashboard/SalesEarning';
import { toMoneyFormat } from 'common/formatters';

// Import context
import { useDashboardContext } from './context';
import { size, sumBy, toString } from 'lodash';

export const Dashboard = () => {
  const { dadosPeriodo } = useDashboardContext();
  const quantidadeVendas = size(dadosPeriodo?.dados ?? []);
  const totalVendas = sumBy(dadosPeriodo?.dados, v => v.totalDaVenda);
  const ticketMedio = quantidadeVendas > 0 ? totalVendas / quantidadeVendas : 0;

  return (
    <React.Fragment>
      <Row>
        <CardUserWelcome />
      </Row>

      <Row xs="1" md="3">
        <MiniWidget iconClassName="bx bx-receipt" title="Vendas" value={toString(quantidadeVendas)} />
        <MiniWidget iconClassName="bx bx-money" title="Total Vendas" value={toMoneyFormat(totalVendas, true)} />
        <MiniWidget
          iconClassName="bx bx-purchase-tag-alt"
          title="Ticket Médio"
          value={toMoneyFormat(ticketMedio, true)}
        />
      </Row>

      <Row>
        <SalesEarning />
      </Row>
    </React.Fragment>
  );
};
