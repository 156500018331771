import { Dictionary } from 'lodash';
import React, { useContext } from 'react';

type RemotePageContextType = {
  storeConfig: (url: string, config: RemoteTableConfig) => void;
  getConfig: (url: string) => RemoteTableConfig;
  clear: () => void;
};

interface RemoteTableConfig {
  currentPage: number;
  pageSize: number;
  searchText: string | null;
}

const RemotePageTableContext = React.createContext<RemotePageContextType | null>(null);

type Props = {
  children: React.ReactNode;
};

export const RemotePageTableProvider = ({ children }: Props) => {
  const [tables, setTables] = React.useState<Dictionary<RemoteTableConfig>>({});

  const storeConfig = (url: string, config: RemoteTableConfig) => {
    const copyTables = { ...tables };
    copyTables[url] = config;

    setTables(copyTables);
  };

  const getConfig = (url: string) => {
    return (
      tables[url] ?? {
        currentPage: 1,
        pageSize: 8,
        searchText: null,
      }
    );
  };

  const clear = () => {
    setTables({});
  };

  return (
    <RemotePageTableContext.Provider value={{ storeConfig, getConfig, clear }}>
      {children}
    </RemotePageTableContext.Provider>
  );
};

export const useRemotePageTableContext = () => {
  const context = useContext(RemotePageTableContext);
  if (context === undefined || context === null) {
    throw new Error('useRemotePageTableContext must be used within a RemotePageTableContext');
  }

  return context;
};
